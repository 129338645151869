import React, { useState } from 'react'
import { ListingData, ListingStore } from '../../stores/ListingStore'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore } from '../../stores'
import { CButton, CTooltip } from '@coreui/react'
import { ConfirmationBoundaryModal } from '../custom'
import { canListingPublish, canListingUnpublish } from '../../lib/listing/utils'
import { publishIcon } from '../../assets/brand/publishIcon'
import { unpublishIcon } from '../../assets/brand/unpublishIcon'

type TProps = {
  listing: ListingData
  listingStore?: ListingStore
  notificationStore?: NotificationStore
  onAction: (data: ListingData) => void
  withText?: boolean
}
export const ListingPublishOrUnpublishActionButton = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isPublishPromptOpen, setIsPublishPromptOpen] = useState<boolean>(false)
    const [isPublishSuspending, setIsPublishSuspending] = useState<boolean>(false)
    const [isUnpublishPromptOpen, setIsUnpublishPromptOpen] = useState<boolean>(false)
    const [isUnpublishSuspending, setIsUnpublishSuspending] = useState<boolean>(false)

    const onPublishConfirm = () => {
      setIsPublishSuspending(true)
      props.listingStore
        ?.publishListing(props.listing.id)
        .then((data) => {
          setIsPublishSuspending(false)
          setIsPublishPromptOpen(false)
          props.notificationStore?.setNotificationMessage(
            'Successfully published listing.',
            'success',
            3000,
          )
          props.onAction(data)
        })
        .catch(() => {
          setIsPublishSuspending(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to publish listing. Please try again',
            'danger',
            3000,
          )
        })
    }

    const onUnpublishConfirm = () => {
      setIsUnpublishSuspending(true)
      props.listingStore
        ?.unpublishListing(props.listing.id)
        .then((data) => {
          setIsUnpublishSuspending(false)
          setIsUnpublishPromptOpen(false)
          props.notificationStore?.setNotificationMessage(
            'Successfully unpublished listing.',
            'success',
            3000,
          )
          props.onAction(data)
        })
        .catch(() => {
          setIsUnpublishSuspending(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to unpublish listing. Please try again',
            'danger',
            3000,
          )
        })
    }

    const renderPublishActionButton = () => {
      const isDisabled = !canListingPublish(props.listing)
      return (
        <>
          <CTooltip content={'Publish this listing'}>
            <CButton
              className="span-btn publish-action-btn"
              data-testid="listing-publish-btn"
              onClick={() => setIsPublishPromptOpen(true)}
              disabled={isDisabled}
            >
              {publishIcon()} {props.withText && <span>Publish</span>}
            </CButton>
          </CTooltip>
          <ConfirmationBoundaryModal
            onCancel={() => setIsPublishPromptOpen(false)}
            onConfirm={onPublishConfirm}
            isOpen={isPublishPromptOpen}
            headerText={'Are you sure you want to publish this listing?'}
            isSuspended={isPublishSuspending}
          >
            <p>
              Once published, this listing is visible to all DoD VISION users. You can unpublish
              this listing at any time.
            </p>
          </ConfirmationBoundaryModal>
        </>
      )
    }

    const renderUnpublishActionButton = () => {
      const isDisabled = !canListingUnpublish(props.listing)

      return (
        <>
          <CTooltip content={'Unpublish this listing'}>
            <CButton
              className="span-btn publish-action-btn"
              data-testid="listing-unpublish-btn"
              onClick={() => setIsUnpublishPromptOpen(true)}
              disabled={isDisabled}
            >
              {unpublishIcon()} {props.withText && <span>Unpublish</span>}
            </CButton>
          </CTooltip>
          <ConfirmationBoundaryModal
            onCancel={() => setIsUnpublishPromptOpen(false)}
            onConfirm={onUnpublishConfirm}
            isOpen={isUnpublishPromptOpen}
            headerText={'Are you sure you want to unpublish this listing?'}
            isSuspended={isUnpublishSuspending}
          >
            <p>
              By unpublishing this listing you will set this and all its content as hidden from DOD
              VISION users. You can publish this again at anytime so long as the listing payment
              status is in good standing.
            </p>
          </ConfirmationBoundaryModal>
        </>
      )
    }

    return props.listing.isPublished ? renderUnpublishActionButton() : renderPublishActionButton()
  }),
)
