import React from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CFormLabel, CRow } from '@coreui/react'
import {
  GoogleSuggestAddressInput,
  SingleImageUploader,
  SingleUserSelect,
  ValidatedCFormInput,
} from '../../../custom'
import { validateLength, validateWebsite, validateYear } from '../../../../lib/helpers/validation'
import { SocialMediaAccountsForm, SocialMediaFormData } from '../SocialMediaAccountsForm'
import { AccountStore, ApplicationStore, UserData } from '../../../../stores'
import { inject, observer } from 'mobx-react'
import { GoogleAddressData } from '../../../custom/GoogleSuggestAddressInput'

type OrganizationDetailsType = {
  value: Partial<ListingOrganizationFormData>
  onChange: (value: Partial<ListingOrganizationFormData>) => void
  accountStore?: AccountStore
}

export type ListingOrganizationFormData = {
  name: string
  tagLine: string
  logo: any
  yearFounded: string
  website: string
  headquarters: Partial<GoogleAddressData>
  socialMedia?: Array<SocialMediaFormData>
  pointOfContact?: UserData
}

export const OrganizationDetails = inject(
  ApplicationStore.names.accountStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: OrganizationDetailsType) => {
    return (
      <section className="OrganizationDetails">
        <CCard>
          <CCardHeader>Details</CCardHeader>
          <CCardBody>
            <CRow className="mb-2">
              <CFormLabel className="col-sm-3 colform-label">Title</CFormLabel>
              <CCol>
                <ValidatedCFormInput
                  required
                  validate={(v) => validateLength(v, 2)}
                  data-testid="organization-name-input"
                  placeholder="Product, service, or company name"
                  value={props.value.name || ''}
                  onChange={(value: string | undefined) => {
                    props.onChange({ ...props.value, name: value })
                  }}
                  renderInvalidMessage={() => 'Must be at least 2 characters'}
                />
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CFormLabel className="col-sm-3 colform-label">Logo</CFormLabel>
              <CCol sm={9}>
                <SingleImageUploader
                  required
                  value={
                    props.value.logo && !(props.value.logo instanceof File)
                      ? props.value.logo.path
                      : props.value.logo
                  }
                  onFile={(file) => props.onChange({ ...props.value, logo: file })}
                  data-testid={'listing-logo-uploader'}
                />
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CFormLabel className="col-sm-3">Tagline</CFormLabel>
              <CCol>
                <ValidatedCFormInput
                  required
                  validate={(v) => validateLength(v, 2)}
                  data-testid="organization-tagline-input"
                  placeholder="Tagline"
                  value={props.value.tagLine || ''}
                  onChange={(value: string | undefined) => {
                    props.onChange({ ...props.value, tagLine: value })
                  }}
                  renderInvalidMessage={() => 'Must be at least 2 characters'}
                />
              </CCol>
            </CRow>

            <CRow>
              <CFormLabel className="col-sm-3 colform-label">Headquarters</CFormLabel>
              <CCol>
                <GoogleSuggestAddressInput
                  onChange={(change) => props.onChange({ ...props.value, headquarters: change })}
                  value={props.value.headquarters || {}}
                  required
                  isEditForm={true}
                />
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CFormLabel className="col-sm-3 colform-label">Year Founded</CFormLabel>

              <CCol>
                <ValidatedCFormInput
                  required
                  type="number"
                  step="1"
                  validate={(v) => validateYear(v, 1700)}
                  data-testid="year-founded-input"
                  placeholder="Year Founded"
                  value={props.value.yearFounded || ''}
                  onChange={(value: string | undefined) => {
                    props.onChange({ ...props.value, yearFounded: value })
                  }}
                  renderInvalidMessage={() => 'Year should be between 1700 and the current year'}
                />
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CFormLabel className="col-sm-3 colform-label">Point of Contact</CFormLabel>
              <CCol sm={9}>
                <SingleUserSelect
                  value={props.value.pointOfContact}
                  onChange={(u) => {
                    props.onChange({ ...props.value, pointOfContact: u })
                  }}
                  getDataFunction={() => props.accountStore!.getDefaultAccountUsers()}
                />
              </CCol>
            </CRow>

            <CRow className="mb-2">
              <CFormLabel className="col-sm-3 colform-label">Website</CFormLabel>
              <CCol>
                <ValidatedCFormInput
                  required
                  validate={(v) => validateWebsite(v)}
                  data-testid="website-input"
                  placeholder="Website"
                  value={props.value.website || ''}
                  onChange={(value: string | undefined) => {
                    props.onChange({ ...props.value, website: value })
                  }}
                  renderInvalidMessage={() => 'Invalid website url'}
                />
              </CCol>
            </CRow>

            <CRow className="mb-2 align-items-center">
              <CCol className="mb-2">
                <span className="optional-wrapper">
                  <CFormLabel className="colform-label">
                    Social Media <span className="subtle">(Optional)</span>
                  </CFormLabel>
                </span>
              </CCol>
            </CRow>
            <CRow className="mb-2 align-items-start">
              <SocialMediaAccountsForm
                value={props.value.socialMedia || []}
                onChange={(change) => props.onChange({ ...props.value, socialMedia: change })}
              />
            </CRow>
          </CCardBody>
        </CCard>
      </section>
    )
  }),
)
