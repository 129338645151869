import { PropsWithChildren, useState } from 'react'
import classnames from 'classnames'

type TProps = {
  id?: string
  className?: string
  headerText?: string
  isExpanded?: boolean
  onExpandChange?: (value: boolean) => void
}

// Line 30 is Expanded and WasExpanded - explains the loss of state
export const Collapsible = (props: PropsWithChildren<TProps>) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    props.isExpanded == null ? true : props.isExpanded,
  )
  const chevronToggle = isExpanded ? 'up' : 'down'

  return (
    <div className={`collapsible ${props.className ? props.className : ''}`}>
      <div
        data-testid={`collapsible${props.className ? '-' + props.className : ''}`}
        className={classnames('collapsible-header', { isExpanded })}
        onClick={() => {
          setIsExpanded(!isExpanded)
          props.onExpandChange && props.onExpandChange(!isExpanded)
        }}
        tabIndex={0}
        onKeyDown={({ key: keyPress }) => {
          if (keyPress === 'Enter') {
            setIsExpanded(!isExpanded)
            props.onExpandChange && props.onExpandChange(!isExpanded)
          }
        }}
      >
        <h1>{props.headerText}</h1>
        <i
          className={`fas fa-chevron-${chevronToggle}`}
          title="Toggle collapsible content"
          role="img"
          data-testid="chevron-toggle"
        />
      </div>
      <div
        data-testid="collapsible-content"
        className={`collapsible-content ${isExpanded ? '' : 'd-none'}`}
      >
        {props.children}
      </div>
    </div>
  )
}
