import {
  CCollapse,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import React, { useState } from 'react'
import { TechnologyTypeStatistic } from '../../../../stores/AnalyticsStore'

type TProps = {
  data?: Array<TechnologyTypeStatistic>
}

export const AdminAnalyticsTechnologyTypeTable = (props: TProps) => {
  const [visible, setVisible] = useState(false)

  const tableRow = (rowData: TechnologyTypeStatistic) => {
    let percent = (_string: string) => {
      return (Number(_string) * 100).toString().substr(0, 2) + '%'
    }
    let listingPct = ' (' + percent(rowData.listingPct) + ')'
    let listingProductPct = ' (' + percent(rowData.listingProductPct) + ')'
    let listingServicePct = ' (' + percent(rowData.listingServicePct) + ')'
    let usersPct = ' (' + percent(rowData.usersPct) + ')'
    let visionUsersPct = ' (' + percent(rowData.visionUsersPct) + ')'

    return (
      <CTableRow key={rowData.name}>
        <CTableDataCell>{rowData.name}</CTableDataCell>
        <CTableDataCell data-testid="listing-count-container">
          <b>{rowData.listingCount}</b>
          {listingPct}
        </CTableDataCell>
        <CTableDataCell data-testid="listing-product-count-container">
          <b>{rowData.listingProductCount}</b>
          {listingProductPct}
        </CTableDataCell>
        <CTableDataCell data-testid="listing-service-count-container">
          <b>{rowData.listingServiceCount}</b>
          {listingServicePct}
        </CTableDataCell>
        <CTableDataCell data-testid="listing-users-count-container">
          <b>{rowData.usersCount}</b>
          {usersPct}
        </CTableDataCell>
        <CTableDataCell data-testid="listing-vision-users-count-container">
          <b>{rowData.visionUsersCount}</b>
          {visionUsersPct}
        </CTableDataCell>
      </CTableRow>
    )
  }

  const techTypeTable = () => {
    if (!visible) {
      return
    }
    return (
      <CTable
        bordered
        data-testid="admin-analytics-technology-type-table"
        id="admin-analytics-technology-type-table"
      >
        <CTableHead color="dark">
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-truncate">
              Technology Type
            </CTableHeaderCell>
            <CTableHeaderCell scope="col">Company Focuses</CTableHeaderCell>
            <CTableHeaderCell scope="col">Company Products</CTableHeaderCell>
            <CTableHeaderCell scope="col">Company Services</CTableHeaderCell>
            <CTableHeaderCell scope="col">Government Users</CTableHeaderCell>
            <CTableHeaderCell scope="col">Vision Users</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {props.data?.map((row) => {
            return tableRow(row)
          })}
        </CTableBody>
      </CTable>
    )
  }

  const tableToggleMessage = () => {
    if (visible) {
      return (
        <div>
          See Less Technology Types Data
          <i
            id="toggle-technology-type-angle-up"
            data-testid="toggle-technology-type-angle-up"
            className="fas fa-angle-up"
          />
        </div>
      )
    } else {
      return (
        <div>
          See All Technology Types Data
          <i
            id="toggle-technology-type-angle-down"
            data-testid="toggle-technology-type-angle-down"
            className="fas fa-angle-down"
          />
        </div>
      )
    }
  }

  return (
    <div id="technology-type-table-container">
      <br />
      <h4
        id="toggle-technology-type-table"
        data-testid="see-less-technology-type"
        onClick={() => {
          setVisible(!visible)
        }}
      >
        {tableToggleMessage()}
      </h4>
      <CCollapse
        id="see-less-technology-type-collapse"
        data-testid="see-less-technology-type-collapse"
        visible={visible}
      >
        {techTypeTable()}
      </CCollapse>
    </div>
  )
}

export default AdminAnalyticsTechnologyTypeTable
