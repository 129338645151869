import { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'

export interface ICustomDropdownItem {
  label: string
}

export interface CustomDropdownProps<T extends ICustomDropdownItem> {
  options: Array<string | T>
  placeholder: string
  selectedOption?: string | T
  onSelect: (selection: string | T) => void
  onSelectNone?: () => void
  ariaRequired?: boolean
  CustomDropdownClass?: string
  dropdownClass?: string
  dataTestId?: string
  disabled?: boolean
}

export const CustomDropdown = <T extends ICustomDropdownItem>(props: CustomDropdownProps<T>) => {
  const [isExpanded, toggleIsExpanded] = useState<boolean>(false)

  const itemRefs = useRef<Array<HTMLParagraphElement>>([])
  const dropdownRef = useRef<HTMLDivElement>(null)
  let node: any = useRef()

  const handleTargetOutside = (e: MouseEvent | FocusEvent) => {
    if (node.current.contains(e.target)) {
      return
    }
    toggleIsExpanded(false)
  }

  const handleEscape = ({ key: kypress }: KeyboardEvent) => {
    if (kypress === 'Escape') {
      toggleIsExpanded(false)
    }
  }

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener('mousedown', handleTargetOutside)
      document.addEventListener('focusin', handleTargetOutside)
      document.addEventListener('keydown', handleEscape)
    }
    return () => {
      document.removeEventListener('mousedown', handleTargetOutside)
      document.removeEventListener('focusin', handleTargetOutside)
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isExpanded])

  const isLabeled = (o: string | T | undefined): o is T => {
    return o != null ? typeof o !== 'string' : false
  }

  const getDisplayLabel = (o: string | T | undefined): string | T | undefined => {
    return isLabeled(o) ? o.label : o
  }

  return (
    <section className={`CustomDropdown ${props.CustomDropdownClass}`} ref={node}>
      <div
        className={classnames('dropdown-value', props.dropdownClass, props.disabled && 'disabled')}
        data-testid={props.dataTestId || `CustomDropdown-dropdown-value`}
        tabIndex={0}
        ref={dropdownRef}
        onClick={() => {
          if (props.disabled) return
          toggleIsExpanded(!isExpanded)
        }}
        aria-required={props.ariaRequired}
        role="listbox"
        aria-disabled={props.disabled}
        onKeyDown={(e) => {
          if (props.disabled) return
          let { key: keyPress } = e
          if (keyPress === 'Enter') {
            toggleIsExpanded(!isExpanded)
          } else if (keyPress === 'ArrowDown') {
            e.preventDefault()
            toggleIsExpanded(true)
            itemRefs.current.length && itemRefs.current[0].focus()
          }
        }}
      >
        <p data-testid={'CustomDropdown-value'}>
          {getDisplayLabel(props.selectedOption) || props.placeholder}
        </p>
        <div className="icon-container">
          <i className="fas fa-caret-up" />
          <i className="fas fa-caret-down" />
        </div>
      </div>

      {isExpanded && (
        <div
          data-testid="CustomDropdown-dropdown-menu"
          className={classnames('dropdown-menu', {
            isExpanded: isExpanded,
          })}
        >
          {props.onSelectNone && (
            <p
              data-testid="CustomDropdown-select-none"
              onClick={() => {
                toggleIsExpanded(false)
                props.onSelectNone && props.onSelectNone()
              }}
              className={'dropdown-item clickable select-none'}
              tabIndex={-1}
              ref={(element) => {
                if (element) {
                  itemRefs.current[0] = element
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  props.onSelectNone && props.onSelectNone()
                  toggleIsExpanded(false)
                  dropdownRef.current && dropdownRef.current.focus()
                } else if (e.key === 'ArrowDown') {
                  e.preventDefault()
                  itemRefs.current.length > 1 && itemRefs.current[1].focus()
                } else if (e.key === 'ArrowUp') {
                  e.preventDefault()
                  dropdownRef.current && dropdownRef.current.focus()
                }
              }}
            >
              Select None
            </p>
          )}
          {props.options.map((o, idx) => {
            return (
              <p
                key={idx}
                className={classnames('dropdown-item', {
                  isActive: o === props.selectedOption,
                })}
                tabIndex={-1}
                role="option"
                aria-selected={o === props.selectedOption}
                data-testid={`CustomDropdown-item-${idx}`}
                ref={(element) => {
                  if (element) {
                    itemRefs.current[props.onSelectNone ? idx + 1 : idx] = element
                  }
                }}
                onClick={() => {
                  toggleIsExpanded(false)
                  props.onSelect(o)
                }}
                onKeyDown={(e) => {
                  let { key: keyPress } = e
                  if (keyPress === 'Enter') {
                    toggleIsExpanded(false)
                    props.onSelect(o)
                  } else if (keyPress === 'ArrowDown') {
                    e.preventDefault()
                    let nextItemIndex = props.onSelectNone ? idx + 2 : idx + 1
                    nextItemIndex < itemRefs.current.length &&
                      itemRefs.current[nextItemIndex].focus()
                  } else if (keyPress === 'ArrowUp') {
                    e.preventDefault()
                    let previousItemIndex = props.onSelectNone ? idx : idx - 1
                    previousItemIndex > -1 && itemRefs.current[previousItemIndex].focus()
                  }
                }}
              >
                {getDisplayLabel(o)}
              </p>
            )
          })}
        </div>
      )}
    </section>
  )
}
