import { CCard, CCol, CRow } from '@coreui/react'
import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { AnalyticsStore, ApplicationStore, NotificationStore } from '../../../stores'
import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'

type TProps = {
  analyticsStore?: AnalyticsStore
  notificationStore?: NotificationStore
}

export const AdminAnalyticsResponseRateHeader = inject(
  ApplicationStore.names.analyticsStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [adminAnalyticsData, setAdminAnalyticsData] = useState<{
      govUserResponseRatePctg: number | string | null
      govUserEngagedCompanies: number
    }>()

    const convertResponseRate =
      adminAnalyticsData?.govUserResponseRatePctg &&
      (Number(adminAnalyticsData.govUserResponseRatePctg) * 100).toFixed(0)

    const getGovUserEngagedCompaines = () => {
      return props.analyticsStore!.getGovUserEngagedCompaniesStatistic()
    }

    const getGovUserChatResponseStats = () => {
      return props.analyticsStore!.getGovUserChatResponseStatistic()
    }

    const getHeaderResponse = async () => {
      setIsLoading(true)
      try {
        const res = await Promise.all([getGovUserChatResponseStats(), getGovUserEngagedCompaines()])
        setAdminAnalyticsData({
          govUserResponseRatePctg: res[0].value,
          govUserEngagedCompanies: res[1].count,
        })
      } catch (err) {
        setError(true)
        props.notificationStore?.setNotificationMessage(
          'Error retrieving response rate data. Please try again.',
          'danger',
          3000,
        )
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 300)
      }
    }

    useEffect(() => {
      getHeaderResponse()
    }, [])

    return (
      <CCard className="card-body py-4">
        <SuspensionBoundary data-testid="spinner" isLoading={isLoading}>
          {error ? (
            <div className="text-center">Error retrieving government user response rate data</div>
          ) : (
            <CRow className="analytics-response-rate-header">
              <CCol className="col-md-12 col-lg-6">
                <div className="d-flex flex-row justify-content-sm-start justify-content-md-center align-items-center gap-2 pt-2">
                  <h1 className="analytics-header-digits">{convertResponseRate}%</h1>
                  <div className="anaylytics-header-text d-flex">
                    <h3 className="mt-2 text-wrap">Average government user response rate</h3>
                  </div>
                </div>
              </CCol>
              <CCol className="col-md-12 col-lg-6">
                {' '}
                <div className="d-flex flex-row justify-content-sm-start justify-content-md-center align-items-center gap-2 pt-2">
                  <h1 className="analytics-header-digits">
                    {adminAnalyticsData?.govUserEngagedCompanies}
                  </h1>
                  <div className="anaylytics-header-text d-flex">
                    <h3 className="mt-2 text-wrap">
                      Companies engaged in chats with government users
                    </h3>
                  </div>
                </div>
              </CCol>
            </CRow>
          )}
        </SuspensionBoundary>
      </CCard>
    )
  }),
)
