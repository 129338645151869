import React, { useEffect, useState } from 'react'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, UserStore, AuthStore, NotificationStore } from '../stores'
import { ClaimRequestData, claimErrorDictionary } from '../stores/UserStore'
import { extractRestfulError } from '../lib/errors/utils'
import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import { SupportLinks } from '../components/custom'
import { CCard, CCardBody, CCardFooter, CCol, CContainer, CRow } from '@coreui/react'
import { Redirect } from 'react-router-dom'
import { ResendClaimForm } from '../components/form'

interface ConfirmClaimLandingProps {
  userStore?: UserStore
  authStore?: AuthStore
  notificationStore?: NotificationStore
}

export const ConfirmClaimLanding = inject(
  ApplicationStore.names.authStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.userStore,
  'applicationStore',
)(
  observer((props: ConfirmClaimLandingProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [claimRequest, setClaimRequest] = useState<ClaimRequestData | undefined>(undefined)
    const [error, setError] = useState<string | undefined>(undefined)
    const [params] = useQueryParams({
      claimToken: withDefault(StringParam, undefined),
    })

    useEffect(() => {
      setIsLoading(true)

      if (params.claimToken) {
        props.userStore
          ?.checkClaim(params.claimToken)
          .then((claimRequest) => {
            setClaimRequest(claimRequest)
          })
          .catch((error) => {
            let errorMessage = extractRestfulError(error)
            if (errorMessage?.error && claimErrorDictionary.hasOwnProperty(errorMessage.error)) {
              setError(errorMessage.error)
            } else {
              setError('UnknownError')
            }
          })
          .finally(() => setIsLoading(false))
      } else {
        setIsLoading(false)
        setError('InvalidClaimToken')
      }
    }, [])

    const renderFailure = () => {
      if (error === 'ClaimPeriodExpired') {
        return (
          <>
            <CRow className="mb-2 justify-content-md-center">
              <CCol>
                <h2 className="text-center">Claim Request Failed</h2>
              </CCol>
            </CRow>
            <CRow className="mb-2 justify-content-md-center">
              <CCol className="text-center">
                <p>
                  The claim token provided is expired. Please click resend if you would like us to
                  resend a claim email. If already have an account, you may go to{' '}
                  <a href="/">sign in</a>.
                </p>
              </CCol>
            </CRow>
            <ResendClaimForm formData={{ claimToken: params.claimToken! }} />
          </>
        )
      }
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <h2 className="text-center">Failed to Confirm Claim Request</h2>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <p className="text-center">{error && claimErrorDictionary[error]}</p>
            </CCol>
          </CRow>

          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <p className="text-center">
                If you already have an account, you may go to our <a href="/">sign in</a> page and
                log in there.
              </p>
            </CCol>
          </CRow>
        </>
      )
    }

    if (!params.claimToken) {
      return <Redirect to={'/'} />
    }

    return (
      <section className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard>
                <CCardBody className="p-4">
                  <SuspensionBoundary isLoading={isLoading}>
                    {error && renderFailure()}
                    {!error && !!claimRequest && (
                      <Redirect
                        to={`/claim_signup?claimToken=${params.claimToken}&firstName=${claimRequest.firstName}&lastName=${claimRequest.lastName}`}
                      />
                    )}
                  </SuspensionBoundary>
                </CCardBody>
                <CCardFooter className="bg-white">
                  <CRow className="justify-content-center">
                    <CCol>
                      <SupportLinks />
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    )
  }),
)
