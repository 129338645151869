import {
  isDirtySelectableItem,
  SelectableItem,
  ServerBackedMultiSelectAutoComplete,
  PaginationParams,
} from '@mobilizeyourtech/vision-core-react'
import { useEffect, useState } from 'react'
import { initializeSearchDropdown } from '../custom'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore } from '../../stores'
import { ServerStore, TechnologyTypeResponse } from '../../stores/ServerStore'

export interface UrlFederatedIgniteTechnologyTypesMultiSelectAutoCompleteProps {
  paramKey:
    | 'listingTechnologyTypeIds'
    | 'productTechnologyTypeIds'
    | 'serviceTechnologyTypeIds'
    | 'awardTechnologyTypeIds'
  dataTestId?: string
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}

export const UrlFederatedTechnologyTypesMultiSelectAutoComplete = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: UrlFederatedIgniteTechnologyTypesMultiSelectAutoCompleteProps) => {
    const [params, setParams] = useQueryParams({
      [props.paramKey]: withDefault(StringParam, undefined),
    })
    const [selectedTechnologyTypes, setSelectedTechnologyTypes] = useState<
      Array<TechnologyTypeResponse>
    >([])

    useEffect(() => {
      if (params[props.paramKey] == null || params[props.paramKey]?.split(',').length === 0) {
        setSelectedTechnologyTypes([])
      } else {
        const currentIds = selectedTechnologyTypes
          .map((item) => item.id.toString())
          .sort()
          .join(',')
        const paramIds = params[props.paramKey]!.split(',').sort().join(',')

        if (currentIds !== paramIds) {
          initializeSearchDropdown(
            {
              ids: params[props.paramKey],
              getDropdownData: getTechnologyTypes,
              setSelected: setSelectedTechnologyTypes,
              typeForErrorMessage: 'technology types',
            },
            props.notificationStore!,
          )
        }
      }
    }, [params[props.paramKey]])

    // Technology Types
    const getTechnologyTypes = (p: PaginationParams & { ids?: string }) => {
      return props.serverStore!.getTechnologyTypes(p).then((response) => response)
    }

    const selectTechnologyTypes = (selected: Array<TechnologyTypeResponse & SelectableItem>) => {
      setSelectedTechnologyTypes(selected)
      setParams({
        ...params,
        [props.paramKey]: selected.length === 0 ? undefined : selected.map((e) => e.id).join(','),
      })
    }

    const getSelectedTechnologyTypes = () => {
      return selectedTechnologyTypes.map((t) => {
        return { ...t, label: t.name }
      })
    }

    return (
      <ServerBackedMultiSelectAutoComplete
        data-testid={props.dataTestId ? props.dataTestId : 'tech'}
        loadMore={getTechnologyTypes}
        selections={getSelectedTechnologyTypes()}
        // DirtySelectableItems are only possible when allowDirty === true
        onChange={(selected) => {
          let _selected = selected.filter((e) => !isDirtySelectableItem(e)) as Array<
            TechnologyTypeResponse & SelectableItem
          >
          selectTechnologyTypes(_selected)
        }}
        placeholder={'Select Technology Types'}
        reloadOnOpen
      />
    )
  }),
)
