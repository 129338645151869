import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { titleCase } from '@mobilizeyourtech/vision-core-react'
import { PieChart, Pie, Cell, Legend } from 'recharts'

import { IgnitePieChartPlaceHolder } from './IgnitePieChartPlaceholder'
import { PieChartCellDataRecord } from '../../stores/AnalyticsStore'

type TProps = {
  data?: PieChartCellDataRecord[]
  name: string
}

const colors = [
  '#0070D8',
  '#455766',
  '#FFB742',
  '#89E2FD',
  '#00AC7E',
  '#f0483e',
  '#960700',
  '#01b189',
  '#008566',
  '#973A85',
]

const style = {
  top: '50%',
  transform: 'translate(40%, -30%)',
  lineHeight: '30px',
}

let filterEmptyItems = (array?: PieChartCellDataRecord[]) => {
  return array?.slice().filter(function (a) {
    return a.value > 0
  })
}
export const isGraphEmpty = (data: PieChartCellDataRecord[] | undefined) => {
  let noArray = data === undefined
  let noItemsInArray = data && data?.length === 0
  let noValuesInArrayItems = data && data?.length > 0 && data.every((entry) => entry.value === 0)

  return noArray || noItemsInArray || noValuesInArrayItems
}

export const IgnitePieChart = inject()(
  observer((props: TProps) => {
    const [data, setData] = useState<Array<PieChartCellDataRecord> | undefined>(props.data || [])

    useEffect(() => {
      setData(props.data)
    }, [props.data])

    const formatLegend = (value: string, entry: any) => {
      return (
        <span
          tabIndex={0}
          className="text-truncate legend-item"
          data-testid={`${entry.payload.testingId}`}
        >
          {`${titleCase(value)}`}
        </span>
      )
    }

    useEffect(() => {}, [window.innerWidth])

    const Chart = (props: TProps) => {
      let filteredData = filterEmptyItems(props.data)
      return (
        <div data-testid={props.name}>
          <PieChart height={160} width={290}>
            <Pie
              dataKey="value"
              startAngle={90}
              endAngle={-270}
              data={filteredData}
              cy="50%"
              cx="75%"
              outerRadius={80}
              innerRadius={60}
              nameKey={'name'}
              paddingAngle={3}
            >
              {filteredData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
            <Legend
              iconType={'circle'}
              iconSize={20}
              layout="vertical"
              verticalAlign="middle"
              align={'right'}
              wrapperStyle={style}
              formatter={formatLegend}
            />
          </PieChart>
        </div>
      )
    }

    return (
      <div className="pie-chart-container">
        {isGraphEmpty(data) && <IgnitePieChartPlaceHolder {...props} />}
        {!isGraphEmpty(data) && <Chart {...props} />}
      </div>
    )
  }),
)

export default IgnitePieChart
