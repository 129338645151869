import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ListingStore, NotificationStore } from '../../stores'
import { ListingAwardData } from '../../stores/ListingStore'
import { useRouteMatch } from 'react-router-dom'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { Pagination } from '../custom'
import { PaginationData } from '../../lib/types/Params'
import { CRow, CCard, CButton, CCardBody, CContainer } from '@coreui/react'
import { RichTooltip, SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import { ListingAwardCard } from './ListingAwardCard'
import ViewListingArtifactModal from './ViewListingArtifactModal'
import { ConnectAwardSearch } from '../Organisms/SearchFilters'
import { UrlFederatedBasicDropdown } from '../Molecules'

type TProps = {
  listingStore?: ListingStore
  notificationStore?: NotificationStore
}

interface MatchParams {
  id: string
}

export const ListingAwardsTab = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [pagination, setPagination] = useState<PaginationData | undefined>(undefined)
    const [paginationParams] = useQueryParams({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 10),
    })
    const {
      params: { id },
    } = useRouteMatch<MatchParams>()

    const [, setArtifactParams] = useQueryParams({
      artifactId: withDefault(StringParam, undefined),
      artifactType: withDefault(StringParam, undefined),
    })

    const [params, setParams] = useQueryParams({
      searchAwards: withDefault(StringParam, undefined),
      awardPhase: withDefault(StringParam, undefined),
      awardNaicsCodeIds: withDefault(StringParam, undefined),
      awardContractNumber: withDefault(StringParam, undefined),
      awardAmountMin: withDefault(NumberParam, undefined),
      awardAmountMax: withDefault(NumberParam, undefined),
      awardDateMin: withDefault(StringParam, undefined),
      awardDateMax: withDefault(StringParam, undefined),
      awardState: withDefault(StringParam, undefined),
      awardTechnologyTypeIds: withDefault(StringParam, undefined),
      sortBy: withDefault(StringParam, 'createdAt'),
      sortDirection: withDefault(StringParam, 'desc'),
      highlight: withDefault(StringParam, 'true'),
      eager: withDefault(StringParam, undefined),
    })

    const [awards, setAwards] = useState<Array<ListingAwardData>>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>(undefined)

    const loadData = () => {
      let isPromiseStale = false

      setIsLoading(true)
      props.listingStore
        ?.getListingAwards({
          listingId: id,
          params: { ...params, ...paginationParams },
        })
        .then((response) => {
          if (!isPromiseStale) {
            setAwards(response.data)
            setPagination(response.pagination)
          }
        })
        .catch((error) => {
          if (!isPromiseStale) {
            setError(error)
            props.notificationStore?.setNotificationMessage('Error loading awards', 'error', 3000)
          }
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 300)
          return () => {
            isPromiseStale = true
          }
        })
    }

    useEffect(() => {
      loadData()
    }, [paginationParams, params])

    const resetSearch = () => {
      setParams({
        searchAwards: undefined,
        awardPhase: undefined,
        awardNaicsCodeIds: undefined,
        awardContractNumber: undefined,
        awardAmountMin: undefined,
        awardAmountMax: undefined,
        awardDateMin: undefined,
        awardDateMax: undefined,
        awardState: undefined,
        awardTechnologyTypeIds: undefined,
        sortBy: undefined,
        sortDirection: undefined,
        highlight: 'true',
      })
    }

    const renderProductContent = () => {
      if (error) {
        return (
          <CCard className="no-products">
            <CCardBody
              className={'subtle d-flex text-center align-items-center justify-content-center'}
            >
              Error loading Awards - please reload
            </CCardBody>
          </CCard>
        )
      } else if (awards.length > 0) {
        return awards.map((award) => {
          return (
            <ListingAwardCard
              award={award}
              key={award.id}
              handleParamsChange={(id: string | number) =>
                setArtifactParams({ artifactId: id as string, artifactType: 'award' })
              }
            />
          )
        })
      } else if (pagination && pagination.count === 0) {
        return (
          <CCard className="no-awards">
            <CCardBody
              className={'subtle d-flex text-center align-items-center justify-content-center'}
            >
              There are no awards to display
            </CCardBody>
          </CCard>
        )
      }
    }

    return (
      <section className="ListingAwardsTab px-3" data-testid={'listing-awards-tab'}>
        <ViewListingArtifactModal
          handleEditClick={(id) => {
            setArtifactParams({ artifactType: undefined, artifactId: undefined })
          }}
        />
        <div className="listing-awards-tab-header">
          <h1 className="ms-2">Awards</h1>
          <RichTooltip
            aria-label={'alabel'}
            tooltipContent={
              <span className="awards-tooltip">
                Award data is populated from third party sources, so you may experience some latency
                based on when an award is granted to when it is entered into those third party
                sources.
              </span>
            }
            location="top"
            timeout={300}
          >
            <i className="fas fa-info-circle" />
          </RichTooltip>
        </div>
        <CCard>
          <CCardBody className="gap-2">
            <div className="d-flex flex-row justify-content-end align-items-center mb-3">
              <CButton data-testid="reset-btn" onClick={() => resetSearch()}>
                <i className="fas fa-redo" />
                Reset
              </CButton>
            </div>
            <ConnectAwardSearch isStandaloneAwardFilter />
          </CCardBody>
        </CCard>
        <CRow className="p-0">
          <div className="row w-100 my-2">
            <div id="sort-by-dropdown" className="col-3 d-flex flex-column">
              <label className="input-label fw-bold">Sort By</label>
              <UrlFederatedBasicDropdown
                options={[
                  { label: 'Created At', urlValue: 'createdAt' },
                  { label: 'Updated At', urlValue: 'updatedAt' },
                ]}
                data-testid="sort-by"
                paramKey={'sortBy'}
                defaultParam={params['sortBy']}
                placeholder="Select an option"
              />
            </div>
            <div id="order-by-dropdown" className="col-3 d-flex flex-column">
              <label className="input-label ms-1 fw-bold">Sort Order</label>
              <UrlFederatedBasicDropdown
                options={[
                  { label: 'Ascending', urlValue: 'asc' },
                  { label: 'Descending', urlValue: 'desc' },
                ]}
                data-testid="order-by"
                paramKey={'sortDirection'}
                defaultParam={params['sortDirection']}
                placeholder="Select an option"
              />
            </div>
          </div>
        </CRow>
        <SuspensionBoundary isLoading={isLoading}>
          <CContainer>
            <CRow className="listing-awards" xxl={{ cols: 3 }}>
              {renderProductContent()}
            </CRow>
          </CContainer>
        </SuspensionBoundary>
        <div className="pagination-container">
          {pagination && <Pagination pagination={pagination} />}
        </div>
      </section>
    )
  }),
)
