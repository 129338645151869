import React from 'react'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'
import {
  CustomDropdown,
  CustomDropdownProps,
  ICustomDropdownItem,
} from '../../../custom/CustomDropdown'
import classnames from 'classnames'

type UrlFederatedBasicDropdownItem = {
  label: string
  urlValue: string
}

export interface UrlFederatedDropdownProps<T extends ICustomDropdownItem>
  extends Omit<CustomDropdownProps<T>, 'onSelect' | 'onSelectNone' | 'options'> {
  paramKey: string
  options: Array<UrlFederatedBasicDropdownItem>
  defaultParam?: string
  'data-testid'?: string
}

export const UrlFederatedBasicDropdown = <T extends ICustomDropdownItem>({
  paramKey,
  options,
  defaultParam,
  'data-testid': dataTestId = 'federated-dropdown',
  ...props
}: UrlFederatedDropdownProps<T>) => {
  const [params, setParams] = useQueryParams({
    [paramKey]: withDefault(StringParam, defaultParam ?? undefined),
  })

  const handleSelect = (selection: string | ICustomDropdownItem) => {
    const urlVal = options.find((o) => o.label === selection)?.urlValue

    return setParams({
      [paramKey]: urlVal,
    })
  }

  return (
    <div data-testid={dataTestId} className="url-federated-dropdown w-100">
      <CustomDropdown
        {...props}
        onSelect={handleSelect}
        CustomDropdownClass={classnames('UrlFederatedBasicDropdown', props.CustomDropdownClass)}
        options={options.map((o) => o.label)}
        selectedOption={options.find((o) => o.urlValue === params[paramKey])?.label}
      />
    </div>
  )
}

export default UrlFederatedBasicDropdown
