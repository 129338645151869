import React, { FormEvent, useState } from 'react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { GovUserExtendedDataOrganizationForm, SignupUserData, SignupUserForm } from './fragments'
import { CButton, CCol, CForm, CRow } from '@coreui/react'
import { getTimezoneOptions, parseTimezone } from '../../lib/time'
import { validateLength } from '../../lib/helpers/validation'
import { hasGovRole } from '../../stores/UserStore'
import { GovUserExtendedDataFormData } from './fragments/types'
import { GovUserExtendedDataProfileForm } from './fragments/GovUserExtendedDataProfileForm'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  onSubmitted?: () => void
}

export const EditProfileForm = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer(({ userStore, ...props }: TProps) => {
    const isGovUser = hasGovRole(userStore?.currentUserData!)

    const [formData, setFormData] = useState<Partial<SignupUserData>>({
      firstName: userStore?.currentUserData?.firstName,
      lastName: userStore?.currentUserData?.lastName,
      title: userStore?.currentUserData?.title,
      cellPhone: userStore?.currentUserData?.cellPhone,
      workPhone: userStore?.currentUserData?.workPhone,
      workPhoneExt: userStore?.currentUserData?.workPhoneExt,
      timezone: parseTimezone(userStore!.currentUserData!.timezone, getTimezoneOptions('original')),
      headshot: userStore?.currentUserData?.headshot?.path,
      socialMedia: userStore?.currentUserData?.socialMediaAccounts,
    })
    const [govUserExtendedFormData, setGovUserExtendedFormData] = useState<
      Partial<GovUserExtendedDataFormData> | undefined
    >(
      isGovUser
        ? {
            govUserOrganization:
              userStore!.currentUserData!.govUserExtendedData!.govUserOrganization,
            govUserProgramOffice:
              userStore!.currentUserData!.govUserExtendedData!.govUserProgramOffice,
            technologyTypes: userStore?.currentUserData?.technologyTypes?.map((r) => {
              return { ...r, label: r.name, destroyable: true }
            }),
            profileDetail: userStore?.currentUserData?.govUserExtendedData?.profileDetail,
            interactionDetail: userStore?.currentUserData?.govUserExtendedData?.interactionDetail,
            linesOfEffort:
              !userStore?.currentUserData?.govUserExtendedData?.linesOfEffort ||
              userStore?.currentUserData?.govUserExtendedData?.linesOfEffort.length === 0
                ? [{ details: '' }]
                : userStore.currentUserData.govUserExtendedData.linesOfEffort,
          }
        : undefined,
    )
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setHasValidated(true)
      setIsSubmitting(true)

      const govUserExtendedDataAttributes = isGovUser
        ? {
            govUserOrganizationId: govUserExtendedFormData!.govUserOrganization!.id,
            govUserProgramOfficeId: govUserExtendedFormData!.govUserProgramOffice?.id,
            profileDetail: govUserExtendedFormData!.profileDetail,
            interactionDetail: govUserExtendedFormData!.interactionDetail,
            linesOfEffort: govUserExtendedFormData!.linesOfEffort!,
          }
        : undefined

      userStore
        ?.updateUser({
          firstName: formData.firstName!,
          lastName: formData.lastName!,
          title: formData.title!,
          cellPhone: isGovUser ? undefined : formData.cellPhone,
          workPhone: isGovUser ? undefined : formData.workPhone,
          workPhoneExt: isGovUser ? undefined : formData.workPhoneExt,
          timezone:
            typeof formData.timezone === 'string' ? formData.timezone : formData.timezone!.value,
          headshot: formData.headshot instanceof File ? formData.headshot : undefined,
          // TODO destroy semantics,
          socialMedia: isGovUser
            ? undefined
            : formData.socialMedia?.map((sm) => {
                return {
                  ...sm,
                  destroy: (sm as any).id && !sm.identifier ? true : undefined,
                }
              }),
          govUserExtendedDataAttributes,
          technologyTypes: isGovUser ? govUserExtendedFormData!.technologyTypes : undefined,
        })
        .then(() => {
          setTimeout(() => {
            // auth store salt to reload data
            setIsSubmitting(false)
            props.authStore?.generateAndSetSalt()
            props.notificationStore?.setNotificationMessage(
              'Profile updated successfully.',
              'success',
              3000,
            )
            props.onSubmitted && props.onSubmitted()
          }, 500)
        })
        .catch(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to update profile, please try again.',
            'danger',
            3000,
          )
        })
    }

    const isSubmittable = (): boolean => {
      let errors = [
        validateLength(formData.firstName, 2),
        validateLength(formData.lastName, 2),
        validateLength(formData.title, 2),
      ]

      if (isGovUser) {
        if (!govUserExtendedFormData?.govUserOrganization?.id) {
          return false
        }

        if (
          !govUserExtendedFormData?.technologyTypes ||
          govUserExtendedFormData.technologyTypes.length < 1 ||
          govUserExtendedFormData.technologyTypes.every((tt) => tt.destroy) ||
          !govUserExtendedFormData?.linesOfEffort ||
          govUserExtendedFormData.linesOfEffort.length < 1 ||
          govUserExtendedFormData.linesOfEffort.every(
            (loe) => loe.destroy || loe.details.trim() === '',
          )
        ) {
          return false
        }

        errors.push(
          validateLength(govUserExtendedFormData?.profileDetail?.replace(/<[^>]*>/g, ''), 2),
        )

        errors.push(
          validateLength(govUserExtendedFormData?.interactionDetail?.replace(/<[^>]*>/g, ''), 2),
        )
      } else {
        errors.push(
          // TODO: Validate phone
          validateLength(formData.cellPhone, 2),
        )

        formData.socialMedia &&
          formData.socialMedia.forEach((socialAccount) => {
            if (socialAccount.identifier.length === 1) {
              errors.push(validateLength(socialAccount.identifier, 2))
            }
          })
      }

      if (errors.some((err) => !!err)) {
        return false
      }

      return [formData.timezone].every((e) => !!e)
    }

    return (
      <section className="EditProfileForm" data-testid="edit-profile-form">
        <CForm
          onSubmit={onSubmit}
          validated={hasValidated}
          className="needs-validation"
          {...{ noValidate: true }}
        >
          {isGovUser && (
            <CRow>
              <CCol>
                <GovUserExtendedDataOrganizationForm
                  value={govUserExtendedFormData!}
                  onChange={(value) => {
                    setGovUserExtendedFormData((prev) => ({
                      ...prev,
                      ...value,
                    }))
                  }}
                />
              </CCol>
            </CRow>
          )}
          <SignupUserForm
            value={formData}
            onChange={(v) => {
              setFormData((prev) => ({ ...prev, ...v }))
            }}
            isGovUser={isGovUser}
          />
          {isGovUser && (
            <CRow>
              <CCol>
                <GovUserExtendedDataProfileForm
                  value={govUserExtendedFormData!}
                  onChange={(value) => {
                    setGovUserExtendedFormData((prev) => ({
                      ...prev,
                      ...value,
                    }))
                  }}
                />
              </CCol>
            </CRow>
          )}
          <CRow className="action-row justify-content-end">
            <CCol sm={3} className="text-end">
              <CButton
                type="submit"
                data-testid="save-button"
                disabled={!isSubmittable() || isSubmitting}
              >
                {isSubmitting ? (
                  <i data-testid="suspense-spinner" className="fa fa-spinner fa-spin" />
                ) : (
                  <i className="fas fa-save" />
                )}
                Save
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </section>
    )
  }),
)
