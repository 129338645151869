import { ActiveConversationStatistic } from '../../../../stores/AnalyticsStore'
import { IgniteLineChart } from '../../../lineChart/IgniteLineChart'

type TProp = {
  data?: ActiveConversationStatistic[]
  width?: number
}
export const ActiveConversationStatisticLineChart = (props: TProp) => {
  let chartLines = [
    { displayName: 'Active Conversations', dataKey: 'activeConversations', color: '#f0483e' },
    { displayName: 'Messages Sent', dataKey: 'messagesSent', color: '#0070D8' },
  ]

  return (
    <div
      data-testid="active-conversations-statistic-line-chart"
      id="active-conversations-statistic-line-chart"
    >
      <IgniteLineChart
        data={props.data}
        chartLines={chartLines}
        xAxis="month"
        width={props.width}
        chartId="sbc-timeline-line-chart"
      />
    </div>
  )
}
