import { makeAutoObservable } from 'mobx'
import {
  ServerStore,
  AuthStore,
  UserStore,
  NotificationStore,
  ListingStore,
  ConnectListingStore,
} from './'
import { AccountStore } from './AccountStore'
import { AnalyticsStore } from './AnalyticsStore'

export class ApplicationStore {
  public static names = {
    serverStore: 'serverStore',
    authStore: 'authStore',
    userStore: 'userStore',
    notificationStore: 'notificationStore',
    listingStore: 'listingStore',
    accountStore: 'accountStore',
    connectListingStore: 'connectListingStore',
    analyticsStore: 'analyticsStore',
  }
  public readonly serverStore
  public readonly authStore
  public readonly userStore
  public readonly notificationStore
  public readonly listingStore
  public readonly accountStore: AccountStore
  public readonly connectListingStore
  public readonly analyticsStore

  public sidebarShow = true
  public sidebarUnfoldable = false
  public siteSettings: { [key: string]: any } | undefined = undefined

  constructor() {
    makeAutoObservable(this)
    this.authStore = new AuthStore()
    this.notificationStore = new NotificationStore()
    this.listingStore = new ListingStore(this.authStore, this.notificationStore)
    this.serverStore = new ServerStore(this.authStore, this.notificationStore)
    this.userStore = new UserStore(this.authStore, this.notificationStore)
    this.accountStore = new AccountStore(this.authStore, this.notificationStore)
    this.connectListingStore = new ConnectListingStore(this.authStore, this.notificationStore)
    this.analyticsStore = new AnalyticsStore(this.authStore, this.notificationStore)
  }

  getStores = () => {
    return {
      [ApplicationStore.names.serverStore]: this.serverStore,
      [ApplicationStore.names.authStore]: this.authStore,
      [ApplicationStore.names.userStore]: this.userStore,
      [ApplicationStore.names.notificationStore]: this.notificationStore,
      [ApplicationStore.names.listingStore]: this.listingStore,
      [ApplicationStore.names.accountStore]: this.accountStore,
      [ApplicationStore.names.connectListingStore]: this.connectListingStore,
      [ApplicationStore.names.analyticsStore]: this.analyticsStore,
    }
  }

  public toggleSidebarShow() {
    this.setSidebarShow(!this.sidebarShow)
  }

  public setSidebarShow(val: boolean) {
    this.sidebarShow = val
  }

  public toggleSidebarUnfoldable() {
    this.setSidebarUnfoldable(!this.sidebarUnfoldable)
  }

  public setSidebarUnfoldable(val: boolean) {
    this.sidebarUnfoldable = val
  }

  public getSiteSettings(): Promise<{ [key: string]: any }> {
    return this.serverStore
      .server()
      .get('/api/v1/site_settings')
      .then(({ data }) => data)
  }

  public setSiteSettings(siteSettings: { [key: string]: any }) {
    this.siteSettings = siteSettings
  }

  public checkSignupUserEmail(email: string): Promise<{ value: boolean }> {
    return this.serverStore
      .server()
      .get('/api/v1/gov/check_email', { params: { email } })
      .then(({ data }) => data)
  }
}
