import React from 'react'

export const SupportLinks = () => {
  return (
    <div className="LegalLinks">
      <span>
        <a href="https://mobilizeyourtech.com/docs" target="_blank" rel="noreferrer">
          Support
        </a>
      </span>
    </div>
  )
}
