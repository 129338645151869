import { inject, observer } from 'mobx-react'
import { StringParam, NumberParam, useQueryParams, withDefault } from 'use-query-params'
import { CustomDropdown, UrlFederatedTechnologyTypesMultiSelectAutoComplete } from '../../../custom'
import { DebouncedInput } from '@mobilizeyourtech/vision-core-react'
import { CCol, CFormInput } from '@coreui/react'
import DatePicker from 'react-date-picker'
import { ServerBackedDropdown, UrlFederatedNaicsCodeMultiSelectAutoComplete } from '../../../custom'
import {
  ApplicationStore,
  NotificationStore,
  ConnectListingStore,
  ServerStore,
} from '../../../../stores'
import { PaginationParams } from '@mobilizeyourtech/vision-core-react'
import { makeDateComputerOffset } from '../../../../lib/time'
import { getISOLocalDate } from '@wojtekmaj/date-utils'
import { isoUSStateCodes } from '../../../../lib/geography/countryRegionData'
import classNames from 'classnames'

interface ConnectAwardSearchProps {
  connectListingStore?: ConnectListingStore
  notificationStore?: NotificationStore
  serverStore?: ServerStore
  isStandaloneAwardFilter?: boolean
}

export const ConnectAwardSearch = inject(
  ApplicationStore.names.connectListingStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.serverStore,
)(
  observer((props: ConnectAwardSearchProps) => {
    const [params, setParams] = useQueryParams({
      searchAwards: withDefault(StringParam, undefined),
      awardPhase: withDefault(StringParam, undefined),
      awardNaicsCodeIds: withDefault(StringParam, undefined),
      awardContractNumber: withDefault(StringParam, undefined),
      awardAmountMin: withDefault(NumberParam, undefined),
      awardAmountMax: withDefault(NumberParam, undefined),
      awardDateMin: withDefault(StringParam, undefined),
      awardDateMax: withDefault(StringParam, undefined),
      awardState: withDefault(StringParam, undefined),
      awardTechnologyTypeIds: withDefault(StringParam, undefined),
      sortBy: withDefault(StringParam, 'createdAt'),
      sortDirection: withDefault(StringParam, 'desc'),
    })

    const getAwardsPhases = async (params: PaginationParams) => {
      return props!.serverStore!.getAwardsPhases(params).then((response) => {
        return {
          data: response.data.map((data) => {
            return { ...data, id: data.label }
          }),
          pagination: response.pagination,
        }
      })
    }

    const renderMinMaxAmounts = () => {
      const minValue = params.awardAmountMin?.toString()
      const maxValue = params.awardAmountMax?.toString()

      return (
        <div className="d-flex flex-row gap-2 align-items-center">
          <label placeholder="Min">Amount</label>
          <CFormInput
            type="number"
            placeholder="Min"
            data-testid="min"
            value={minValue || ''}
            onChange={({ target: { value } }) => {
              setParams({
                ...params,
                awardAmountMin: value ? parseInt(value) : undefined,
              })
            }}
          />
          <label placeholder="Max">To</label>
          <CFormInput
            type="number"
            placeholder="Max"
            data-testid="max"
            value={maxValue || ''}
            onChange={({ target: { value } }) => {
              setParams({
                ...params,
                awardAmountMax: value ? parseInt(value) : undefined,
              })
            }}
          />
        </div>
      )
    }

    const renderMinMaxDates = () => {
      return (
        <div className="d-flex flex-row gap-2 align-items-center">
          <label className="fixed-width" placeholder="Min">
            Date
          </label>
          <DatePicker
            data-testid="min-date-picker"
            onChange={(value: Date) => {
              setParams({ ...params, awardDateMin: value && getISOLocalDate(value) })
            }}
            maxDate={new Date()}
            value={params.awardDateMin ? makeDateComputerOffset(params.awardDateMin) : undefined}
            className={'calender'}
            required={false}
            openCalendarOnFocus={false}
          />

          <label placeholder="Max">To</label>
          <DatePicker
            data-testid="max-date-picker"
            onChange={(value: Date) => {
              setParams({ ...params, awardDateMax: value && getISOLocalDate(value) })
            }}
            maxDate={new Date()}
            value={params.awardDateMax ? makeDateComputerOffset(params.awardDateMax) : undefined}
            className={'calender'}
            required={false}
            openCalendarOnFocus={false}
          />
        </div>
      )
    }

    return (
      <section
        className={classNames('d-flex flex-column gap-2', {
          'gap-4': props.isStandaloneAwardFilter,
        })}
      >
        <div>
          <DebouncedInput
            placeholder="Search Awards"
            data-testid="search-awards"
            className="award-search-input"
            value={params.searchAwards || ''}
            onChange={(value) =>
              setParams({
                ...params,
                searchAwards: value,
              })
            }
          />
        </div>

        <div
          className={classNames('d-flex flex-row gap-2', {
            'gap-3': props.isStandaloneAwardFilter,
          })}
        >
          <CCol>
            <DebouncedInput
              placeholder="Search Award Contract Number"
              className="award-contract-number"
              data-testid="search-awards-contract-num"
              value={params.awardContractNumber?.toString() || ''}
              onChange={(value) =>
                setParams({
                  ...params,
                  awardContractNumber: value,
                })
              }
            />
          </CCol>
          <CCol>
            <CustomDropdown
              placeholder="Search State"
              CustomDropdownClass="m-0 p-0"
              dropdownClass="darker-strokes-border"
              data-testid="search-awards-state"
              options={isoUSStateCodes}
              selectedOption={params.awardState}
              onSelect={(value) => {
                setParams({ ...params, awardState: value as string })
              }}
              onSelectNone={() => {
                setParams({ ...params, awardState: undefined })
              }}
            />
          </CCol>
        </div>

        <div
          className={classNames('d-flex flex-row gap-2', {
            'gap-3': props.isStandaloneAwardFilter,
          })}
        >
          <CCol>
            <ServerBackedDropdown
              placeholder="Select Phase"
              getData={(params) => getAwardsPhases(params)}
              onSelectNone={() => {
                setParams({ ...params, awardPhase: undefined })
              }}
              onSelect={(selected) => {
                setParams({ ...params, awardPhase: selected.label })
              }}
              selected={
                params.awardPhase
                  ? {
                      id: params.awardPhase,
                      label: params.awardPhase as string,
                    }
                  : undefined
              }
              testId="-awards-phases"
              normalPlaceholderFont={true}
            />
          </CCol>
          <CCol>
            <div>
              <UrlFederatedNaicsCodeMultiSelectAutoComplete
                paramKey="awardNaicsCodeIds"
                dataTestId={'naics-award'}
              />
            </div>
          </CCol>
        </div>

        <div>
          <UrlFederatedTechnologyTypesMultiSelectAutoComplete
            paramKey="awardTechnologyTypeIds"
            dataTestId="tech-award"
          />
        </div>

        <div className="d-flex flex-row gap-2 flex-wrap align-items-center">
          {renderMinMaxAmounts()}
          {renderMinMaxDates()}
        </div>
      </section>
    )
  }),
)
