export const visionVisibilityIcon = (className = undefined) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_101_21)">
        <path
          className="icon-path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.93211 5.4866C1.35405 4.49162 1.49107 3.19524 2.34316 2.34315C3.19525 1.49106 4.49162 1.35404 5.4866 1.9321C5.7814 0.819795 6.79497 0 8.00001 0C9.20504 0 10.2186 0.819791 10.5134 1.93209C11.5084 1.35404 12.8048 1.49106 13.6568 2.34314C14.5089 3.19524 14.646 4.49162 14.0679 5.4866C15.1802 5.7814 16 6.79497 16 8.00001C16 9.20504 15.1802 10.2186 14.0679 10.5134C14.646 11.5084 14.5089 12.8048 13.6569 13.6569C12.8048 14.5089 11.5084 14.646 10.5134 14.0679C10.2186 15.1802 9.20504 16 8 16C6.79496 16 5.7814 15.1802 5.48659 14.0679C4.49162 14.646 3.19524 14.5089 2.34315 13.6568C1.49106 12.8048 1.35404 11.5084 1.93209 10.5134C0.819796 10.2186 6.03256e-06 9.20504 6.08523e-06 8.00001C6.13791e-06 6.79497 0.819806 5.7814 1.93211 5.4866Z"
          fill="#0D6EFD"
        />
        <path
          d="M8.36429 7.46111L9.96443 3.75509L10.1914 3.24002H9.70845L7.71657 5.87652L5.6853 8.53789L5.68427 8.53893H7.63573L6.03559 12.246L5.80966 12.76H6.29157L8.28345 10.1235L10.3147 7.46215L10.3158 7.46111H8.36429Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_101_21">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
