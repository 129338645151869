import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useDebouncedCallback } from 'use-debounce'

type TProps = {
  information?: string | JSX.Element
  hasInfoIcon?: boolean
  children?: JSX.Element
  infoIconClassName?: string
  className?: string
  testId?: string
  ariaLabel: string
  onClick?: () => void
  onKeyDown?: (toggle: boolean) => void
}

export const CustomInformationPopup = (props: TProps) => {
  const [infoPopup, toggleInfoPopup] = useState<boolean>(false)
  const icon = props.hasInfoIcon ? 'fas fa-info-circle' : props.infoIconClassName

  const toggleInfoPopupOpen = useDebouncedCallback((value) => toggleInfoPopup(value), 200)

  const renderInformation = () => {
    if (typeof props.information === 'string') {
      return <span>{ReactHtmlParser(props.information)}</span>
    }

    return props.information
  }

  const ariaLabel = () => {
    let str = props.ariaLabel || ''
    if (typeof props.information === 'string') {
      return `${str} ${props.information}`
    }
    return str
  }

  return (
    <section className="CustomInformationPopup">
      {/* Render children (e.g. header text) to the left side of icon, otherwise we would need too many surgical scss styling  */}
      {props.hasInfoIcon && props.children && props.children}

      <div
        className={`info-icon-container ${props.className}`}
        aria-label={ariaLabel()}
        tabIndex={0}
        data-testid={props.testId || 'custom-info-popup-icon'}
        onClick={props.onClick}
        onMouseOver={() => toggleInfoPopupOpen(true)}
        onMouseOut={() => toggleInfoPopupOpen(false)}
        onKeyDown={({ key: keyPress }) => {
          if (keyPress === 'Enter') {
            toggleInfoPopup(true)
            props.onKeyDown && props.onKeyDown(true)
          } else {
            toggleInfoPopup(false)
            props.onKeyDown && props.onKeyDown(false)
          }
        }}
      >
        {/* Render children without the icon  */}
        {!props.hasInfoIcon && props.children && props.children}

        {/* Render default info icon after children to the right */}
        {props.hasInfoIcon && <i className={icon} />}

        {infoPopup && props.information && (
          <div className="info-panel multiline" data-testid="info-panel">
            {renderInformation()}
          </div>
        )}
      </div>
    </section>
  )
}
