import {
  SelectableItem,
  ServerBackedMultiSelectAutoComplete,
  PaginationParams,
  isDirtySelectableItem,
} from '@mobilizeyourtech/vision-core-react'
import { useEffect, useState } from 'react'
import { initializeSearchDropdown } from '../custom'
import { INaicsCodes } from '../form/fragments/listing'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../stores'

export interface UrlFederatedNaicsCodeMultiSelectAutoCompleteProps {
  paramKey: 'naicsCodeIds' | 'awardNaicsCodeIds'
  dataTestId?: string
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}
export const UrlFederatedNaicsCodeMultiSelectAutoComplete = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: UrlFederatedNaicsCodeMultiSelectAutoCompleteProps) => {
    const [params, setParams] = useQueryParams({
      [props.paramKey]: withDefault(StringParam, undefined),
    })
    const [selectedNaicsCodes, setSelectedNaicsCodes] = useState<Array<INaicsCodes>>([])

    useEffect(() => {
      if (params[props.paramKey] == null || params[props.paramKey]?.split(',').length === 0) {
        setSelectedNaicsCodes([])
      } else {
        const currentIds = selectedNaicsCodes
          .map((item) => item.id.toString())
          .sort()
          .join(',')
        const paramIds = params[props.paramKey]!.split(',').sort().join(',')

        if (currentIds !== paramIds) {
          initializeSearchDropdown(
            {
              ids: params[props.paramKey],
              getDropdownData: getIgniteNaicsCodes,
              setSelected: setSelectedNaicsCodes,
              typeForErrorMessage: 'naics codes',
            },
            props.notificationStore!,
          )
        }
      }
    }, [params[props.paramKey]])

    const getIgniteNaicsCodes = async (p: PaginationParams & { ids?: string }) => {
      return props.serverStore!.getNaicsCodes(p).then((response) => response)
    }

    const selectNaicsCodes = (selected: Array<INaicsCodes & SelectableItem>) => {
      setParams({
        ...params,
        [props.paramKey]: selected.length === 0 ? undefined : selected.map((e) => e.id).join(','),
      })
      setSelectedNaicsCodes(selected)
    }

    const getSelectedNaicsCodes = () => {
      return selectedNaicsCodes.map((t) => {
        return { ...t, label: t.label }
      })
    }

    return (
      <ServerBackedMultiSelectAutoComplete
        data-testid={props.dataTestId ? props.dataTestId : 'naics'}
        loadMore={getIgniteNaicsCodes}
        selections={getSelectedNaicsCodes()}
        // DirtySelectableItems are only possible when allowDirty === true
        onChange={(selected) => {
          let _selected = selected.filter((e) => !isDirtySelectableItem(e)) as Array<
            INaicsCodes & SelectableItem
          >
          selectNaicsCodes(_selected)
        }}
        placeholder="Select NAICS Codes"
        reloadOnOpen
      />
    )
  }),
)
