import React, { useEffect, useState } from 'react'
import {
  ApplicationStore,
  AuthStore,
  NotificationStore,
  UserStore,
  InvitedUserData,
} from '../stores'
import { inject, observer } from 'mobx-react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Redirect } from 'react-router-dom'
import { CCard, CCardBody, CCardFooter, CCol, CContainer, CRow } from '@coreui/react'
import { SupportLinks } from '../components/custom'
import { extractRestfulError } from '../lib/errors/utils'
import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
}

export const AcceptInviteLanding = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      inviteToken: withDefault(StringParam, undefined),
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [knownErrorReason, setKnownErrorReason] = useState<string | undefined>(undefined)
    const [invite, setInvite] = useState<InvitedUserData | undefined>(undefined)

    useEffect(() => {
      checkToken()
    }, [])

    const checkToken = () => {
      setIsLoading(true)
      if (params.inviteToken) {
        // For suspense with other loaders (i.e. the AuthBoundary)
        props.userStore
          ?.checkUserInvite(params.inviteToken!)
          .then((inviteResponse) => {
            setInvite(inviteResponse)
          })
          .catch((error) => {
            setKnownErrorReason(extractRestfulError(error)?.error || 'Unknown')
          })
          .finally(() => setIsLoading(false))
      } else {
        setIsLoading(false)
      }
    }
    const renderCheckResults = () => {
      if (!!invite && !knownErrorReason) {
        return (
          <Redirect
            to={`/invite_signup?inviteToken=${params.inviteToken}&firstName=${invite?.firstName}&lastName=${invite?.lastName}`}
          />
        )
      } else {
        return renderFailure()
      }
    }

    const determineFailureMessage = () => {
      switch (knownErrorReason) {
        case 'InvalidInviteToken':
          return 'The invite token is invalid.'
        case 'InvitePeriodExpired':
          return 'This invite token expired.'
        default:
          return 'There was a problem validating your invite.'
      }
    }

    const renderFailure = () => {
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <h2 className="text-center">Failed to Confirm Invite</h2>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <p className="text-center subtle">{determineFailureMessage()}</p>
            </CCol>
          </CRow>

          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <p className="text-center">
                Please reach out to your Ignite administrator regarding your invite. If you already
                have an account, you may go to our <a href="/">sign in</a> page and log in there.
              </p>
            </CCol>
          </CRow>
        </>
      )
    }

    return (
      <section className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard>
                <CCardBody className="p-4">
                  <SuspensionBoundary isLoading={isLoading}>
                    {renderCheckResults()}
                  </SuspensionBoundary>
                </CCardBody>
                <CCardFooter className="bg-white">
                  <CRow className="justify-content-center">
                    <CCol>
                      <SupportLinks />
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    )
  }),
)

export default AcceptInviteLanding
