import { NamedServerArtifact, NamedWithLabelServerArtifact } from '../../lib/types'
import { PaginationParams, ServerPageData } from '@mobilizeyourtech/vision-core-react'
import { NotificationStore } from '../../stores'
import { Dispatch, SetStateAction } from 'react'

export { Alert } from './Alert'
export { Spinner } from './Spinner'

export { OrganizationDetails } from '../form/fragments/listing/OrganizationDetails'
export { ContractingDetails } from '../form/fragments/listing/ContractingDetails'

export { SingleImageUploader } from './SingleImageUploader'
export { ImageCroppingTool } from './ImageCroppingTool'

export { RegionSelectInput } from './RegionSelectInput'
export { CountrySelectInput } from './CountrySelectInput'

export { TimezoneSelect } from './TimezoneSelect'
export { ValidatedCFormSelect } from './ValidatedCFormSelect'
export { ValidatedCFormInput } from './ValidatedCFormInput'
export { ValidatedCFormTextArea } from './ValidatedCFormTextArea'

export { SingleUserSelect } from './SingleUserSelect'
export { GoogleSuggestAddressInput } from './GoogleSuggestAddressInput'
export { ConfirmationBoundaryModal } from './ConfirmationBoundaryModal'
export { LegalLinks } from './LegalLinks'
export { Pagination } from './Pagination'
export { SupportLinks } from './SupportLinks'
export { AsyncValidatedTextInput } from './AsyncValidatedTextInput'
export { IconPopover } from './IconPopover'

export { PotentiallyExternalRedirectLink } from './PotentiallyExternalRedirectLink'
export { CustomInformationPopup } from './CustomInformationPopup'
export { ServerBackedDropdown } from './ServerBackedDropdown'
export { CustomDropdown } from './CustomDropdown'
export { Collapsible } from './Collapsible'
export type { DropdownItem } from './ServerBackedDropdown'
export { UrlFederatedNaicsCodeMultiSelectAutoComplete } from './UrlFederatedNaicsCodeMultiSelectAutoComplete'
export { UrlFederatedTechnologyTypesMultiSelectAutoComplete } from './UrlFederatedTechnologyTypesMultiSelectAutoComplete'
export { UrlFederatedBusinessClassificationsMultiSelectAutoComplete } from './UrlFederatedBusinessClassificationsMultiSelectAutoComplete'

export { ChatPanelHeader } from './ChatPanelHeader'
export { ChatThreadPanelMessages } from './ChatThreadPanelMessages'

export { StartChatButton } from './StartChatButton'
export { ToggleChatStatusButton } from './ToggleChatStatusButton'

type SearchParamType<T extends NamedWithLabelServerArtifact | NamedServerArtifact> = {
  ids: string | undefined
  getDropdownData: (p: PaginationParams & { ids?: string }) => Promise<ServerPageData<T>>
  setSelected: Dispatch<SetStateAction<T[]>>
  typeForErrorMessage: string
}

export const initializeSearchDropdown = <
  T extends NamedWithLabelServerArtifact | NamedServerArtifact,
>(
  searchParams: SearchParamType<T>,
  notificationStore: NotificationStore,
) => {
  if (searchParams.ids) {
    searchParams
      .getDropdownData({ page: 1, perPage: searchParams.ids?.split(',').length, ...searchParams })
      .then((response) =>
        searchParams.setSelected(
          response.data.map((d) => {
            const label = (d as NamedWithLabelServerArtifact).label
            const name = (d as NamedServerArtifact).name

            return { ...d, label: name ? name : label }
          }),
        ),
      )
      .catch(() =>
        notificationStore?.setNotificationMessage(
          'Failed to populate ' + searchParams.typeForErrorMessage + '. Please try again later',
          'error',
          3000,
        ),
      )
  }
}
