import { observer } from 'mobx-react'
import { ListingAwardData } from '../../stores/ListingStore'
import { CCard, CCardBody, CCardSubtitle, CCardTitle } from '@coreui/react'
import { parseDate } from '@mobilizeyourtech/vision-core-react'
import ReactHtmlParser from 'react-html-parser'

type TProps = {
  award: ListingAwardData
  handleParamsChange: (id: string | number) => void
}

export const ListingAwardCard = observer((props: TProps) => {
  return (
    <CCard className="ListingAwardCard" onClick={() => props.handleParamsChange(props.award.id)}>
      <CCardBody data-testid="card-body">
        <div className="award-card-header pb-3">
          <div className="header-left">
            <div className="artifact-details">
              <CCardTitle className="truncate-overflow">{props.award.awardTitle}</CCardTitle>
              <CCardSubtitle className={'subtle'}>
                {props.award.contractNumber}
                {props.award.awardYear && '  -  ' + props.award.awardYear}
                {props.award.phase && '  -  ' + props.award.phase}
              </CCardSubtitle>
            </div>
          </div>
        </div>
        <div className="date-row">
          <p className="date">
            Contract Start Date:{' '}
            {props.award.contractStartDate ? parseDate(props.award.contractStartDate) : 'N/A'}
          </p>
          <p className="date">
            Contract End Date:{' '}
            {props.award.contractEndDate ? (
              parseDate(props.award.contractEndDate)
            ) : (
              <span className="grey-label">N/A</span>
            )}
          </p>
        </div>
        <div className="truncate-overflow">
          {props.award.searchMetadata?.highlight ? (
            <div className={'search-highlight bold-color-primary-accent'}>
              {ReactHtmlParser(props.award.searchMetadata.highlight)}
            </div>
          ) : (
            <p className="truncate-overflow">{props.award.awardAbstract}</p>
          )}
        </div>
      </CCardBody>
    </CCard>
  )
})
