import { ApplicationStore, UserStore } from '../../stores'
import { observer, inject } from 'mobx-react'
import { makeChatThreadHeadliner } from './ChatThreadCard'
import {
  ChatParticipantResponse,
  ChatThreadResponse,
  isAccountParticipant,
} from '../../stores/ServerStore'
import { ToggleChatStatusButton } from './ToggleChatStatusButton'

type ChatPanelHeaderProps = {
  chatThread: ChatThreadResponse
  userStore?: UserStore
  onChatThreadUpdated: () => void
}

export const ChatPanelHeader = inject(ApplicationStore.names.userStore)(
  observer((props: ChatPanelHeaderProps) => {
    const user = props.userStore?.currentUserData
    const currentIsGovUser = props.userStore?.currentUserData?.userRoles.includes('gov')

    const displayParticipantList = props.chatThread.participants.filter(
      (participant: ChatParticipantResponse) => {
        if (isAccountParticipant(participant) && !currentIsGovUser) {
          return user?.accountId !== participant.participantId
        } else if (isAccountParticipant(participant) && currentIsGovUser) {
          return true
        } else {
          return user?.id !== participant.participantId
        }
      },
    )

    return (
      <div className="chat-header d-flex justify-content-between" data-testid={'chat-header'}>
        <h5>
          <span className="chat-header-span">To: </span>
          {makeChatThreadHeadliner(displayParticipantList)}
        </h5>
        {currentIsGovUser && (
          <ToggleChatStatusButton
            chatThread={props.chatThread}
            onChatThreadUpdated={props.onChatThreadUpdated}
          />
        )}
      </div>
    )
  }),
)
