import { ServerStore, UserData } from './'
import {
  FileResponseData,
  GenericNamedItemResponse,
  GenericLabeledItemResponse,
} from '../lib/types'
import { ServerPageData, PaginatedSearchableParams, PaginationParams } from '../lib/types/Params'
import { IBusinessClassifications, INaicsCodes } from '../components/form/fragments/listing'
import { GenericSearchMetadata, SocialMediaAccount } from './UserStore'
import { TechnologyTypeResponse } from './ServerStore'
import { IgniteAwardQueryParams } from './ListingStore'

export interface IdentifiedServerArtifact {
  id: number
}

export interface NamedServerArtifact {
  name: string
}

export interface IgniteSearchMetadataFulltextMatchType extends IdentifiedServerArtifact {
  rank: number // a float
  highlight?: string // only present if the highlight=true param was used
}

export interface IgniteSearchMetadataTechnologyTypesMatchType extends IdentifiedServerArtifact {
  technologyTypes: Array<NamedServerArtifact>
}

export interface IgniteSearchMetadataNaicsCodesMatchType extends IdentifiedServerArtifact {
  naicsCodes: Array<NamedServerArtifact & { code: string | number }>
}

export interface IgniteSearchMetadataBusinessClassificationsMatchType
  extends IdentifiedServerArtifact {
  businessClassifications: Array<NamedServerArtifact>
}

export type ConnectListingResponse = {
  id: number
  name: string
  logo: FileResponseData
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  stateProvince: string
  postalCode: string
  country: string
  capabilityStatement: string
  dunsCode?: string
  samCode?: string
  uniqueEntityId: string
  tagLine: string
  website: string
  yearFounded: string
  createdAt: string
  updatedAt: string
  lastLogin: string
  lastSync: string
  profileUpdated: string
  technologyTypes: Array<GenericNamedItemResponse>
  socialMediaAccounts: Array<SocialMediaAccount>
  businessClassifications: Array<GenericNamedItemResponse>
  naicsCodes: Array<ConnectNaicsCode>
  listingArtifactLinks?: Array<ConnectListingArtifactLink>
  listingArtifactAttachments?: Array<ConnectListingArtifactAttachment>
  pointOfContact?: UserData
  accountId: number
  // Only provided if the GET used any of our search filter params
  searchMetadata?: {
    fulltextSearchMetadata?: {
      listings?: Array<IgniteSearchMetadataFulltextMatchType>
      products?: Array<IgniteSearchMetadataFulltextMatchType>
      services?: Array<IgniteSearchMetadataFulltextMatchType>
      useCases?: Array<IgniteSearchMetadataFulltextMatchType>
      awards?: Array<IgniteSearchMetadataFulltextMatchType>
    }
    highestRankedFulltextMatch?: IgniteSearchMetadataFulltextMatchType & {
      artifactType: 'product' | 'service' | 'useCase' | 'award'
    }
    technologyTypeSearchMetadata: {
      listings?: Array<IgniteSearchMetadataTechnologyTypesMatchType>
      products?: Array<IgniteSearchMetadataTechnologyTypesMatchType>
      services?: Array<IgniteSearchMetadataTechnologyTypesMatchType>
      useCases?: Array<IgniteSearchMetadataTechnologyTypesMatchType>
      awards?: Array<IgniteSearchMetadataTechnologyTypesMatchType>
    }
    naicsCodeSearchMetadata: {
      listings?: Array<IgniteSearchMetadataNaicsCodesMatchType>
    }
    businessClassificationsSearchMetadata: {
      listings?: Array<IgniteSearchMetadataBusinessClassificationsMatchType>
    }
    awardAmountRangeSearchMetadata: {
      awards?: Array<IdentifiedServerArtifact>
    }
    awardContractNumberSearchMetadata: {
      awards?: Array<IdentifiedServerArtifact>
    }
    awardDateRangeSearchMetadata: {
      awards?: Array<IdentifiedServerArtifact>
    }
    awardPhaseSearchMetadata: {
      awards?: Array<IdentifiedServerArtifact>
    }
    awardStateSearchMetadata: {
      awards?: Array<IdentifiedServerArtifact>
    }
    awardNaicsCodesSearchMetadata: {
      awards?: Array<IdentifiedServerArtifact>
    }
    matchedArtifacts: {
      awards?: Array<IdentifiedServerArtifact>
      products?: Array<IdentifiedServerArtifact>
      services?: Array<IdentifiedServerArtifact>
      useCases?: Array<IdentifiedServerArtifact>
    }
  }
  // Only provided if the resourceMetadata was eager loaded
  resourceMetadata?: {
    products: {
      total: number
      technologyTypes: {
        distinct: number
      }
    }
    services: {
      total: number
      technologyTypes: {
        distinct: number
      }
    }
    useCases: {
      total: number
    }
    awards: {
      total: number
      naicsCodes: {
        distinct: number
      }
    }
  }
}
export const isConnectListingResponse = (o: any): o is ConnectListingResponse => {
  if (o === undefined) return false
  return 'id' in o && !!o['id'] && 'uniqueEntityId' in o && !!o['uniqueEntityId']
}

export type ConnectNaicsCode = GenericNamedItemResponse & {
  code: string
}

export interface ConnectListingArtifactPricing {
  id: string | number
  amount: number
  periodIncrement: string
  periodValue: number
}

export interface ConnectListingArtifactLink {
  id: string | number
  name: string
  description: string
  url: string
}

export interface ConnectListingArtifactAttachment {
  id: string | number
  name: string
  description: string
  file: FileResponseData
}

export interface ConnectListingArtifactTechnologyType {
  artifactId: number
  technologyTypeId: number
  listingArtifactTechnologyTypeId: number
  name: string
}

export interface ConnectListingUseCaseData {
  id: string | number
  listingId: string | number
  name: string
  description: string
  url?: string
  logo?: FileResponseData
  startDate?: string
  endDate?: string
  listingArtifactLinks?: Array<ConnectListingArtifactLink>
  listingArtifactAttachments?: Array<ConnectListingArtifactAttachment>
  listingArtifactTechnologyTypes?: Array<ConnectListingArtifactTechnologyType>
}
export interface ConnectListingAwardData {
  id: number
  contractNumber: string
  contractAwardDate?: string
  contractStartDate?: string
  contractEndDate?: string
  awardAmount?: string
  awardTitle?: string
  productServiceCode?: string
  principleNaicsCode?: string
  state?: string
  awardAbstract?: string
  phase?: string
  branch?: string
  program?: string
  piName?: string
  piTitle?: string
  piPhone?: string
  piEmail?: string
  awardYear?: string
  researchInstitutionName?: string
  searchMetadata?: GenericSearchMetadata
}
export interface ConnectListingServiceProductData {
  id: string | number
  listingId: string | number
  name: string
  description: string
  url?: string
  logo?: FileResponseData
  listingArtifactPricings?: Array<ConnectListingArtifactPricing>
  listingArtifactLinks?: Array<ConnectListingArtifactLink>
  listingArtifactAttachments?: Array<ConnectListingArtifactAttachment>
  listingArtifactTechnologyTypes?: Array<ConnectListingArtifactTechnologyType>
}

export type PersonData = {
  name: string
  email: string
  phone: string
  title: string
  contactType: string
}

export type ConnectSearchParams = PaginatedSearchableParams & { ids?: string }

export class ConnectListingStore extends ServerStore {
  public getConnectListings = async (params: {
    page: number | string
    perPage: number | string
    search?: string
    searchListings?: string
    searchProducts?: string
    searchServices?: string
    searchUseCases?: string
    searchAwards?: string
    listingTechnologyTypeIds?: string
    listingBusinessClassificationIds?: string
    naicsCodeIds?: string
    listingIdentifier?: string
    serviceTechnologyTypeIds?: string
    productTechnologyTypeIds?: string
    sort?: string
    sortDirection?: string
    eager?: string
  }): Promise<ServerPageData<ConnectListingResponse>> => {
    return this.server()
      .get('/api/v1/connect/listings', { params })
      .then(({ data }) => data as ServerPageData<ConnectListingResponse>)
  }

  public getConnectBusinessClassifications = async (
    params: ConnectSearchParams,
  ): Promise<ServerPageData<IBusinessClassifications>> => {
    return this.server()
      .get('/api/v1/connect/business_classifications', {
        params,
      })
      .then(({ data }) => data as ServerPageData<IBusinessClassifications>)
  }

  public getConnectTechnologyTypes = async (
    params: ConnectSearchParams,
  ): Promise<ServerPageData<TechnologyTypeResponse>> => {
    return this.server()
      .get('/api/v1/connect/technology_types', {
        params,
      })
      .then(({ data }) => data as ServerPageData<TechnologyTypeResponse>)
  }

  public getAwardsPhases = async (
    params: PaginationParams,
  ): Promise<ServerPageData<GenericLabeledItemResponse>> => {
    return this.server()
      .get('/api/v1/connect/awards/phases', { params })
      .then(({ data }) => data)
  }

  public getSingleConnectListing = (
    id: string | number,
    eager?: string,
  ): Promise<ConnectListingResponse> => {
    return this.server()
      .get(`/api/v1/connect/listings/${id}`, {
        params: { eager },
      })
      .then(({ data }) => data)
  }

  public getConnectListingServices = async ({
    listingId,
    params,
    eager,
  }: {
    listingId: string | number
    params: PaginationParams
    eager?: string
  }): Promise<ServerPageData<ConnectListingServiceProductData>> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/listing_services`, {
        params: { page: params.page, perPage: params.perPage, eager: eager },
      })
      .then(({ data }) => data)
  }

  public getListingAwards = async ({
    listingId,
    params,
  }: {
    listingId: string | number
    params: PaginationParams & IgniteAwardQueryParams
  }): Promise<ServerPageData<ConnectListingAwardData>> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/listing_awards`, {
        params: { ...params },
      })
      .then(({ data }) => data)
  }

  public getSingleListingAward = ({
    listingId,
    awardId,
    eager,
  }: {
    listingId: string
    awardId: string
    eager?: string
  }): Promise<ConnectListingUseCaseData> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/listing_awards/${awardId}`, { params: { eager } })
      .then(({ data }) => data)
  }

  public getListingPersonData = async (
    listingId: string | number,
    params: PaginationParams,
  ): Promise<ServerPageData<PersonData>> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/people`, { params })
      .then(({ data }) => data)
  }

  public getConnectListingProducts = async ({
    listingId,
    params,
    eager,
  }: {
    listingId: string | number
    params: PaginationParams
    eager?: string
  }): Promise<ServerPageData<ConnectListingServiceProductData>> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/listing_products`, {
        params: { page: params.page, perPage: params.perPage, eager: eager },
      })
      .then(({ data }) => data)
  }

  public getSingleListingProduct = async ({
    listingId,
    productId,
    eager,
  }: {
    listingId: string
    productId: string
    eager?: string
  }): Promise<ConnectListingServiceProductData> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/listing_products/${productId}`, {
        params: { eager },
      })
      .then(({ data }) => data)
  }

  public getSingleListingService = ({
    listingId,
    serviceId,
    eager,
  }: {
    listingId: string
    serviceId: string
    eager?: string
  }): Promise<ConnectListingServiceProductData> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/listing_services/${serviceId}`, {
        params: { eager },
      })
      .then(({ data }) => data)
  }

  public getConnectListingUseCases = async ({
    listingId,
    params,
    eager,
  }: {
    listingId: string | number
    params: PaginationParams
    eager?: string
  }): Promise<ServerPageData<ConnectListingUseCaseData>> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/listing_use_cases`, {
        params: { page: params.page, perPage: params.perPage, eager: eager },
      })
      .then(({ data }) => data)
  }

  public getSingleListingUseCase = ({
    listingId,
    useCaseId,
    eager,
  }: {
    listingId: string
    useCaseId: string
    eager?: string
  }): Promise<ConnectListingUseCaseData> => {
    return this.server()
      .get(`/api/v1/connect/listings/${listingId}/listing_use_cases/${useCaseId}`, {
        params: { eager },
      })
      .then(({ data }) => data)
  }

  public getConnectNaicsCodes = async ({
    page,
    perPage,
    ids,
    search,
  }: {
    page: number | string
    perPage: number | string
    ids?: string
    search?: string
  }): Promise<ServerPageData<INaicsCodes>> => {
    return this.server()
      .get('/api/v1/connect/naics_codes', {
        params: { page, perPage, ids, search },
      })
      .then(({ data }) => data as ServerPageData<INaicsCodes>)
  }
}
