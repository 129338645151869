import { DafUsersStatistic } from '../../../../stores/AnalyticsStore'
import { IgniteLineChart } from '../../../lineChart/IgniteLineChart'

type TProp = {
  data?: DafUsersStatistic[]
  width?: number
}
export const DafUserStatisticsLineChart = (props: TProp) => {
  let chartLines = [
    { displayName: 'DAF Logins', dataKey: 'userLogins', color: '#f0483e' },
    { displayName: 'DAF Messages Sent', dataKey: 'messagesSent', color: '#0070D8' },
  ]

  return (
    <div
      data-testid="daf-user-statistics-line-chart-container"
      id="daf-user-statistics-line-chart-container"
    >
      <IgniteLineChart
        data={props.data}
        chartLines={chartLines}
        xAxis="month"
        width={props.width}
        chartId="daf-user-statistics-line-chart"
      />
    </div>
  )
}
