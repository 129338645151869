import { SimpleCollapse } from '@mobilizeyourtech/vision-core-react'
import React from 'react'
import MobilizeVISIONLogo from '../../assets/brand/Ignite_Logo_Mobilize_Full_Color.png'
import { visionVisibilityIcon } from '../../assets/brand/vision_visibility_icon'
import { CCard, CCardBody, CLink } from '@coreui/react'
import { leftArrow } from '../../components/icons/leftArrow'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { ApplicationStore } from '../../stores'

const choices = [
  {
    icon: visionVisibilityIcon('vision-badge-blue'),
    header: 'I have a VISION Account',
    nextStep: 'Connect your VISION account to Ignite',
    instructions: (siteSettings: { [key: string]: any }) => {
      return (
        <ol>
          <li>
            <CLink className="clickable" href={siteSettings.visionUrl} target={'_blank'}>
              Login
            </CLink>{' '}
            to your VISION account
          </li>
          <li>Click &quot;Ignite SBIR|STTR&quot; in the left sidebar</li>
          <li>
            On the Ignite search page OR an Ignite storefront, click the &quot;View in Ignite&quot;
            button
          </li>
          <li>Follow the instructions to create your Ignite account</li>
        </ol>
      )
    },
  },
  {
    icon: visionVisibilityIcon('vision-badge-grey'),
    header: 'My organization has a VISION license, but I have not created my account',
    nextStep: 'Create a VISION account and connect it to Ignite',
    instructions: (siteSettings: { [key: string]: any }) => (
      <div>
        <ol>
          <li>
            <CLink
              className="clickable"
              href="https://mobilizeyourtech.com/vision-signup"
              target={'_blank'}
            >
              Click here
            </CLink>{' '}
            for instructions on how to create your VISION account
          </li>
          <li>Click &quot;Ignite SBIR|STTR&quot; in the left sidebar</li>
          <li>
            On the Ignite search page OR an Ignite storefront, click the &quot;View in Ignite&quot;
            button
          </li>
          <li>Follow the instructions to create your Ignite account</li>
        </ol>

        <div className="mt-2 not-sure-instruction">
          Not sure if your organization has a VISION license?
        </div>

        <div className="mt-1">
          <a href="mailto:support@mobilizeignite.com">Click here</a> to email us and we&apos;ll help
          you find out.
        </div>
      </div>
    ),
  },
  {
    icon: <i className="fas fa-user" />,
    header: 'My organization does not have a VISION license',
    nextStep: (
      <>
        <CLink className={'clickable'} href={'/gov_user'}>
          Click here
        </CLink>{' '}
        to create an Ignite account
      </>
    ),
  },
]

export type SignupChoiceProps = {
  applicationStore?: ApplicationStore
}
export const SignupChoice = inject('applicationStore')(
  observer((props: SignupChoiceProps) => {
    return (
      <section className="SignupChoice">
        <div className="choice-header w-100 d-flex justify-content-center">
          <Link
            className="nav-back clickable"
            data-testid="nav-back"
            aria-label="navigate back to signin"
            tabIndex={0}
            to="/"
          >
            {leftArrow()}
          </Link>
          <img
            src={MobilizeVISIONLogo}
            className="MobilizeVisionLogo mb-3"
            alt="Mobilize Vision Logo"
          />
        </div>

        <div className="choice-prompt mt-3 d-flex flex-column justify-content-center">
          <div className="text-center">
            <h5>
              <b>Choose the option that best represents you</b>
            </h5>
          </div>
          <div className={'d-flex justify-content-center'}>
            <SimpleCollapse className={'why-matter-collapse'} toggleContent={'Why does it matter?'}>
              <p className="prompt-text text-center">
                {/*Some SBIR/STTR companies choose to only show their storefronts to users with VISION*/}
                {/*accounts connected. Connecting your VISION account to your Ignite account will*/}
                {/*ensure that you can view these storefronts.*/}
                {/*<br />*/}
                {/*<br />*/}
                VISION users get a VISION-verified badge {visionVisibilityIcon()}, establishing
                greater trust and recognition in Ignite.
              </p>
            </SimpleCollapse>
          </div>
        </div>

        <div className="d-flex w-100 justify-content-center gap-2 flex-wrap mt-2">
          {choices.map((choice, idx) => (
            <CCard key={idx} className="choice-card" data-testid={`signup-choice-${idx}`}>
              <CCardBody>
                {choice.icon}
                <h5 className="mt-1">
                  <b>{choice.header}</b>
                </h5>
                <div className="mt-3">Next Step: {choice.nextStep}</div>

                {choice.instructions && (
                  <SimpleCollapse
                    data-testid={`signup-choice-instructions-${idx}`}
                    className="mt-1"
                    toggleContent={'Click for instructions'}
                  >
                    {choice.instructions(props.applicationStore!.siteSettings!)}
                  </SimpleCollapse>
                )}
              </CCardBody>
            </CCard>
          ))}
        </div>
      </section>
    )
  }),
)
