import {
  extractGenericListingArtifactFormData,
  GenericListingArtifactFormData,
  GenericListingArtifactFormFragment,
} from './fragments/listing/GenericListingArtifact'
import { Wizard, WizardStepProps } from '@mobilizeyourtech/vision-core-react'
import {
  BulkAttachments,
  ListingArtifactAttachmentFormData,
} from './fragments/listing/BulkAttachments'
import { BulkLinks, ListingArtifactLinkFormData } from './fragments/listing/BulkLinks'
import { useMemo } from 'react'
import { titleCase } from '../../lib/helpers/utils'
import { validateLength, validateWebsite } from '../../lib/helpers/validation'

export type ListingArtifactFormData = GenericListingArtifactFormData & {
  listingArtifactAttachmentsAttributes?: Array<ListingArtifactAttachmentFormData>
  listingArtifactLinksAttributes?: Array<ListingArtifactLinkFormData>
}
export interface GenericListingArtifactFormFragmentProps {
  formData: Partial<ListingArtifactFormData>
  onChange: (change: Partial<ListingArtifactFormData>) => void
  submittable?: boolean // Meaning should this wizard expose the submit button or should it be controlled above this?
  artifact: 'product' | 'service' | 'use case'
}

export const isListingArtifactSubmittable = (formData: Partial<ListingArtifactFormData>) => {
  // Required fields
  const required = [formData.name].every((f) => !!f)
  const valid = [
    validateLength(formData.name, 2),
    validateLength(formData.description, 2),
    formData.url ? validateWebsite(formData.url) : undefined,
  ].every((v) => !v)

  const validAttachments = formData.listingArtifactAttachmentsAttributes
    ? formData.listingArtifactAttachmentsAttributes?.every((v) => !!v.file)
    : true
  const validLinks = formData.listingArtifactLinksAttributes
    ? formData.listingArtifactLinksAttributes?.every((v) => !validateWebsite(v.url) && !!v.name)
    : true

  return required && valid && validAttachments && validLinks
}

export const ListingArtifactFormWizard = (props: GenericListingArtifactFormFragmentProps) => {
  const steps = useMemo(
    () => [
      {
        label: titleCase(props.artifact),
        render: (_props: WizardStepProps<Partial<ListingArtifactFormData>>) => (
          <GenericListingArtifactFormFragment
            artifact={props.artifact}
            formData={extractGenericListingArtifactFormData(_props.formData)}
            onChange={(change) => _props.onChange({ ..._props.formData, ...change })}
          />
        ),
      },
      {
        label: 'Files',
        render: (_props: WizardStepProps<Partial<ListingArtifactFormData>>) => (
          <BulkAttachments
            formData={_props.formData.listingArtifactAttachmentsAttributes || []}
            onChange={(change) =>
              _props.onChange({ ..._props.formData, listingArtifactAttachmentsAttributes: change })
            }
          />
        ),
      },
      {
        label: 'Links',
        render: (_props: WizardStepProps<Partial<ListingArtifactFormData>>) => (
          <BulkLinks
            formData={_props.formData.listingArtifactLinksAttributes || []}
            onChange={(change) =>
              _props.onChange({ ..._props.formData, listingArtifactLinksAttributes: change })
            }
          />
        ),
      },
    ],
    [props.artifact],
  )

  return (
    <section className="ListingArtifactFormWizard" data-testid="ListingArtifactFormWizard">
      <Wizard
        steps={steps}
        formData={props.formData}
        onComplete={() => {}}
        onFormDataChange={props.onChange}
        canComplete={isListingArtifactSubmittable}
        submittable={false}
      />
    </section>
  )
}
