import { PaginationData } from '../../lib/types'
import { PaginationParams, ServerPageData } from '../../lib/types/Params'
import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { Spinner } from './Spinner'
import { CustomInformationPopup } from './'

export interface DropdownItem {
  id: string | number
  label: string
  description?: string
}

type TProps<T extends DropdownItem> = {
  getData: (params: PaginationParams) => Promise<ServerPageData<T>>
  onSelect: (item: T) => void
  selected?: T
  placeholder?: string
  isMultiselect?: boolean
  scrollLoadOffsetPx?: number // default 50
  loadingDebounceMs?: number
  testId?: string
  className?: string
  isOpen?: boolean
  onSelectNone?: () => void
  infoIconClassName?: string // must match className for CustomInformationPop
  required?: boolean
  normalPlaceholderFont?: boolean
}

export const ServerBackedDropdown = <T extends DropdownItem>(props: TProps<T>) => {
  const [selected, setSelected] = useState<T | undefined>(props.selected)
  const [items, setItems] = useState<Array<T> | undefined>(props.isOpen ? [] : undefined)
  const [lastPage, setLastPage] = useState<PaginationData | undefined>(undefined)
  const [isOpen, toggleIsOpen] = useState<boolean>(props.isOpen || false)
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
  const [loadingError, setLoadingError] = useState<any>(undefined)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const itemRefs = useRef<Array<HTMLDivElement>>([])
  const ref: any = useRef(null)

  const handleClickOutside = (e: MouseEvent | FocusEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      toggleIsOpen(false)
    }
  }

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      toggleIsOpen(false)
    }
  }

  const handleOpen = () => {
    if (!items || items.length === 0) {
      extendData()
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      handleOpen()
    }
  }, [props.isOpen, props.selected, items])

  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('focusin', handleClickOutside)
      document.addEventListener('keydown', handleEscape)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('focusin', handleClickOutside)
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isOpen])

  const extendData = async () => {
    if (!isLoadingData) {
      setIsLoadingData(true)
    }
    try {
      const data = await props.getData({ page: lastPage ? lastPage.page + 1 : 1, perPage: 10 })
      setItems([...(items || []), ...data.data])
      setLastPage(data.pagination)
    } catch (e) {
      if (!items) {
        setItems([])
      }
      setLoadingError(e)
    } finally {
      setTimeout(() => setIsLoadingData(false), props.loadingDebounceMs || 200)
    }
  }

  const onScroll = (e: any) => {
    if (isLoadingData || loadingError || (lastPage && lastPage.page === lastPage.pages)) {
      return
    }

    const offset = props.scrollLoadOffsetPx || 50
    if (e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - offset) {
      extendData()
    }
  }

  const handleClick = () => {
    if (isOpen) {
      toggleIsOpen(false)
    } else {
      toggleIsOpen(true)
      if (!items) {
        extendData()
      }
    }
  }

  const handleSelect = (item: T) => {
    setSelected(item)
    props.onSelect(item)
    toggleIsOpen(false)
  }

  const handleNoneSelect = () => {
    if (props.onSelectNone) {
      setSelected(undefined)
      props.onSelectNone()
      toggleIsOpen(false)
    }
  }

  const renderItems = () => {
    if (!items) {
      return <span />
    }

    return (
      <div
        data-testid={`dropdown-menu${props.testId || ''}`}
        className={classnames('dropdown-menu', {
          active: isOpen,
        })}
        onScroll={onScroll}
      >
        {props.onSelectNone ? (
          <div
            data-testid="dropdown-item-select-none"
            tabIndex={-1}
            className="dropdown-item clickable select-none"
            onClick={() => handleNoneSelect()}
            role="option"
            aria-selected={false}
            ref={(element) => {
              if (element) {
                itemRefs.current[0] = element
              }
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                (e.target && (e.target as HTMLButtonElement)).className !==
                  `${props.infoIconClassName}-info-icon`
              ) {
                handleNoneSelect()
                dropdownRef.current && dropdownRef.current.focus()
              } else if (e.key === 'ArrowDown') {
                e.preventDefault()
                itemRefs.current.length > 1 && itemRefs.current[1].focus()
              } else if (e.key === 'ArrowUp') {
                e.preventDefault()
                dropdownRef.current && dropdownRef.current.focus()
              }
            }}
          >
            Select None
          </div>
        ) : null}
        {items.map((i, idx) => {
          return (
            <div
              key={i.id}
              data-testid={`dropdown-item-${i.label?.toLowerCase() || ''}`}
              className={classnames('dropdown-item clickable', {
                selected: selected && i.id === selected.id,
              })}
              aria-selected={selected && i.id === selected.id}
              id={i.label}
              tabIndex={-1}
              role="option"
              onClick={() => handleSelect(i)}
              ref={(element) => {
                if (element) {
                  itemRefs.current[props.onSelectNone ? idx + 1 : idx] = element
                }
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Enter' &&
                  (e.target && (e.target as HTMLButtonElement)).className !==
                    `${props.infoIconClassName}-info-icon`
                ) {
                  handleSelect(i)
                  dropdownRef.current && dropdownRef.current.focus()
                } else if (e.key === 'ArrowDown') {
                  e.preventDefault()
                  let nextItemIndex = props.onSelectNone ? idx + 2 : idx + 1
                  nextItemIndex < itemRefs.current.length && itemRefs.current[nextItemIndex].focus()
                } else if (e.key === 'ArrowUp' && idx === 0) {
                  e.preventDefault()
                  props.onSelectNone
                    ? itemRefs.current[0].focus()
                    : dropdownRef.current && dropdownRef.current.focus()
                } else if (e.key === 'ArrowUp') {
                  e.preventDefault()
                  let previousItemIndex = props.onSelectNone ? idx : idx - 1
                  previousItemIndex > -1 && itemRefs.current[previousItemIndex].focus()
                }
              }}
            >
              <div className="label-description-container">
                <p>{i.label}</p>
                {i.description && props.infoIconClassName && (
                  <CustomInformationPopup
                    infoIconClassName={props.infoIconClassName}
                    hasInfoIcon={true}
                    ariaLabel={`More info about ${i.label}`}
                    information={i.description}
                  />
                )}
              </div>

              {i.description && !props.infoIconClassName && (
                <span className={'dropdown-option-sublabel subtle'}>{i.description}</span>
              )}
            </div>
          )
        })}
        {isLoadingData && (
          <div
            data-testid={`dropdown-item-loader${props.testId || ''}`}
            className="dropdown-item no-click dropdown-item-loader"
          >
            <Spinner className="dropdown-item-spinner" />
          </div>
        )}
        {!!loadingError && (
          <div
            data-testid={`dropdown-item-error${props.testId || ''}`}
            className="dropdown-item no-click dropdown-item-error"
          >
            <span>Error loading data...</span>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={`ServerBackedDropdown ${props.className || ''}`} ref={ref}>
      {props.required && <i className="fas fa-asterisk required-icon"></i>}
      <div className="dropdown">
        <div
          data-testid={`dropdown-input${props.testId || ''}`}
          className="clickable dropdown-input"
          ref={dropdownRef}
          tabIndex={0}
          role="listbox"
          onClick={handleClick}
          aria-required={props.required}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClick()
            } else if (e.key === 'ArrowDown') {
              e.preventDefault()
              toggleIsOpen(true)
              handleOpen()
              itemRefs.current.length && itemRefs.current[0].focus()
            }
          }}
        >
          <p
            className={classnames('dropdown-label', {
              subtle: !props.normalPlaceholderFont && !selected,
            })}
            data-testid={`dropdown-value-or-placeholder${props.testId || ''}`}
          >
            {selected ? selected.label : props.placeholder || 'Select option...'}
          </p>
          {isOpen ? <i className="fas fa-caret-down" /> : <i className="fas fa-caret-up" />}
        </div>

        {isOpen && renderItems()}
      </div>
    </div>
  )
}
