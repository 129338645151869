import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CCol, CContainer, CForm, CFormCheck, CRow } from '@coreui/react'
import { ApplicationStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { hasGovRole } from '../../stores/UserStore'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
}
export const UserSettingsSection = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const isGovUser = hasGovRole(props.userStore?.currentUserData!)

    const [allowMessageRequests, setAllowMessageRequests] = useState<boolean | undefined>(undefined)
    const [allowInAppNotifications, setAllowInApp] = useState<boolean | undefined>(undefined)
    const [allowByEmailNotifications, setAllowByEmail] = useState<boolean | undefined>(undefined)

    const [isLoadingMessagePermission, setIsLoadingMessagePermission] = useState<boolean>(false)
    const [isLoadingInAppNotification, setIsLoadingInAppNotification] = useState<boolean>(false)
    const [isLoadingByEmailNotification, setIsLoadingByEmailNotification] = useState<boolean>(false)

    useEffect(() => {
      props.userStore
        ?.retrieveCurrentUserData(
          'govUserSetting,userEmailNotificationSetting,userAppNotificationSetting',
        )
        .then((data) => {
          let allowMessageRequests =
            ('govUserSetting' in data && data.govUserSetting.allowMessageRequests) || false
          isGovUser && setAllowMessageRequests(allowMessageRequests)
          setAllowInApp(data.userAppNotificationSetting?.newChatMessage || false)
          setAllowByEmail(data.userEmailNotificationSetting?.newChatMessage || false)
        })
        .catch((e) => {
          props.notificationStore?.setNotificationMessage(
            'Unable to get user settings. Please try again.',
            'danger',
            3000,
          )
        })

      return () => {}
    }, [])

    const messagePermissionOnClick = () => {
      handleClick(setIsLoadingMessagePermission, { allowMessageRequests: !allowMessageRequests })
    }

    const inAppNotificationOnClick = () => {
      handleClick(setIsLoadingInAppNotification, {
        allowInAppNotifications: !allowInAppNotifications,
      })
    }

    const byEmailOnClick = () => {
      handleClick(setIsLoadingByEmailNotification, {
        allowByEmailNotifications: !allowByEmailNotifications,
      })
    }

    const handleClick = async (
      setIsLoadingFunction: Dispatch<SetStateAction<boolean>>,
      params: {
        allowMessageRequests?: boolean
        allowInAppNotifications?: boolean
        allowByEmailNotifications?: boolean
      },
    ) => {
      setIsLoadingFunction(true)

      props
        .userStore!.updateUserSettings(params)
        .then((data) => {
          isGovUser && setAllowMessageRequests(data.govUserSetting?.allowMessageRequests)
          setAllowInApp(data.userAppNotificationSetting!.newChatMessage!)
          setAllowByEmail(data.userEmailNotificationSetting!.newChatMessage!)
        })
        .catch((e) => {
          props.notificationStore?.setNotificationMessage(
            'Unable to update user settings. Please try again.',
            'danger',
            3000,
          )
        })
        .finally(() => {
          setIsLoadingFunction(false)
        })
    }
    let MessagePermissionsCheckbox = (props: TProps) => {
      return (
        <CForm>
          <CFormCheck
            data-testid="message-permissions-checkbox"
            type="checkbox"
            name="message-permissions-checkbox"
            id="message-permissions-checkbox"
            label="Allow New Company Messages"
            checked={allowMessageRequests}
            onClick={messagePermissionOnClick}
            onChange={() => {}} //to satisfy CoreUI minimum.
            disabled={isLoadingMessagePermission}
          />
        </CForm>
      )
    }
    let InAppNotificationsCheckbox = (props: TProps) => {
      return (
        <CForm>
          <CFormCheck
            data-testid="in-app-notification-checkbox"
            type="checkbox"
            name="in-app-notification-checkbox"
            id="in-app-notification-checkbox"
            label="Notify Me In App On New Chat Messages"
            checked={allowInAppNotifications}
            onClick={inAppNotificationOnClick}
            onChange={() => {}} //to satisfy CoreUI minimum.
            disabled={isLoadingInAppNotification}
          />
        </CForm>
      )
    }
    let ByEmailNotificationsCheckbox = (props: TProps) => {
      return (
        <CForm>
          <CFormCheck
            data-testid="by-email-notification-checkbox"
            type="checkbox"
            name="by-email-notification-checkbox"
            id="by-email-notification-checkbox"
            label="Notify Me By Email On New Chat Messages"
            checked={allowByEmailNotifications}
            onClick={byEmailOnClick}
            onChange={() => {}} //to satisfy CoreUI minimum.
            disabled={isLoadingByEmailNotification}
          />
        </CForm>
      )
    }

    let MessagePermissionsSettings = (props: TProps) => {
      return (
        <>
          <h5 className="mt-2">Privacy</h5>
          <div className="gov-privacy-settings">
            <MessagePermissionsCheckbox {...props} />
          </div>
        </>
      )
    }

    let NotificationsSettings = (props: TProps) => {
      return (
        <>
          <h5 className="mt-2">Notifications</h5>
          <div className="gov-notifications-settings">
            <InAppNotificationsCheckbox {...props} />
            <ByEmailNotificationsCheckbox {...props} />
          </div>
        </>
      )
    }

    let GovUserSettings = (props: TProps) => {
      return (
        <CRow className="action-row">
          <CCol>
            <MessagePermissionsSettings {...props} />
          </CCol>
        </CRow>
      )
    }

    let UserSettings = (props: TProps) => {
      return (
        <CRow className="action-row">
          <CCol>
            <NotificationsSettings {...props} />
          </CCol>
        </CRow>
      )
    }

    return (
      <CContainer className="p-0" data-testid="user-settings">
        {isGovUser && <GovUserSettings {...props} />}
        <UserSettings {...props} />
      </CContainer>
    )
  }),
)
