import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Collapsible, UrlFederatedTechnologyTypesMultiSelectAutoComplete } from '../../../custom'
import { DebouncedInput } from '@mobilizeyourtech/vision-core-react'
import { objectContainsAnyValue } from '../../../../lib/helpers/utils'

export const ConnectCollapsibleServiceSearch = () => {
  const [params, setParams] = useQueryParams({
    searchServices: withDefault(StringParam, undefined),
    serviceTechnologyTypeIds: withDefault(StringParam, undefined),
  })

  return (
    <Collapsible
      headerText="Services"
      className="services"
      isExpanded={objectContainsAnyValue(params)}
    >
      <section className="IgniteCollapsibleServiceSearch">
        <DebouncedInput
          placeholder="Search Services"
          data-testid="search-services"
          className="mb-2"
          value={params.searchServices || ''}
          onChange={(value) =>
            setParams({
              ...params,
              searchServices: value,
            })
          }
        />

        <div className="mb-2">
          <UrlFederatedTechnologyTypesMultiSelectAutoComplete
            paramKey="serviceTechnologyTypeIds"
            dataTestId={'tech-service'}
          />
        </div>
      </section>
    </Collapsible>
  )
}
