import React from 'react'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { months } from '../../lib/helpers/utils'

type TProps = {
  data?: Array<Record<string, any>>
  chartLines: Array<{ displayName: string; dataKey: string; color: string }>
  xAxis: string
  chartId: string
  width?: number
}

const formatLegend = (value: string) => {
  return <span style={{ color: '#000000' }}>{`${value}`}</span>
}

const formatXDate = (iprops: any) => {
  let date = new Date(iprops.payload.value)
  let formattedDate = `${months[date.getUTCMonth()]?.substr(0, 3)} ${date
    .getUTCFullYear()
    .toString()
    .substr(-2)}`
  return (
    <g
      transform={`translate(${iprops.x},${iprops.y})`}
      data-testid={`x-axis-${iprops.payload.value}`}
      tabIndex={0}
    >
      <text x={0} y={0} dy={16} textAnchor="middle">
        {formattedDate}
      </text>
    </g>
  )
}

export const IgniteLineChart = (props: TProps) => {
  const renderLines = () => {
    return props.chartLines.map((chartLine) => {
      let total = props.data?.map((a: Record<string, any>) => a[chartLine.dataKey])?.slice(-1)
      let label = chartLine.displayName + ' (' + (total ? total : '0') + ')'

      return (
        <Line
          key={chartLine.dataKey}
          isAnimationActive={!props.chartLines}
          name={label}
          type="monotone"
          dataKey={chartLine.dataKey}
          stroke={chartLine.color}
          dot={{ fill: chartLine.color }}
        />
      )
    })
  }

  return (
    <div data-testid={props.chartId}>
      <ResponsiveContainer width={props.width || '100%'} height={300} debounce={0}>
        <LineChart id={props.chartId} data={props.data} margin={{ right: 40, top: 20 }}>
          <XAxis
            dataKey={props.xAxis}
            name={props.xAxis}
            padding={{ left: 10, right: 10 }}
            tick={formatXDate}
          />
          <YAxis padding={{ bottom: 10 }} />
          <Tooltip />
          {renderLines()}
          <Legend
            iconType="circle"
            layout="horizontal"
            verticalAlign="top"
            align="right"
            wrapperStyle={{ paddingBottom: '20px' }}
            formatter={formatLegend}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
