import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { OrganizationUserStatistic } from '../../../../stores/AnalyticsStore'

type TProps = {
  data?: Array<OrganizationUserStatistic>
}

export const OrganizationUserStatisticTable = (props: TProps) => {
  const tableRow = (rowData: OrganizationUserStatistic) => {
    return (
      <CTableRow key={rowData.organization}>
        <CTableDataCell
          data-testid="organization-user-statistics-name-container"
          className="text-center organization-user-statistics-name-container"
        >
          {rowData.organization}
        </CTableDataCell>
        <CTableDataCell
          data-testid="organization-user-statistic-count-container"
          className="text-center"
        >
          <h3 className="m-0">{rowData.usersCount}</h3>
        </CTableDataCell>
      </CTableRow>
    )
  }

  const OrganizationUsersTable = () => {
    return (
      <CTable
        bordered
        data-testid="organization-user-statistic-table"
        id="organization-user-statistic-table"
      >
        <CTableHead color="dark">
          <CTableRow>
            <CTableHeaderCell scope="col" className="w-50 text-center">
              <h3 className="m-0">Organization</h3>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="w-50 text-center">
              <h3 className="m-0">Number of Users</h3>
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {props.data?.map((row) => {
            return tableRow(row)
          })}
        </CTableBody>
      </CTable>
    )
  }

  return <div id="organization-user-statistic-table-container">{OrganizationUsersTable()}</div>
}

export default OrganizationUserStatisticTable
