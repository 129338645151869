import { inject, observer } from 'mobx-react'

import { ApplicationStore, AccountStore, NotificationStore, UserStore } from '../../stores'
import { CustomModal, SuspensefulButton, validateLength } from '@mobilizeyourtech/vision-core-react'
import { CCol, CModalBody, CModalFooter } from '@coreui/react'
import { InviteUserForm } from '.'
import { useState } from 'react'
import { UserInviteData } from '../../stores/AccountStore'
import { InvitedUserData } from '../../stores'
import { validateEmail } from '../../lib/helpers/validation'

type TProps = {
  accountStore?: AccountStore
  notificationStore?: NotificationStore
  userStore?: UserStore
  isModalOpen: boolean
  userInviteId: string | number
  currentAccountId: string | number
  formData: InvitedUserData
  closeModal: () => void
  onSubmit: () => void
}

export const EditInviteModal = inject(
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.accountStore,
  ApplicationStore.names.userStore,
)(
  observer((props: TProps) => {
    const [formData, setFormData] = useState<Partial<UserInviteData>>(props.formData)
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const isSubmittable = (): boolean => {
      let firstNameError = validateLength(formData.firstName, 2)
      let lastNameError = validateLength(formData.lastName, 2)
      let emailError = validateEmail(formData.email)

      return !firstNameError && !lastNameError && !emailError
    }

    const handleSubmit = () => {
      if (!isSubmittable()) return

      setHasValidated(true)
      setIsSubmitting(true)
      props
        .accountStore!.editUserInvite(props.currentAccountId, props.userInviteId!, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        })
        .then(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'User invite updated successfully.',
            'success',
            3000,
          )
          props.onSubmit()
        })
        .catch(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Unable to update invite, please try again.',
            'danger',
            3000,
          )
        })
    }

    return (
      <CustomModal
        data-testid="CustomModal"
        className="CustomModal"
        isModalOpen={props.isModalOpen}
        onClose={props.closeModal}
        scrollable
        headerContent={'Edit Invite'}
      >
        <CModalBody data-testid="modal-body-wrapper">
          <div>
            <InviteUserForm
              hasValidated={hasValidated}
              onChange={(change: Partial<UserInviteData>) => {
                setFormData(change)
              }}
              formData={formData}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CCol sm={12} className="text-end px-3 py-2">
            <SuspensefulButton
              buttonText="Submit"
              isSuspended={isSubmitting}
              onInvoke={handleSubmit}
              disabled={!isSubmittable() || isSubmitting}
              data-testid="submit-button"
            />
          </CCol>
        </CModalFooter>
      </CustomModal>
    )
  }),
)
