import {
  isDirtySelectableItem,
  ServerBackedMultiSelectAutoComplete,
  PaginationParams,
} from '@mobilizeyourtech/vision-core-react'
import { useEffect, useState } from 'react'
import { initializeSearchDropdown } from './'
import { IBusinessClassifications } from '../form/fragments/listing'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore, ConnectListingStore } from '../../stores'

export interface UrlFederatedIgniteBusinessClassificationsMultiSelectAutoCompleteProps {
  paramKey: 'listingBusinessClassificationIds'
  connectListingStore?: ConnectListingStore
  notificationStore?: NotificationStore
}
export const UrlFederatedBusinessClassificationsMultiSelectAutoComplete = inject(
  ApplicationStore.names.connectListingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: UrlFederatedIgniteBusinessClassificationsMultiSelectAutoCompleteProps) => {
    const [params, setParams] = useQueryParams({
      [props.paramKey]: withDefault(StringParam, undefined),
    })
    const [selectedBusinessClassifications, setSelectedBusinessClassifications] = useState<
      Array<IBusinessClassifications>
    >([])

    useEffect(() => {
      if (params[props.paramKey] == null || params[props.paramKey]?.split(',').length === 0) {
        setSelectedBusinessClassifications([])
      } else {
        const currentIds = selectedBusinessClassifications
          .map((item) => item.id.toString())
          .sort()
          .join(',')
        const paramIds = params[props.paramKey]!.split(',').sort().join(',')

        if (currentIds !== paramIds) {
          initializeSearchDropdown(
            {
              ids: params.listingBusinessClassificationIds,
              getDropdownData: getBusinessClassifications,
              setSelected: setSelectedBusinessClassifications,
              typeForErrorMessage: 'business classifications',
            },
            props.notificationStore!,
          )
        }
      }
    }, [params[props.paramKey]])

    //Business Classifications
    const getBusinessClassifications = async (p: PaginationParams & { ids?: string }) => {
      return props
        .connectListingStore!.getConnectBusinessClassifications(p)
        .then((response) => response)
    }

    const selectBusinessClassifications = (selected: Array<IBusinessClassifications>) => {
      setParams({
        ...params,
        listingBusinessClassificationIds:
          selected.length === 0 ? undefined : selected.map((e) => e.id).join(','),
      })

      setSelectedBusinessClassifications(selected)
    }

    const getSelectedBusinessClassifications = () => {
      return selectedBusinessClassifications.map((t) => {
        return { ...t, label: t.name }
      })
    }

    return (
      <ServerBackedMultiSelectAutoComplete
        data-testid={'business'}
        loadMore={getBusinessClassifications}
        selections={getSelectedBusinessClassifications()}
        // DirtySelectableItems are only possible when allowDirty === true
        onChange={(selected) => {
          let _selected = selected.filter(
            (e) => !isDirtySelectableItem(e),
          ) as Array<IBusinessClassifications>
          selectBusinessClassifications(_selected)
        }}
        placeholder={'Select Business Classifications'}
        reloadOnOpen
      />
    )
  }),
)
