export const leftArrow = () => {
  return (
    <svg
      className="left-arrow"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <g transform="translate(50 50) scale(0.69 0.69) rotate(-180) translate(-50 -50)">
        <svg
          xmlnsdc="http://purl.org/dc/elements/1.1/"
          xmlnsrdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
          xmlnssvg="http://www.w3.org/2000/svg"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
        >
          <g transform="translate(0,-952.36218)">
            <path
              d="m 77.247459,1000.3632 -15.99914,-17.99999 c -1.03011,-1.22844 -3.06617,-1.33786 -4.26072,-0.26859 -1.1944,1.06926 -1.315,3.10913 -0.2078,4.26859 l 11.56189,13 -43.3418,0 c -1.6568,0 -2.9999,1.34319 -2.9999,2.99999 0,1.6569 1.3431,3 2.9999,3 l 43.3418,0 -11.56189,13 c -1.1072,1.1595 -0.9574,3.1957 0.2371,4.265 1.19449,1.0692 3.20131,0.9634 4.23142,-0.265 l 15.99914,-18 c 1.04459,-1.4553 0.96135,-2.7618 0,-4 z"
              fillOpacity="1"
              stroke="none"
              marker="none"
              visibility="visible"
              display="inline"
              overflow="visible"
            ></path>
          </g>
        </svg>
      </g>
    </svg>
  )
}
