export const visionLogoCobalt = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.3499 4.22001L53.0699 41.87H75.3399L34.9999 94.76C39.5481 95.9647 44.2349 96.5665 48.9399 96.55C77.1299 96.55 97.6199 76.24 97.6199 48.4C97.5899 27.76 86.4699 11.41 69.3499 4.22001ZM22.6599 54.12L62.4999 1.92001C58.0574 0.798099 53.4919 0.237065 48.9099 0.250005C20.7199 0.250005 0.409912 20.38 0.409912 48.4C0.409912 68.84 11.4099 85.22 28.3399 92.49L44.9299 54.12H22.6599Z"
        fill="#005CB9"
      />
    </svg>
  )
}
