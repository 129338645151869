import React from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore, NotificationStore } from '../../../../stores'
import { PaginatedSearchableParams } from '../../../../lib/types/Params'
import {
  isDirtySelectableItem,
  SelectableItem,
  ServerBackedMultiSelectAutoComplete,
} from '@mobilizeyourtech/vision-core-react'
import { ListingArtifactTechnologyType } from '../../../../stores/ListingStore'
import { TechnologyTypeResponse } from '../../../../stores/ServerStore'

type TProps = {
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  selected: Array<(ListingArtifactTechnologyType & SelectableItem) | TechnologyTypeResponse>
  onChange: (
    nc: Array<(ListingArtifactTechnologyType & SelectableItem) | TechnologyTypeResponse>,
  ) => void
}

export const ListingArtifactTechnologyFocusAreas = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const getTechnologyFocusAreas = (params: PaginatedSearchableParams) => {
      return props.serverStore!.getTechnologyTypes(params).then((response) => ({
        pagination: response.pagination,
        data: response.data.map((r) => {
          return { ...r, label: r.name }
        }),
      }))
    }

    const getSelectedTechnologyFocusAreas = () => {
      return props.selected.map((t) => {
        return { ...t, label: t.name }
      })
    }

    return (
      <section className="TechnologyFocusAreas">
        <ServerBackedMultiSelectAutoComplete
          loadMore={getTechnologyFocusAreas}
          onChange={(change) =>
            // DirtySelectableItem is only possible if allowDirty were set to tru
            props.onChange(
              change.filter((e) => !isDirtySelectableItem(e)) as Array<
                ListingArtifactTechnologyType & SelectableItem
              >,
            )
          }
          selections={getSelectedTechnologyFocusAreas()}
          placeholder={'Search and select multiple technologies'}
          className="technology-focus-areas"
          data-testid={'technology-focus-areas-select'}
        />
      </section>
    )
  }),
)
