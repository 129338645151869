import { CCard } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { AnalyticsStore, ApplicationStore, NotificationStore } from '../../../stores'
import { OrganizationUserStatistic } from '../../../stores/AnalyticsStore'

import { PaginationData, SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import OrganizationUserTable from './tables/OrganizationUserStatisticTable'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'
import { Pagination } from '../../custom'

type TProps = {
  analyticsStore?: AnalyticsStore
  notificationStore?: NotificationStore
}

export const OrganizationUserStatistics = inject(
  ApplicationStore.names.analyticsStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [organizationUserStatistics, setOrganizationUserStatistics] = useState<
      Array<OrganizationUserStatistic>
    >([])
    const [pagination, setPagination] = useState<PaginationData | undefined>(undefined)
    const [paginationParams] = useQueryParams({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 10),
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>(undefined)

    const loadData = () => {
      setIsLoading(true)
      props.analyticsStore
        ?.getOrganizationUserStatistics(paginationParams)
        .then((response) => {
          setOrganizationUserStatistics(response.data)
          setPagination(response.pagination)
        })
        .catch((e) => {
          props.notificationStore?.setNotificationMessage(
            'Error loading Organization User Statistic Data',
            'error',
            3000,
          )
          setError(e)
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 300)
        })
    }

    useEffect(() => {
      loadData()
    }, [paginationParams])

    const Table = (props: { data?: OrganizationUserStatistic[] }) => {
      let noResult = (
        <div
          id="admin-analytics-line-chart-and-table-absent"
          data-testid="organization-user-view-table-absent"
          className="text-center"
        >
          Error occurred while loading results
        </div>
      )
      return (
        <div
          id="organization-user-statistics-container"
          className="user-card-container d-flex flex-wrap w-100 align-items-center justify-content-center"
        >
          <CCard className="card-body">
            <SuspensionBoundary data-testid="spinner" isLoading={isLoading}>
              <div className="row">
                <div className="col-sm-12 col-xl-10 offset-xl-1">
                  <div
                    className="stat-header"
                    id="admin-analytics-line-chart-and-table-card-header"
                  >
                    <h2>Organization Users</h2>
                  </div>
                  {!error ? <OrganizationUserTable data={props.data} /> : noResult}
                </div>
              </div>
            </SuspensionBoundary>
            <div className="pagination-container">
              {pagination && <Pagination pagination={pagination} defaultPerPage={10} />}
            </div>
          </CCard>
        </div>
      )
    }
    return <Table data={organizationUserStatistics} />
  }),
)

export default <OrganizationUserStatistics />
