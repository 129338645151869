import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Collapsible, CustomDropdown } from '../../../custom'
import {
  UrlFederatedNaicsCodeMultiSelectAutoComplete,
  UrlFederatedTechnologyTypesMultiSelectAutoComplete,
  UrlFederatedBusinessClassificationsMultiSelectAutoComplete,
} from '../../../custom'
import { DebouncedInput } from '@mobilizeyourtech/vision-core-react'
import { objectContainsAnyValue } from '../../../../lib/helpers/utils'
import { isoUSStateCodes } from '../../../../lib/geography/countryRegionData'

export const ConnectCollapsibleCompanySearch = () => {
  const [params, setParams] = useQueryParams({
    searchListings: withDefault(StringParam, undefined),
    listingIdentifier: withDefault(StringParam, undefined),
    listingState: withDefault(StringParam, undefined),
  })

  return (
    <Collapsible headerText="Company Info" isExpanded={objectContainsAnyValue(params)}>
      <section className="IgniteCollapsibleCompanySearch">
        <DebouncedInput
          placeholder="Search Companies"
          className="mb-2"
          data-testid="search-listings"
          value={params.searchListings || ''}
          onChange={(value) =>
            setParams({
              ...params,
              searchListings: value,
            })
          }
        />
        <div className="d-flex flex-row gap-2 mb-2">
          <div className={`col`}>
            <DebouncedInput
              placeholder="Search Identifier"
              className="listing-identifier"
              data-testid="listing-identifier"
              value={params.listingIdentifier || ''}
              onChange={(value) =>
                setParams({
                  ...params,
                  listingIdentifier: value,
                })
              }
            />
          </div>
          <div className={`col`}>
            <CustomDropdown
              placeholder="Search State"
              CustomDropdownClass="mb-0"
              dropdownClass="darker-strokes-border"
              options={isoUSStateCodes}
              selectedOption={params.listingState}
              onSelect={(value) => {
                setParams({ ...params, listingState: value as string })
              }}
              onSelectNone={() => {
                setParams({ ...params, listingState: undefined })
              }}
            />
          </div>
        </div>

        <div className="mb-2">
          <UrlFederatedNaicsCodeMultiSelectAutoComplete paramKey="naicsCodeIds" />
        </div>
        <div className="mb-2">
          <UrlFederatedTechnologyTypesMultiSelectAutoComplete
            paramKey="listingTechnologyTypeIds"
            dataTestId={'tech-product'}
          />
        </div>
        <div className="mb-2">
          <UrlFederatedBusinessClassificationsMultiSelectAutoComplete paramKey="listingBusinessClassificationIds" />
        </div>
      </section>
    </Collapsible>
  )
}
