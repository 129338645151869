import React, { useState } from 'react'
import { FileUploader } from '@mobilizeyourtech/vision-core-react'
import { ImageCroppingTool } from './ImageCroppingTool'
import { CButton } from '@coreui/react'
import classnames from 'classnames'
import {
  FILE_UPLOAD_MAX_SIZE_BYTES,
  FILE_UPLOAD_MAX_SIZE_CONTENT,
  UNCLASSIFIED_WARNING_PROMPT,
} from '../../lib/helpers/utils'

export type TProps = {
  onFile: (file: File | undefined) => void
  value: File | string | undefined
  required?: boolean
  'data-testid'?: string
  hideUploaderPlaceholder?: boolean
}
enum Mode {
  UPLOAD,
  EDIT,
}
export const SingleImageUploader = (props: TProps) => {
  const [mode, setMode] = useState<Mode>(props.value ? Mode.EDIT : Mode.UPLOAD)
  const [value, setValue] = useState<File | string | undefined>(props.value)
  const [isValidationSuspended, setIsValidationSuspended] = useState<boolean>(false)

  const renderUploader = () => {
    return (
      <FileUploader
        onDrop={(acceptedFiles) => {
          setValue(acceptedFiles[0])
          setMode(Mode.EDIT)
        }}
        text="Drop image file or click to upload."
        subText={UNCLASSIFIED_WARNING_PROMPT}
        additionalText={FILE_UPLOAD_MAX_SIZE_CONTENT}
        accept={[
          { value: 'image/jpg', displayValue: 'JPEG' },
          { value: 'image/jpeg', displayValue: 'JPEG' },
          { value: 'image/png', displayValue: 'PNG' },
        ]}
        hidePlaceholder={props.hideUploaderPlaceholder}
        maxSize={FILE_UPLOAD_MAX_SIZE_BYTES}
      />
    )
  }

  const renderImageCroppingTool = () => {
    return (
      <ImageCroppingTool
        image={value!}
        onCrop={(value, suspense) => {
          setIsValidationSuspended(suspense)
          setValue(value)
          props.onFile(value)
        }}
      />
    )
  }

  const render = () => {
    switch (mode) {
      case Mode.UPLOAD:
        return renderUploader()
      case Mode.EDIT:
        return renderImageCroppingTool()
    }
  }

  return (
    <section
      className={classnames('SingleImageUploader d-flex h-100', {
        ValidatedInput: props.required,
        'form-control': props.required,
        'is-valid': props.required && props.value && !isValidationSuspended,
        'validation-suspended': isValidationSuspended,
      })}
      data-testid={props['data-testid']}
    >
      <div className="w-100">
        {props.required && (
          <i
            className={classnames('fas fa-asterisk required-icon', {
              valid: !!props.value,
            })}
          />
        )}
        {render()}
        <div className="image-tool-container">
          <CButton
            data-testid="select-upload-mode"
            className="icon-button"
            onClick={() => {
              setMode(Mode.UPLOAD)
              props.onFile(undefined)
            }}
          >
            <i className={classnames('fas fa-file-upload', { isActive: mode === Mode.UPLOAD })} />
          </CButton>
        </div>
      </div>

      {isValidationSuspended && (
        <div className={'h-100 d-flex align-items-center'}>
          <span className={'validation-suspense ps-2'}>
            <i className="fa fa-spinner fa-spin" />
          </span>
        </div>
      )}
    </section>
  )
}
