import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore, NotificationStore } from '../../../../stores'
import { PaginationParams } from '../../../../lib/types/Params'
import { DropdownItem, ServerBackedDropdown } from '../../../../components/custom'
import { GovUserOrganizationResponse } from '../../../../stores/ServerStore'

export interface GovUserOrganizationSelectorProps {
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  value?: GovUserOrganizationResponse
  onChange: (value?: GovUserOrganizationResponse & DropdownItem) => void
}

export const GovUserOrganizationSelector = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: GovUserOrganizationSelectorProps) => {
    const getGovUserOrganizations = (params: PaginationParams) => {
      return props.serverStore!.getGovUserOrganizations(params).then((response) => ({
        data: response.data.map((r) => ({
          ...r,
          id: r.id,
          label: r.shortName + ' - ' + r.longName,
        })),
        pagination: response.pagination,
      }))
    }

    const formatOrgName = () => {
      if (props.value) {
        return {
          ...props.value,
          label: `${props.value.shortName} - ${props.value.longName}`,
        }
      }
    }

    const selectedGovUserOrganization = useMemo(formatOrgName, [props.value])

    return (
      <section className="GovUserOrganizations" data-testid="BusinessClassifications-section">
        <ServerBackedDropdown
          className="select-gov-user-org"
          getData={getGovUserOrganizations}
          testId="-select-gov-user-org"
          onSelect={(val) => {
            props.onChange(val)
          }}
          onSelectNone={() => {
            props.onChange(undefined)
          }}
          placeholder="Organization"
          selected={selectedGovUserOrganization}
          loadingDebounceMs={200}
          required={true}
        />
      </section>
    )
  }),
)
