import { ServerPageData } from '@mobilizeyourtech/vision-core-react'
import { ServerStore } from './ServerStore'
import { AccountData, InvitedUserData, UserData } from './UserStore'

export type TermsOrPrivacyResponse = {
  id: string | number
  title: string
  content: string
  publishDate: string
}

export type UserInviteData = {
  firstName: string
  lastName: string
  email: string
}

export type UserInviteResponse = {
  id: number
  firstName: string
  lastName: string
  email: string
  accepted: boolean
  inviteTokenExpiry: string
}

export class AccountStore extends ServerStore {
  public baseCurrentUserURL = '/api/v1/current_user'

  public getDefaultAccountUsers(): Promise<Array<UserData>> {
    return this.server()
      .get(`${this.baseCurrentUserURL}/account/users`)
      .then(({ data }) => data)
  }

  public getAccountUsers(
    accountId: number,
    page: string | number,
    perPage: string | number,
    includeUserInvites: boolean,
  ): Promise<ServerPageData<UserData | InvitedUserData>> {
    return this.server()
      .get(`/api/v1/accounts/${accountId}/users`, {
        params: { page, perPage, includeUserInvites },
      })
      .then(({ data }) => data)
  }

  public inviteUserToAccount(
    accountId: string | number,
    userData: Partial<UserInviteData>,
  ): Promise<UserInviteResponse> {
    return this.server().post(`/api/v1/accounts/${accountId}/user_invites`, userData)
  }

  public fetchUserInvite(
    accountId: string | number,
    userInviteId: string | number,
  ): Promise<UserInviteResponse> {
    return this.server()
      .get(`/api/v1/accounts/${accountId}/user_invites/${userInviteId}`)
      .then(({ data }) => data)
  }

  public editUserInvite(
    accountId: string | number,
    userInviteId: string | number,
    userData: Partial<UserInviteData>,
  ): Promise<UserInviteResponse> {
    return this.server().patch(
      `/api/v1/accounts/${accountId}/user_invites/${userInviteId}`,
      userData,
    )
  }

  public resendUserInvite(
    accountId: string | number,
    userInviteId: string | number,
  ): Promise<UserInviteResponse> {
    return this.server().patch(`/api/v1/accounts/${accountId}/user_invites/${userInviteId}/resend`)
  }

  public deleteUserInvite(
    accountId: string | number,
    userInviteId: string | number,
  ): Promise<void> {
    return this.server().delete(`/api/v1/accounts/${accountId}/user_invites/${userInviteId}`)
  }

  public getLatestTerms(): Promise<TermsOrPrivacyResponse> {
    return this.server()
      .get<TermsOrPrivacyResponse>('/api/v1/terms')
      .then(({ data }) => data)
  }

  public getLatestPrivacy(): Promise<TermsOrPrivacyResponse> {
    return this.server()
      .get<TermsOrPrivacyResponse>('/api/v1/privacy')
      .then(({ data }) => data)
  }

  public getLatestAntiSpamPolicy(): Promise<TermsOrPrivacyResponse> {
    return this.server()
      .get<TermsOrPrivacyResponse>('/api/v1/anti_spam_policy')
      .then(({ data }) => data)
  }

  public signTerms(termId: string | number) {
    return this.server().post(`/api/v1/terms/${termId}/sign`)
  }

  public signPrivacy(privacyId: string | number) {
    return this.server().post(`/api/v1/privacy/${privacyId}/sign`)
  }

  public recordSetupPaymentSessionComplete(): Promise<AccountData> {
    return this.server()
      .patch(`/api/v1/current_user/account/mark_setup_session_complete`)
      .then(({ data }) => data)
  }
}
