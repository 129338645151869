import { ServerStore } from './ServerStore'
import { PaginationParams, ServerPageData } from '../lib/types/Params'

export type TechnologyTypeStatistic = {
  id: number
  listingAwardCount: number
  listingAwardPct: string
  listingCount: number
  listingPct: string
  listingProductCount: number
  listingProductPct: string
  listingServiceCount: number
  listingServicePct: string
  name: string
  usersCount: number
  usersPct: string
  visionUsersCount: number
  visionUsersPct: string
}

export type PieChartCellDataRecord = {
  testingId: string
  name: string
  value: number
  id: string | number
}

export type SBCTimelineStatistic = {
  claimedListingsTotal: number
  listingsTotal: number
  month: string
  usersTotal: number
}

export type ActiveConversationStatistic = {
  activeConversations: number
  messagesSent: number
  month: string
}

export type DafUsersStatistic = {
  userLogins: number
  messagesSent: number
  month: string
}

export type SbcUsersStatistic = {
  userLogins: number
  messagesSent: number
  month: string
}

export type GovUsersTimelineStatistic = {
  month: string
  usersTotal: number
  visionUsersTotal: number
}

export type ListingPageViewStatistic = {
  id: number
  name: string
  pageViews: number
}

export type OrganizationUserStatistic = {
  organization: string
  usersCount: number
}

export type GovUserChatResponseStatistic = {
  value: string | number | null
}

export type GovUserEngagedCompanies = {
  count: number
}

export class AnalyticsStore extends ServerStore {
  public getTechnologyTypeStatistics = async (): Promise<Array<TechnologyTypeStatistic>> => {
    return this.server()
      .get('/api/v1/admin/statistics/technology_types_statistic')
      .then(({ data }) => data as Array<TechnologyTypeStatistic>)
  }

  public getSBCTimelineStatistics = async (): Promise<Array<SBCTimelineStatistic>> => {
    return this.server()
      .get('/api/v1/admin/statistics/company_timeline_statistic')
      .then(({ data }) => data as Array<SBCTimelineStatistic>)
  }

  public getGovUsersTimelineStatistics = async (): Promise<Array<GovUsersTimelineStatistic>> => {
    return this.server()
      .get('/api/v1/admin/statistics/gov_users_timeline_statistic')
      .then(({ data }) => data as Array<GovUsersTimelineStatistic>)
  }

  public getListingPageViewStatistics(
    params: PaginationParams,
  ): Promise<ServerPageData<ListingPageViewStatistic>> {
    return this.server()
      .get('/api/v1/admin/statistics/listing_page_views_statistic', { params })
      .then(({ data }) => data)
  }

  public getGovUserEngagedCompaniesStatistic = async (): Promise<GovUserEngagedCompanies> => {
    return this.server()
      .get('/api/v1/admin/statistics/count_gov_user_engaged_companies_statistic')
      .then(({ data }) => data)
  }

  public getGovUserChatResponseStatistic = async (): Promise<GovUserChatResponseStatistic> => {
    return this.server()
      .get('/api/v1/admin/statistics/pct_gov_user_chat_response_statistic')
      .then(({ data }) => data)
  }

  public getActiveConversationStatistics = async (): Promise<
    Array<ActiveConversationStatistic>
  > => {
    return this.server()
      .get('/api/v1/admin/statistics/active_conversations_statistic')
      .then(({ data }) => data as Array<ActiveConversationStatistic>)
  }

  public getDafUserStatistics = async (): Promise<Array<DafUsersStatistic>> => {
    return this.server()
      .get('/api/v1/admin/statistics/daf_users_statistic')
      .then(({ data }) => data as Array<DafUsersStatistic>)
  }

  public getSbcUserStatistics = async (): Promise<Array<SbcUsersStatistic>> => {
    return this.server()
      .get('/api/v1/admin/statistics/sbc_users_statistic')
      .then(({ data }) => data as Array<SbcUsersStatistic>)
  }

  public getOrganizationUserStatistics(
    params: PaginationParams,
  ): Promise<ServerPageData<OrganizationUserStatistic>> {
    return this.server()
      .get('/api/v1/admin/statistics/organization_users_statistic', { params })
      .then(({ data }) => data)
  }
}
