import React, { useEffect } from 'react'
import { CPagination, CPaginationItem, CFormSelect } from '@coreui/react'
import { PaginationData } from '../../lib/types'
import { NumberParam, useQueryParams, withDefault } from 'use-query-params'
import classNames from 'classnames'

type TProps = {
  pagination: PaginationData
  defaultPerPage?: number
  customPerPages?: number[]
}

export const Pagination = (props: TProps) => {
  const AVAILABLE_PER_PAGES = [5, 10, 20]
  const [paginationParams, setPaginationParams] = useQueryParams({
    page: withDefault(NumberParam, props.pagination.page),
    perPage: withDefault(NumberParam, 10),
  })

  const isPreviousPageEnabled = paginationParams.page && Number(paginationParams.page) > 1

  const isNextPageEnabled = props.pagination.last > props.pagination.page

  useEffect(() => {
    if (props.pagination) {
      if (paginationParams.page != null) {
        if (paginationParams.page > props.pagination.pages) {
          setPaginationParams((current) => ({ ...current, page: props.pagination.pages }))
        } else if (paginationParams.page <= 0 || isNaN(paginationParams.page)) {
          setPaginationParams(() => ({ ...paginationParams, page: 1 }))
        }
      }

      if (paginationParams.perPage != null) {
        if (paginationParams.perPage <= 0 || isNaN(paginationParams.perPage)) {
          setPaginationParams((current) => ({ ...current, perPage: undefined }))
        }
      }
    }
  }, [paginationParams, props.pagination])

  return (
    <section className="Pagination pagination-container row">
      <div className="col-1">
        <CFormSelect
          size="sm"
          className="pagination-select"
          data-testid="pagination-entries-select"
          aria-label="Number of items per page"
          value={paginationParams.perPage || props.defaultPerPage || 10}
          onChange={(event) => {
            setPaginationParams({ perPage: Number(event.target.value), page: 1 })
          }}
        >
          {(props.customPerPages || AVAILABLE_PER_PAGES).map((perPage, index) => {
            return (
              <option
                className="pagination-select-item"
                data-testid={`select-option-${perPage}`}
                key={index}
                value={perPage}
              >
                {perPage}
              </option>
            )
          })}
        </CFormSelect>
      </div>
      <div className="col text-center">
        <p>
          {props.pagination.from} to {props.pagination.to} of {props.pagination.count} entries
        </p>
      </div>
      <div className="col-auto">
        <CPagination aria-label="Page navigation example">
          <CPaginationItem
            aria-label="Previous"
            aria-hidden="true"
            className={classNames('page-item', { disabled: !isPreviousPageEnabled })}
            data-testid={`previous-page-li`}
            disabled={!isPreviousPageEnabled}
            onClick={() => {
              isPreviousPageEnabled &&
                setPaginationParams({ page: Number(paginationParams.page) - 1 })
            }}
          >
            <span>&laquo;</span>
          </CPaginationItem>
          {props.pagination?.series.map((l: string | number) => {
            return (
              <CPaginationItem
                key={l}
                data-testid={`${l}-page-li`}
                className={classNames('page-item', {
                  active: props.pagination.page.toString() === l.toString(),
                  disabled: l === 'gap',
                })}
                onClick={() => {
                  setPaginationParams({ page: Number(l) })
                }}
              >
                {l === 'gap' ? '...' : l}
              </CPaginationItem>
            )
          })}
          <CPaginationItem
            aria-label="Next"
            aria-hidden="true"
            className={`page-item`}
            data-testid={`next-page-li`}
            disabled={!isNextPageEnabled}
            onClick={() => {
              isNextPageEnabled && setPaginationParams({ page: paginationParams.page + 1 })
            }}
          >
            <span>&raquo;</span>
          </CPaginationItem>
        </CPagination>
      </div>
    </section>
  )
}
