import React from 'react'
import { CCard, CCardBody, CCardHeader, CCardSubtitle, CCardTitle } from '@coreui/react'
import { AccountStore, ApplicationStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  accountStore?: AccountStore
}
export const AccountDetailCard = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.accountStore,
)(
  observer((props: TProps) => {
    const wrappedIcon = (className: string | undefined) => {
      if (className) {
        return (
          <div className="icon-wrapper">
            <i className={className} />
          </div>
        )
      }
      return undefined
    }

    return (
      <>
        <CCard className="ProfileDetailCard">
          <CCardHeader component="h5">Account Detail</CCardHeader>
          <CCardBody className="py-4">
            <CCardTitle className="pt-2">
              <b>{props.userStore?.currentAccountData?.name}</b>
            </CCardTitle>
            <CCardSubtitle className="pt-2 pb-3">
              {wrappedIcon('fas fa-id-badge')}
              {props.userStore?.currentAccountData?.uniqueEntityId}
            </CCardSubtitle>
          </CCardBody>
        </CCard>
      </>
    )
  }),
)
