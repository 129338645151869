import { CCard } from '@coreui/react'
import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { AnalyticsStore, ApplicationStore, NotificationStore } from '../../../stores'
import { GovUsersTimelineStatistic } from '../../../stores/AnalyticsStore'
import { AdminAnalyticsGovUsersTimelineLineChart } from './lineCharts/AdminAnalyticsGovUsersTimelineLineChart'

type TProps = {
  analyticsStore?: AnalyticsStore
  notificationStore?: NotificationStore
}
export const AdminAnalyticsGovUsersTimelineStatistics = inject(
  ApplicationStore.names.analyticsStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const [govUsersTimelineStatistics, setGovUsersTimelineStatistics] =
      useState<Array<GovUsersTimelineStatistic>>()

    useEffect(() => {
      setIsLoading(true)
      props
        .analyticsStore!.getGovUsersTimelineStatistics()
        .then((data) => {
          setGovUsersTimelineStatistics(data)
        })
        .catch((e) => {
          props.notificationStore?.setNotificationMessage(
            'Error loading Gov User Timeline Statistic Data',
            'error',
            3000,
          )
          setError(e)
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 300)
        })
    }, [])

    const LineChart = (props: { data?: GovUsersTimelineStatistic[] }) => {
      let noResult = (
        <div
          id="admin-analytics-line-chart-and-table-absent"
          data-testid="gov-users-timeline-chart-absent"
          className="text-center"
        >
          Error occurred while loading results
        </div>
      )

      return (
        <div>
          <CCard className="card-body">
            <SuspensionBoundary data-testid="spinner" isLoading={!error && isLoading}>
              <div className="row">
                <div className="col-sm-12 col-xl-10 offset-xl-1">
                  <div
                    className="stat-header"
                    id="admin-analytics-line-chart-and-table-card-header"
                  >
                    <h2 className="mb-0">Government Timelines</h2>
                  </div>
                  {!error ? (
                    <AdminAnalyticsGovUsersTimelineLineChart data={props.data} />
                  ) : (
                    noResult
                  )}
                </div>
              </div>
            </SuspensionBoundary>
          </CCard>
        </div>
      )
    }

    return <LineChart data={govUsersTimelineStatistics} />
  }),
)

export default <AdminAnalyticsGovUsersTimelineStatistics />
