import { CFormFeedback, CFormInput } from '@coreui/react'
import { InputHTMLAttributes, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import classNames from 'classnames'
import { CFormInputProps } from '@coreui/react/dist/components/form/CFormInput'

export type AsyncValidatedTextInputProps = Omit<
  CFormInputProps & InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value' | 'disabled'
> & {
  value?: string
  validate: (value: string) => Promise<string | undefined>
  makeValidationMessage: (invalidReason: string) => string | JSX.Element
  onValue: (value: string | undefined) => void
  asyncValidationDebounceMs?: number
  'data-testid'?: string
  placeholder?: string
  disabled?: boolean
}
export const AsyncValidatedTextInput = ({
  value: _value,
  validate: _validate,
  onValue,
  makeValidationMessage,
  asyncValidationDebounceMs,
  placeholder,
  disabled,
  ...props
}: AsyncValidatedTextInputProps) => {
  const [value, setValue] = useState<string | undefined>(_value)
  const [isValid, setIsValid] = useState<boolean | undefined>()
  const [invalidReason, setInvalidReason] = useState<string | undefined>()

  useEffect(() => {
    if (_value) {
      validate(_value)
    }
  }, [])

  const validate = async (value: string) => {
    const result = await _validate(value)
    setIsValid(result == null)
    setInvalidReason(result)

    if (result) {
      onValue(undefined)
    } else {
      onValue(value)
    }
  }

  const debouncedValidation = useDebouncedCallback(
    // function
    validate,
    // delay in ms
    asyncValidationDebounceMs == null ? 300 : asyncValidationDebounceMs,
  )

  const handleValueChange = (v: string) => {
    setValue(v)
    debouncedValidation(v)
  }

  const onBlur = () => {
    if (!value) {
      setIsValid(false)
      setInvalidReason('required')
      onValue(undefined)
      return
    }
    validate(value)
  }

  return (
    <section className={'ValidatedInput'}>
      <i
        className={classNames('fas fa-asterisk required-icon', {
          invalid: invalidReason,
          valid: isValid,
        })}
        data-testid={'test-required-icon'}
      />
      <CFormInput
        {...props}
        value={value || ''}
        onChange={({ target: { value: v } }) => {
          handleValueChange(v)
        }}
        valid={isValid}
        invalid={!!invalidReason}
        onBlur={onBlur}
        required
        placeholder={placeholder}
        data-testid={props['data-testid']}
        disabled={disabled}
      />
      {invalidReason && (
        <CFormFeedback invalid>
          {invalidReason !== 'required' && makeValidationMessage(invalidReason)}
        </CFormFeedback>
      )}
    </section>
  )
}
