import React, { SyntheticEvent, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../stores'
import { useHistory } from 'react-router-dom'
import MobilizeVISIONLogo from '../assets/brand/Ignite_Logo_Mobilize_Full_Color.png'
import { CButton, CFormInput } from '@coreui/react'
import { LegalLinks } from '../components/custom'

type TProps = {
  applicationStore?: ApplicationStore
  userStore?: UserStore
  authStore?: AuthStore
  notificationStore?: NotificationStore
}

export const SignIn = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.authStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const history = useHistory()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const mobilizeVisionLogo = MobilizeVISIONLogo

    const signInUser = (e: SyntheticEvent) => {
      e.preventDefault()

      return props.userStore
        ?.signInUser(email, password)
        .then(() => {
          props.notificationStore?.setNotificationMessage(
            'Successfully signed in!',
            'success',
            3000,
          )
          props.authStore?.generateAndSetSalt()
        })
        .catch((err) => {
          props.notificationStore?.setNotificationMessage(
            err.response?.data?.message || 'Failed to sign in, please try again later.',
            'danger',
            3000,
          )
        })
    }

    const goToClaim = () => {
      history.push('/claim')
    }

    const goToGovUserSignup = () => {
      history.push('/gov_user')
    }

    return (
      <section className="SignIn">
        <div className="sign-in-modal">
          <img
            src={mobilizeVisionLogo}
            className="MobilizeVisionLogo mb-3"
            alt="Mobilize Vision Logo"
          />
          <div className="sub-title text-center mt-3">
            <b>Login</b>
            <br />
            <p className={'text-center subtle mt-1 px-5'}>
              VISION users, if you haven’t created an Ignite account, click “Sign Up” below.
            </p>
          </div>
          <form className="sign-in-form" data-testid="sign-in-form" onSubmit={(e) => signInUser(e)}>
            <CFormInput
              type="email"
              id="email-input"
              data-testid="email-input"
              placeholder="Email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              value={email}
            />
            <CFormInput
              type="password"
              id="password-input"
              data-testid="password-input"
              placeholder="Password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              value={password}
            />
            <CButton
              type="submit"
              disabled={email === '' || password === ''}
              className="btn login m-2"
              data-testid="btn-login"
            >
              Login
            </CButton>
          </form>
          <a className="clickable" href="/request_password_reset" data-testid={'request-reset-btn'}>
            Forgot password?
          </a>
          <div className="sub-title text-center mt-3 mb-2">
            <b>Don&apos;t have an Ignite account?</b>
            <br />
          </div>
          <CButton className="btn create" onClick={goToClaim} data-testid={'create-account-btn'}>
            Claim an account (SBIR/STTR Companies)
          </CButton>
          <CButton
            className="btn signup mt-2 mb-5"
            color="dark"
            onClick={goToGovUserSignup}
            data-testid={'sign-up-btn'}
          >
            Sign Up (Government Users)
          </CButton>
          <div>
            <div>
              <span className={'me-2'}>
                <a
                  href="https://www.mobilizeyourtech.com/products/ignite/docs/ignite-faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IGNITE FAQ
                </a>
              </span>
              <span>
                <a href={'mailto:support@mobilizeignite.com'} target="_blank" rel="noreferrer">
                  support@mobilizeignite.com
                </a>
              </span>
            </div>
            <LegalLinks />
          </div>
        </div>
      </section>
    )
  }),
)
