import { CCol, CFormLabel, CRow } from '@coreui/react'
import React from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../../stores'
import { GovUserOrganizationSelector } from './gov'
import { GovUserExtendedDataFormData } from './types'
import { ServerBackedDropdown } from '../../custom'

export interface GovUserExtendedDataOrganizationFormProps {
  value: Partial<GovUserExtendedDataFormData>
  onChange: (value: Partial<GovUserExtendedDataFormData>) => void
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}
export const GovUserExtendedDataOrganizationForm = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: GovUserExtendedDataOrganizationFormProps) => {
    return (
      <>
        <CRow className="mb-3">
          <CFormLabel
            {...{ htmlFor: 'signupOrganizationName' }}
            className="col-sm-2 col-form-label"
          >
            Organization Name
          </CFormLabel>
          <CCol>
            <GovUserOrganizationSelector
              value={props.value.govUserOrganization}
              onChange={(value) => {
                props.onChange({
                  govUserOrganization: value,
                })
              }}
            />
          </CCol>
        </CRow>
        <CRow className="mb-3">
          <CFormLabel
            {...{ htmlFor: 'signupOrganizationName' }}
            className="col-sm-2 col-form-label"
          >
            Component / Detailed Organization
          </CFormLabel>
          <CCol className={'d-flex flex-column justify-content-center'}>
            <ServerBackedDropdown
              className="select-gov-user-program-office"
              getData={props.serverStore!.getGovUserProgramOffices}
              testId="-select-gov-user-program-office"
              onSelect={(val) => {
                props.onChange({
                  govUserProgramOffice: val,
                })
              }}
              onSelectNone={() => {
                props.onChange({
                  govUserProgramOffice: undefined,
                })
              }}
              placeholder="Component/Detailed Organization"
              selected={props.value.govUserProgramOffice}
              loadingDebounceMs={200}
            />
          </CCol>
        </CRow>
      </>
    )
  }),
)
