import { ServerStore, UserData } from './'
import {
  FileResponseData,
  GenericLabeledItemResponse,
  GenericNamedItemResponse,
} from '../lib/types'
import { PaginationParams, ServerPageData } from '../lib/types/Params'
import { ListingArtifactLinkFormData } from '../components/form/fragments/listing/BulkLinks'
import { ListingArtifactAttachmentFormData } from '../components/form/fragments/listing/BulkAttachments'
import { ListingOrganizationFormData } from '../components/form/fragments/listing/OrganizationDetails'
import { ListingContractingDetailsFormData } from '../components/form/fragments/listing/ContractingDetails'
import { ListingArtifactFormData } from '../components/form'
import { GenericSearchMetadata } from './UserStore'

export interface UserListingAccessesAttributes {
  userId: number
  isPointOfContact: boolean
}

export interface ListingTechnologyTypesAttributes {
  technologyTypeId: number
}

export interface ListingNaicsCodesAttributes {
  naicsCodeId: number
}

export interface ListingBusinessClassificationsAttributes {
  businessClassificationId: number
}

export interface ListingSocialMediaAccountAttributes {
  socialMediaTypeId: number
  identifier: string
}

export interface ListingFormData
  extends ListingOrganizationFormData,
    ListingContractingDetailsFormData {
  listingArtifactLinksAttributes?: Array<ListingArtifactLinkFormData>
  listingArtifactAttachmentsAttributes?: Array<ListingArtifactAttachmentFormData>
  listingArtifactTechnologyTypesAttributes?: Array<ListingArtifactTechnologyType>
}

export interface UpdateListingData {
  id: string | number
  name: string
  tagLine: string
  logo: File | FileResponseData
  yearFounded: string | number
  website: string
  pointOfContactId: string | number
  // HEADQUARTERS
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  stateOrProvince: string
  country: string
  postalCode: string
  // CONTRACT DETAILS
  samCode?: string
  dunsCode?: string
  naicsCodes?: Array<DestructibleItem>
  technologyTypes?: Array<DestructibleItem>
  capabilityStatement: string
  businessClassifications?: Array<DestructibleItem>
  socialMediaAccounts?: Array<ListingSocialMediaAccountAttributes>
  listingArtifactLinksAttributes?: Array<ListingArtifactLinkFormData>
  listingArtifactAttachmentsAttributes?: Array<ListingArtifactAttachmentFormData>
  listingArtifactTechnologyTypesAttributes?: Array<ListingArtifactTechnologyType>
}

export type ListingData = {
  id: string | number
  addressLine1?: string
  addressLine2?: string
  addressLine3?: string
  city?: string
  stateProvince?: string
  postalCode: string
  country: string
  capabilityStatement?: string
  cageCode?: string
  dunsCode?: string
  samCode?: string
  samRegistrationDate?: string
  samExpirationDate?: string
  productServiceCodes?: Array<ProductServiceCode>
  uniqueEntityId: string
  tagLine?: string
  website?: string
  accountId: string | number
  createdAt: string
  updatedAt: string
  inactiveDate: string
  yearFounded?: string
  name: string
  logo?: FileResponseData
  listingSocialMediaAccounts: string
  users: string
  businessClassifications: string
  naicsCodes: string
  technologyTypes: string
  offerings: string
  listingContracts: string
  isPublished?: boolean
  isOnlyVisionVisible?: boolean
  listingArtifactLinks?: Array<ListingArtifactLink>
  listingArtifactAttachments?: Array<ListingArtifactAttachment>
  pointOfContact?: UserData
  listingArtifactTechnologyTypes?: Array<ListingArtifactTechnologyType>
  views?: number
}

export type ConnectListingsResponse = {
  id: number
  name: string
  logo: FileResponseData
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  stateProvince: string
  postalCode: string
  country: string
  capabilityStatement: string
  dunsCode?: string
  samCode?: string
  uniqueEntityId: string
  tagLine: string
  website: string
  yearFounded: string
  createdAt: string
  updatedAt: string
  technologyTypes: Array<GenericNamedItemResponse>
}

export interface IgniteAwardQueryParams {
  eager?: string
  searchAwards?: string
  awardContractNumber?: string
  awardState?: string
  awardAmountMin?: number
  awardAmountMax?: number
  awardPhase?: string
  awardNaicsCodeIds?: string
  awardTechnologyTypeIds?: string
  awardDateMin?: string
  awardDateMax?: string
  sortBy?: string
  sortDirection?: string
  highlight?: string
}

export interface ListingArtifactPricing {
  id: string | number
  amount: number
  periodIncrement: string
  periodValue: number
}

export interface ListingArtifactLink {
  id: string | number
  name: string
  description: string
  url: string
}

export interface ListingArtifactAttachment {
  id: string | number
  name: string
  description: string
  file: FileResponseData
}

export interface ListingArtifactTechnologyType {
  artifactId: number
  technologyTypeId: number
  listingArtifactTechnologyTypeId: number
  name: string
}

export interface ProductServiceCode {
  id: string | number
  code: string
  description?: string
}

export const isListingArtifactTechnologyType = (o: any): o is ListingArtifactTechnologyType => {
  return 'listingArtifactTechnologyTypeId' in o && !!o['listingArtifactTechnologyTypeId']
}

export interface ListingServiceProductData {
  id: string | number
  listingId: string | number
  name: string
  description: string
  url?: string
  logo?: FileResponseData
  listingArtifactPricings?: Array<ListingArtifactPricing>
  listingArtifactLinks?: Array<ListingArtifactLink>
  listingArtifactAttachments?: Array<ListingArtifactAttachment>
  listingArtifactTechnologyTypes?: Array<ListingArtifactTechnologyType>
}

export interface ListingUseCaseData {
  id: string | number
  listingId: string | number
  name: string
  description: string
  url?: string
  logo?: FileResponseData
  startDate?: string
  endDate?: string
  listingArtifactLinks?: Array<ListingArtifactLink>
  listingArtifactAttachments?: Array<ListingArtifactAttachment>
  listingArtifactTechnologyTypes?: Array<ListingArtifactTechnologyType>
}

export interface ListingAwardData {
  id: number
  contractNumber: string
  contractAwardDate?: string
  contractStartDate?: string
  contractEndDate?: string
  awardAmount?: string
  awardTitle?: string
  productServiceCode?: string
  principleNaicsCode?: string
  state?: string
  awardAbstract?: string
  phase?: string
  branch?: string
  program?: string
  piName?: string
  piTitle?: string
  piPhone?: string
  piEmail?: string
  awardYear?: string
  researchInstitutionName?: string
  listingArtifactTechnologyTypes?: Array<ListingArtifactTechnologyType>
  anticipatedBenefits?: string
  searchMetadata?: GenericSearchMetadata
}

export const isAward = (o: any): o is ListingAwardData => {
  return 'contractNumber' in o
}

export interface Destructible {
  destroyable?: boolean
  destroy?: boolean
}

export interface DestructibleItem extends Destructible {
  id: string | number
}

export const isUseCase = (
  artifact: ListingServiceProductData | ListingUseCaseData,
): artifact is ListingUseCaseData => {
  return 'startDate' in artifact && 'endDate' in artifact
}

export const isLink = (
  artifact: ListingArtifactLink | ListingArtifactAttachment,
): artifact is ListingArtifactLink => {
  return 'url' in artifact
}

export const isAttachment = (
  artifact: ListingArtifactLink | ListingArtifactAttachment,
): artifact is ListingArtifactAttachment => {
  return 'file' in artifact
}

export class ListingStore extends ServerStore {
  public listings: any

  public getListings = async (params: PaginationParams): Promise<ServerPageData<ListingData>> => {
    return this.server()
      .get('/api/v1/current_user/listings', {
        params: { page: params.page, perPage: params.perPage },
      })
      .then(({ data }) => data)
  }

  public getSingleListing = (id: string | number, eager?: string): Promise<ListingData> => {
    return this.server()
      .get(`/api/v1/listings/${id}`, {
        params: { eager },
      })
      .then(({ data }) => data)
  }

  public getSingleListingService = ({
    listingId,
    serviceId,
    eager,
  }: {
    listingId: string
    serviceId: string
    eager?: string
  }): Promise<ListingServiceProductData> => {
    return this.server()
      .get(`/api/v1/listings/${listingId}/listing_services/${serviceId}`, { params: { eager } })
      .then(({ data }) => data)
  }

  public getAwardsPhases = async (
    params: PaginationParams,
  ): Promise<ServerPageData<GenericLabeledItemResponse>> => {
    return this.server()
      .get('/api/v1/connect/awards/phases', { params })
      .then(({ data }) => data)
  }

  public getSingleListingProduct = ({
    listingId,
    productId,
    eager,
  }: {
    listingId: string
    productId: string
    eager?: string
  }): Promise<ListingServiceProductData> => {
    return this.server()
      .get(`/api/v1/listings/${listingId}/listing_products/${productId}`, { params: { eager } })
      .then(({ data }) => data)
  }

  public getSingleListingUseCase = ({
    listingId,
    useCaseId,
    eager,
  }: {
    listingId: string
    useCaseId: string
    eager?: string
  }): Promise<ListingUseCaseData> => {
    return this.server()
      .get(`/api/v1/listings/${listingId}/listing_use_cases/${useCaseId}`, { params: { eager } })
      .then(({ data }) => data)
  }

  public getSingleListingAward = ({
    listingId,
    awardId,
    eager,
  }: {
    listingId: string
    awardId: string
    eager?: string
  }): Promise<ListingUseCaseData> => {
    return this.server()
      .get(`/api/v1/listings/${listingId}/listing_awards/${awardId}`, { params: { eager } })
      .then(({ data }) => data)
  }

  public getPointOfContact = (id: string | number): Promise<UserData> => {
    return this.server()
      .get(`/api/v1/listings/${id}/users/point_of_contact`)
      .then(({ data }) => data)
  }

  public getListingServices = async ({
    listingId,
    params,
    eager,
  }: {
    listingId: string | number
    params: PaginationParams
    eager?: string
  }): Promise<ServerPageData<ListingServiceProductData>> => {
    return this.server()
      .get(`/api/v1/listings/${listingId}/listing_services`, {
        params: { page: params.page, perPage: params.perPage, eager: eager },
      })
      .then(({ data }) => data)
  }

  public getListingUseCases = async ({
    listingId,
    params,
    eager,
  }: {
    listingId: string | number
    params: PaginationParams
    eager?: string
  }): Promise<ServerPageData<ListingUseCaseData>> => {
    return this.server()
      .get(`/api/v1/listings/${listingId}/listing_use_cases`, {
        params: { page: params.page, perPage: params.perPage, eager: eager },
      })
      .then(({ data }) => data)
  }

  public getListingProducts = async ({
    listingId,
    params,
    eager,
  }: {
    listingId: string | number
    params: PaginationParams
    eager?: string
  }): Promise<ServerPageData<ListingServiceProductData>> => {
    return this.server()
      .get(`/api/v1/listings/${listingId}/listing_products`, {
        params: { page: params.page, perPage: params.perPage, eager: eager },
      })
      .then(({ data }) => data)
  }

  public updateListingService = async ({
    listingId,
    serviceId,
    payload,
  }: {
    listingId: string | number
    serviceId: string | number
    payload: ListingArtifactFormData
  }): Promise<ListingServiceProductData> => {
    const formData = this.composeFormData(payload)
    return this.server()
      .patch<ListingServiceProductData>(
        `/api/v1/listings/${listingId}/listing_services/${serviceId}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
      .then(({ data }) => data)
  }

  public updateListingUseCase = async ({
    listingId,
    useCaseId,
    payload,
  }: {
    listingId: string | number
    useCaseId: string | number
    payload: ListingArtifactFormData
  }): Promise<ListingServiceProductData> => {
    const formData = this.composeFormData(payload)
    return this.server()
      .patch<ListingUseCaseData>(
        `/api/v1/listings/${listingId}/listing_use_cases/${useCaseId}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
      .then(({ data }) => data)
  }

  private composeFormData = (payload: ListingArtifactFormData): FormData => {
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('description', payload.description)
    payload.url && formData.append('url', payload.url)

    if (payload.logo instanceof File) {
      formData.append('logo', payload.logo as File)
    }

    payload.startDate && formData.append('startDate', payload.startDate)
    payload.endDate && formData.append('endDate', payload.endDate)

    payload.listingArtifactAttachmentsAttributes &&
      payload.listingArtifactAttachmentsAttributes.forEach((attachment, i) => {
        attachment.id &&
          formData.append(
            `listingArtifactAttachmentsAttributes[${i}][id]`,
            attachment.id.toString(),
          )
        attachment.name &&
          formData.append(`listingArtifactAttachmentsAttributes[${i}][name]`, attachment.name)
        attachment.description &&
          formData.append(
            `listingArtifactAttachmentsAttributes[${i}][description]`,
            attachment.description,
          )
        if (attachment.file instanceof File) {
          formData.append(
            `listingArtifactAttachmentsAttributes[${i}][file]`,
            attachment.file as File,
          )
        }
        formData.append(
          `listingArtifactAttachmentsAttributes[${i}][destroy]`,
          attachment.destroy ? 'true' : 'false',
        )
      })

    payload.listingArtifactLinksAttributes &&
      payload.listingArtifactLinksAttributes.forEach((link, i) => {
        link.id && formData.append(`listingArtifactLinksAttributes[${i}][id]`, link.id.toString())
        link.name && formData.append(`listingArtifactLinksAttributes[${i}][name]`, link.name)
        link.description &&
          formData.append(`listingArtifactLinksAttributes[${i}][description]`, link.description)
        formData.append(`listingArtifactLinksAttributes[${i}][url]`, link.url)
        formData.append(
          `listingArtifactLinksAttributes[${i}][destroy]`,
          link.destroy ? 'true' : 'false',
        )
      })

    payload.listingArtifactTechnologyTypesAttributes &&
      payload.listingArtifactTechnologyTypesAttributes.forEach((techType, i) => {
        isListingArtifactTechnologyType(techType) &&
          formData.append(
            `listingArtifactTechnologyTypesAttributes[${i}][id]`,
            techType.listingArtifactTechnologyTypeId.toString(),
          )

        const technologyTypeId = isListingArtifactTechnologyType(techType)
          ? techType.technologyTypeId
          : techType.id
        formData.append(
          `listingArtifactTechnologyTypesAttributes[${i}][technologyTypeId]`,
          technologyTypeId.toString(),
        )

        isListingArtifactTechnologyType(techType) &&
          formData.append(
            `listingArtifactTechnologyTypesAttributes[${i}][destroy]`,
            techType.destroy ? 'true' : 'false',
          )
      })

    return formData
  }

  public getListingAwards = async ({
    listingId,
    params,
  }: {
    listingId: string | number
    params: PaginationParams & IgniteAwardQueryParams
  }): Promise<ServerPageData<ListingAwardData>> => {
    return this.server()
      .get(`/api/v1/listings/${listingId}/listing_awards`, {
        params: { ...params },
      })
      .then(({ data }) => data)
  }

  public createListingService = async ({
    listingId,
    payload,
  }: {
    listingId: number | string
    payload: ListingArtifactFormData
  }): Promise<ListingServiceProductData> => {
    const formData = this.composeFormData(payload)

    return this.server()
      .post<ListingServiceProductData>(`/api/v1/listings/${listingId}/listing_services`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => data)
  }

  public createListingProduct = async ({
    listingId,
    payload,
  }: {
    listingId: number | string
    payload: ListingArtifactFormData
  }): Promise<ListingServiceProductData> => {
    const formData = this.composeFormData(payload)

    return this.server()
      .post<ListingServiceProductData>(`/api/v1/listings/${listingId}/listing_products`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => data)
  }

  public updateListingProduct = async ({
    listingId,
    productId,
    payload,
  }: {
    listingId: string | number
    productId: string | number
    payload: ListingArtifactFormData
  }): Promise<ListingServiceProductData> => {
    let formData = this.composeFormData(payload)

    return this.server()
      .patch(`/api/v1/listings/${listingId}/listing_products/${productId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => data)
  }

  public createListingUseCase = async ({
    listingId,
    payload,
  }: {
    listingId: number | string
    payload: ListingArtifactFormData
  }): Promise<ListingUseCaseData> => {
    const formData = this.composeFormData(payload)
    return this.server()
      .post<ListingServiceProductData>(
        `/api/v1/listings/${listingId}/listing_use_cases`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
      .then(({ data }) => data)
  }

  public updateListing = async (payload: UpdateListingData): Promise<ListingData> => {
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('tagLine', payload.tagLine)
    if (payload.logo instanceof File) {
      formData.append('logo', payload.logo as File)
    }
    formData.append('yearFounded', payload.yearFounded.toString())
    formData.append('website', payload.website)
    payload.samCode && formData.append('samCode', payload.samCode)
    payload.dunsCode && formData.append('dunsCode', payload.dunsCode)
    formData.append('capabilityStatement', payload.capabilityStatement)

    formData.append('addressLine_1', payload.addressLine1)
    formData.append('addressLine_2', payload.addressLine2 ? payload.addressLine2 : '')
    formData.append('addressLine_3', payload.addressLine3 ? payload.addressLine3 : '')
    formData.append('city', payload.city)
    formData.append('stateProvince', payload.stateOrProvince)
    formData.append('country', payload.country)
    formData.append('postalCode', payload.postalCode)
    formData.append('pointOfContactId', payload.pointOfContactId.toString())

    payload.naicsCodes &&
      payload.naicsCodes.forEach((code) => {
        formData.append('naicsCodes[][id]', code.id.toString())
        formData.append('naicsCodes[][destroy]', code.destroy!.toString())
      })

    payload.technologyTypes &&
      payload.technologyTypes.forEach((type) => {
        formData.append('technologyTypes[][id]', type.id.toString())
        formData.append('technologyTypes[][destroy]', type.destroy!.toString())
      })

    payload.businessClassifications &&
      payload.businessClassifications.forEach((el) => {
        formData.append(`businessClassifications[][id]`, el.id.toString())
        formData.append(`businessClassifications[][destroy]`, el.destroy!.toString())
      })

    payload.socialMediaAccounts &&
      payload.socialMediaAccounts.forEach((el) => {
        formData.append(`socialMediaAccounts[][socialMediaTypeId]`, el.socialMediaTypeId.toString())
        formData.append(`socialMediaAccounts[][identifier]`, el.identifier.toString())
        formData.append(
          `socialMediaAccounts[][destroy]`,
          el.identifier.length < 2 ? 'true' : 'false',
        )
      })

    payload.listingArtifactAttachmentsAttributes &&
      payload.listingArtifactAttachmentsAttributes.forEach((attachment, i) => {
        attachment.id &&
          formData.append(
            `listingArtifactAttachmentsAttributes[${i}][id]`,
            attachment.id.toString(),
          )
        attachment.name &&
          formData.append(`listingArtifactAttachmentsAttributes[${i}][name]`, attachment.name)
        attachment.description &&
          formData.append(
            `listingArtifactAttachmentsAttributes[${i}][description]`,
            attachment.description,
          )
        if (attachment.file instanceof File) {
          formData.append(
            `listingArtifactAttachmentsAttributes[${i}][file]`,
            attachment.file as File,
          )
        }
        formData.append(
          `listingArtifactAttachmentsAttributes[${i}][destroy]`,
          attachment.destroy ? 'true' : 'false',
        )
      })

    payload.listingArtifactLinksAttributes &&
      payload.listingArtifactLinksAttributes.forEach((link, i) => {
        link.id && formData.append(`listingArtifactLinksAttributes[${i}][id]`, link.id.toString())
        link.name && formData.append(`listingArtifactLinksAttributes[${i}][name]`, link.name)
        link.description &&
          formData.append(`listingArtifactLinksAttributes[${i}][description]`, link.description)
        formData.append(`listingArtifactLinksAttributes[${i}][url]`, link.url)
        formData.append(
          `listingArtifactLinksAttributes[${i}][destroy]`,
          link.destroy ? 'true' : 'false',
        )
      })

    return this.server()
      .patch(`/api/v1/current_user/listings/${payload.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => data)
  }

  public publishListing(listingId: number | string): Promise<ListingData> {
    return this.server()
      .patch(`/api/v1/current_user/listings/${listingId}/publish`)
      .then(({ data }) => data)
  }

  public setListingVisibility(listingId: number | string, isOnlyVisionVisible: boolean) {
    return this.server()
      .patch(
        `/api/v1/current_user/listings/${listingId}`,
        {
          isOnlyVisionVisible,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then(({ data }) => data)
  }

  public unpublishListing(listingId: number | string): Promise<ListingData> {
    return this.server()
      .patch(`/api/v1/current_user/listings/${listingId}/unpublish`)
      .then(({ data }) => data)
  }

  public deleteProduct = ({
    listingId,
    productId,
  }: {
    listingId: string | number
    productId: string | number
  }): Promise<null> => {
    return this.server().delete(`/api/v1/listings/${listingId}/listing_products/${productId}`)
  }

  public deleteService = ({
    listingId,
    serviceId,
  }: {
    listingId: string | number
    serviceId: string | number
  }): Promise<null> => {
    return this.server().delete(`/api/v1/listings/${listingId}/listing_services/${serviceId}`)
  }

  public deleteUseCase = ({
    listingId,
    useCaseId,
  }: {
    listingId: string | number
    useCaseId: string | number
  }): Promise<null> => {
    return this.server().delete(`/api/v1/listings/${listingId}/listing_use_cases/${useCaseId}`)
  }
}
