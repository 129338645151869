import classnames from 'classnames'
import { Avatar, AvatarProps } from '@mobilizeyourtech/vision-core-react'
import { CTooltip } from '@coreui/react'
import { UserData } from '../../../../stores'
import { AccountData, isAccountData, isUserData } from '../../../../stores/UserStore'
import { activeStatusIcon } from '../../../../assets/brand/active_status_icon'
import { ConnectListingResponse } from '../../../../stores/ConnectListingStore'

interface TProps extends Omit<AvatarProps, 'src' | 'prefix' | 'suffix'> {
  user?: UserData | AccountData | ConnectListingResponse
  displayIsActive?: boolean
}

export const UserAvatar = ({ user, displayIsActive, ...props }: TProps) => {
  // TODO refactor to be 2 separate Avatar components - 1 scoped for Users and 1 for Accounts
  const source = () => {
    if (isUserData(user)) {
      return {
        image: user.headshot?.path,
        firstName: user.firstName,
        lastName: user.lastName,
      }
    }
    if (isAccountData(user)) {
      return {
        image: user.logo?.path,
        firstName: user.name,
      }
    }
    return undefined
  }

  const renderActiveDisplay = user && isUserData(user) && user.isActive && displayIsActive
  const renderTooltipContent = () => {
    return (
      <div className="d-flex justify-content-center align-items-center gap-1">
        {activeStatusIcon()}
        <em>Active in the last 90 days</em>
      </div>
    )
  }

  return renderActiveDisplay ? (
    <CTooltip content={renderTooltipContent()}>
      <div className="user-avatar-wrapper position-relative" data-testid="active-user-avatar">
        <Avatar
          {...props}
          className={classnames('user-avatar', props.className)}
          src={source()?.image}
          prefix={source()?.firstName || ''}
          suffix={source()?.lastName || ''}
        />
        <div className="is-active-indicator position-absolute end-0 bottom-0">
          {activeStatusIcon()}
        </div>
      </div>
    </CTooltip>
  ) : (
    <div className="user-avatar-wrapper position-relative" data-testid="default-user-avatar">
      <Avatar
        {...props}
        className={classnames('user-avatar', props.className)}
        src={source()?.image}
        prefix={source()?.firstName || ''}
        suffix={source()?.lastName || ''}
      />
    </div>
  )
}
