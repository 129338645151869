import React, { FormEvent, useState } from 'react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { CButton, CCol, CForm, CFormLabel, CRow } from '@coreui/react'
import { IconPopover, ValidatedCFormInput } from '../custom'
import { validateLength, validatePassword, validateValueMatch } from '../../lib/helpers/validation'
import { ChangePasswordData } from '../../stores/UserStore'
import { extractRestfulError } from '../../lib/errors/utils'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  onSubmitted?: () => void
}
export const ChangePasswordForm = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
)(
  observer((props: TProps) => {
    const [formData, setFormData] = useState<Partial<ChangePasswordData>>({})
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const isSubmittable = (): boolean => {
      let errors = [
        validateLength(formData.currentPassword, 6),
        validatePassword(formData.password, 6),
        validateValueMatch(formData.password, formData.passwordConfirmation),
      ]

      if (errors.some((err) => !!err)) {
        return false
      }

      return true
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setHasValidated(true)
      setIsSubmitting(true)
      props.userStore
        ?.changePassword(formData as ChangePasswordData)
        .then(() => {
          setTimeout(() => {
            // auth store salt to reload data
            setIsSubmitting(false)
            props.authStore?.generateAndSetSalt()
            props.notificationStore?.setNotificationMessage(
              'Password changed successfully.',
              'success',
              3000,
            )
            props.onSubmitted && props.onSubmitted()
          }, 500)
        })
        .catch((err) => {
          const e = extractRestfulError(err)
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            e && e.status === 400 ? e.message : 'Failed to change password, please try again.',
            'danger',
            3000,
          )
        })
    }

    const onChange = (change: Partial<ChangePasswordData>) =>
      setFormData({ ...formData, ...change })

    return (
      <section className="EditProfileForm" data-testid="change-password-form">
        <CForm
          onSubmit={onSubmit}
          validated={hasValidated}
          className="needs-validation"
          {...{ noValidate: true }}
        >
          <CRow className="mb-3">
            <CFormLabel {...{ htmlFor: 'currentPassword' }} className="col-sm-3 col-form-label">
              Current Password
            </CFormLabel>

            <CCol>
              <ValidatedCFormInput
                type="password"
                id="currentPassword"
                data-testid="currentPassword"
                placeholder="Current Password"
                value={formData.currentPassword || ''}
                onChange={(currentPassword: string | undefined) => onChange({ currentPassword })}
                required
                validate={(v) => validatePassword(v, 6)}
                renderInvalidMessage={() => undefined}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CFormLabel {...{ htmlFor: 'newPassword' }} className="col-sm-3 col-form-label">
              New Password
              <IconPopover
                content={
                  <span>
                    Must be at least 6 characters, contain at least one capital and lowercase
                    letter, number, and special character (#?!@$%^&*-)
                  </span>
                }
                placement={'right'}
                iconClassName={'fas fa-question-circle ms-2 text-primary'}
              />
            </CFormLabel>

            <CCol>
              <ValidatedCFormInput
                type="password"
                id="newPassword"
                data-testid="newPassword"
                placeholder="New Password"
                value={formData.password || ''}
                required
                onChange={(newPassword: string | undefined) => onChange({ password: newPassword })}
                validate={(v) => validatePassword(v, 6)}
                renderInvalidMessage={() =>
                  'Must be at least 6 characters, contain at least one capital and lowercase letter, number, and special character (#?!@$%^&*-)'
                }
              />
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <CFormLabel {...{ htmlFor: 'confirmNewPassword' }} className="col-sm-3 col-form-label">
              Confirm New Password
            </CFormLabel>

            <CCol>
              <ValidatedCFormInput
                type="password"
                id="confirmNewPassword"
                data-testid="confirmNewPassword"
                placeholder="Confirm New Password"
                value={formData.passwordConfirmation || ''}
                required
                onChange={(confirmNewPassword: string | undefined) =>
                  onChange({ passwordConfirmation: confirmNewPassword })
                }
                validate={(v) => validateValueMatch(formData.password, v)}
                renderInvalidMessage={() => 'New passwords must match'}
              />
            </CCol>
          </CRow>

          <CRow className="action-row justify-content-end">
            <CCol sm={2} className="text-end">
              <CButton
                type="submit"
                data-testid="save-button"
                disabled={!isSubmittable() || isSubmitting}
              >
                {isSubmitting ? (
                  <i data-testid="suspense-spinner" className="fa fa-spinner fa-spin" />
                ) : (
                  <i className="fas fa-save" />
                )}
                Save
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </section>
    )
  }),
)
