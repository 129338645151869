import { inject, observer } from 'mobx-react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { CCard, CCardBody, CCardFooter, CCol, CContainer, CRow } from '@coreui/react'
import { SupportLinks } from '../components/custom'
import { Redirect } from 'react-router-dom'
import React from 'react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../stores'
import { ResendClaimForm } from '../components/form'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
  authStore?: AuthStore
  applicationStore?: ApplicationStore
}
export const SubmitClaimResult = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.authStore,
  'applicationStore',
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      resultMessage: withDefault(StringParam, undefined),
      email: withDefault(StringParam, undefined),
      uniqueEntityId: withDefault(StringParam, undefined),
    })

    const determineTitle = () => {
      if (params.resultMessage === 'ClaimSuccess') {
        return 'Claim Request Processed'
      }
      return 'Claim Request Unsuccessful'
    }

    const failureMessage = () => {
      switch (params.resultMessage) {
        case 'UserAlreadyExists':
          return 'A user already exists with the email provided.'
        default:
          return 'There was a problem creating your claim request.'
      }
    }

    const resultSuccessResend = (): boolean => {
      return params.resultMessage === 'ClaimSuccess' && !!params.email && !!params.uniqueEntityId
    }

    const resultFailureResend = (): boolean => {
      return (
        params.resultMessage === 'ClaimRequestAlreadyExists' &&
        !!params.email &&
        !!params.uniqueEntityId
      )
    }

    const resultSamInstructions = (): boolean => {
      return (
        params.resultMessage === 'EmailUnmatched' ||
        params.resultMessage === 'AccountAlreadyClaimed'
      )
    }

    const shouldDisplayResendForm = (): boolean => {
      return (
        params.resultMessage === 'ClaimSuccess' ||
        params.resultMessage === 'ClaimRequestAlreadyExists'
      )
    }

    const determineResult = () => {
      if (resultSuccessResend()) {
        return (
          <p className="text-center" data-testid="result-success-and-form">
            To claim your Ignite account, please check your email and follow the instructions. If
            you do not receive an email, please verify the info below and click resend for a new
            claim email to be sent to you. If the info is incorrect, you may try again{' '}
            <a href="/claim">here</a> or go to <a href="/">sign in</a>.
          </p>
        )
      } else if (resultFailureResend()) {
        return (
          <p className="text-center" data-testid="result-failure-and-form">
            The email provided already has a claim request in progress. Please verify the
            information below and click resend if you would like us to resend a claim email. If the{' '}
            information is incorrect, you may try again <a href="/claim">here</a> or go to{' '}
            <a href="/">sign in</a>.
          </p>
        )
      } else if (resultSamInstructions()) {
        return (
          <p className="text-center" data-testid={params.resultMessage + 'Explanation'}>
            It looks like that email does not match our records or the account has already been
            claimed. Ignite listings are tied to your{' '}
            <a href={'https://sam.gov/content/home'}>Sam.gov</a> listing, so if you&apos;re sure you
            typed the email correctly, here&apos;s what to do next:
          </p>
        )
      } else if (params.resultMessage === 'ClaimSuccess') {
        return (
          <p className="text-center" data-testid="result-success">
            Your claim request has been successfully submitted. To claim your Ignite account, please
            check your email and follow the instructions.
          </p>
        )
      } else {
        return (
          <p className="text-center" data-testid={params.resultMessage}>
            {failureMessage()} You may try again <a href="/claim">here</a> or go to{' '}
            <a href="/">sign in</a>.
          </p>
        )
      }
    }

    const renderResendForm = () => {
      if (params.email && params.uniqueEntityId) {
        return (
          <ResendClaimForm
            formData={{ email: params.email, uniqueEntityId: params.uniqueEntityId }}
          />
        )
      }
    }

    const renderListedInstructions = () => {
      if (resultSamInstructions()) {
        return (
          <ol data-testid={params.resultMessage + 'List'}>
            <li>
              Contact{' '}
              <a href={'https://sam.gov/content/home'} target="_blank" rel="noreferrer">
                Sam.gov
              </a>{' '}
              to find the Point of Contact email on your account. Try again with that email address.
            </li>
            <li>
              Contact{' '}
              <a href={'mailto:support@mobilizeignite.com'} target="_blank" rel="noreferrer">
                support@mobilizeignite.com
              </a>{' '}
              if you have any questions about the application.
            </li>
            <li>
              You may also try to claim your account again <a href="/claim">here</a> or{' '}
              <a href="/">sign in</a>.
            </li>
          </ol>
        )
      }
    }
    const renderResult = () => {
      return (
        <>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>
              <h2 className="text-center">{determineTitle()}</h2>
            </CCol>
          </CRow>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>{determineResult()}</CCol>
          </CRow>
          <CRow className="mb-2 justify-content-md-center">
            <CCol>{renderListedInstructions()}</CCol>
          </CRow>
          {shouldDisplayResendForm() && renderResendForm()}
        </>
      )
    }

    if (!params.resultMessage) {
      return <Redirect to={'/'} />
    }

    return (
      <section className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard>
                <CCardBody className="p-4">{renderResult()}</CCardBody>
                <CCardFooter className="bg-white">
                  <CRow className="justify-content-center">
                    <CCol>
                      <SupportLinks />
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    )
  }),
)
