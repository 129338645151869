import { CButton, CCol, CLink } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { ApplicationStore, NotificationStore, UserStore } from '../stores'
import MobilizeVISIONLogo from '../assets/brand/Ignite_Logo_Mobilize_Full_Color.png'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { ValidatedCFormInput } from '../components/custom'
import { validatePassword, validateValueMatch } from '../lib/helpers/validation'
import { useHistory } from 'react-router-dom'

type TProps = {
  applicationStore?: ApplicationStore
  userStore?: UserStore
  notificationStore?: NotificationStore
}

export const ResetPassword = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [params] = useQueryParams({
      resetPasswordToken: withDefault(StringParam, undefined),
    })

    const history = useHistory()
    const [password, setPassword] = useState<string | undefined>('')
    const [confirmPassword, setConfirmPassword] = useState<string | undefined>('')
    const mobilizeVisionLogo = MobilizeVISIONLogo

    const isSubmittable = (): boolean => {
      let errors = [validatePassword(password, 6), validateValueMatch(password, confirmPassword)]

      if (errors.some((err) => !!err)) {
        return false
      }

      return true
    }

    const resetPassword = (e: SyntheticEvent) => {
      e.preventDefault()

      props.userStore
        ?.resetPassword(password!, confirmPassword!, params.resetPasswordToken!)
        .then((res) => {
          props.notificationStore?.setNotificationMessage(
            'Your password has been reset. Please login using your new password.',
            'info',
            5000,
          )
          history.push('/signin')
        })
        .catch((err) => {
          props.notificationStore?.setNotificationMessage(
            'Unable to reset your password. Please try again',
            'danger',
            5000,
          )
        })
    }

    useEffect(() => {
      if (!params.resetPasswordToken) {
        history.push('/signin')
      }
    }, [params])

    return (
      <section className="RequestReset">
        <div className="sign-in-modal">
          <img
            src={mobilizeVisionLogo}
            className="MobilizeVisionLogo mb-4"
            alt="Mobilize Vision Logo"
          />
          <h5>Reset your password.</h5>
          <p>Fill in and confirm your new password.</p>
          <form data-testid="request-reset-password-form" onSubmit={(e) => resetPassword(e)}>
            <ValidatedCFormInput
              type="password"
              id="signupUserPassword"
              data-testid="resetUserPassword"
              placeholder="Password"
              value={password || ''}
              onChange={(password: string | undefined) => setPassword(password)}
              required
              validate={(v) => validatePassword(v, 6)}
              renderInvalidMessage={() =>
                'Must be at least 6 characters, contain at least one capital and lowercase letter, number, and special character'
              }
            />
            <ValidatedCFormInput
              type="password"
              id="signupUserPassword"
              data-testid="resetUserPasswordConfirmation"
              placeholder="Confirm Password"
              value={confirmPassword || ''}
              onChange={(confirmPassword: string | undefined) =>
                setConfirmPassword(confirmPassword)
              }
              required
              validate={(v) => validateValueMatch(password, v)}
              renderInvalidMessage={() => 'Passwords must match'}
            />
            <CButton
              type="submit"
              disabled={!isSubmittable()}
              className="btn"
              data-testid="btn-request-password-reset"
            >
              Reset Password
            </CButton>
          </form>
          <CCol className="link-group">
            <CLink href="/claim">Claim account</CLink>
            <CLink href="/signin">Sign in</CLink>
            <CLink href="/request_password_reset">Request reset password</CLink>
          </CCol>
        </div>
      </section>
    )
  }),
)
