import { ListingAwardData } from '../../stores/ListingStore'
import { CBadge, CCard, CCardBody, CCardTitle, CCol, CContainer, CRow } from '@coreui/react'
import { parseDate } from '@mobilizeyourtech/vision-core-react'
import classnames from 'classnames'

export interface ViewListingAwardProps {
  data: ListingAwardData
}

export const ViewListingAward = (props: ViewListingAwardProps) => {
  const renderPair = (name: string, value: any, multiline: boolean = false) => {
    return (
      <CRow className="mb-2">
        <CCol sm={5} lg={4} className={'text-secondary'}>
          {name}
        </CCol>

        <CCol className={classnames({ multiline })}>{value}</CCol>
      </CRow>
    )
  }

  return (
    <section
      className={'ViewListingAward d-flex flex-column justify-content-center align-items-center'}
    >
      <CContainer>
        <CCard className="mb-3">
          <CCardBody>
            <CCardTitle className="pb-2 border-bottom border-bottom-light">Detail</CCardTitle>
            {renderPair('Title', props.data.awardTitle, true)}
            {renderPair('Contract Number', props.data.contractNumber)}
            {renderPair('Branch', props.data.branch)}
            {renderPair('Program', props.data.program)}
            {renderPair('Phase', props.data.phase)}
            {renderPair('Product Service Code', props.data.productServiceCode)}
            {renderPair('Principal NAICS Code', props.data.principleNaicsCode)}
          </CCardBody>
        </CCard>

        {props.data.listingArtifactTechnologyTypes &&
          props.data.listingArtifactTechnologyTypes.length > 0 && (
            <CCard className="mb-3 p-2">
              <CCardBody>
                <CCardTitle className="pb-2 border-bottom border-bottom-light">
                  Technology Types
                </CCardTitle>
                {props.data.listingArtifactTechnologyTypes.map((tt) => (
                  <CBadge
                    key={tt.technologyTypeId}
                    color="primary"
                    className="ms-auto px-3 py-2 mx-2 mt-1"
                  >
                    {tt.name}
                  </CBadge>
                ))}
              </CCardBody>
            </CCard>
          )}

        <CCard className="mb-3">
          <CCardBody>
            <CCardTitle className="pb-2 border-bottom border-bottom-light">Contract</CCardTitle>
            {renderPair('Amount', props.data.awardAmount)}
            {renderPair('State', props.data.state)}
            {renderPair(
              'Award Date',
              props.data.contractAwardDate && parseDate(props.data.contractAwardDate),
            )}
            {renderPair(
              'Start Date',
              props.data.contractStartDate && parseDate(props.data.contractStartDate),
            )}
            {renderPair(
              'End Date',
              props.data.contractEndDate && parseDate(props.data.contractEndDate),
            )}
          </CCardBody>
        </CCard>

        <CCard className="mb-3">
          <CCardBody>
            <CCardTitle className="pb-2 border-bottom border-bottom-light">PI</CCardTitle>
            {renderPair('Name', props.data.piName)}
            {renderPair('Title', props.data.piTitle)}
            {renderPair('Phone', props.data.piPhone)}
            {renderPair('Email', props.data.piEmail)}
            {renderPair('Research Institution', props.data.researchInstitutionName)}
          </CCardBody>
        </CCard>

        <CCard className="mb-3">
          <CCardBody>
            <CCardTitle className="pb-2 border-bottom border-bottom-light">Description</CCardTitle>
            <CRow className="mb-2">
              <CCol className="multiline">{props.data.awardAbstract}</CCol>
            </CRow>
          </CCardBody>
        </CCard>

        {props.data.anticipatedBenefits && (
          <CCard className="mb-3">
            <CCardBody>
              <CCardTitle className="pb-2 border-bottom border-bottom-light">
                Anticipated Benefits
              </CCardTitle>
              <CRow className="mb-2">
                <CCol className="multiline">{props.data.anticipatedBenefits}</CCol>
              </CRow>
            </CCardBody>
          </CCard>
        )}
      </CContainer>
    </section>
  )
}
