import { PieChartCellDataRecord, TechnologyTypeStatistic } from '../../../../stores/AnalyticsStore'
import IgnitePieChart from '../../../piechart/IgnitePieChart'

type TProps = {
  data?: Array<TechnologyTypeStatistic>
}

let getTopTenRecordsByField = (
  array: Record<string, any>[],
  field: string,
): Array<PieChartCellDataRecord> => {
  let sortOnFieldDescFunc = (a: Record<string, any>, b: Record<string, any>) =>
    a[field] < b[field] ? 1 : -1

  return array
    .slice()
    .sort(sortOnFieldDescFunc)
    .slice(0, 10)
    .map((d) => {
      return { id: d.id, testingId: field + '-' + d.id, name: d.name, value: d[field] }
    })
}
export const AdminAnalyticsTechnologyTypePieCharts = (props: TProps) => {
  let focusData = props.data && getTopTenRecordsByField(props.data, 'listingCount')
  let productData = props.data && getTopTenRecordsByField(props.data, 'listingProductCount')
  let serviceData = props.data && getTopTenRecordsByField(props.data, 'listingServiceCount')
  let awardData = props.data && getTopTenRecordsByField(props.data, 'listingAwardCount')
  let govUsersData = props.data && getTopTenRecordsByField(props.data, 'usersCount')
  let visionUsersData = props.data && getTopTenRecordsByField(props.data, 'visionUsersCount')

  return (
    <div id="technology-type-pie-charts-container">
      <div className="row stat-header technology-types-card-header">
        <div className="col-sm-11 offset-xl-1">
          <h2>Technology Types At a Glance</h2>
        </div>
      </div>
      <div className="row justify-content-left">
        <div className=" col-sm-5 col-xl-4 offset-xl-1">
          <h3>Top 10 Company Focuses</h3>
          <IgnitePieChart data={focusData} name="technology-type-focuses-pie-chart" />
        </div>
        <div className="col-sm-5 col-xl-4 offset-sm-1 offset-xxl-2">
          <h3>Top 10 Company Products</h3>
          <IgnitePieChart data={productData} name="technology-type-products-pie-chart" />
        </div>
      </div>
      <div className="row justify-content-left">
        <div className=" col-sm-5 col-xl-4 offset-xl-1">
          <h3>Top 10 Company Services</h3>
          <IgnitePieChart data={serviceData} name="technology-type-services-pie-chart" />
        </div>
        <div className=" col-sm-5 col-xl-4 offset-sm-1 offset-xxl-2">
          <h3>Top 10 Company Awards</h3>
          <IgnitePieChart data={awardData} name="technology-type-awards-pie-chart" />
        </div>
      </div>
      <div className="row justify-content-left">
        <div className=" col-sm-5 col-xl-4 offset-xl-1 ">
          <h3>Top 10 with Government Users</h3>
          <IgnitePieChart data={govUsersData} name="technology-type-gov-users-pie-chart" />
        </div>
        <div className=" col-sm-5 col-xl-4 offset-sm-1 offset-xxl-2">
          <h3>Top 10 with Vision Users</h3>
          <IgnitePieChart data={visionUsersData} name="technology-type-vision-users-pie-chart" />
        </div>
      </div>
    </div>
  )
}

export default AdminAnalyticsTechnologyTypePieCharts
