import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { ListingPageViewStatistic } from '../../../../stores/AnalyticsStore'

type TProps = {
  data?: Array<ListingPageViewStatistic>
}

export const AdminAnalyticsListingPageViewsTable = (props: TProps) => {
  const tableRow = (rowData: ListingPageViewStatistic) => {
    return (
      <CTableRow key={rowData.name}>
        <CTableDataCell data-testid="listing-page-name-container" className="text-center">
          <h3 className="m-0">{rowData.name}</h3>
        </CTableDataCell>
        <CTableDataCell data-testid="listing-page-views-count-container" className="text-center">
          <h3 className="m-0">{rowData.pageViews}</h3>
        </CTableDataCell>
      </CTableRow>
    )
  }

  const listingPageViewTable = () => {
    return (
      <CTable
        bordered
        data-testid="admin-analytics-listing-page-view-table"
        id="admin-analytics-listing-page-view-table"
      >
        <CTableHead color="dark">
          <CTableRow>
            <CTableHeaderCell scope="col" className="w-50 text-center">
              <h3 className="m-0">SBC Organization</h3>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="w-50 text-center">
              <h3 className="m-0">Number of Page Views</h3>
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {props.data?.map((row) => {
            return tableRow(row)
          })}
        </CTableBody>
      </CTable>
    )
  }

  return <div id="listing-page-view-table-container">{listingPageViewTable()}</div>
}

export default AdminAnalyticsListingPageViewsTable
