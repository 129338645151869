import React from 'react'
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CRow,
  CTooltip,
} from '@coreui/react'
import { ApplicationStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { getTimezoneOptions, parseTimezone } from '../../lib/time'
import { SocialMediaAccount } from '../../stores/UserStore'
import { determineSocialMediaIconClassName } from '../../lib/helpers/utils'
import { UserAvatar } from '../Molecules/Avatars/UserAvatar/UserAvatar'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
}
export const ProfileDetailCard = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const isGovUser = props.userStore?.currentUserData?.userRoles?.includes('gov')
    const wrappedIcon = (className: string | undefined) => {
      if (className) {
        return (
          <div className="icon-wrapper">
            <i className={className} />
          </div>
        )
      }
      return undefined
    }

    const renderSocialMediaAccount = (sma: SocialMediaAccount) => {
      return (
        <CCol key={sma.id}>
          {wrappedIcon(determineSocialMediaIconClassName(sma.socialMediaTypeName))}
          {sma.identifier}
        </CCol>
      )
    }

    const unconfirmedEmailMessage = (email: string) => {
      return `A confirmation email has been sent to ${email}`
    }

    const timezone =
      props.userStore?.currentUserData?.timezone &&
      parseTimezone(props.userStore.currentUserData.timezone, getTimezoneOptions('original'))?.label

    const phoneSection = () => {
      return (
        <>
          <CRow>
            <CCol>
              {wrappedIcon('fas fa-mobile-alt')}
              {props.userStore?.currentUserData?.cellPhone}
            </CCol>
          </CRow>
          {props.userStore?.currentUserData?.workPhone && (
            <CRow>
              <CCol>
                {wrappedIcon('fas fa-phone-alt')}
                {props.userStore?.currentUserData?.workPhone}
              </CCol>
            </CRow>
          )}
          {props.userStore?.currentUserData?.workPhoneExt && (
            <CRow>
              <CCol>
                {wrappedIcon('fa fa-random')}
                {props.userStore?.currentUserData?.workPhoneExt}
              </CCol>
            </CRow>
          )}
        </>
      )
    }
    const socialMediaSection = () => {
      return (
        <CRow>
          {props.userStore?.currentUserData?.socialMediaAccounts?.map(renderSocialMediaAccount)}
        </CRow>
      )
    }

    return (
      <CCard className="ProfileDetailCard h-100">
        <CCardHeader component="h5">User Detail</CCardHeader>
        <CCardBody>
          <div className="card-image-container">
            <UserAvatar user={props.userStore!.currentUserData!} />
          </div>
        </CCardBody>
        <CCardFooter>
          <CCardTitle>
            <b>
              {props.userStore?.currentUserData?.firstName}{' '}
              {props.userStore?.currentUserData?.lastName}
            </b>
          </CCardTitle>
          <CCardSubtitle className={'d-flex flex-column gap-1'}>
            <p className={'subtle'}>{props.userStore?.currentUserData?.title}</p>
            {isGovUser && (
              <>
                <p className={'subtle'}>
                  {props.userStore?.currentUserData?.govUserExtendedData?.govUserOrganization
                    .shortName +
                    ' - ' +
                    props.userStore?.currentUserData?.govUserExtendedData?.govUserOrganization
                      .longName}
                </p>
                <p className={'subtle'}>
                  {
                    props.userStore?.currentUserData?.govUserExtendedData?.govUserProgramOffice
                      ?.label
                  }
                </p>
              </>
            )}
          </CCardSubtitle>
          <CRow>
            <CCol>
              {wrappedIcon('far fa-envelope')}
              {props.userStore?.currentUserData?.email}
              {props.userStore?.currentUserData?.unconfirmedEmail && (
                <CTooltip
                  content={unconfirmedEmailMessage(
                    props.userStore?.currentUserData?.unconfirmedEmail,
                  )}
                >
                  <span>
                    <i
                      data-testid="unconfirmed-tooltip"
                      className="unconfirmed-icon fas fa-info-circle"
                    />
                  </span>
                </CTooltip>
              )}
            </CCol>
          </CRow>
          {!isGovUser && phoneSection()}
          <CRow>
            <CCol>
              {wrappedIcon('far fa-clock')}
              {timezone}
            </CCol>
          </CRow>
          {!isGovUser && socialMediaSection()}
        </CCardFooter>
      </CCard>
    )
  }),
)
