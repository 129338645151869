import React, { useState } from 'react'
import { ListingData, ListingStore } from '../../stores/ListingStore'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore } from '../../stores'
import { CButton, CTooltip } from '@coreui/react'
import { ConfirmationBoundaryModal } from '../custom'
import { visionVisibilityIcon } from '../../assets/brand/vision_visibility_icon'

type TProps = {
  listing: ListingData
  listingStore?: ListingStore
  notificationStore?: NotificationStore
  onAction: (data: ListingData) => void
  withText?: boolean
}
export const ListingVisionVisibilityActionButton = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false)
    const [isSuspending, setIsSuspending] = useState<boolean>(false)

    const { isOnlyVisionVisible } = props.listing

    const determineVisibilityMessage = () => {
      return isOnlyVisionVisible
        ? 'Successfully changed listing visibility to everyone'
        : 'Successfully changed listing visibility to VISION users only.'
    }

    const toggleVisibilityConfirm = () => {
      setIsSuspending(true)
      props.listingStore
        ?.setListingVisibility(props.listing.id, !isOnlyVisionVisible)
        .then((data) => {
          setIsSuspending(false)
          setIsPromptOpen(false)
          props.notificationStore?.setNotificationMessage(
            determineVisibilityMessage(),
            'success',
            3000,
          )
          props.onAction(data)
        })
        .catch(() => {
          setIsSuspending(false)
          props.notificationStore?.setNotificationMessage(
            'Failed to change listing visibility. Please try again',
            'danger',
            3000,
          )
        })
    }

    const renderToggleVisibilityButton = () => {
      return (
        <>
          <CTooltip
            content={`Make visible to ${isOnlyVisionVisible ? 'everyone' : 'VISION users only'} `}
          >
            <CButton
              className="span-btn visibility-action-btn"
              data-testid={
                isOnlyVisionVisible ? 'everyone-visibility-btn' : 'vision-visibility-btn'
              }
              onClick={() => setIsPromptOpen(true)}
              disabled={isSuspending}
            >
              {isOnlyVisionVisible ? <i className="fa fa-users"></i> : visionVisibilityIcon()}{' '}
              {props.withText && (
                <span>{`Make visible to ${
                  isOnlyVisionVisible ? 'everyone' : 'VISION Only'
                } `}</span>
              )}
            </CButton>
          </CTooltip>
          <ConfirmationBoundaryModal
            onCancel={() => setIsPromptOpen(false)}
            onConfirm={toggleVisibilityConfirm}
            isOpen={isPromptOpen}
            headerText={`Are you sure you want to make this listing viewable to ${
              isOnlyVisionVisible ? 'everyone' : 'only VISION users'
            }?`}
            isSuspended={isSuspending}
          >
            <p>
              {`Once selected, this listing is visible to ${
                isOnlyVisionVisible ? 'everyone' : 'only DoD VISION users'
              } . You can change this
              setting at any time.`}
            </p>
          </ConfirmationBoundaryModal>
        </>
      )
    }

    return renderToggleVisibilityButton()
  }),
)
