import { useHistory } from 'react-router-dom'
import { FallbackProps } from 'react-error-boundary'
import CompanyConnectorLogo from '../../assets/brand/Ignite_Logo_Full_Color.png'
import { CCard, CCardBody, CButton } from '@coreui/react'

export const ErrorFallback = (props: FallbackProps) => {
  const { error } = props
  const history = useHistory()

  history.listen(() => {
    props.resetErrorBoundary()
  })

  const determineErrorMessage = (e: any) => {
    if (e.isAxiosError && e.response?.status === 404) {
      return 'The requested resource does not exist'
    }
    return 'Something went wrong!'
  }

  return (
    <div className="error-fallback d-flex justify-content-center align-items-center p-5">
      <CCard
        data-testid="error-fallback"
        className="error-fallback-card d-flex flex-column p-1 justify-self-center text-center mt-5"
      >
        <CCardBody className="error-fallback-content">
          <img src={CompanyConnectorLogo} className="ignite-loading" alt="Ignite app loading" />
          <hr />
          <p>{determineErrorMessage(error)}</p>
          <p>
            If you continue to experience issues, please contact{' '}
            <a href="mailto:support@mobilizeignite.com">support@mobilizeignite.com</a>
          </p>
          <br />
          <CButton
            data-testid="reset-error-boundary-button"
            size="lg"
            onClick={() => {
              props.resetErrorBoundary()
              history.push('/')
            }}
          >
            <i className="fa fa-undo" /> Take me back
          </CButton>
        </CCardBody>
      </CCard>
    </div>
  )
}
