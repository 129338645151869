import { CPopoverProps } from '@coreui/react/dist/components/popover/CPopover'
import { CPopover } from '@coreui/react'
import React from 'react'

export interface IconPopoverProps extends Omit<CPopoverProps, 'children'> {
  iconClassName?: string
}
export const IconPopover = ({ iconClassName, ...props }: IconPopoverProps) => {
  return (
    <CPopover
      /* TODO this should be ['click', 'hover', 'focus'] since the component and the typescript supports it. However their prop type is wrong and it causes a console error */
      trigger={'hover'}
      {...props}
    >
      <i
        tabIndex={0}
        className={`clickable ${iconClassName ? iconClassName : 'fas fa-question-circle'}`}
      />
    </CPopover>
  )
}
