import { CFormLabel, CRow } from '@coreui/react'
import React from 'react'
import { BulkLinkInput } from '@mobilizeyourtech/vision-core-react'

export interface ListingArtifactLinkFormData {
  id?: string | number
  name?: string
  description?: string
  url: string
  destroy?: boolean
  destroyable?: boolean
}

export interface ListingArtifactLinksAttributesProps {
  formData: Array<ListingArtifactLinkFormData>
  onChange: (change: Array<ListingArtifactLinkFormData>) => void
}

export const BulkLinks = (props: ListingArtifactLinksAttributesProps) => {
  return (
    <CRow>
      <CFormLabel className="col-form-label">Links</CFormLabel>
      <BulkLinkInput
        data-testid="artifact-links-bulk-input"
        links={props.formData || []}
        onChange={(change) => props.onChange(change)}
        requiredFields={['name']}
      />
    </CRow>
  )
}
