import React, { PropsWithChildren } from 'react'
import { useState } from 'react'
import { CustomModal, formatUrlProtocol } from '@mobilizeyourtech/vision-core-react'
import { CButton } from '@coreui/react'
import parse from 'url-parse'

type TProps = {
  url: string
  linkText?: string
  'data-testid'?: string
}

export const PotentiallyExternalRedirectLink = (props: PropsWithChildren<TProps>) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleConfirm = () => {
    const open = window.open()
    open!.opener = null as any
    open!.location = formatUrlProtocol(props.url) as any
  }

  const formatUrlForDisplay = (url: string): string => {
    let formattedUrl = formatUrlProtocol(url)
    let parsedUrl = parse(formattedUrl)

    return parsedUrl.origin + parsedUrl.pathname
  }

  return (
    <React.Fragment>
      <span
        className="potentially-external-redirect-link"
        data-testid={props['data-testid'] || 'potentially-external-redirect-link'}
        onClick={() => setIsModalOpen(true)}
      >
        {props.children || props.linkText || props.url}
      </span>
      <CustomModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="potentially-external-redirect-link-confirm-modal"
      >
        <section data-testid="potentially-external-redirect-link-confirm-modal">
          <div className="modal-body px-4">
            <h5 className="text-center mb-2">You are being navigated away from this website!</h5>

            <p className="text-center">This link will navigate you away from this website to:</p>
            <p className="text-center text-truncate">
              <b>{formatUrlForDisplay(props.url)}</b>
            </p>
            <br />
          </div>
          <div className="modal-footer">
            <CButton
              data-testid="potentially-external-redirect-link-confirm-btn"
              onClick={() => handleConfirm()}
            >
              Take Me There
            </CButton>
            <CButton
              data-testid="potentially-external-redirect-link-cancel-btn"
              onClick={() => setIsModalOpen(false)}
              color="light"
            >
              Cancel
            </CButton>
          </div>
        </section>
      </CustomModal>
    </React.Fragment>
  )
}
