import { Location } from 'history'
export enum SESSION_VARIABLES {
  PATH_NAME = 'PATH_NAME',
  QUERY = 'QUERY',
  Q_PARAM = 'Q_PARAM',
  Q_USER_EXISTS = 'Q_USER_EXISTS',
  LINKED_TO_VISION = 'LINKED_TO_VISION',
}

///////////////////////////////////
// Redirect Session Helpers
///////////////////////////////////
export const setRedirectSessionVariables = (location: Location<any>) => {
  let noStoredSessionRedirect = !getFullSessionUrl()
  let redirectTo = new URLSearchParams(location.search).get('redirectTo')

  if (noStoredSessionRedirect || redirectTo) {
    let pathName = location.pathname
    let queryParams = location.search

    if (redirectTo) {
      pathName = redirectTo.split('?')[0]
      queryParams = redirectTo.split('?')[1] ? '?' + redirectTo.split('?')[1] : ''
    }

    window.sessionStorage.setItem(SESSION_VARIABLES.PATH_NAME, pathName)
    window.sessionStorage.setItem(SESSION_VARIABLES.QUERY, queryParams)
  }
}
export const clearRedirectSessionVariables = () => {
  window.sessionStorage.removeItem(SESSION_VARIABLES.PATH_NAME)
  window.sessionStorage.removeItem(SESSION_VARIABLES.QUERY)
}
export const getSessionPath = () => {
  return window.sessionStorage.getItem(SESSION_VARIABLES.PATH_NAME)
}
export const getSessionQuery = () => {
  return window.sessionStorage.getItem(SESSION_VARIABLES.QUERY)
}
export const getFullSessionUrl = () => {
  let ret = undefined

  if (getSessionPath()) {
    ret = getSessionPath()
  }

  if (ret && getSessionQuery()) {
    ret = ret + getSessionQuery()
  }

  return ret
}

///////////////////////////////////
// Redirect Session Helpers
///////////////////////////////////

///////////////////////////////////
// Q Param Session Helpers
///////////////////////////////////
export const clearQParamSessionVariables = () => {
  window.sessionStorage.removeItem(SESSION_VARIABLES.Q_PARAM)
  window.sessionStorage.removeItem(SESSION_VARIABLES.Q_USER_EXISTS)
  window.sessionStorage.removeItem(SESSION_VARIABLES.LINKED_TO_VISION)
}

export const setSessionQParam = (q: string) => {
  window.sessionStorage.setItem(SESSION_VARIABLES.Q_PARAM, q)
}

export const setSessionQUserExists = (args: { userExists: boolean; isLinked: boolean }) => {
  window.sessionStorage.setItem(SESSION_VARIABLES.LINKED_TO_VISION, args.isLinked.toString())
  window.sessionStorage.setItem(SESSION_VARIABLES.Q_USER_EXISTS, args.userExists.toString())
}

export const getSessionQParam = () => {
  let ret = window.sessionStorage.getItem(SESSION_VARIABLES.Q_PARAM)
  return ret || null
}

export const getSessionQUserExists = () => {
  let ret = window.sessionStorage.getItem(SESSION_VARIABLES.Q_USER_EXISTS)
  return ret ? Boolean(JSON.parse(ret)) : null
}

export const getSessionQUserLinkedToVision = () => {
  let ret = window.sessionStorage.getItem(SESSION_VARIABLES.LINKED_TO_VISION)
  return ret ? Boolean(JSON.parse(ret)) : null
}

export const removeQParamFromLocation = (location: Location<any>) => {
  let search = new URLSearchParams(location.search)
  search.delete('q')
  location.search = '?' + search.toString()
  return location
}

///////////////////////////////////
// Q Param Session Helpers
///////////////////////////////////
