import { CCol, CFormLabel, CRow } from '@coreui/react'
import React from 'react'
import { IconPopover, ValidatedCFormInput } from '../../custom'
import { validateEmail, validateLength } from '../../../lib/helpers/validation'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../../stores'

export type ClaimRequestFormData = {
  firstName: string
  lastName: string
  email: string
}

type TProps = {
  value: Partial<ClaimRequestFormData>
  onChange: (value: Partial<ClaimRequestFormData>) => void
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}
export const ClaimRequestForm = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const onChange = (change: Partial<ClaimRequestFormData>) =>
      props.onChange({ ...props.value, ...change })

    const defaultCharLengthMessage = 'Must be a minimum of 2 characters'

    return (
      <>
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'claimRequestEmail' }} className="col-sm-2 col-form-label">
            Email
            <IconPopover
              content={
                <span>
                  To be eligible to claim an existing account, you must submit the claim request
                  using an email that matches available PI/POC data records from previously awarded
                  contracts or that matches the same domain as the associated entity data from{' '}
                  <a href={'https://sam.gov'}>SAM.GOV</a> or{' '}
                  <a href={'https://www.sbir.gov/'}>SBIR.GOV</a>. After submitting the claim, you
                  will be required to verify you are the owner of this email. If you are unable,
                  please contact support to request a path to access.
                </span>
              }
              placement={'right'}
              iconClassName={'fas fa-question-circle ms-2 text-primary'}
            />
          </CFormLabel>

          <CCol>
            <ValidatedCFormInput
              type="email"
              id="claimRequestEmail"
              data-testid="claimRequestEmail"
              placeholder="Email"
              value={props.value.email}
              required
              onChange={(email: string | undefined) => {
                onChange({ email })
              }}
              validate={validateEmail}
              renderInvalidMessage={() => 'Provide a valid email'}
            />
          </CCol>
        </CRow>
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'claimRequestFirstName' }} className="col-sm-2 col-form-label">
            First Name
          </CFormLabel>
          <CCol>
            <ValidatedCFormInput
              type="text"
              id="claimRequestFirstName"
              data-testid="claimRequestFirstName"
              placeholder="First Name"
              value={props.value.firstName || ''}
              onChange={(value) => {
                onChange({ firstName: value })
              }}
              required
              validate={(v) => validateLength(v, 2)}
              renderInvalidMessage={() => defaultCharLengthMessage}
            />
          </CCol>
        </CRow>
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'claimRequestLastName' }} className="col-sm-2 col-form-label">
            Last Name
          </CFormLabel>
          <CCol>
            <ValidatedCFormInput
              type="text"
              id="claimRequestLastName"
              data-testid="claimRequestLastName"
              placeholder="Last Name"
              value={props.value.lastName || ''}
              onChange={(value) => onChange({ lastName: value })}
              required
              validate={(v) => validateLength(v, 2)}
              renderInvalidMessage={() => defaultCharLengthMessage}
            />
          </CCol>
        </CRow>
      </>
    )
  }),
)
