import { CBadge, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import React from 'react'
import { TechnologyTypeResponse } from '../../stores/ServerStore'

export interface GovUserTechnologyTypesCardProps {
  technologyTypes: Array<TechnologyTypeResponse>
}

export const GovUserTechnologyTypesCard = (props: GovUserTechnologyTypesCardProps) => {
  return (
    <CCard className="GovUserTechnologyTypesCard h-100">
      <CCardHeader className="py-2" component="h5">
        Technology Types
      </CCardHeader>
      <CCardBody>
        <CRow className="justify-content-md-start pt-2">
          {props.technologyTypes.map((tt) => {
            return (
              <CCol key={tt.name} className={'col-md-auto px-1 mb-2'}>
                <CBadge color="primary" className="text-wrap text-md-start py-2">
                  {tt.name}
                </CBadge>
              </CCol>
            )
          })}
        </CRow>
      </CCardBody>
    </CCard>
  )
}
