import { SBCTimelineStatistic } from '../../../../stores/AnalyticsStore'
import { IgniteLineChart } from '../../../lineChart/IgniteLineChart'

type TProp = {
  data?: SBCTimelineStatistic[]
  width?: number
}
export const AdminAnalyticsSBCTimelineLineChart = (props: TProp) => {
  let chartLines = [
    { displayName: 'Claimed Listings', dataKey: 'claimedListingsTotal', color: '#FFB742' },
    { displayName: 'SBC Users', dataKey: 'usersTotal', color: '#f0483e' },
    { displayName: 'Total Listings', dataKey: 'listingsTotal', color: '#0070D8' },
  ]

  return (
    <div
      data-testid="admin-analytics-sbc-timeline-line-chart"
      id="admin-analytics-sbc-timeline-line-chart"
    >
      <IgniteLineChart
        data={props.data}
        chartLines={chartLines}
        xAxis="month"
        width={props.width}
        chartId="sbc-timeline-line-chart"
      />
    </div>
  )
}
