import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../stores'
import React, { useState } from 'react'
import { ChatThreadResponse } from '../../stores/ServerStore'
import { RichTooltip, SuspensefulButton } from '@mobilizeyourtech/vision-core-react'

type TProps = {
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  chatThread: ChatThreadResponse
  onChatThreadUpdated: () => void
}

export const ToggleChatStatusButton = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isSuspending, setIsIsSuspending] = useState<boolean>(false)

    const { id, status } = props.chatThread

    const tooltipMessage = `This will ${status === 'active' ? 'pause' : 'unpause'} the chat thread.`

    const pauseChatThread = () => {
      setIsIsSuspending(true)

      props.serverStore
        ?.pauseChatThread(id)
        .then(() => {
          props.onChatThreadUpdated()
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Error pausing chat thread. Please try again.',
            'danger',
            3000,
          )
        })
        .finally(() => {
          setTimeout(() => {
            setIsIsSuspending(false)
          }, 300)
        })
    }

    const unpauseChatThread = () => {
      setIsIsSuspending(true)
      props.serverStore
        ?.unpauseChatThread(id)
        .then(() => {
          props.onChatThreadUpdated()
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Error unpausing chat thread. Please try again.',
            'danger',
            3000,
          )
        })
        .finally(() => {
          setTimeout(() => {
            setIsIsSuspending(false)
          }, 300)
        })
    }

    const renderButton = () => {
      return (
        <SuspensefulButton
          className="chat-pause-button d-flex align-items-center justify-content-center"
          isSuspended={isSuspending}
          disabled={isSuspending || status === 'pre_active'}
          iconClassname={status === 'active' ? 'fas fa-pause' : 'fas fa-play'}
          onInvoke={() => (status === 'active' ? pauseChatThread() : unpauseChatThread())}
          buttonText={''}
          data-testid="chat-pause-button"
        />
      )
    }

    const renderButtonWithTooltip = () => {
      return (
        <RichTooltip
          className="pause-button-tooltip"
          tooltipContent={`${tooltipMessage} This can be changed at any time.`}
        >
          {renderButton()}
        </RichTooltip>
      )
    }

    return (
      <div className="pause-button-wrapper">
        {status === 'pre_active' ? renderButton() : renderButtonWithTooltip()}
      </div>
    )
  }),
)
