import {
  BulkFileUploader,
  RichFileAccept,
  ServerFileData,
  RichTooltip,
} from '@mobilizeyourtech/vision-core-react'
import { CCol, CFormLabel, CRow } from '@coreui/react'
import React from 'react'
import {
  FILE_UPLOAD_MAX_SIZE_BYTES,
  FILE_UPLOAD_MAX_SIZE_CONTENT,
  UNCLASSIFIED_WARNING_PROMPT,
} from '../../../../lib/helpers/utils'

export interface ListingArtifactAttachmentFormData {
  id?: string | number
  name?: string
  description?: string
  file: ServerFileData | File
  destroy?: boolean
}

export interface BulkAttachmentsProps {
  formData: Array<ListingArtifactAttachmentFormData>
  onChange: (change: Array<ListingArtifactAttachmentFormData>) => void
}

const allowedFileTypes: Array<RichFileAccept> = [
  { value: 'image/gif', displayValue: 'GIF (.gif)' },
  { value: 'image/jpeg', displayValue: 'JPEG (.jpeg)' },
  { value: 'image/png', displayValue: 'PNG (.png)' },
  { value: 'image/svg+xml', displayValue: 'SVG (.svg)' },
  { value: 'application/pdf', displayValue: 'PDF (.pdf)' },
  { value: 'application/msword', displayValue: 'DOC (.doc)' },
  {
    value: 'application/vnd.ms-word.document.macroenabled.12',
    displayValue: 'XML Word Macro-Enabled Document (.docm)',
  },
  {
    value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    displayValue: 'XML Word Document (.docx)',
  },
  { value: 'text/csv', displayValue: 'CSV (.csv)' },
  {
    value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    displayValue: 'Excel Workbook (.xlsx)',
  },
  {
    value: 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    displayValue: 'Excel Binary Workbook (.xlsb)',
  },
  { value: 'application/vnd.ms-excel', displayValue: 'Excel Spreadsheet (.xls)' },
  {
    value: 'application/vnd.ms-excel.sheet.macroenabled.12',
    displayValue: 'Excel Macro-Enabled Workbook (.xlsm)',
  },
  { value: 'text/plain', displayValue: 'CSV (.csv)' },
  {
    value: 'application/vnd.ms-powerpoint',
    displayValue: 'PowerPoint Binary Presentation (.ppt)',
  },
  {
    value: 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    displayValue: 'PowerPoint Macro-Enabled Presentation (.pptm)',
  },
  {
    value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    displayValue: 'PowerPoint XML Presentation (.pptx)',
  },
]
export const BulkAttachments = (props: BulkAttachmentsProps) => {
  return (
    <>
      <CRow className="bulk-attachments">
        <CFormLabel className="col-form-label">
          Attachments
          <RichTooltip
            tooltipContent={
              <span className="attachment-tooltip">
                Provide attachments that will give the government user a more robust picture of your
                company&apos;s capabilities.
              </span>
            }
            location="top"
            timeout={300}
          >
            <i className="fas fa-info-circle" />
          </RichTooltip>
        </CFormLabel>
        <BulkFileUploader
          data-testid={'artifact-attachments-bulk-uploader'}
          files={props.formData || []}
          onChange={(change) => props.onChange(change)}
          fileUploaderProps={{
            accept: allowedFileTypes,
            subText: UNCLASSIFIED_WARNING_PROMPT,
            additionalText: FILE_UPLOAD_MAX_SIZE_CONTENT,
            maxSize: FILE_UPLOAD_MAX_SIZE_BYTES,
          }}
        />
        <CCol></CCol>
      </CRow>
    </>
  )
}
