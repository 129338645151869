import { CBadge, CCard, CCardBody, CCardSubtitle, CCardTitle, CCol, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { TechnologyTypeResponse } from '../../stores/ServerStore'

type TProps = {
  path: string
  notificationStore?: NotificationStore
  serverStore?: ServerStore
}
export const TechnologyFocusAreasCard = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [technologyTypeData, setTechnologyTypeData] = useState<
      Array<TechnologyTypeResponse> | undefined
    >(undefined)
    useEffect(() => {
      props.serverStore
        ?.server()
        .get<Array<TechnologyTypeResponse>>(props.path)
        .then(({ data }) => {
          setTechnologyTypeData(data)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Failed to load listing technology data. Please try again.',
            'danger',
            3000,
          )
        })
    }, [props.path])

    return (
      <CCard className="listing-card">
        <CCardBody>
          <CCardTitle className="bold-text">Company Focuses</CCardTitle>
          <CCardSubtitle className="small-text">Technology focuses of your company.</CCardSubtitle>

          <CRow className="mt-2 justify-content-md-start px-2">
            {technologyTypeData?.map((tt) => {
              return (
                <CCol key={tt.id} className={'col-md-auto px-1'}>
                  <CBadge color="primary" className="text-wrap text-md-start">
                    {tt.name}
                  </CBadge>
                </CCol>
              )
            })}
          </CRow>
        </CCardBody>
      </CCard>
    )
  }),
)
