import { CBadge } from '@coreui/react'
import { ListingArtifactTechnologyType } from '../../stores/ListingStore'

type TProps = {
  data: ListingArtifactTechnologyType
}

export const ViewListingArtifactTechnologyType = (props: TProps) => {
  return (
    <div className="d-inline-block">
      <CBadge
        data-testid={`listing-artifact-tech-type-${props.data.name}`}
        color="primary"
        size="sm"
        className="px-3 py-2 mx-1"
      >
        <p>{props.data.name}</p>
      </CBadge>
    </div>
  )
}
