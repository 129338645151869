import { makeAutoObservable } from 'mobx'
import { uuid } from '../lib/helpers/utils'

export class AuthStore {
  private readonly accessToken: string = 'vision-ignite-access-token'
  public JWT: string | null
  // This is a helpful value you can change if you ever want the AuthBoundary to reload its state
  public salt: string

  constructor() {
    this.JWT = this.getJWT()
    this.salt = uuid()
    makeAutoObservable(this)
  }

  public setJWT(jwt: string | null) {
    if (jwt) {
      localStorage.setItem(this.accessToken, jwt)
    } else {
      localStorage.removeItem(this.accessToken)
    }

    this.JWT = jwt
  }

  public getJWT() {
    if (!this.JWT) {
      const jwt = localStorage.getItem(this.accessToken)
      this.JWT = jwt
    }
    return this.JWT
  }

  public isAuthenticated() {
    return !!this.getJWT()
  }

  public generateAndSetSalt() {
    this.salt = uuid()
  }
}
