import { CCard, CCardHeader, CCardBody } from '@coreui/react'
import { CCardProps } from '@coreui/react/dist/components/card/CCard'
import ReactHtmlParser from 'react-html-parser'
import classNames from 'classnames'
import { LineOfEffort } from 'stores/UserStore'

interface LinesOfEffortCardProps extends CCardProps {
  linesOfEffort?: Array<LineOfEffort>
}

export const LinesOfEffortCard = ({
  linesOfEffort = [],
  className,
  ...props
}: LinesOfEffortCardProps) => {
  const linesOfEffortSection = () => {
    if (linesOfEffort.length === 0) {
      return <span className="subtle">Nothing to show</span>
    }

    return (
      <ol className="ps-3 mt-2">
        {linesOfEffort.map((lineOfEffort) => {
          return <li key={lineOfEffort.id}>{ReactHtmlParser(lineOfEffort.details)}</li>
        })}
      </ol>
    )
  }

  return (
    <CCard className={classNames(className, 'ProfileDetailCard')} {...props}>
      <CCardHeader component="h5">
        Lines of Effort
        <br />
        <span>Problem statements I am looking for solutions on</span>
      </CCardHeader>
      <CCardBody>{linesOfEffortSection()}</CCardBody>
    </CCard>
  )
}
