import { CCol, CFormLabel, CRow } from '@coreui/react'
import React from 'react'
import { AsyncValidatedTextInput, IconPopover, ValidatedCFormInput } from '../../custom'
import { validateLength } from '../../../lib/helpers/validation'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore } from '../../../stores'

export type SignupAccountData = {
  name: string
  uniqueEntityId: string
}
type TProps = {
  value: Partial<SignupAccountData>
  onChange: (value: Partial<SignupAccountData>) => void
  serverStore?: ServerStore
  asyncValidationDebounceMs?: number
  disabled?: string[]
  isAccountNameHidden?: boolean
}
export const SignupAccountForm = inject(ApplicationStore.names.serverStore)(
  observer((props: TProps) => {
    const validateUniqueEntityId = async (value: string) => {
      // Force to say this is valid (i.e. in edit when the user cannot change their Unique Entity ID
      if (props.disabled?.includes('uniqueEntityId') && props.value.uniqueEntityId) {
        return undefined
      }

      try {
        const data = await props.serverStore?.checkUniqueEntity(value)
        return data ? undefined : 'InvalidUniqueEntityId'
      } catch (_e) {
        return 'ServerError'
      }
    }

    const craftInvalidMessage = (invalidReason: string) => {
      switch (invalidReason) {
        case 'InvalidUniqueEntityId':
          return (
            <p data-testid="ueid-error">
              Invalid Unique Entity Id. Please contact our support team at{' '}
              <a href="mailto:support@mobilizeignite.com">support@mobilizeignite.com</a>.
            </p>
          )
        default:
          return 'Error validating Unique Entity ID. Please try again.'
      }
    }

    const accountNameInput = () => {
      if (props.isAccountNameHidden) {
        return
      }
      return (
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'signupAccountName' }} className="col-sm-2 col-form-label">
            Account Name
          </CFormLabel>
          <CCol>
            <ValidatedCFormInput
              type="text"
              id="signupAccountName"
              data-testid={'signupAccountName'}
              placeholder="Account Name"
              value={props.value.name || ''}
              onChange={(value) => props.onChange({ ...props.value, name: value })}
              required
              validate={(v) => validateLength(v, 2)}
              renderInvalidMessage={() => 'Must be a minimum of 2 characters'}
            />
          </CCol>
        </CRow>
      )
    }

    return (
      <>
        {accountNameInput()}
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'signupUniqueEntityId' }} className="col-sm-2 col-form-label">
            Unique Entity ID
            <IconPopover
              content={
                <span>
                  The <a href={'https://sam.gov'}>SAM.GOV</a> unique entity indentifier. A valid
                  unique entity identifier is required for signup and only one account per unique
                  entity identifier is supported within IGNITE.
                </span>
              }
              placement={'right'}
              iconClassName={'fas fa-question-circle ms-2 text-primary'}
            />
          </CFormLabel>
          <CCol>
            <AsyncValidatedTextInput
              value={props.value.uniqueEntityId}
              onValue={(value) => {
                props.onChange({ ...props.value, uniqueEntityId: value })
              }}
              validate={validateUniqueEntityId}
              makeValidationMessage={craftInvalidMessage}
              data-testid="signupUniqueEntityId"
              placeholder={'Unique Entity ID'}
              disabled={props.disabled?.includes('uniqueEntityId')}
            />
          </CCol>
        </CRow>
      </>
    )
  }),
)
