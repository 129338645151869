import { inject, observer } from 'mobx-react'
import { useRouteMatch } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { parseDateTime } from '../../lib/time'
import { ApplicationStore, ListingStore, NotificationStore } from '../../stores'
import {
  ListingArtifactAttachment,
  ListingArtifactLink,
  ListingData,
} from '../../stores/ListingStore'
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
  CTooltip,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CNavbar,
  CBadge,
} from '@coreui/react'
import {
  BusinessClassificationsCard,
  ContractingInformationCard,
  ListingPublishedStatusIndicator,
  ListingPublishOrUnpublishActionButton,
  PointOfContactCard,
  SocialMediaDetails,
  TechnologyFocusAreasCard,
  ListingServicesTab,
  ListingUseCasesTab,
  ListingProductsTab,
  ListingAwardsTab,
} from '../../components/listing'
import { formatUrlProtocol } from '../../lib/helpers/utils'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { ViewListingArtifactAttachmentOrLink } from '../../components/listing/ViewListingArtifactAttachmentOrLink'
import { OptionalImage } from '@mobilizeyourtech/vision-core-react'
import classnames from 'classnames'
import { visionVisibilityIcon } from '../../assets/brand/vision_visibility_icon'

type TProps = {
  listingStore?: ListingStore
  notificationStore?: NotificationStore
}

interface MatchParams {
  id: string
}

const tabs = {
  LISTING: 'STOREFRONT',
  SERVICES: 'SERVICES',
  PRODUCTS: 'PRODUCTS',
  USE_CASES: 'USE_CASES',
  AWARDS: 'AWARDS',
}

const ViewListing = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [params, setParams] = useQueryParams({
      checkoutSuccess: withDefault(StringParam, undefined),
      tab: withDefault(StringParam, tabs.LISTING),
    })

    const {
      params: { id },
    } = useRouteMatch<MatchParams>()
    const [listing, setListing] = useState<ListingData | undefined>(undefined)

    useEffect(() => {
      props
        .listingStore!.getSingleListing(id, 'links,attachments,productServiceCodes,views')
        .then((listingResponse) => {
          setListing(listingResponse)
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Unable to get listing data; please try again later',
            'danger',
          )
        })
    }, [params])

    const renderStatusIndicator = (listing: ListingData) => {
      if (!listing.isPublished) {
        return (
          <>
            <i className="fa fa-circle grey"></i>
            <p className="visibility-status">Visible to no one</p>
          </>
        )
      } else if (listing.isOnlyVisionVisible) {
        return (
          <>
            <CBadge className="vision-icon">{visionVisibilityIcon()}</CBadge>
            <p className="visibility-status">Visible only to VISION users</p>
          </>
        )
      } else {
        return (
          <>
            <i className="fa fa-circle green"></i>
            <p className="visibility-status">Visible to all government users</p>
          </>
        )
      }
    }

    if (!listing) {
      return <></>
    }

    return (
      <section className="ViewListing">
        <CNavbar colorScheme="light" placement="fixed-top">
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                active={params.tab === tabs.LISTING}
                onClick={() => setParams({ tab: tabs.LISTING }, 'replace')}
                data-testid="storefront-tab-button"
              >
                Storefront
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                active={params.tab === tabs.PRODUCTS}
                onClick={() => setParams({ tab: tabs.PRODUCTS }, 'replace')}
                data-testid="products-tab-button"
              >
                Products
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                active={params.tab === tabs.SERVICES}
                onClick={() => setParams({ tab: tabs.SERVICES }, 'replace')}
                data-testid="services-tab-button"
              >
                Services
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                active={params.tab === tabs.USE_CASES}
                onClick={() => setParams({ tab: tabs.USE_CASES }, 'replace')}
                data-testid="use-cases-tab-button"
              >
                Use Cases
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                active={params.tab === tabs.AWARDS}
                onClick={() => setParams({ tab: tabs.AWARDS }, 'replace')}
                data-testid="awards-tab-button"
              >
                Awards
              </CNavLink>
            </CNavItem>
          </CNav>
        </CNavbar>

        <CTabContent>
          <CTabPane role="tabpanel" visible={params.tab === tabs.LISTING}>
            <div className="view-infobar container">
              <div className="view-infobar-status align-middle">
                <ListingPublishedStatusIndicator listing={listing} />
                <div className="d-flex justify-content-center align-items-center ms-3">
                  {renderStatusIndicator(listing)}
                </div>
              </div>
              <CButtonGroup role="group">
                {/* Disabling this per stakeholder request but leaving this commented for quick reenable */}
                {/*<ListingVisionVisibilityActionButton*/}
                {/*  listing={listing}*/}
                {/*  onAction={(data) => setListing(data)}*/}
                {/*/>*/}
                <ListingPublishOrUnpublishActionButton
                  listing={listing}
                  onAction={(data) => setListing(data)}
                />
                <CTooltip content={'Edit this listing'}>
                  <CButton
                    className="edit-listing-btn span-btn"
                    href={`/storefront/${listing.id}/edit`}
                  >
                    <i className="fas fa-pencil-alt" />
                  </CButton>
                </CTooltip>
              </CButtonGroup>
            </div>
            <CContainer className="company-details">
              <CCard>
                <CCardBody>
                  <CRow className="mb-2">
                    <CCol className="listing-element-container">
                      <OptionalImage
                        src={listing.logo?.path}
                        title="Company Logo"
                        className="listing-logo"
                      />
                    </CCol>

                    <CCol xs={5}>
                      <h3>{listing.name}</h3>
                      <p className="bold-text">{listing.tagLine}</p>
                      <p>
                        {listing.website && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={formatUrlProtocol(listing.website)}
                          >
                            {listing.website}
                          </a>
                        )}
                      </p>
                    </CCol>

                    <CCol>
                      <strong>Headquarters</strong>
                      <p>{listing.addressLine1}</p>
                      <p>{listing.addressLine2}</p>
                      <p>{listing.addressLine3}</p>
                      <p>
                        {listing.city && listing.city + ','} {listing.stateProvince}{' '}
                        {listing.postalCode}
                      </p>
                      <p>{listing.country}</p>
                    </CCol>

                    <CCol>
                      <SocialMediaDetails path={listing.listingSocialMediaAccounts} />
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol className="listing-element-container" />

                    <CCol xs={5}>
                      <p className="small-text">Company Founded Year: {listing.yearFounded}</p>
                      <p className="small-text">
                        Last Profile Sync: {parseDateTime(listing.updatedAt)}
                      </p>
                      <p className="small-text">{`Profile Views in the Last 30 Days: ${
                        listing.views ?? 0
                      }`}</p>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CContainer>

            <CContainer>
              <CRow>
                <CCol lg={4}>
                  <BusinessClassificationsCard path={listing.businessClassifications} />
                  <ContractingInformationCard
                    naicsCodesPath={listing.naicsCodes}
                    samCode={listing.samCode}
                    dunsCode={listing.dunsCode}
                    cageCode={listing.cageCode}
                    samExpirationDate={listing.samExpirationDate}
                    samRegistrationDate={listing.samRegistrationDate}
                    uniqueEntityId={listing.uniqueEntityId}
                    productServiceCodes={listing.productServiceCodes}
                  />
                  <TechnologyFocusAreasCard path={listing.technologyTypes} />
                  <CCard className="listing-card links">
                    <CCardBody>
                      <CCardTitle className="bold-text pr-2">Links</CCardTitle>

                      {listing.listingArtifactLinks?.length === 0 ? (
                        <CCardText className="subtle mr-2">No Links to Show</CCardText>
                      ) : (
                        listing.listingArtifactLinks?.map((listing: ListingArtifactLink) => (
                          <ViewListingArtifactAttachmentOrLink key={listing.id} data={listing} />
                        ))
                      )}
                    </CCardBody>
                  </CCard>
                </CCol>

                <CCol xs={8}>
                  <PointOfContactCard listingId={listing.id} />

                  <CCard className="listing-card">
                    <CCardBody>
                      <CCardTitle className="listing-details bold-text">
                        Statement of Capabilities
                      </CCardTitle>
                      <CCardText
                        className={classnames('multiline', {
                          subtle: !listing.capabilityStatement,
                        })}
                      >
                        {listing.capabilityStatement
                          ? listing.capabilityStatement
                          : 'No statement of capabilities'}
                      </CCardText>
                    </CCardBody>
                  </CCard>
                  <CCard className="listing-card">
                    <CCardBody>
                      <CCardTitle className="bold-text pr-2">Attachments</CCardTitle>
                      {listing.listingArtifactAttachments?.length === 0 ? (
                        <CCardText className="subtle mr-2">No Attachments to Show</CCardText>
                      ) : (
                        listing.listingArtifactAttachments?.map(
                          (attachment: ListingArtifactAttachment) => (
                            <ViewListingArtifactAttachmentOrLink
                              key={attachment.id}
                              data={attachment}
                            />
                          ),
                        )
                      )}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CContainer>
          </CTabPane>
          {params.tab === tabs.PRODUCTS && <ListingProductsTab />}
          {params.tab === tabs.SERVICES && <ListingServicesTab />}
          {params.tab === tabs.USE_CASES && <ListingUseCasesTab />}
          {params.tab === tabs.AWARDS && <ListingAwardsTab />}
        </CTabContent>
      </section>
    )
  }),
)

export default React.memo(ViewListing)
