import { CCard, CCardBody, CCardText, CCardFooter, CBadge } from '@coreui/react'
import {
  ActionConfirmationModal,
  ActionDropdown,
  ActionDropdownMenuOption,
  Avatar,
} from '@mobilizeyourtech/vision-core-react'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { AccountStore, NotificationStore, ApplicationStore } from '../../stores'
import { InvitedUserData, UserData } from '../../stores/UserStore'
import { EditInviteModal } from './EditInviteModal'

export interface MenuOption extends ActionDropdownMenuOption {
  callback: () => void
}

type TProps = {
  accountStore?: AccountStore
  notificationStore?: NotificationStore
  user: UserData | InvitedUserData
  currentAccountId: string | number
  onSubmit: () => void
}

export const UserDetailCard = inject(
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.accountStore,
)(
  observer((props: TProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [actionType, setActionType] = useState<string | undefined>(undefined)

    const isInvitedUser = (user: UserData | InvitedUserData): user is InvitedUserData => {
      return 'userInviteId' in user && !!user['userInviteId']
    }
    const id = isInvitedUser(props.user) ? props.user.userInviteId : props.user.id

    const hasExpired = (dateString: string) => {
      var currentDate = new Date()
      var expirationDate = new Date(dateString)

      return currentDate > expirationDate
    }

    const userInviteExpired =
      isInvitedUser(props.user) &&
      props.user.inviteTokenExpiry &&
      hasExpired(props.user.inviteTokenExpiry)
        ? true
        : false

    const determineRoute = (actionType: string) => {
      let path
      if (actionType === 'resend') {
        path = props.accountStore?.resendUserInvite(props.currentAccountId, id)
      } else if (actionType === 'delete') {
        path = props.accountStore?.deleteUserInvite(props.currentAccountId, id)
      }
      return path
    }

    const determineMessage = (actionType: string) => {
      let message
      if (actionType === 'resend') {
        message = 'User invite resent successfully.'
      } else if (actionType === 'delete') {
        message = 'User invite deleted successfully.'
      }
      return message
    }

    const resendOrDelete = (actionType: string) => {
      determineRoute(actionType)!
        .then(() => {
          props.notificationStore?.setNotificationMessage(
            determineMessage(actionType),
            'success',
            3000,
          )
          props.onSubmit()
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            `Unable to ${actionType} user invite, please try again later`,
            'danger',
            3000,
          )
          setActionType(undefined)
        })
    }

    const menuOptions: Array<MenuOption> = [
      {
        id: 0,
        name: 'Resend Invite',
        iconClassName: 'fas fa-redo',
        callback: () => {
          setActionType('resend')
        },
      },
      {
        id: 1,
        name: 'Edit Invite',
        iconClassName: 'fas fa-pen',
        callback: () => setIsEditing(true),
      },
      {
        id: 2,
        name: 'Delete Invite',
        iconClassName: 'fa fa-trash',
        callback: () => {
          setActionType('delete')
        },
      },
    ]

    return (
      <CCard
        data-testid={`userDetailCard-${props.user.email}`}
        className="user-card m-2 d-flex flex-column align-items-center justify-content-between text-center py-3"
      >
        <CCardBody className="user-card-body">
          {isInvitedUser(props.user) && (
            <div className="invited-user-options">
              <div className="badge-container">
                <CBadge
                  data-testid={`user-invite-badge-${props.user.email}`}
                  color={userInviteExpired ? 'secondary' : 'primary'}
                  className="text-wrap text-md-start"
                >
                  {userInviteExpired ? 'Invite Expired' : 'Invited'}
                </CBadge>
              </div>
              <ActionDropdown
                data-testid={`user-invite-dropdown-${props.user.email}`}
                menuOptions={menuOptions}
                onSelect={(option: MenuOption) => option.callback()}
                dropdownOptions={{ iconClassName: '', text: '' }}
              />
            </div>
          )}
          {!!actionType && (
            <ActionConfirmationModal
              alignment="center"
              isModalOpen={!!actionType}
              actionText={`Are you sure you'd like to ${actionType} this invite?`}
              onClose={() => setActionType(undefined)}
              onSubmit={() => {
                resendOrDelete(actionType)
              }}
            />
          )}
          {isEditing && isInvitedUser(props.user) && (
            <EditInviteModal
              data-testid="invite-user-modal"
              isModalOpen={isEditing}
              closeModal={() => setIsEditing(false)}
              userInviteId={id}
              currentAccountId={props.currentAccountId}
              formData={props.user}
              onSubmit={() => props.onSubmit()}
            />
          )}
          <div className="avatar-container d-flex justify-content-center align-items-center">
            <Avatar
              className="user-card-avatar"
              src={!isInvitedUser(props.user) ? props.user.headshot?.path : ''}
              prefix={props.user.firstName}
              suffix={props.user.lastName}
            />
          </div>
          <h5 className="user-name pt-3 pb-1">
            {props.user.firstName} {}
            {props.user.lastName}
          </h5>
          <CCardText className="user-title">
            {!isInvitedUser(props.user) ? props.user.title : ''}
          </CCardText>
          {!isInvitedUser(props.user) && <p>Zone: {props.user.timezone}</p>}
        </CCardBody>
        <CCardFooter className="user-card-footer pt-3">
          <div className="user-value-with-icon py-1">
            <i className="user-value-icon far fa-envelope" />
            <p className="truncate-icon-value">{props.user.email}</p>
          </div>
          {!isInvitedUser(props.user) && props.user.cellPhone && (
            <div className="user-value-with-icon py-1">
              <i className="user-value-icon fas fa-phone-alt"></i>
              <p className="truncate-icon-value">{props.user.cellPhone}</p>
            </div>
          )}
          {!isInvitedUser(props.user) && props.user.workPhone && (
            <div className="user-value-with-icon py-1">
              <i className="user-value-icon fas fa-phone-alt"></i>
              <p className="truncate-icon-value">{props.user.workPhone}</p>
            </div>
          )}
        </CCardFooter>
      </CCard>
    )
  }),
)
