import { ApplicationStore, NotificationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { CCard, CCardBody, CCardHeader, CRow } from '@coreui/react'
import ReactHtmlParser from 'react-html-parser'
import React from 'react'
import { LinesOfEffortCard } from 'components/Organisms/EntityCards/LinesOfEffortCard/LinesOfEffortCard'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
}

export const GovUserExtendedProfileDetailCard = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const profileDetailSection = () => {
      return (
        <CRow>
          <div className={'ql-editor'}>
            {props.userStore?.currentUserData?.govUserExtendedData?.profileDetail &&
              ReactHtmlParser(props.userStore.currentUserData.govUserExtendedData.profileDetail)}
          </div>
        </CRow>
      )
    }

    const interactionDetailSection = () => {
      return (
        <CRow>
          <div className={'ql-editor'}>
            {props.userStore?.currentUserData?.govUserExtendedData?.interactionDetail &&
              ReactHtmlParser(
                props.userStore.currentUserData.govUserExtendedData.interactionDetail,
              )}
          </div>
        </CRow>
      )
    }

    return (
      <>
        <LinesOfEffortCard
          linesOfEffort={props.userStore?.currentUserData?.govUserExtendedData?.linesOfEffort}
          className="mt-3"
        />
        <CCard className="ProfileDetailCard mt-3">
          <CCardHeader component="h5">Problem Statement & Role Description</CCardHeader>
          <CCardBody>{profileDetailSection()}</CCardBody>
        </CCard>
        <CCard className="ProfileDetailCard mt-3">
          <CCardHeader component="h5">Desired Interactions With Small Businesses</CCardHeader>
          <CCardBody>{interactionDetailSection()}</CCardBody>
        </CCard>
      </>
    )
  }),
)
