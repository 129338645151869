import React from 'react'
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { ApplicationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { UserSettingsSection } from './UserSettingsSection'

type TProps = {
  userStore?: UserStore
}

export const UserSettingsCard = inject(ApplicationStore.names.userStore)(
  observer((props: TProps) => {
    return (
      <CCard className="UserSettingsCard">
        <CCardHeader className="py-2" component="h5">
          Settings
        </CCardHeader>
        <CCardBody className="py-1">
          <UserSettingsSection />
        </CCardBody>
      </CCard>
    )
  }),
)
