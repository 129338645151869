import { CCard } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { AnalyticsStore, ApplicationStore, NotificationStore } from '../../../stores'
import { ListingPageViewStatistic } from '../../../stores/AnalyticsStore'

import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import AdminAnalyticsListingPageViewsTable from './tables/AdminAnalyticsListingPageViewsTable'

type TProps = {
  analyticsStore?: AnalyticsStore
  notificationStore?: NotificationStore
}

export const AdminAnalyticsListingPageViewsStatistics = inject(
  ApplicationStore.names.analyticsStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const [listingPageViewsStatistics, setListingPageViewsStatistics] = useState<
      Array<ListingPageViewStatistic>
    >([])

    useEffect(() => {
      setIsLoading(true)
      props
        .analyticsStore!.getListingPageViewStatistics({ page: 1, perPage: 10 })
        .then((response) => {
          setListingPageViewsStatistics(response.data)
        })
        .catch((e) => {
          props.notificationStore?.setNotificationMessage(
            'Error loading Listing Page Views Statistic Data',
            'error',
            3000,
          )
          setError(e)
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 300)
        })
    }, [])
    const ListingPageViewsTable = (props: { data?: ListingPageViewStatistic[] }) => {
      let noResult = (
        <div
          id="admin-analytics-line-chart-and-table-absent"
          data-testid="listing-page-view-table-absent"
          className="text-center"
        >
          Error occurred while loading results
        </div>
      )
      return (
        <div
          id="admin-analytics-listing-page-views-statistics-container"
          className="user-card-container d-flex flex-wrap w-100 align-items-center justify-content-center"
        >
          <CCard className="card-body">
            <SuspensionBoundary data-testid="spinner" isLoading={isLoading}>
              <div className="row">
                <div className="col-sm-12 col-xl-10 offset-xl-1">
                  <div
                    className="stat-header"
                    id="admin-analytics-line-chart-and-table-card-header "
                  >
                    <h2 className="mb-0">Top SBC Organization Views</h2>
                  </div>
                  {!error ? <AdminAnalyticsListingPageViewsTable data={props.data} /> : noResult}
                </div>
              </div>
            </SuspensionBoundary>
          </CCard>
        </div>
      )
    }
    return <ListingPageViewsTable data={listingPageViewsStatistics} />
  }),
)

export default <AdminAnalyticsListingPageViewsStatistics />
