import { observer } from 'mobx-react'
import { useState } from 'react'
import { ListingServiceProductData, ListingUseCaseData, isUseCase } from '../../stores/ListingStore'
import { CCard, CCardBody, CCardLink, CCardTitle } from '@coreui/react'
import {
  OptionalImage,
  ActionDropdown,
  parseDate,
  ActionDropdownMenuOption,
  ActionConfirmationModal,
  formatUrlProtocol,
} from '@mobilizeyourtech/vision-core-react'

type TProps = {
  listingArtifact: ListingServiceProductData | ListingUseCaseData
  onDelete: (artifactId: string | number) => void
  handleParamsChange: (id: string | number) => void
  handleEdit: (artifactId: string | number) => void
}

interface ArtifactMenuOption extends ActionDropdownMenuOption {
  callback: () => void
}

export const ListingArtifactCard = observer((props: TProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const artifactOptions: Array<ArtifactMenuOption> = [
    {
      id: 0,
      name: 'Edit',
      iconClassName: 'fa fa-pen',
      callback: () => props.handleEdit(props.listingArtifact.id),
    },
    {
      id: 1,
      name: 'Remove',
      iconClassName: 'fa fa-trash',
      callback: () => setIsConfirmationModalOpen(true),
    },
  ]

  return (
    <CCard className="ListingArtifactCard">
      <ActionConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        onSubmit={() => props.onDelete(props.listingArtifact.id)}
        onClose={() => setIsConfirmationModalOpen(false)}
        actionText={`Are you sure you want to delete ${props.listingArtifact.name}?`}
        alignment="center"
      />

      <CCardBody data-testid="card-body">
        <div className="listing-artifact-card-header">
          <div className="header-left">
            <OptionalImage
              data-testid="optional-image"
              onClick={() => props.handleParamsChange(props.listingArtifact.id)}
              className="artifact-logo"
              src={props.listingArtifact.logo?.path}
            />
            <div className="artifact-details">
              <CCardTitle onClick={() => props.handleParamsChange(props.listingArtifact.id)}>
                {props.listingArtifact.name}
              </CCardTitle>
              <CCardLink href={formatUrlProtocol(props.listingArtifact.url!)} target="_blank">
                {props.listingArtifact.url && props.listingArtifact.url.replace(/^https?:\/\//, '')}
              </CCardLink>
            </div>
          </div>
          <div className="header-right">
            <ActionDropdown
              menuOptions={artifactOptions}
              onSelect={(option) => option.callback()}
              data-testid={'artifact-actions'}
              dropdownToggleProps={{
                caret: false,
              }}
            />
          </div>
        </div>
        {isUseCase(props.listingArtifact) && (
          <div className="use-case-date-row">
            <p className="use-case-date">
              <span className="bold-label">Start Date: </span>
              {props.listingArtifact.startDate ? (
                parseDate(props.listingArtifact.startDate)
              ) : (
                <span className="grey-label">N/A</span>
              )}
            </p>
            <p className="use-case-date">
              <span className="bold-label">End Date: </span>
              {props.listingArtifact.endDate ? (
                parseDate(props.listingArtifact.endDate)
              ) : (
                <span className="grey-label">N/A</span>
              )}
            </p>
          </div>
        )}
        <p className="truncate-overflow">{props.listingArtifact.description}</p>
      </CCardBody>
    </CCard>
  )
})
