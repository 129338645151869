import { StringParam, NumberParam, useQueryParams, withDefault } from 'use-query-params'
import { Collapsible } from '../../../custom'
import { objectContainsAnyValue } from '../../../../lib/helpers/utils'
import { ConnectAwardSearch } from '../ConnectAwardSearch/ConnectAwardSearch'

export const ConnectCollapsibleAwardSearch = () => {
  const [params] = useQueryParams({
    searchAwards: withDefault(StringParam, undefined),
    awardPhase: withDefault(StringParam, undefined),
    awardNaicsCodeIds: withDefault(StringParam, undefined),
    awardContractNumber: withDefault(StringParam, undefined),
    awardAmountMin: withDefault(NumberParam, undefined),
    awardAmountMax: withDefault(NumberParam, undefined),
    awardDateMin: withDefault(StringParam, undefined),
    awardDateMax: withDefault(StringParam, undefined),
    awardState: withDefault(StringParam, undefined),
    awardTechnologyTypeIds: withDefault(StringParam, undefined),
  })

  return (
    <Collapsible
      headerText="Award Info"
      className="awards"
      isExpanded={objectContainsAnyValue(params)}
    >
      <ConnectAwardSearch />
    </Collapsible>
  )
}
