import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { useRouteMatch } from 'react-router-dom'

import {
  ListingStore,
  NotificationStore,
  ApplicationStore,
  ConnectListingStore,
} from '../../stores'
import {
  isAward,
  ListingAwardData,
  ListingServiceProductData,
  ListingUseCaseData,
} from '../../stores/ListingStore'
import { CustomModal, SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import { CCardBody, CCardHeader, CModalBody } from '@coreui/react'
import { ViewListingArtifact } from './ViewListingArtifact'
import { ViewListingAward } from './ViewListingAward'

type TProps = {
  listingStore?: ListingStore
  connectListingStore?: ConnectListingStore
  notificationStore?: NotificationStore
  handleEditClick: (artifactId: string | number) => void
  connect?: Boolean
}

interface MatchParams {
  id: string
}

const ViewListingArtifactModal = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.connectListingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [params, setParams] = useQueryParams({
      artifactType: withDefault(StringParam, undefined),
      artifactId: withDefault(StringParam, undefined),
    })

    const {
      params: { id: listingId },
    } = useRouteMatch<MatchParams>()

    const [data, setData] = useState<
      ListingServiceProductData | ListingUseCaseData | ListingAwardData | undefined
    >(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | undefined>(undefined)

    useEffect(() => {
      if (params.artifactId && params.artifactType) {
        setError(undefined)
        setIsLoading(true)
        let promise:
          | Promise<ListingServiceProductData | ListingUseCaseData | ListingAwardData>
          | undefined = undefined

        let store = props.connect ? props.connectListingStore : props.listingStore
        if (params.artifactType === 'service') {
          promise = store!.getSingleListingService({
            listingId,
            serviceId: params.artifactId,
            eager: 'links,attachments,technologyTypes',
          })
        } else if (params.artifactType === 'product') {
          promise = store!.getSingleListingProduct({
            listingId,
            productId: params.artifactId,
            eager: 'links,attachments,technologyTypes',
          })
        } else if (params.artifactType === 'use case') {
          promise = store!.getSingleListingUseCase({
            listingId,
            useCaseId: params.artifactId,
            eager: 'links,attachments',
          })
        } else if (params.artifactType === 'award') {
          promise = store!.getSingleListingAward({
            listingId,
            awardId: params.artifactId,
            eager: 'links,attachments,technologyTypes',
          })
        } else {
          setError(`Unsupported listing artifact of type ${params.artifactType}`)
          setTimeout(() => {
            setIsLoading(false)
          }, 1000)
        }

        promise
          ?.then((response) => {
            setData(response)
          })
          .catch(() => {
            setError(`Unable to get ${params.artifactType} data; please try again later`)
          })
          .finally(() => {
            setTimeout(() => {
              setIsLoading(false)
            }, 1000)
          })
      } else {
        setData(undefined)
      }
    }, [params])

    const renderArtifact = () => {
      if (!data) {
        return
      }

      if (isAward(data)) {
        return <ViewListingAward data={data} />
      }
      return (
        <ViewListingArtifact
          data={data}
          artifactType={params.artifactType!}
          handleEditClick={(artifactId) => props.handleEditClick(artifactId)}
        />
      )
    }
    return (
      <CustomModal
        data-testid="CustomModal"
        className="CustomModal"
        isModalOpen={!!params.artifactType && !!params.artifactId}
        onClose={() => setParams({ artifactId: undefined, artifactType: undefined })}
        scrollable
        size={data && isAward(data) ? 'lg' : undefined}
      >
        <CModalBody className="modal-body-wrapper" data-testid="modal-body-wrapper">
          <SuspensionBoundary isLoading={isLoading} data-testid="loader">
            <div>
              {error ? (
                <CCardBody className="display-error d-flex w-100">
                  <CCardHeader className="w-100 d-flex justify-content-center">{error}</CCardHeader>
                </CCardBody>
              ) : (
                renderArtifact()
              )}
            </div>
          </SuspensionBoundary>
        </CModalBody>
      </CustomModal>
    )
  }),
)

export default ViewListingArtifactModal
