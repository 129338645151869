import { SimpleCollapse } from '@mobilizeyourtech/vision-core-react'
import React from 'react'
import MobilizeVISIONLogo from '../../assets/brand/Ignite_Logo_Mobilize_Full_Color.png'
import { visionVisibilityIcon } from '../../assets/brand/vision_visibility_icon'
import { CCard, CCardBody, CLink } from '@coreui/react'
import { leftArrow } from '../../components/icons/leftArrow'
import { Link } from 'react-router-dom'

export const SignupOrSignin = () => {
  const choices = [
    {
      icon: <i className="fas fa-user" />,
      header: 'I have a IGNITE Account',
      nextStep: (
        <>
          <CLink data-testid={'signin-link'} className={'clickable'} href={'/signin'}>
            Click here
          </CLink>{' '}
          to sign in to an existing Ignite account
        </>
      ),
    },
    {
      icon: <i className="far fa-regular fa-user" />,
      header: 'I do not have an IGNITE Account',
      nextStep: (
        <>
          <CLink data-testid={'signup-link'} className={'clickable'} href={'/gov_user'}>
            Click here
          </CLink>{' '}
          to create an Ignite account
        </>
      ),
    },
  ]

  return (
    <section className="SignupChoice">
      <div className="choice-header w-100 d-flex justify-content-center">
        <Link
          className="nav-back clickable"
          data-testid="nav-back"
          aria-label="navigate back to signin"
          tabIndex={0}
          to="/"
        >
          {leftArrow()}
        </Link>
        <img
          src={MobilizeVISIONLogo}
          className="MobilizeVisionLogo mb-3"
          alt="Mobilize Vision Logo"
        />
      </div>

      <div className="choice-prompt mt-3 d-flex flex-column justify-content-center">
        <div className="text-center">
          <h5>
            <b>Choose the option that best represents you</b>
          </h5>
        </div>
        <div className={'d-flex justify-content-center'}>
          <SimpleCollapse className={'why-matter-collapse'} toggleContent={'Why does it matter?'}>
            <p className="prompt-text text-center">
              VISION users get a VISION-verified badge {visionVisibilityIcon()}, establishing
              greater trust and recognition in Ignite.
            </p>
          </SimpleCollapse>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-center gap-2 flex-wrap mt-2">
        {choices.map((choice, idx) => (
          <CCard key={idx} className="choice-card" data-testid={`signup-or-signin-choice-${idx}`}>
            <CCardBody>
              {choice.icon}
              <h5 className="mt-1">
                <b>{choice.header}</b>
              </h5>
              <div className="mt-3">Next Step: {choice.nextStep}</div>
            </CCardBody>
          </CCard>
        ))}
      </div>
    </section>
  )
}
