import React from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore, NotificationStore } from '../../../../stores'

import { PaginatedSearchableParams, ServerPageData } from '../../../../lib/types/Params'
import {
  isDirtySelectableItem,
  SelectableItem,
  ServerBackedMultiSelectAutoComplete,
} from '@mobilizeyourtech/vision-core-react'

export interface INaicsCodes {
  id: number
  name: string
  code: string
  label: string
}

type TProps = {
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  selected: Array<INaicsCodes & SelectableItem>
  onChange: (nc: Array<INaicsCodes & SelectableItem>) => void
}

export const NaicsCodes = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const getNaicsCodes = (
      params: PaginatedSearchableParams,
    ): Promise<ServerPageData<INaicsCodes & SelectableItem>> => {
      return props.serverStore!.getNaicsCodes(params).then((response) => ({
        pagination: response.pagination,
        data: response.data.map((r) => ({ ...r, label: `(${r.code}) - ${r.name}` })),
      }))
    }

    const getSelectedNaicsCodes = () => {
      return props.selected.map((nc) => {
        return { ...nc, label: `(${nc.code}) - ${nc.name}` }
      })
    }

    return (
      <section className="NaicsCodes">
        <ServerBackedMultiSelectAutoComplete
          loadMore={getNaicsCodes}
          onChange={(change) =>
            // DirtySelectableItem is only possible if allowDirty were set to tru
            props.onChange(
              change.filter((e) => !isDirtySelectableItem(e)) as Array<
                INaicsCodes & SelectableItem
              >,
            )
          }
          selections={getSelectedNaicsCodes()}
          placeholder={'Search and select multiple NAICS codes'}
          className="naics-codes"
          data-testid={'naics-codes-select'}
        />
      </section>
    )
  }),
)
