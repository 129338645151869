import { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ListingStore, NotificationStore } from '../../stores'
import { ListingUseCaseData } from '../../stores/ListingStore'
import { PaginationData } from '../../lib/types/Params'
import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import { CCard, CButton, CCardBody, CRow } from '@coreui/react'
import { Pagination } from '../../components/custom/Pagination'
import { ListingArtifactCard } from './ListingArtifactCard'
import { ListingArtifactFormModal } from '../form'
import ViewListingArtifactModal from './ViewListingArtifactModal'

type TProps = {
  listingStore?: ListingStore
  notificationStore?: NotificationStore
  timeout?: number
}

interface MatchParams {
  id: string
}

export const ListingUseCasesTab = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const {
      params: { id },
    } = useRouteMatch<MatchParams>()

    const [paginationParams] = useQueryParams({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 10),
    })

    const [, setParams] = useQueryParams({
      artifactId: withDefault(StringParam, undefined),
      artifactType: withDefault(StringParam, undefined),
    })

    const [editUseCaseId, setEditUseCaseId] = useState<string | number | undefined>(undefined)
    const [useCases, setUseCases] = useState<Array<ListingUseCaseData>>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false)
    const [pagination, setPagination] = useState<PaginationData | undefined>(undefined)
    const [error, setError] = useState<any>(undefined)

    useEffect(() => {
      loadData()
    }, [paginationParams])

    const loadData = () => {
      setIsLoading(true)
      props.listingStore
        ?.getListingUseCases({
          listingId: id,
          params: paginationParams,
        })
        .then((response) => {
          setUseCases(response.data)
          setPagination(response.pagination)
        })
        .catch((error) => {
          setError(error)
          props.notificationStore?.setNotificationMessage('Error loading Use Cases', 'error', 3000)
        })
        .finally(() => setIsLoading(false))
    }

    const handleDelete = (useCaseId: string | number) => {
      props.listingStore
        ?.deleteUseCase({ listingId: id, useCaseId })
        .then(() => {
          props.notificationStore?.setNotificationMessage('Use Case deleted', 'success', 3000)
          loadData()
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Could not delete Use Case - please reload and try again',
            'danger',
            3000,
          )
        })
    }

    const handleSuccessfulUpdate = (artifactType: string, artifactId: string) => {
      loadData()
      setParams({ artifactType, artifactId })
    }

    const renderUseCaseContent = () => {
      if (error) {
        return (
          <CCard className="no-use-cases">
            <CCardBody
              className={'subtle d-flex text-center align-items-center justify-content-center'}
            >
              Error loading Use Cases - please reload
            </CCardBody>
          </CCard>
        )
      } else if (useCases.length > 0) {
        return useCases.map((useCase) => {
          return (
            <ListingArtifactCard
              handleParamsChange={(id: string | number) =>
                setParams({ artifactId: id as string, artifactType: 'use case' })
              }
              handleEdit={(id) => setEditUseCaseId(id)}
              listingArtifact={useCase}
              key={useCase.id}
              onDelete={handleDelete}
            />
          )
        })
      } else if (pagination && pagination.count === 0) {
        return (
          <CCard className="no-use-cases">
            <CCardBody
              className={'subtle d-flex text-center align-items-center justify-content-center'}
            >
              There are no use cases to display
            </CCardBody>
          </CCard>
        )
      }
    }

    return (
      <section className="ListingUseCasesTab">
        <ViewListingArtifactModal
          handleEditClick={(artifactId) => {
            setEditUseCaseId(artifactId)
            setParams({ artifactType: undefined, artifactId: undefined })
          }}
        />
        <ListingArtifactFormModal
          artifact="use case"
          listingId={id}
          method="create"
          onSubmit={() => loadData()}
          visible={isCreateModalVisible}
          setVisibility={setIsCreateModalVisible}
          timeout={props?.timeout}
        />
        <ListingArtifactFormModal
          artifact="use case"
          listingId={id}
          method="update"
          onSubmit={(artifact, id) => handleSuccessfulUpdate(artifact, id.toString())}
          visible={!!editUseCaseId}
          setVisibility={() => setEditUseCaseId(undefined)}
          artifactId={editUseCaseId}
          timeout={props.timeout}
        />
        <div className="listing-use-cases-tab-header">
          <h1>Use Cases</h1>
          <CButton
            className="create-use-case-button"
            variant="ghost"
            disabled={isLoading}
            data-testid="create-use-case-button"
            onClick={() => setIsCreateModalVisible(true)}
          >
            <i className="fa fa-plus" />
            Create Use Case
          </CButton>
        </div>
        <SuspensionBoundary isLoading={isLoading}>
          <CRow className="listing-use-cases" xxl={{ cols: 3 }}>
            {renderUseCaseContent()}
          </CRow>
        </SuspensionBoundary>

        <div className="pagination-container">
          {pagination && <Pagination pagination={pagination} />}
        </div>
      </section>
    )
  }),
)
