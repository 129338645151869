import { AdminAnalyticsResponseRateHeader } from '../../components/admin/adminAnalytics/AdminAnalyticsResponseRateStatistics'
import { AdminAnalyticsSBCTimelineStatistics } from '../../components/admin/adminAnalytics/AdminAnalyticsSBCTimelineStatistics'
import { AdminAnalyticsTechnologyTypeStatistics } from '../../components/admin/adminAnalytics/AdminAnalyticsTechnologyTypeStatistics'
import { AdminAnalyticsGovUsersTimelineStatistics } from '../../components/admin/adminAnalytics/AdminAnalyticsGovUserTimelineStatistics'
import { AdminAnalyticsListingPageViewsStatistics } from '../../components/admin/adminAnalytics/AdminAnalyticsListingPageViewsStatistics'
import { ActiveConversationStatistics } from '../../components/admin/adminAnalytics/ActiveConversationStatistics'
import { DafUsersStatistics } from '../../components/admin/adminAnalytics/DafUsersStatistics'
import { SbcUserStatistics } from '../../components/admin/adminAnalytics/SbcUsersStatistics'
import { OrganizationUserStatistics } from '../../components/admin/adminAnalytics/OrganizationUserStatistic'

export const AdminAnalytics = () => {
  return (
    <div className="admin-analytics">
      <AdminAnalyticsResponseRateHeader />
      <AdminAnalyticsTechnologyTypeStatistics />
      <AdminAnalyticsSBCTimelineStatistics />
      <AdminAnalyticsGovUsersTimelineStatistics />
      <AdminAnalyticsListingPageViewsStatistics />
      <ActiveConversationStatistics />
      <DafUsersStatistics />
      <SbcUserStatistics />
      <OrganizationUserStatistics />
    </div>
  )
}
