import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, UserStore } from '../../stores'
import { ChatMessageResponse, ChatThreadResponse } from '../../stores/ServerStore'
import { Spinner } from './index'
import { formatDate } from '../../lib/helpers/utils'
import { UserAvatar } from '../user'
import { CCardText, CRow } from '@coreui/react'
import { IntersectObservantPill } from '@mobilizeyourtech/vision-core-react'

type ChatThreadPanelMessagesProps = {
  chatThread: ChatThreadResponse
  userStore?: UserStore
  chatMessages: Array<ChatMessageResponse>
  latestMessageAtLoad: ChatMessageResponse | undefined
  isExtending: boolean
  isExtendingError: boolean
  extendData: () => void
  chatEndRef: React.MutableRefObject<any>
  onViewNewestMessage: () => void
  showNewMessageIndicator: boolean
  timeout?: number
  onChatThreadUpdated: () => void
}

export const ChatThreadPanelMessages = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.serverStore,
)(
  observer((props: ChatThreadPanelMessagesProps) => {
    const user = props.userStore!.currentUserData!
    const [lastMessageSeenAtLoad] = useState<Date>(new Date(props.chatThread.lastMessageSeenAt))
    const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false)
    const [unreadMessageTimeout, setUnreadMessageTimeout] = useState<
      ReturnType<typeof setTimeout> | undefined
    >(undefined)

    useEffect(() => {
      setUnreadMessageTimeout(
        setTimeout(() => {
          setHasUnreadMessages(maybeShowMoreUnread())
        }, props.timeout || 300),
      )

      return () => {
        unreadMessageTimeout && clearTimeout(unreadMessageTimeout)
      }
    }, [props.latestMessageAtLoad])

    const maybeShowMoreUnread = (): boolean => {
      if (
        props.chatMessages.length === 0 ||
        !props.latestMessageAtLoad ||
        props.chatThread.currentUserUnreadCount === 0 ||
        props.chatThread.currentUserUnreadCount === undefined
      )
        return false

      let latestMessageTime = new Date(props.latestMessageAtLoad.createdAt).getTime()
      let latestMessageSeenTime = new Date(props.chatThread.lastMessageSeenAt).getTime()

      return latestMessageTime > latestMessageSeenTime
    }

    const renderDivider = (dateBoundary: Date) => {
      const now = new Date()

      // NOTE: The chatMessages.length is included below to ensure that a single
      // date grouping with more than 10 messages does not load all messages at
      // once. This also allows us to maintain snapshot tests because the
      // chatMessages.length can be predictable and consistent in testing.
      return (
        <div
          key={`${dateBoundary.getFullYear()}.${dateBoundary.getMonth()}.${dateBoundary.getDate()}-${
            props.chatMessages.length
          }`}
          className="date-divider py-1"
        >
          <div className="boundary-value" />
          <div className="date-value">
            {now.getFullYear() === dateBoundary.getFullYear() &&
            now.getMonth() === dateBoundary.getMonth() &&
            now.getDate() === dateBoundary.getDate()
              ? 'Today'
              : formatDate(dateBoundary)}
          </div>
        </div>
      )
    }

    const renderSeenAtDivider = (): JSX.Element => {
      return (
        <IntersectObservantPill
          id={`new-messages-divider-${props.chatMessages.length}`}
          key={`new-messages-divider-${props.chatMessages.length}`}
          onVisible={() => {
            unreadMessageTimeout && clearTimeout(unreadMessageTimeout)
            setHasUnreadMessages(false)
            props.onChatThreadUpdated()
          }}
          data-testid={'more-messages-intersection-observer'}
        >
          <div className={'last-message-seen-divider'} data-testid={'last-message-seen-divider'}>
            <hr />
            <span>New</span>
          </div>
        </IntersectObservantPill>
      )
    }

    const renderChatMessage = (message: ChatMessageResponse, date: Date) => {
      return (
        <div
          className="chat-card w-100 p-2 d-flex flex-row gap-2"
          key={message.id}
          data-testid={`chat-message-${message.id}`}
        >
          <UserAvatar user={message.user} />
          <div className="chat-card-text">
            <div className="chat-card-header-text d-flex gap-1 align-items-start justify-content-start">
              <h6 className="chat-user-name">
                {message.user.firstName} {message.user.lastName}
              </h6>
              <h6 className="chat-date">{date.toLocaleTimeString()}</h6>
            </div>
            <CCardText className={user.id === message.userId ? 'outgoing' : 'incoming'}>
              {message.content}
            </CCardText>
          </div>
        </div>
      )
    }

    const renderMessages = () => {
      if (!props.latestMessageAtLoad) {
        return
      }

      const latestMessage = new Date(props.latestMessageAtLoad.createdAt)
      // setting prevMessage as first message so that even if there's only one message, the
      // new message divider will render
      let prevMessage: ChatMessageResponse = props.chatMessages[0]
      let currentDivider: Date | undefined = undefined
      let result: Array<JSX.Element> = []
      let hasNewDivider = false

      props.chatMessages.forEach((chat, idx) => {
        let date = new Date(chat.createdAt)

        if (!currentDivider) {
          currentDivider = date
          result = [...result, renderDivider(currentDivider)]
        }
        if (
          currentDivider.getFullYear() !== date.getFullYear() ||
          currentDivider.getMonth() !== date.getMonth() ||
          currentDivider.getDate() !== date.getDate()
        ) {
          currentDivider = date
          result = [...result, renderDivider(currentDivider)]
        }

        if (
          !hasNewDivider && // if there isn't already a divider added
          chat.userId !== props.userStore?.currentUserData?.id && // and this message was sent by someone other than the current user
          new Date(prevMessage.createdAt).getTime() === lastMessageSeenAtLoad.getTime() && // and if prevMessage was the last one seen
          date <= latestMessage // and the message was created before the latest message when user visited the thread
        ) {
          result = [...result, renderSeenAtDivider()]
          hasNewDivider = true
        }

        // render newest message pill above the last message to hide the new messages
        // notice as the last message scrolls into view
        if (idx === props.chatMessages.length - 1) {
          result = [...result, renderNewestMessagePill()]
        }

        result = [...result, renderChatMessage(chat, date)]

        prevMessage = chat
      })

      return result
    }

    const renderNewestMessagePill = () => {
      return (
        <IntersectObservantPill
          id={`chat-bottom-pill-${props.chatMessages.length}`}
          key={`chat-bottom-pill-${props.chatMessages.length}`}
          data-testid={'chat-bottom-pill'}
          onVisible={props.onViewNewestMessage}
        />
      )
    }

    const renderMoreUnreadNotice = () => {
      return (
        <span className="more-unread-notice" data-testid="more-unread-notice">
          More Unread
        </span>
      )
    }

    return (
      <>
        {hasUnreadMessages && renderMoreUnreadNotice()}
        <div className="message-container">
          <div className="chat-wrapper">
            {!props.isExtending && !props.isExtendingError && (
              <IntersectObservantPill
                data-testid="observer"
                className="observer"
                id="message-top-observer"
                entryProps={{
                  root: null,
                  rootMargin: '100px',
                  threshold: 0.5,
                }}
                onVisible={() => props.extendData()}
              />
            )}
            {props.isExtending && (
              <div className="observer-spinner-container d-flex justify-content-center pt-2">
                <Spinner className="message-suspense" />
              </div>
            )}
            {props.isExtendingError && (
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-center">Error loading messages ...</span>
              </div>
            )}
            <CRow className="chat-wrapper p-0 m-0">{renderMessages()}</CRow>

            {props.showNewMessageIndicator && (
              <span
                className={'new-message-indicator'}
                onClick={() => props.chatEndRef.current.scrollIntoView()}
              >
                New Messages
              </span>
            )}

            <span className="panel-end" data-testid="panel-end" ref={props.chatEndRef} />
          </div>
        </div>
      </>
    )
  }),
)
