import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ListingStore, NotificationStore } from '../../stores'
import { ListingServiceProductData } from '../../stores/ListingStore'
import { useRouteMatch } from 'react-router-dom'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { Pagination } from '../custom'
import { PaginationData } from '../../lib/types/Params'
import { CRow, CButton, CCard, CCardBody } from '@coreui/react'
import { ListingArtifactFormModal } from '../form'
import { ListingArtifactCard } from './ListingArtifactCard'
import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import ViewListingArtifactModal from './ViewListingArtifactModal'

type TProps = {
  listingStore?: ListingStore
  notificationStore?: NotificationStore
  timeout?: number
  /** Optional timeout for form modal - mostly passthrough for testing */
}
interface MatchParams {
  id: string
}

export const ListingServicesTab = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [pagination, setPagination] = useState<PaginationData | undefined>(undefined)
    const [paginationParams] = useQueryParams({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 10),
    })
    const {
      params: { id },
    } = useRouteMatch<MatchParams>()

    const [, setParams] = useQueryParams({
      artifactId: withDefault(StringParam, undefined),
      artifactType: withDefault(StringParam, undefined),
    })

    const [services, setServices] = useState<Array<ListingServiceProductData>>([])
    const [editServiceId, setEditServiceId] = useState<string | number | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false)
    const [error, setError] = useState<any>(undefined)

    useEffect(() => {
      getData()
    }, [paginationParams])

    const getData = () => {
      setIsLoading(true)
      props.listingStore
        ?.getListingServices({
          listingId: id,
          params: paginationParams,
        })
        .then((response) => {
          setServices(response.data)
          setPagination(response.pagination)
        })
        .catch((error) => {
          setError(error)
          props.notificationStore?.setNotificationMessage('Error loading services', 'error', 3000)
        })
        .finally(() => setIsLoading(false))
    }

    const handleDelete = (serviceId: string | number) => {
      props.listingStore
        ?.deleteService({ listingId: id, serviceId })
        .then(() => {
          props.notificationStore?.setNotificationMessage('Service deleted', 'success', 3000)
          getData()
        })
        .catch(() => {
          props.notificationStore?.setNotificationMessage(
            'Could not delete Service - please reload and try again',
            'danger',
            3000,
          )
        })
    }

    const renderServiceContent = () => {
      if (error) {
        return (
          <CCard className="no-services">
            <CCardBody
              className={'subtle d-flex text-center align-items-center justify-content-center'}
            >
              Error loading Services - please reload
            </CCardBody>
          </CCard>
        )
      } else if (services.length > 0) {
        return services.map((service) => {
          return (
            <ListingArtifactCard
              listingArtifact={service}
              onDelete={handleDelete}
              key={service.id}
              handleEdit={(id: string | number) => setEditServiceId(id)}
              handleParamsChange={(id: string | number) =>
                setParams({ artifactId: id as string, artifactType: 'service' })
              }
            />
          )
        })
      } else if (pagination && pagination.count === 0) {
        return (
          <CCard className="no-services">
            <CCardBody
              className={'subtle d-flex text-center align-items-center justify-content-center'}
            >
              There are no services to display
            </CCardBody>
          </CCard>
        )
      }
    }

    const handleSuccessfulUpdate = (artifactType: string, artifactId: string) => {
      getData()
      setParams({ artifactType, artifactId })
    }

    return (
      <section className="ListingServicesTab">
        <ViewListingArtifactModal
          handleEditClick={(artifactId) => {
            setEditServiceId(artifactId)
            setParams({ artifactType: undefined, artifactId: undefined })
          }}
        />
        <ListingArtifactFormModal
          artifact="service"
          listingId={id}
          method="create"
          onSubmit={() => getData()}
          visible={isCreateModalVisible}
          setVisibility={setIsCreateModalVisible}
          timeout={props.timeout}
        />
        <ListingArtifactFormModal
          artifact="service"
          listingId={id}
          method="update"
          onSubmit={(artifact, id) => handleSuccessfulUpdate(artifact, id.toString())}
          visible={!!editServiceId}
          setVisibility={() => setEditServiceId(undefined)}
          artifactId={editServiceId}
          timeout={props.timeout}
        />
        <div className="listing-services-tab-header">
          <h1>Services</h1>
          <CButton
            className="create-service-button"
            variant="ghost"
            disabled={isLoading}
            data-testid="create-service-button"
            onClick={() => {
              setIsCreateModalVisible(true)
            }}
          >
            <i className="fa fa-plus" />
            Create Service
          </CButton>
        </div>
        <SuspensionBoundary isLoading={isLoading}>
          <CRow className="listing-services" xxl={{ cols: 3 }}>
            {renderServiceContent()}
          </CRow>
        </SuspensionBoundary>

        <div className="pagination-container">
          {pagination && <Pagination pagination={pagination} />}
        </div>
      </section>
    )
  }),
)
