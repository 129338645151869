import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import { Provider } from 'mobx-react'
import { Route, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ApplicationStore } from './stores/ApplicationStore'
import { QueryParamProvider } from 'use-query-params'
import { LastLocationProvider } from 'react-router-last-location'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/boundary'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5PPXV3LB',
}

TagManager.initialize(tagManagerArgs)

const applicationStore = new ApplicationStore()
const history = createBrowserHistory()

ReactDOM.render(
  <React.StrictMode>
    <Provider applicationStore={applicationStore} {...applicationStore.getStores()}>
      <Router history={history}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => null}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <LastLocationProvider>
              <App />
            </LastLocationProvider>
          </QueryParamProvider>
        </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
