import { SbcUsersStatistic } from '../../../../stores/AnalyticsStore'
import { IgniteLineChart } from '../../../lineChart/IgniteLineChart'

type TProp = {
  data?: SbcUsersStatistic[]
  width?: number
}
export const SbcUserStatisticLineChart = (props: TProp) => {
  let chartLines = [
    { displayName: 'SBC Logins', dataKey: 'userLogins', color: '#f0483e' },
    { displayName: 'SBC Messages Sent', dataKey: 'messagesSent', color: '#0070D8' },
  ]

  return (
    <div data-testid="sbc-user-statistics-line-chart" id="sbc-user-statistics-line-chart">
      <IgniteLineChart
        data={props.data}
        chartLines={chartLines}
        xAxis="month"
        width={props.width}
        chartId="sbc-users-line-chart"
      />
    </div>
  )
}
