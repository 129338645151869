import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ListingStore, NotificationStore } from '../../stores'
import { ListingServiceProductData } from '../../stores/ListingStore'
import { useRouteMatch } from 'react-router-dom'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { Pagination } from '../custom'
import { PaginationData } from '../../lib/types/Params'
import { CCol, CRow, CButton, CCard, CCardBody } from '@coreui/react'
import { ListingArtifactCard } from './ListingArtifactCard'
import { ListingArtifactFormModal } from '../form'
import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import ViewListingArtifactModal from './ViewListingArtifactModal'

type TProps = {
  listingStore?: ListingStore
  notificationStore?: NotificationStore
  timeout?: number
}

interface MatchParams {
  id: string
}

export const ListingProductsTab = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [pagination, setPagination] = useState<PaginationData | undefined>(undefined)
    const [paginationParams] = useQueryParams({
      page: withDefault(NumberParam, 1),
      perPage: withDefault(NumberParam, 10),
    })
    const {
      params: { id },
    } = useRouteMatch<MatchParams>()

    const [, setParams] = useQueryParams({
      artifactId: withDefault(StringParam, undefined),
      artifactType: withDefault(StringParam, undefined),
    })

    const [products, setProducts] = useState<Array<ListingServiceProductData>>([])
    const [editProductId, setEditProductId] = useState<string | number | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false)
    const [error, setError] = useState<any>(undefined)

    useEffect(() => {
      loadData()
    }, [paginationParams])

    const loadData = () => {
      setIsLoading(true)
      props.listingStore
        ?.getListingProducts({
          listingId: id,
          params: paginationParams,
        })
        .then((response) => {
          setProducts(response.data)
          setPagination(response.pagination)
        })
        .catch((error) => {
          setError(error)
          props.notificationStore?.setNotificationMessage('Error loading products', 'error', 3000)
        })
        .finally(() => setIsLoading(false))
    }

    const handleDelete = (productId: string | number) => {
      props.listingStore
        ?.deleteProduct({ listingId: id, productId })
        .then(() => {
          props.notificationStore?.setNotificationMessage('Product deleted', 'success', 3000)
          loadData()
        })
        .catch(() =>
          props.notificationStore?.setNotificationMessage(
            'Could not delete Product - please reload and try again',
            'danger',
            3000,
          ),
        )
    }

    const renderProductContent = () => {
      if (error) {
        return (
          <CCol lg={3}>
            <CCard className="no-products">
              <CCardBody
                className={'subtle d-flex text-center align-items-center justify-content-center'}
              >
                Error loading Products - please reload
              </CCardBody>
            </CCard>
          </CCol>
        )
      } else if (products.length > 0) {
        return products.map((product) => {
          return (
            <ListingArtifactCard
              handleParamsChange={(id: string | number) =>
                setParams({ artifactId: id as string, artifactType: 'product' })
              }
              handleEdit={(id) => setEditProductId(id)}
              listingArtifact={product}
              onDelete={handleDelete}
              key={product.id}
            />
          )
        })
      } else if (pagination && pagination.count === 0) {
        return (
          <CCard className="no-products">
            <CCardBody
              className={'subtle d-flex text-center align-items-center justify-content-center'}
            >
              There are no products to display
            </CCardBody>
          </CCard>
        )
      }
    }

    const handleSuccessfulUpdate = (artifactType: string, artifactId: string) => {
      loadData()
      setParams({ artifactType, artifactId })
    }

    return (
      <section className="ListingProductsTab">
        <ViewListingArtifactModal
          handleEditClick={(id) => {
            setEditProductId(id)
            setParams({ artifactType: undefined, artifactId: undefined })
          }}
        />
        <ListingArtifactFormModal
          artifact="product"
          listingId={id}
          method="create"
          onSubmit={() => loadData()}
          visible={isCreateModalVisible}
          setVisibility={setIsCreateModalVisible}
          timeout={props.timeout}
        />
        <ListingArtifactFormModal
          artifact="product"
          listingId={id}
          method="update"
          onSubmit={(artifact, id) => handleSuccessfulUpdate(artifact, id.toString())}
          visible={!!editProductId}
          setVisibility={() => setEditProductId(undefined)}
          timeout={props.timeout}
          artifactId={editProductId}
        />
        <div className="listing-products-tab-header">
          <h1>Products</h1>
          <CButton
            className="create-product-button"
            variant="ghost"
            disabled={isLoading}
            data-testid="create-product-button"
            onClick={() => {
              setIsCreateModalVisible(true)
            }}
          >
            <i className="fa fa-plus" />
            Create Product
          </CButton>
        </div>
        <SuspensionBoundary isLoading={isLoading}>
          <CRow className="listing-products" xxl={{ cols: 3 }}>
            {renderProductContent()}
          </CRow>
        </SuspensionBoundary>

        <div className="pagination-container">
          {pagination && <Pagination pagination={pagination} />}
        </div>
      </section>
    )
  }),
)
