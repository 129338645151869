import React from 'react'
import { CCol, CContainer, CRow } from '@coreui/react'
import { ProfileDetailCard, AccountDetailCard, UserSettingsCard } from '../../components/user'
import { ApplicationStore, UserStore } from '../../stores'
import { inject, observer } from 'mobx-react'
import { GovUserExtendedProfileDetailCard } from '../../components/user/GovUserExtendedProfileDetailCard'
import { AccountActions } from '../../components/user/AccountActions'
import { GovUserTechnologyTypesCard } from '../../components/user/GovUserTechnologyTypesCard'

type TProps = {
  userStore?: UserStore
}

const Account = inject(ApplicationStore.names.userStore)(
  observer((props: TProps) => {
    return (
      <section className="Account">
        <CContainer>
          <CRow>
            <CCol>
              <div className={'d-flex flex-row justify-content-end px-4 mb-3'}>
                <AccountActions />
              </div>
            </CCol>
          </CRow>
          <CRow className={'h-100'}>
            <CCol className={'d-flex flex-column flex-1'}>
              <ProfileDetailCard />
            </CCol>
            <CCol className={'d-flex flex-column gap-2 justify-content-start'}>
              {props.userStore?.currentUserData?.userRoles.includes('ignite') && (
                <CRow>
                  <CCol>
                    <AccountDetailCard />
                  </CCol>
                </CRow>
              )}
              <CRow>
                <CCol>
                  <UserSettingsCard />
                </CCol>
              </CRow>
              {props.userStore?.currentUserData?.userRoles.includes('gov') && (
                <CRow className={'h-100'}>
                  <CCol className={'h-100'}>
                    <GovUserTechnologyTypesCard
                      technologyTypes={props.userStore?.currentUserData?.technologyTypes || []}
                    />
                  </CCol>
                </CRow>
              )}
            </CCol>
          </CRow>
          {props.userStore?.currentUserData?.userRoles.includes('gov') && (
            <CRow className="mt-2 mb-3">
              <CCol>
                <GovUserExtendedProfileDetailCard />
              </CCol>
            </CRow>
          )}
        </CContainer>
      </section>
    )
  }),
)

export default Account
