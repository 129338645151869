import React, { PropsWithChildren, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, AuthStore, NotificationStore, UserStore } from '../../stores'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import {
  AntiSpamPolicy,
  ConfirmationInstructions,
  ConfirmClaimLanding,
  ConfirmEmailLanding,
  Privacy,
  RequestPasswordReset,
  ResetPassword,
  SignIn,
  SignTermsAndPrivacy,
  ClaimRequestSignUp,
  InviteSignUp,
  AcceptInviteLanding,
  Terms,
  SubmitClaimRequest,
  SubmitClaimResult,
  GovUserSignup,
  SignupChoice,
} from '../../views'
import { VisionRequestInterceptedError } from '../../lib/errors/utils'
import {
  clearQParamSessionVariables,
  getSessionQParam,
  getSessionQUserExists,
  removeQParamFromLocation,
  setRedirectSessionVariables,
  setSessionQParam,
  setSessionQUserExists,
} from '../../lib/helpers/sessionHelpers'
import { StringParam, useQueryParam } from 'use-query-params'
import { SignupOrSignin } from '../../views/signup/SignupOrSignin'
import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'

type TProps = {
  authStore?: AuthStore
  userStore?: UserStore
  notificationStore?: NotificationStore
}
export const AuthBoundary = inject(
  ApplicationStore.names.authStore,
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: PropsWithChildren<TProps>) => {
    const location = useLocation<any>()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [qParam, setQParam] = useQueryParam('q', StringParam)

    const authenticated = props.authStore?.isAuthenticated()
    const confirmed = props.userStore?.currentUserData?.confirmed

    const initializeUserAndAccountData = async () => {
      try {
        const userData = await props.userStore!.retrieveCurrentUserData('technology_types')
        let accountData
        if (userData.userRoles.includes('ignite')) {
          accountData = await props.userStore!.retrieveCurrentAccountData()
        }

        accountData && props.userStore?.setCurrentAccountData(accountData)
        props.userStore?.setCurrentUserData(userData)
        props.userStore?.createWebsocketConsumer()
      } catch (err) {
        if (!(err instanceof VisionRequestInterceptedError)) {
          props.notificationStore?.setNotificationMessage(
            'Failed to load user and account data. Please try again',
            'danger',
            3000,
          )
        }
      }
    }

    const setQSessionVariables = async (params: string) => {
      await props.userStore
        ?.checkIfVisionUserHasAccountAndIsLinked(params)
        .then((userHasAccount: { userExists: boolean; isLinked: boolean }) => {
          setSessionQUserExists(userHasAccount)
        })
    }

    const validateQAndInitializeUser = async () => {
      // if q, store q in session store, pop it from the url
      const stored_q = getSessionQParam()
      const q = qParam
      if (q && !stored_q) {
        setSessionQParam(q)
        setQParam(undefined)
        try {
          await setQSessionVariables(q)
          if (props.authStore?.JWT) {
            await initializeUserAndAccountData()
          } else {
            if (getSessionQUserExists()) {
              history.push('/sign_in')
            } else {
              history.push('/signup_or_signin')
            }
          }
        } catch (e) {
          // decryption error
          clearQParamSessionVariables()
        }
      } else {
        setQParam(undefined)
        if (props.authStore?.JWT) {
          await initializeUserAndAccountData()
        }
      }
    }

    const initializeAuthBoundary = async () => {
      await validateQAndInitializeUser()
      setIsLoading(false)
    }

    useEffect(() => {
      initializeAuthBoundary()
    }, [props.authStore!.salt])

    useEffect(() => {
      let filteredLocation = removeQParamFromLocation(location)
      setRedirectSessionVariables(filteredLocation)
    }, [])

    return (
      <>
        <SuspensionBoundary isLoading={isLoading}>
          <Switch>
            {!authenticated && <Route exact path="/signin" component={SignIn} />}
            {!authenticated && <Route exact path="/claim_signup" component={ClaimRequestSignUp} />}
            {!authenticated && <Route exact path="/claim" component={SubmitClaimRequest} />}
            {!authenticated && <Route exact path="/claim_result" component={SubmitClaimResult} />}
            {!authenticated && <Route exact path="/invite_signup" component={InviteSignUp} />}
            {!authenticated && (
              <Route exact path="/accept_invite" component={AcceptInviteLanding} />
            )}
            {!authenticated && (
              <Route exact path="/confirm_claim" component={ConfirmClaimLanding} />
            )}
            {!authenticated && <Route exact path="/gov_user" component={GovUserSignup} />}
            {!authenticated && (
              <Route exact path="/request_password_reset" component={RequestPasswordReset} />
            )}
            {!authenticated && <Route exact path="/reset_password" component={ResetPassword} />}
            {(!confirmed || !authenticated) && (
              <Route exact path="/confirmation_instructions" component={ConfirmationInstructions} />
            )}
            {authenticated && <Route exact path="/sign_terms" component={SignTermsAndPrivacy} />}
            <Route exact path="/confirm_email" component={ConfirmEmailLanding} />
            {!authenticated && <Route exact path="/terms" component={Terms} />}
            {!authenticated && <Route exact path="/privacy" component={Privacy} />}
            {!authenticated && <Route exact path="/anti_spam" component={AntiSpamPolicy} />}
            {!authenticated && <Route exact path="/signup/choice" component={SignupChoice} />}
            {!authenticated && <Route exact path="/signup_or_signin" component={SignupOrSignin} />}
            {!authenticated && <Redirect to="/signin" />}
            {authenticated && confirmed && props.children}
          </Switch>
        </SuspensionBoundary>
      </>
    )
  }),
)
