import { CCol, CFormLabel, CRow } from '@coreui/react'
import { SingleImageUploader, ValidatedCFormInput, ValidatedCFormTextArea } from '../../../custom'
import { validateLength, validateWebsite } from '../../../../lib/helpers/validation'
import {
  isServerFileData,
  SelectableItem,
  ServerFileData,
} from '@mobilizeyourtech/vision-core-react'
import { titleCase } from '../../../../lib/helpers/utils'
import DatePicker from 'react-date-picker'
import { ListingArtifactTechnologyType } from '../../../../stores/ListingStore'
import { ListingArtifactTechnologyFocusAreas } from '.'
import { TechnologyTypeResponse } from '../../../../stores/ServerStore'

export interface GenericListingArtifactFormData {
  name: string
  description: string
  url?: string
  logo?: File | ServerFileData
  startDate?: string
  endDate?: string
  listingArtifactTechnologyTypesAttributes?: Array<
    (ListingArtifactTechnologyType & SelectableItem) | TechnologyTypeResponse
  >
}
export const extractGenericListingArtifactFormData = <T,>({
  name,
  description,
  url,
  logo,
  startDate,
  endDate,
  listingArtifactTechnologyTypesAttributes,
}: T & Partial<GenericListingArtifactFormData>) => ({
  name,
  description,
  url,
  logo,
  startDate,
  endDate,
  listingArtifactTechnologyTypesAttributes,
})

export interface GenericListingArtifactFormFragmentProps {
  formData: Partial<GenericListingArtifactFormData>
  onChange: (change: Partial<GenericListingArtifactFormData>) => void
  artifact: 'product' | 'service' | 'use case'
}
export const GenericListingArtifactFormFragment = (
  props: GenericListingArtifactFormFragmentProps,
) => {
  const renderDatePicker = (label: string, formKey: string, value?: string) => {
    return (
      <>
        <CFormLabel
          {...{ htmlFor: `listing-artifact-${formKey}-input` }}
          className="col-sm-2 col-form-label"
        >
          {label}
        </CFormLabel>
        <CCol xs={4}>
          <DatePicker
            calendarAriaLabel={label}
            clearAriaLabel={`Clear ${label} input`}
            onChange={(date: Date) =>
              props.onChange({ ...props.formData, [formKey]: date?.toISOString() })
            }
            value={value ? new Date(value) : undefined}
            openCalendarOnFocus={false}
            className="calendar"
          />
        </CCol>
      </>
    )
  }

  return (
    <>
      <CRow className="mb-3">
        <CFormLabel {...{ htmlFor: 'listing-artifact-name-input' }} className="col-sm-2">
          Name
        </CFormLabel>
        <CCol>
          <ValidatedCFormInput
            required
            data-testid={'listing-artifact-name-input'}
            id="listing-artifact-name-input"
            value={props.formData.name || ''}
            validate={(v) => validateLength(v, 2)}
            onChange={(v) => props.onChange({ ...props.formData, name: v })}
            renderInvalidMessage={() => 'Must be a minimum of 2 characters'}
            placeholder={`${titleCase(props.artifact)} name`}
          />
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CFormLabel
          {...{ htmlFor: 'listing-artifact-description-input' }}
          className="col-sm-2 col-form-label"
        >
          Description
        </CFormLabel>
        <CCol>
          <ValidatedCFormTextArea
            type="text"
            placeholder={`${titleCase(props.artifact)} description`}
            id={'listing-artifact-description-input'}
            data-testid={'listing-artifact-description-input'}
            onChange={(value) => props.onChange({ ...props.formData, description: value })}
            value={props.formData.description || ''}
            validate={(v) => validateLength(v, 2)}
            renderInvalidMessage={() => 'Must be at least 2 characters'}
            required
          />
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CFormLabel
          {...{ htmlFor: 'listing-artifact-url-input' }}
          className="col-sm-2 col-form-label"
        >
          Url
        </CFormLabel>
        <CCol>
          <ValidatedCFormInput
            type="text"
            data-testid={'listing-artifact-url-input'}
            id={'listing-artifact-url-input'}
            onChange={(value) => props.onChange({ ...props.formData, url: value })}
            value={props.formData.url || ''}
            validate={(v) => validateWebsite(v)}
            renderInvalidMessage={() => 'Invalid url'}
            placeholder={`${titleCase(props.artifact)} website or url`}
          />
        </CCol>
      </CRow>
      {props.artifact !== 'use case' && (
        <CRow className="mb-3">
          <CFormLabel
            {...{ htmlFor: 'listing-artifact-technology-dropdown' }}
            className="col-sm-2 col-form-label"
          >
            Technology Types
          </CFormLabel>
          <CCol>
            <ListingArtifactTechnologyFocusAreas
              selected={props.formData.listingArtifactTechnologyTypesAttributes || []}
              data-testid="tech-type-dropdown"
              onChange={(change) =>
                props.onChange({
                  ...props.formData,
                  listingArtifactTechnologyTypesAttributes: change,
                })
              }
            />
          </CCol>
        </CRow>
      )}

      {props.artifact === 'use case' && (
        <CRow className="mb-3 align-items-center">
          {renderDatePicker('Start Date', 'startDate', props.formData.startDate)}
          {renderDatePicker('End Date', 'endDate', props.formData.endDate)}
        </CRow>
      )}

      <CRow className="mb-3">
        <CFormLabel className="col-sm-2 col-form-label">Logo</CFormLabel>
        <CCol>
          <SingleImageUploader
            data-testid={'artifact-logo-uploader'}
            onFile={(f) => props.onChange({ ...props.formData, logo: f })}
            value={
              props.formData.logo && isServerFileData(props.formData.logo)
                ? props.formData.logo.path
                : props.formData.logo
            }
          />
        </CCol>
      </CRow>
    </>
  )
}
