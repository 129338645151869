import React, { useEffect, useState } from 'react'
import { ApplicationStore, NotificationStore, UserData } from '../../stores'
import { inject, observer } from 'mobx-react'
import { CDropdownProps } from '@coreui/react/dist/components/dropdown/CDropdown'
import { ValidatedCFormSelect } from './ValidatedCFormSelect'

type TProps = Omit<
  CDropdownProps & React.RefAttributes<HTMLDivElement | HTMLLIElement>,
  'value' | 'onChange'
> & {
  value?: UserData
  onChange: (value: UserData | undefined) => void
  getDataFunction: () => Promise<Array<UserData>>
  notificationStore?: NotificationStore
}

export const SingleUserSelect = inject(
  ApplicationStore.names.accountStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [users, setUsers] = useState<Array<UserData>>([])
    const { getDataFunction } = props

    useEffect(() => {
      getDataFunction()
        .then((data) => {
          setUsers(data)
        })
        .catch((err) => {
          props.notificationStore?.setNotificationMessage(
            'Failed to get user data. Please try again',
            'danger',
            3000,
          )
          // TODO notificationStore
        })
    }, [])

    const makeUserLabel = (user: UserData) => {
      return `${user.firstName} ${user.lastName}`
    }

    const handleChange = (id: string | undefined) => {
      props.onChange(users?.find((u) => u.id.toString() === id))
      return
    }

    return (
      <ValidatedCFormSelect
        aria-label="select point of contact"
        id={props.id}
        onChange={(v) => handleChange(v)}
        value={props.value?.id.toString()}
        required
        data-testid={'single-user-select'}
        validate={(v) => (v ? undefined : 'required')}
      >
        {users?.map((u) => (
          <option key={u.id} value={u.id}>
            {makeUserLabel(u)}
          </option>
        ))}
      </ValidatedCFormSelect>
    )
  }),
)
