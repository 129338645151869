import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore, UserStore } from '../../stores'
import {
  RichTooltip,
  SuspensefulButton,
  SuspensefulButtonProps,
} from '@mobilizeyourtech/vision-core-react'
import { useEffect, useState } from 'react'
import { ConnectListingResponse, isConnectListingResponse } from '../../stores/ConnectListingStore'
import { GovUserData, hasGovRole, hasIgniteRole, isUserData } from '../../stores/UserStore'
import { ChatParticipantIdentifiers, ChatThreadResponse } from '../../stores/ServerStore'
import { useHistory } from 'react-router-dom'
import { InitiateChatModal } from './InitiateChatModal'
import classnames from 'classnames'

export interface StartChatButtonProps
  extends Omit<SuspensefulButtonProps, 'isSuspended' | 'onInvoke' | 'buttonText'> {
  target: ConnectListingResponse | GovUserData
  serverStore?: ServerStore
  userStore?: UserStore
  tooltipDebounceMs?: number
}

export const StartChatButton = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.userStore,
)(
  observer(
    ({
      serverStore,
      userStore,
      target,
      tooltipDebounceMs = 350,
      ...props
    }: StartChatButtonProps) => {
      const history = useHistory()
      const [isSuspended, setIsSuspended] = useState<boolean>(true)
      const [isInitiateModalOpen, setIsInitiateModalOpen] = useState<boolean>(false)
      const [disabledReason, setDisabledReason] = useState<string | undefined>(undefined)
      const [participants, setParticipants] = useState<
        Array<ChatParticipantIdentifiers> | undefined
      >(undefined)
      const [existingChatThread, setExistingChatThread] = useState<ChatThreadResponse | undefined>(
        undefined,
      )

      const makeAndSetParticipants = () => {
        if (isConnectListingResponse(target) && hasGovRole(userStore!.currentUserData!)) {
          setParticipants([
            {
              participantType: 'Account',
              participantId: target.accountId,
            },
            {
              participantType: 'User',
              participantId: userStore!.currentUserData!.id,
            },
          ])
        } else if (isUserData(target) && hasIgniteRole(userStore!.currentUserData!)) {
          setParticipants([
            {
              participantType: 'User',
              participantId: target.id,
            },
            {
              participantType: 'Account',
              participantId: userStore!.currentAccountData!.id,
            },
          ])
        } else {
          // This means there is some unexpected illegal combination of participants
          setDisabledReason(
            `You are unable to chat with this ${
              isConnectListingResponse(target) ? 'account' : 'user'
            }`,
          )
        }
      }

      useEffect(() => {
        makeAndSetParticipants()
      }, [])

      useEffect(() => {
        if (participants) {
          serverStore!
            .findChatThreadByParticipants(participants)
            .then((data) => {
              if (isUserData(target) && !target.govUserSetting.allowMessageRequests && !data) {
                setDisabledReason("This user's preferences do not allow new messages")
              }
              setExistingChatThread(data)
            })
            .catch(() => {
              setDisabledReason('Error initiating chat. Please refresh.')
            })
            .finally(() => setIsSuspended(false))
        } else {
          setIsSuspended(false)
        }
      }, [participants])

      const onInvokeChat = () => {
        if (existingChatThread) {
          history.push(`/chat?threadId=${existingChatThread.id}`)
          return
        }
        setIsInitiateModalOpen(true)
      }

      const renderTooltipContent = () => {
        let message = disabledReason
        const wider = !!disabledReason
        if (!message) {
          if (!existingChatThread) {
            message = 'Start a chat'
          } else {
            message = 'Go to chat'
          }
        }
        return <div className={classnames('chat-button-tooltip', { wider })}>{message}</div>
      }

      return (
        <section className="StartChatButton">
          <RichTooltip
            tooltipContent={renderTooltipContent()}
            location={'bottom'}
            timeout={tooltipDebounceMs}
          >
            <SuspensefulButton
              {...props}
              data-testid="start-chat-button"
              iconClassname={!!disabledReason ? 'fas fa-comment-slash' : 'fas fa-comment'}
              isSuspended={isSuspended}
              disabled={!!disabledReason}
              onInvoke={onInvokeChat}
              buttonText={'Chat'}
            />
          </RichTooltip>

          {participants && (
            <InitiateChatModal
              target={target}
              participants={participants}
              isModalOpen={isInitiateModalOpen}
              onClose={() => setIsInitiateModalOpen(false)}
            />
          )}
        </section>
      )
    },
  ),
)
