import React from 'react'
import { CCol, CForm, CFormLabel, CRow } from '@coreui/react'
import { ValidatedCFormInput } from '../custom'
import { UserInviteData } from '../../stores/AccountStore'
import { validateLength } from '@mobilizeyourtech/vision-core-react'
import { validateEmail } from '../../lib/helpers/validation'
type TProps = {
  hasValidated: boolean
  formData: Partial<UserInviteData>
  onChange: (change: Partial<UserInviteData>) => void
  onSubmitted?: () => void
}

export const InviteUserForm = (props: TProps) => {
  const defaultCharLengthMessage = 'Must be a minimum of 2 characters'

  return (
    <section className="invite-user-form-container" data-testid="invite-user-form">
      <CForm
        validated={props.hasValidated}
        className="needs-validation p-3"
        {...{ noValidate: true }}
      >
        <CRow className="py-2">
          <CCol className="ValidatedInput">
            <CFormLabel {...{ htmlFor: 'firstName' }}>First Name</CFormLabel>

            <ValidatedCFormInput
              onChange={(value) => props.onChange({ ...props.formData, firstName: value })}
              value={props.formData.firstName}
              id="firstName"
              name="firstName"
              placeholder="Enter First Name.."
              type="text"
              required
              data-testid="firstName"
              validate={(v) => validateLength(v, 2)}
              renderInvalidMessage={() => defaultCharLengthMessage}
            />
          </CCol>
        </CRow>
        <CRow className="py-2">
          <CCol>
            <CFormLabel {...{ htmlFor: 'lastName' }}>Last Name</CFormLabel>
            <ValidatedCFormInput
              onChange={(value) => props.onChange({ ...props.formData, lastName: value })}
              value={props.formData.lastName}
              id="lastName"
              name="firstName"
              placeholder="Enter Last Name.."
              type="text"
              required
              data-testid="lastName"
              validate={(v) => validateLength(v, 2)}
              renderInvalidMessage={() => defaultCharLengthMessage}
            />
          </CCol>
        </CRow>
        <CRow className="py-2">
          <CCol className="pb-4">
            <CFormLabel {...{ htmlFor: 'email' }}>Email</CFormLabel>
            <ValidatedCFormInput
              onChange={(value) => props.onChange({ ...props.formData, email: value })}
              value={props.formData.email}
              type="email"
              id="email"
              name="email"
              placeholder="Enter Email.."
              autoComplete="email"
              required
              data-testid="email"
              validate={(v) => validateEmail(v)}
              renderInvalidMessage={() => 'Please Enter Valid Email'}
            />
          </CCol>
        </CRow>
      </CForm>
    </section>
  )
}
