import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Collapsible } from '../../../custom'
import { DebouncedInput } from '@mobilizeyourtech/vision-core-react'
import { objectContainsAnyValue } from '../../../../lib/helpers/utils'

export const ConnectCollapsibleUseCaseSearch = () => {
  const [params, setParams] = useQueryParams({
    searchUseCases: withDefault(StringParam, undefined),
  })

  return (
    <Collapsible headerText="Use Cases" isExpanded={objectContainsAnyValue(params)}>
      <section className="IgniteCollapsibleUseCaseSearch">
        <DebouncedInput
          placeholder="Search Use Cases"
          data-testid="search-use-cases"
          value={params.searchUseCases || ''}
          onChange={(value) =>
            setParams({
              ...params,
              searchUseCases: value,
            })
          }
        />
      </section>
    </Collapsible>
  )
}
