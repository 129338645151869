export type TRegion = {
  long: string
  short: string
}

export const isTRegion = (value: any): value is TRegion => {
  return !!(value && value.long && value.short)
}

export type TCountry = {
  long: string
  short: string
  regions: Array<TRegion>
}

export const isTCountry = (value: any): value is TCountry => {
  return !!(value && value.long && value.short && value.regions)
}

export const isoUSStateCodes: Array<string> = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export const countryRegionData: Array<TCountry> = [
  {
    long: 'Afghanistan',
    short: 'AF',
    regions: [
      {
        long: 'Badakhshan',
        short: 'BDS',
      },
      {
        long: 'Badghis',
        short: 'BDG',
      },
      {
        long: 'Baghlan',
        short: 'BGL',
      },
      {
        long: 'Balkh',
        short: 'BAL',
      },
      {
        long: 'Bamyan',
        short: 'BAM',
      },
      {
        long: 'Daykundi',
        short: 'DAY',
      },
      {
        long: 'Farah',
        short: 'FRA',
      },
      {
        long: 'Faryab',
        short: 'FYB',
      },
      {
        long: 'Ghazni',
        short: 'GHA',
      },
      {
        long: 'Ghor',
        short: 'GHO',
      },
      {
        long: 'Helmand',
        short: 'HEL',
      },
      {
        long: 'Herat',
        short: 'HER',
      },
      {
        long: 'Jowzjan',
        short: 'JOW',
      },
      {
        long: 'Kabul',
        short: 'KAB',
      },
      {
        long: 'Kandahar',
        short: 'KAN',
      },
      {
        long: 'Kapisa',
        short: 'KAP',
      },
      {
        long: 'Khost',
        short: 'KHO',
      },
      {
        long: 'Kunar',
        short: 'KNR',
      },
      {
        long: 'Kunduz',
        short: 'KDZ',
      },
      {
        long: 'Laghman',
        short: 'LAG',
      },
      {
        long: 'Logar',
        short: 'LOW',
      },
      {
        long: 'Maidan Wardak',
        short: 'WAR',
      },
      {
        long: 'Nangarhar',
        short: 'NAN',
      },
      {
        long: 'Nimruz',
        short: 'NIM',
      },
      {
        long: 'Nuristan',
        short: 'NUR',
      },
      {
        long: 'Paktia',
        short: 'PIA',
      },
      {
        long: 'Paktika',
        short: 'PKA',
      },
      {
        long: 'Panjshir',
        short: 'PAN',
      },
      {
        long: 'Parwan',
        short: 'PAR',
      },
      {
        long: 'Samangan',
        short: 'SAM',
      },
      {
        long: 'Sar-e Pol',
        short: 'SAR',
      },
      {
        long: 'Takhar',
        short: 'TAK',
      },
      {
        long: 'Urozgan',
        short: 'ORU',
      },
      {
        long: 'Zabul',
        short: 'ZAB',
      },
    ],
  },
  {
    long: 'Åland Islands',
    short: 'AX',
    regions: [
      {
        long: 'Brändö',
        short: 'BR',
      },
      {
        long: 'Eckerö',
        short: 'EC',
      },
      {
        long: 'Finström',
        short: 'FN',
      },
      {
        long: 'Föglö',
        short: 'FG',
      },
      {
        long: 'Geta',
        short: 'GT',
      },
      {
        long: 'Hammarland',
        short: 'HM',
      },
      {
        long: 'Jomala',
        short: 'JM',
      },
      {
        long: 'Kumlinge',
        short: 'KM',
      },
      {
        long: 'Kökar',
        short: 'KK',
      },
      {
        long: 'Lemland',
        short: 'LE',
      },
      {
        long: 'Lumparland',
        short: 'LU',
      },
      {
        long: 'Mariehamn',
        short: 'MH',
      },
      {
        long: 'Saltvik',
        short: 'SV',
      },
      {
        long: 'Sottunga',
        short: 'ST',
      },
      {
        long: 'Sund',
        short: 'SD',
      },
      {
        long: 'Vårdö',
        short: 'VR',
      },
    ],
  },
  {
    long: 'Albania',
    short: 'AL',
    regions: [
      {
        long: 'Berat',
        short: '01',
      },
      {
        long: 'Dibër',
        short: '09',
      },
      {
        long: 'Durrës',
        short: '02',
      },
      {
        long: 'Elbasan',
        short: '03',
      },
      {
        long: 'Fier',
        short: '04',
      },
      {
        long: 'Gjirokastër',
        short: '05',
      },
      {
        long: 'Korçë',
        short: '06',
      },
      {
        long: 'Kukës',
        short: '07',
      },
      {
        long: 'Lezhë',
        short: '08',
      },
      {
        long: 'Shkodër',
        short: '10',
      },
      {
        long: 'Tirana',
        short: '11',
      },
      {
        long: 'Vlorë',
        short: '12',
      },
    ],
  },
  {
    long: 'Algeria',
    short: 'DZ',
    regions: [
      {
        long: 'Adrar',
        short: '01',
      },
      {
        long: 'Aïn Defla',
        short: '44',
      },
      {
        long: 'Aïn Témouchent',
        short: '46',
      },
      {
        long: 'Algiers',
        short: '16',
      },
      {
        long: 'Annaba',
        short: '23',
      },
      {
        long: 'Batna',
        short: '05',
      },
      {
        long: 'Béchar',
        short: '08',
      },
      {
        long: 'Béjaïa',
        short: '06',
      },
      {
        long: 'Biskra',
        short: '07',
      },
      {
        long: 'Blida',
        short: '09',
      },
      {
        long: 'Bordj Bou Arréridj',
        short: '34',
      },
      {
        long: 'Bouïra',
        short: '10',
      },
      {
        long: 'Boumerdès',
        short: '35',
      },
      {
        long: 'Chlef',
        short: '02',
      },
      {
        long: 'Constantine',
        short: '25',
      },
      {
        long: 'Djelfa',
        short: '17',
      },
      {
        long: 'El Bayadh',
        short: '32',
      },
      {
        long: 'El Oued',
        short: '39',
      },
      {
        long: 'El Tarf',
        short: '36',
      },
      {
        long: 'Ghardaïa',
        short: '47',
      },
      {
        long: 'Guelma',
        short: '24',
      },
      {
        long: 'Illizi',
        short: '33',
      },
      {
        long: 'Jijel',
        short: '18',
      },
      {
        long: 'Khenchela',
        short: '40',
      },
      {
        long: 'Laghouat',
        short: '03',
      },
      {
        long: 'Mascara',
        short: '29',
      },
      {
        long: 'Médéa',
        short: '26',
      },
      {
        long: 'Mila',
        short: '43',
      },
      {
        long: 'Mostaganem',
        short: '27',
      },
      {
        long: 'Msila',
        short: '28',
      },
      {
        long: 'Naâma',
        short: '45',
      },
      {
        long: 'Oran',
        short: '31',
      },
      {
        long: 'Ouargla',
        short: '30',
      },
      {
        long: 'Oum el Bouaghi',
        short: '04',
      },
      {
        long: 'Relizane',
        short: '48',
      },
      {
        long: 'Saïda',
        short: '20',
      },
      {
        long: 'Sétif',
        short: '19',
      },
      {
        long: 'Sidi Bel Abbès',
        short: '22',
      },
      {
        long: 'Skikda',
        short: '21',
      },
      {
        long: 'Souk Ahras',
        short: '41',
      },
      {
        long: 'Tamanghasset',
        short: '11',
      },
      {
        long: 'Tébessa',
        short: '12',
      },
      {
        long: 'Tiaret',
        short: '14',
      },
      {
        long: 'Tindouf',
        short: '37',
      },
      {
        long: 'Tipaza',
        short: '42',
      },
      {
        long: 'Tissemsilt',
        short: '38',
      },
      {
        long: 'Tizi Ouzou',
        short: '15',
      },
      {
        long: 'Tlemcen',
        short: '13',
      },
    ],
  },
  {
    long: 'American Samoa',
    short: 'AS',
    regions: [
      {
        long: 'Tutuila',
        short: '01',
      },
      {
        long: "Aunu'u",
        short: '02',
      },
      {
        long: "Ta'ū",
        short: '03',
      },
      {
        long: 'Ofu‑Olosega',
        short: '04',
      },
      {
        long: 'Rose Atoll',
        short: '21',
      },
      {
        long: 'Swains Island',
        short: '22',
      },
    ],
  },
  {
    long: 'Andorra',
    short: 'AD',
    regions: [
      {
        long: 'Andorra la Vella',
        short: '07',
      },
      {
        long: 'Canillo',
        short: '02',
      },
      {
        long: 'Encamp',
        short: '03',
      },
      {
        long: 'Escaldes-Engordany',
        short: '08',
      },
      {
        long: 'La Massana',
        short: '04',
      },
      {
        long: 'Ordino',
        short: '05',
      },
      {
        long: 'Sant Julià de Lòria',
        short: '06',
      },
    ],
  },
  {
    long: 'Angola',
    short: 'AO',
    regions: [
      {
        long: 'Bengo',
        short: 'BGO',
      },
      {
        long: 'Benguela',
        short: 'BGU',
      },
      {
        long: 'Bié',
        short: 'BIE',
      },
      {
        long: 'Cabinda',
        short: 'CAB',
      },
      {
        long: 'Cuando Cubango',
        short: 'CCU',
      },
      {
        long: 'Cuanza Norte',
        short: 'CNO',
      },
      {
        long: 'Cuanza Sul',
        short: 'CUS',
      },
      {
        long: 'Cunene',
        short: 'CNN',
      },
      {
        long: 'Huambo',
        short: 'HUA',
      },
      {
        long: 'Huíla',
        short: 'HUI',
      },
      {
        long: 'Luanda',
        short: 'LUA',
      },
      {
        long: 'Lunda Norte',
        short: 'LNO',
      },
      {
        long: 'Lunda Sul',
        short: 'LSU',
      },
      {
        long: 'Malanje',
        short: 'MAL',
      },
      {
        long: 'Moxico',
        short: 'MOX',
      },
      {
        long: 'Namibe',
        short: 'NAM',
      },
      {
        long: 'Uíge',
        short: 'UIG',
      },
      {
        long: 'Zaire',
        short: 'ZAI',
      },
    ],
  },
  {
    long: 'Anguilla',
    short: 'AI',
    regions: [
      {
        long: 'Anguilla',
        short: '01',
      },
      {
        long: 'Anguillita Island',
        short: '02',
      },
      {
        long: 'Blowing Rock',
        short: '03',
      },
      {
        long: 'Cove Cay',
        short: '04',
      },
      {
        long: 'Crocus Cay',
        short: '05',
      },
      {
        long: "Deadman's Cay",
        short: '06',
      },
      {
        long: 'Dog Island',
        short: '07',
      },
      {
        long: 'East Cay',
        short: '08',
      },
      {
        long: 'Little Island',
        short: '09',
      },
      {
        long: 'Little Scrub Island',
        short: '10',
      },
      {
        long: 'Mid Cay',
        short: '11',
      },
      {
        long: 'North Cay',
        short: '12',
      },
      {
        long: 'Prickly Pear Cays',
        short: '13',
      },
      {
        long: 'Rabbit Island',
        short: '14',
      },
      {
        long: 'Sandy Island/Sand Island',
        short: '15',
      },
      {
        long: 'Scilly Cay',
        short: '16',
      },
      {
        long: 'Scrub Island',
        short: '17',
      },
      {
        long: 'Seal Island',
        short: '18',
      },
      {
        long: 'Sombrero/Hat Island',
        short: '19',
      },
      {
        long: 'South Cay',
        short: '20',
      },
      {
        long: 'South Wager Island',
        short: '21',
      },
      {
        long: 'West Cay',
        short: '22',
      },
    ],
  },
  {
    long: 'Antarctica',
    short: 'AQ',
    regions: [
      {
        long: 'Antarctica',
        short: 'AQ',
      },
    ],
  },
  {
    long: 'Antigua and Barbuda',
    short: 'AG',
    regions: [
      {
        long: 'Antigua Island',
        short: '01',
      },
      {
        long: 'Barbuda Island',
        short: '02',
      },
      {
        long: 'Bird Island',
        short: '04',
      },
      {
        long: 'Bishop Island',
        short: '05',
      },
      {
        long: 'Blake Island',
        short: '06',
      },
      {
        long: 'Crump Island',
        short: '09',
      },
      {
        long: 'Dulcina Island',
        short: '10',
      },
      {
        long: 'Exchange Island',
        short: '11',
      },
      {
        long: 'Five Islands',
        short: '12',
      },
      {
        long: 'Great Bird Island',
        short: '13',
      },
      {
        long: 'Green Island',
        short: '14',
      },
      {
        long: 'Guiana Island',
        short: '15',
      },
      {
        long: 'Hawes Island',
        short: '17',
      },
      {
        long: 'Hells Gate Island',
        short: '16',
      },
      {
        long: 'Henry Island',
        short: '18',
      },
      {
        long: 'Johnson Island',
        short: '19',
      },
      {
        long: 'Kid Island',
        short: '20',
      },
      {
        long: 'Lobster Island',
        short: '22',
      },
      {
        long: 'Maiden Island',
        short: '24',
      },
      {
        long: 'Moor Island',
        short: '25',
      },
      {
        long: 'Nanny Island',
        short: '26',
      },
      {
        long: 'Pelican Island',
        short: '27',
      },
      {
        long: 'Prickly Pear Island',
        short: '28',
      },
      {
        long: 'Rabbit Island',
        short: '29',
      },
      {
        long: 'Red Head Island',
        short: '31',
      },
      {
        long: 'Redonda Island',
        short: '03',
      },
      {
        long: 'Sandy Island',
        short: '32',
      },
      {
        long: 'Smith Island',
        short: '33',
      },
      {
        long: 'The Sisters',
        short: '34',
      },
      {
        long: 'Vernon Island',
        short: '35',
      },
      {
        long: 'Wicked Will Island',
        short: '36',
      },
      {
        long: 'York Island',
        short: '37',
      },
    ],
  },
  {
    long: 'Argentina',
    short: 'AR',
    regions: [
      {
        long: 'Buenos Aires',
        short: 'B',
      },
      {
        long: 'Capital Federal',
        short: 'C',
      },
      {
        long: 'Catamarca',
        short: 'K',
      },
      {
        long: 'Chaco',
        short: 'H',
      },
      {
        long: 'Chubut',
        short: 'U',
      },
      {
        long: 'Córdoba',
        short: 'X',
      },
      {
        long: 'Corrientes',
        short: 'W',
      },
      {
        long: 'Entre Ríos',
        short: 'E',
      },
      {
        long: 'Formosa',
        short: 'P',
      },
      {
        long: 'Jujuy',
        short: 'Y',
      },
      {
        long: 'La Pampa',
        short: 'L',
      },
      {
        long: 'La Rioja',
        short: 'F',
      },
      {
        long: 'Mendoza',
        short: 'M',
      },
      {
        long: 'Misiones',
        short: 'N',
      },
      {
        long: 'Neuquén',
        short: 'Q',
      },
      {
        long: 'Río Negro',
        short: 'R',
      },
      {
        long: 'Salta',
        short: 'A',
      },
      {
        long: 'San Juan',
        short: 'J',
      },
      {
        long: 'San Luis',
        short: 'D',
      },
      {
        long: 'Santa Cruz',
        short: 'Z',
      },
      {
        long: 'Santa Fe',
        short: 'S',
      },
      {
        long: 'Santiago del Estero',
        short: 'G',
      },
      {
        long: 'Tierra del Fuego',
        short: 'V',
      },
      {
        long: 'Tucumán',
        short: 'T',
      },
    ],
  },
  {
    long: 'Armenia',
    short: 'AM',
    regions: [
      {
        long: 'Aragatsotn',
        short: 'AG',
      },
      {
        long: 'Ararat',
        short: 'AR',
      },
      {
        long: 'Armavir',
        short: 'AV',
      },
      {
        long: 'Gegharkunik',
        short: 'GR',
      },
      {
        long: 'Kotayk',
        short: 'KT',
      },
      {
        long: 'Lori',
        short: 'LO',
      },
      {
        long: 'Shirak',
        short: 'SH',
      },
      {
        long: 'Syunik',
        short: 'SU',
      },
      {
        long: 'Tavush',
        short: 'TV',
      },
      {
        long: 'Vayots Dzor',
        short: 'VD',
      },
      {
        long: 'Yerevan',
        short: 'ER',
      },
    ],
  },
  {
    long: 'Aruba',
    short: 'AW',
    regions: [
      {
        long: 'Aruba',
        short: 'AW',
      },
    ],
  },
  {
    long: 'Australia',
    short: 'AU',
    regions: [
      {
        long: 'Australian Capital Territory',
        short: 'ACT',
      },
      {
        long: 'New South Wales',
        short: 'NSW',
      },
      {
        long: 'Northern Territory',
        short: 'NT',
      },
      {
        long: 'Queensland',
        short: 'QLD',
      },
      {
        long: 'South Australia',
        short: 'SA',
      },
      {
        long: 'Tasmania',
        short: 'TAS',
      },
      {
        long: 'Victoria',
        short: 'VIC',
      },
      {
        long: 'Western Australia',
        short: 'WA',
      },
    ],
  },
  {
    long: 'Austria',
    short: 'AT',
    regions: [
      {
        long: 'Burgenland',
        short: '1',
      },
      {
        long: 'Kärnten',
        short: '2',
      },
      {
        long: 'Niederösterreich',
        short: '3',
      },
      {
        long: 'Oberösterreich',
        short: '4',
      },
      {
        long: 'Salzburg',
        short: '5',
      },
      {
        long: 'Steiermark',
        short: '6',
      },
      {
        long: 'Tirol',
        short: '7',
      },
      {
        long: 'Vorarlberg',
        short: '8',
      },
      {
        long: 'Wien',
        short: '9',
      },
    ],
  },
  {
    long: 'Azerbaijan',
    short: 'AZ',
    regions: [
      {
        long: 'Abşeron',
        short: 'ABS',
      },
      {
        long: 'Ağcabədi',
        short: 'AGC',
      },
      {
        long: 'Ağdam',
        short: 'AGM',
      },
      {
        long: 'Ağdaş',
        short: 'AGS',
      },
      {
        long: 'Ağstafa',
        short: 'AGA',
      },
      {
        long: 'Ağsu',
        short: 'AGU',
      },
      {
        long: 'Astara',
        short: 'AST',
      },
      {
        long: 'Bakı',
        short: 'BAK',
      },
      {
        long: 'Babək',
        short: 'BAB',
      },
      {
        long: 'Balakən',
        short: 'BAL',
      },
      {
        long: 'Bərdə',
        short: 'BAR',
      },
      {
        long: 'Beyləqan',
        short: 'BEY',
      },
      {
        long: 'Biləsuvar',
        short: 'BIL',
      },
      {
        long: 'Cəbrayıl',
        short: 'CAB',
      },
      {
        long: 'Cəlilabad',
        short: 'CAL',
      },
      {
        long: 'Culfa',
        short: 'CUL',
      },
      {
        long: 'Daşkəsən',
        short: 'DAS',
      },
      {
        long: 'Füzuli',
        short: 'FUZ',
      },
      {
        long: 'Gədəbəy',
        short: 'GAD',
      },
      {
        long: 'Goranboy',
        short: 'GOR',
      },
      {
        long: 'Göyçay',
        short: 'GOY',
      },
      {
        long: 'Göygöl',
        short: 'GYG',
      },
      {
        long: 'Hacıqabul',
        short: 'HAC',
      },
      {
        long: 'İmişli',
        short: 'IMI',
      },
      {
        long: 'İsmayıllı',
        short: 'ISM',
      },
      {
        long: 'Kəlbəcər',
        short: 'KAL',
      },
      {
        long: 'Kǝngǝrli',
        short: 'KAN',
      },
      {
        long: 'Kürdəmir',
        short: 'KUR',
      },
      {
        long: 'Laçın',
        short: 'LAC',
      },
      {
        long: 'Lənkəran',
        short: 'LAN',
      },
      {
        long: 'Lerik',
        short: 'LER',
      },
      {
        long: 'Masallı',
        short: 'MAS',
      },
      {
        long: 'Neftçala',
        short: 'NEF',
      },
      {
        long: 'Oğuz',
        short: 'OGU',
      },
      {
        long: 'Ordubad',
        short: 'ORD',
      },
      {
        long: 'Qəbələ',
        short: 'QAB',
      },
      {
        long: 'Qax',
        short: 'QAX',
      },
      {
        long: 'Qazax',
        short: 'QAZ',
      },
      {
        long: 'Qobustan',
        short: 'QOB',
      },
      {
        long: 'Quba',
        short: 'QBA',
      },
      {
        long: 'Qubadli',
        short: 'QBI',
      },
      {
        long: 'Qusar',
        short: 'QUS',
      },
      {
        long: 'Saatlı',
        short: 'SAT',
      },
      {
        long: 'Sabirabad',
        short: 'SAB',
      },
      {
        long: 'Şabran',
        short: 'SBN',
      },
      {
        long: 'Sədərək',
        short: 'SAD',
      },
      {
        long: 'Şahbuz',
        short: 'SAH',
      },
      {
        long: 'Şəki',
        short: 'SAK',
      },
      {
        long: 'Salyan',
        short: 'SAL',
      },
      {
        long: 'Şamaxı',
        short: 'SMI',
      },
      {
        long: 'Şəmkir',
        short: 'SKR',
      },
      {
        long: 'Samux',
        short: 'SMX',
      },
      {
        long: 'Şərur',
        short: 'SAR',
      },
      {
        long: 'Siyəzən',
        short: 'SIY',
      },
      {
        long: 'Şuşa',
        short: 'SUS',
      },
      {
        long: 'Tərtər',
        short: 'TAR',
      },
      {
        long: 'Tovuz',
        short: 'TOV',
      },
      {
        long: 'Ucar',
        short: 'UCA',
      },
      {
        long: 'Xaçmaz',
        short: 'XAC',
      },
      {
        long: 'Xızı',
        short: 'XIZ',
      },
      {
        long: 'Xocalı',
        short: 'XCI',
      },
      {
        long: 'Xocavənd',
        short: 'XVD',
      },
      {
        long: 'Yardımlı',
        short: 'YAR',
      },
      {
        long: 'Yevlax',
        short: 'YEV',
      },
      {
        long: 'Zəngilan',
        short: 'ZAN',
      },
      {
        long: 'Zaqatala',
        short: 'ZAQ',
      },
      {
        long: 'Zərdab',
        short: 'ZAR',
      },
    ],
  },
  {
    long: 'Bahamas',
    short: 'BS',
    regions: [
      {
        long: 'Acklins Island',
        short: '01',
      },
      {
        long: 'Berry Islands',
        short: '22',
      },
      {
        long: 'Bimini',
        short: '02',
      },
      {
        long: 'Black Point',
        short: '23',
      },
      {
        long: 'Cat Island',
        short: '03',
      },
      {
        long: 'Central Abaco',
        short: '24',
      },
      {
        long: 'Crooked Island and Long Cay',
        short: '28',
      },
      {
        long: 'East Grand Bahama',
        short: '29',
      },
      {
        long: 'Exuma',
        short: '04',
      },
      {
        long: 'Freeport',
        short: '05',
      },
      {
        long: 'Fresh Creek',
        short: '06',
      },
      {
        long: "Governor's Harbour",
        short: '07',
      },
      {
        long: 'Green Turtle Cay',
        short: '08',
      },
      {
        long: 'Harbour Island',
        short: '09',
      },
      {
        long: 'High Rock',
        short: '10',
      },
      {
        long: 'Inagua',
        short: '11',
      },
      {
        long: 'Kemps Bay',
        short: '12',
      },
      {
        long: 'Long Island',
        short: '13',
      },
      {
        long: 'Marsh Harbour',
        short: '14',
      },
      {
        long: 'Mayaguana',
        short: '15',
      },
      {
        long: 'Moore’s Island',
        short: '40',
      },
      {
        long: 'New Providence',
        short: '16',
      },
      {
        long: 'Nichollstown and Berry Islands',
        short: '17',
      },
      {
        long: 'North Abaco',
        short: '42',
      },
      {
        long: 'North Andros',
        short: '41',
      },
      {
        long: 'North Eleuthera',
        short: '33',
      },
      {
        long: 'Ragged Island',
        short: '18',
      },
      {
        long: 'Rock Sound',
        short: '19',
      },
      {
        long: 'San Salvador and Rum Cay',
        short: '20',
      },
      {
        long: 'Sandy Point',
        short: '21',
      },
      {
        long: 'South Abaco',
        short: '35',
      },
      {
        long: 'South Andros',
        short: '36',
      },
      {
        long: 'South Eleuthera',
        short: '37',
      },
      {
        long: 'West Grand Bahama',
        short: '39',
      },
    ],
  },
  {
    long: 'Bahrain',
    short: 'BH',
    regions: [
      {
        long: 'Al Janūbīyah',
        short: '14',
      },
      {
        long: 'Al Manāmah',
        short: '13',
      },
      {
        long: 'Al Muḩarraq',
        short: '15',
      },
      {
        long: 'Al Wusţá',
        short: '16',
      },
      {
        long: 'Ash Shamālīyah',
        short: '17',
      },
    ],
  },
  {
    long: 'Bangladesh',
    short: 'BD',
    regions: [
      {
        long: 'Barisal',
        short: 'A',
      },
      {
        long: 'Chittagong',
        short: 'B',
      },
      {
        long: 'Dhaka',
        short: 'C',
      },
      {
        long: 'Khulna',
        short: 'D',
      },
      {
        long: 'Mymensingh',
        short: 'M',
      },
      {
        long: 'Rajshahi',
        short: 'E',
      },
      {
        long: 'Rangpur',
        short: 'F',
      },
      {
        long: 'Sylhet',
        short: 'G',
      },
    ],
  },
  {
    long: 'Barbados',
    short: 'BB',
    regions: [
      {
        long: 'Christ Church',
        short: '01',
      },
      {
        long: 'Saint Andrew',
        short: '02',
      },
      {
        long: 'Saint George',
        short: '03',
      },
      {
        long: 'Saint James',
        short: '04',
      },
      {
        long: 'Saint John',
        short: '05',
      },
      {
        long: 'Saint Joseph',
        short: '06',
      },
      {
        long: 'Saint Lucy',
        short: '07',
      },
      {
        long: 'Saint Michael',
        short: '08',
      },
      {
        long: 'Saint Peter',
        short: '09',
      },
      {
        long: 'Saint Philip',
        short: '10',
      },
      {
        long: 'Saint Thomas',
        short: '11',
      },
    ],
  },
  {
    long: 'Belarus',
    short: 'BY',
    regions: [
      {
        long: 'Brest voblast',
        short: 'BR',
      },
      {
        long: 'Gorod Minsk',
        short: 'HM',
      },
      {
        long: 'Homiel voblast',
        short: 'HO',
      },
      {
        long: 'Hrodna voblast',
        short: 'HR',
      },
      {
        long: 'Mahilyow voblast',
        short: 'MA',
      },
      {
        long: 'Minsk voblast',
        short: 'MI',
      },
      {
        long: 'Vitsebsk voblast',
        short: 'VI',
      },
    ],
  },
  {
    long: 'Belgium',
    short: 'BE',
    regions: [
      {
        long: 'Brussels',
        short: 'BRU',
      },
      {
        long: 'Flanders',
        short: 'VLG',
      },
      {
        long: 'Wallonia',
        short: 'WAL',
      },
    ],
  },
  {
    long: 'Belize',
    short: 'BZ',
    regions: [
      {
        long: 'Belize District',
        short: 'BZ',
      },
      {
        long: 'Cayo District',
        short: 'CY',
      },
      {
        long: 'Corozal District',
        short: 'CZL',
      },
      {
        long: 'Orange Walk District',
        short: 'OW',
      },
      {
        long: 'Stann Creek District',
        short: 'SC',
      },
      {
        long: 'Toledo District',
        short: 'TOL',
      },
    ],
  },
  {
    long: 'Benin',
    short: 'BJ',
    regions: [
      {
        long: 'Alibori',
        short: 'AL',
      },
      {
        long: 'Atakora',
        short: 'AK',
      },
      {
        long: 'Atlantique',
        short: 'AQ',
      },
      {
        long: 'Borgou',
        short: 'BO',
      },
      {
        long: 'Collines Department',
        short: 'CO',
      },
      {
        long: 'Donga',
        short: 'DO',
      },
      {
        long: 'Kouffo',
        short: 'KO',
      },
      {
        long: 'Littoral Department',
        short: 'LI',
      },
      {
        long: 'Mono Department',
        short: 'MO',
      },
      {
        long: 'Ouémé',
        short: 'OU',
      },
      {
        long: 'Plateau',
        short: 'PL',
      },
      {
        long: 'Zou',
        short: 'ZO',
      },
    ],
  },
  {
    long: 'Bermuda',
    short: 'BM',
    regions: [
      {
        long: 'City of Hamilton',
        short: '03',
      },
      {
        long: 'Devonshire Parish',
        short: '01',
      },
      {
        long: 'Hamilton Parish',
        short: '02',
      },
      {
        long: 'Paget Parish',
        short: '04',
      },
      {
        long: 'Pembroke Parish',
        short: '05',
      },
      {
        long: 'Sandys Parish',
        short: '08',
      },
      {
        long: "Smith's Parish",
        short: '09',
      },
      {
        long: 'Southampton Parish',
        short: '10',
      },
      {
        long: "St. George's Parish",
        short: '07',
      },
      {
        long: 'Town of St. George',
        short: '06',
      },
      {
        long: 'Warwick Parish',
        short: '11',
      },
    ],
  },
  {
    long: 'Bhutan',
    short: 'BT',
    regions: [
      {
        long: 'Bumthang',
        short: '33',
      },
      {
        long: 'Chhukha',
        short: '12',
      },
      {
        long: 'Dagana',
        short: '22',
      },
      {
        long: 'Gasa',
        short: 'GA',
      },
      {
        long: 'Haa',
        short: '13',
      },
      {
        long: 'Lhuntse',
        short: '44',
      },
      {
        long: 'Mongar',
        short: '42',
      },
      {
        long: 'Paro',
        short: '11',
      },
      {
        long: 'Pemagatshel',
        short: '43',
      },
      {
        long: 'Punakha',
        short: '23',
      },
      {
        long: 'Samdrup Jongkhar',
        short: '45',
      },
      {
        long: 'Samtse',
        short: '14',
      },
      {
        long: 'Sarpang',
        short: '31',
      },
      {
        long: 'Thimphu',
        short: '15',
      },
      {
        long: 'Trashigang',
        short: '41',
      },
      {
        long: 'Trashiyangtse',
        short: 'TY',
      },
      {
        long: 'Trongsa',
        short: '32',
      },
      {
        long: 'Tsirang',
        short: '21',
      },
      {
        long: 'Wangdue Phodrang',
        short: '24',
      },
      {
        long: 'Zhemgang',
        short: '34',
      },
    ],
  },
  {
    long: 'Bolivia',
    short: 'BO',
    regions: [
      {
        long: 'Beni',
        short: 'B',
      },
      {
        long: 'Chuquisaca',
        short: 'H',
      },
      {
        long: 'Cochabamba',
        short: 'C',
      },
      {
        long: 'La Paz',
        short: 'L',
      },
      {
        long: 'Oruro',
        short: 'O',
      },
      {
        long: 'Pando',
        short: 'N',
      },
      {
        long: 'Potosí',
        short: 'P',
      },
      {
        long: 'Santa Cruz',
        short: 'S',
      },
      {
        long: 'Tarija',
        short: 'T',
      },
    ],
  },
  {
    long: 'Bonaire, Sint Eustatius and Saba',
    short: 'BQ',
    regions: [
      {
        long: 'Bonaire',
        short: 'BO',
      },
      {
        long: 'Saba Isand',
        short: 'SA',
      },
      {
        long: 'Sint Eustatius',
        short: 'SE',
      },
    ],
  },
  {
    long: 'Bosnia and Herzegovina',
    short: 'BA',
    regions: [
      {
        long: 'Brčko Distrikt',
        short: 'BRC',
      },
      {
        long: 'Federacija Bosne i Hercegovine',
        short: 'BIH',
      },
      {
        long: 'Republika Srpska',
        short: 'SRP',
      },
    ],
  },
  {
    long: 'Botswana',
    short: 'BW',
    regions: [
      {
        long: 'Central',
        short: 'CE',
      },
      {
        long: 'Ghanzi',
        short: 'GH',
      },
      {
        long: 'Kgalagadi',
        short: 'KG',
      },
      {
        long: 'Kgatleng',
        short: 'KL',
      },
      {
        long: 'Kweneng',
        short: 'KW',
      },
      {
        long: 'North West',
        short: 'NW',
      },
      {
        long: 'North-East',
        short: 'NE',
      },
      {
        long: 'South East',
        short: 'SE',
      },
      {
        long: 'Southern',
        short: 'SO',
      },
    ],
  },
  {
    long: 'Bouvet Island',
    short: 'BV',
    regions: [
      {
        long: 'Bouvet Island',
        short: 'BV',
      },
    ],
  },
  {
    long: 'Brazil',
    short: 'BR',
    regions: [
      {
        long: 'Acre',
        short: 'AC',
      },
      {
        long: 'Alagoas',
        short: 'AL',
      },
      {
        long: 'Amapá',
        short: 'AP',
      },
      {
        long: 'Amazonas',
        short: 'AM',
      },
      {
        long: 'Bahia',
        short: 'BA',
      },
      {
        long: 'Ceará',
        short: 'CE',
      },
      {
        long: 'Distrito Federal',
        short: 'DF',
      },
      {
        long: 'Espírito Santo',
        short: 'ES',
      },
      {
        long: 'Goiás',
        short: 'GO',
      },
      {
        long: 'Maranhão',
        short: 'MA',
      },
      {
        long: 'Mato Grosso',
        short: 'MT',
      },
      {
        long: 'Mato Grosso do Sul',
        short: 'MS',
      },
      {
        long: 'Minas Gerais',
        short: 'MG',
      },
      {
        long: 'Pará',
        short: 'PA',
      },
      {
        long: 'Paraíba',
        short: 'PB',
      },
      {
        long: 'Paraná',
        short: 'PR',
      },
      {
        long: 'Pernambuco',
        short: 'PE',
      },
      {
        long: 'Piauí',
        short: 'PI',
      },
      {
        long: 'Rio de Janeiro',
        short: 'RJ',
      },
      {
        long: 'Rio Grande do Norte',
        short: 'RN',
      },
      {
        long: 'Rio Grande do Sul',
        short: 'RS',
      },
      {
        long: 'Rondônia',
        short: 'RO',
      },
      {
        long: 'Roraima',
        short: 'RR',
      },
      {
        long: 'Santa Catarina',
        short: 'SC',
      },
      {
        long: 'São Paulo',
        short: 'SP',
      },
      {
        long: 'Sergipe',
        short: 'SE',
      },
      {
        long: 'Tocantins',
        short: 'TO',
      },
    ],
  },
  {
    long: 'British Indian Ocean Territory',
    short: 'IO',
    regions: [
      {
        long: 'British Indian Ocean Territory',
        short: 'IO',
      },
    ],
  },
  {
    long: 'Brunei Darussalam',
    short: 'BN',
    regions: [
      {
        long: 'Belait',
        short: 'BE',
      },
      {
        long: 'Brunei Muara',
        short: 'BM',
      },
      {
        long: 'Temburong',
        short: 'TE',
      },
      {
        long: 'Tutong',
        short: 'TU',
      },
    ],
  },
  {
    long: 'Bulgaria',
    short: 'BG',
    regions: [
      {
        long: 'Blagoevgrad',
        short: '01',
      },
      {
        long: 'Burgas',
        short: '02',
      },
      {
        long: 'Dobrich',
        short: '08',
      },
      {
        long: 'Gabrovo',
        short: '07',
      },
      {
        long: 'Jambol',
        short: '28',
      },
      {
        long: 'Khaskovo',
        short: '26',
      },
      {
        long: 'Kjustendil',
        short: '10',
      },
      {
        long: 'Kurdzhali',
        short: '09',
      },
      {
        long: 'Lovech',
        short: '11',
      },
      {
        long: 'Montana',
        short: '12',
      },
      {
        long: 'Pazardzhik',
        short: '13',
      },
      {
        long: 'Pernik',
        short: '14',
      },
      {
        long: 'Pleven',
        short: '15',
      },
      {
        long: 'Plovdiv',
        short: '16',
      },
      {
        long: 'Razgrad',
        short: '17',
      },
      {
        long: 'Ruse',
        short: '18',
      },
      {
        long: 'Shumen',
        short: '27',
      },
      {
        long: 'Silistra',
        short: '19',
      },
      {
        long: 'Sliven',
        short: '20',
      },
      {
        long: 'Smoljan',
        short: '21',
      },
      {
        long: 'Sofija',
        short: '23',
      },
      {
        long: 'Sofija-Grad',
        short: '22',
      },
      {
        long: 'Stara Zagora',
        short: '24',
      },
      {
        long: 'Turgovishhe',
        short: '25',
      },
      {
        long: 'Varna',
        short: '03',
      },
      {
        long: 'Veliko Turnovo',
        short: '04',
      },
      {
        long: 'Vidin',
        short: '05',
      },
      {
        long: 'Vraca',
        short: '06',
      },
    ],
  },
  {
    long: 'Burkina Faso',
    short: 'BF',
    regions: [
      {
        long: 'Balé',
        short: 'BAL',
      },
      {
        long: 'Bam/Lake Bam',
        short: 'BAM',
      },
      {
        long: 'Banwa Province',
        short: 'BAN',
      },
      {
        long: 'Bazèga',
        short: 'BAZ',
      },
      {
        long: 'Bougouriba',
        short: 'BGR',
      },
      {
        long: 'Boulgou Province',
        short: 'BLG',
      },
      {
        long: 'Boulkiemdé',
        short: 'BLK',
      },
      {
        long: 'Comoé/Komoe',
        short: 'COM',
      },
      {
        long: 'Ganzourgou Province',
        short: 'GAN',
      },
      {
        long: 'Gnagna',
        short: 'GNA',
      },
      {
        long: 'Gourma Province',
        short: 'GOU',
      },
      {
        long: 'Houet',
        short: 'HOU',
      },
      {
        long: 'Ioba',
        short: 'IOB',
      },
      {
        long: 'Kadiogo',
        short: 'KAD',
      },
      {
        long: 'Kénédougou',
        short: 'KEN',
      },
      {
        long: 'Komondjari',
        short: 'KMD',
      },
      {
        long: 'Kompienga',
        short: 'KMP',
      },
      {
        long: 'Kossi Province',
        short: 'KOS',
      },
      {
        long: 'Koulpélogo',
        short: 'KOP',
      },
      {
        long: 'Kouritenga',
        short: 'KOT',
      },
      {
        long: 'Kourwéogo',
        short: 'KOW',
      },
      {
        long: 'Léraba',
        short: 'LER',
      },
      {
        long: 'Loroum',
        short: 'LOR',
      },
      {
        long: 'Mouhoun',
        short: 'MOU',
      },
      {
        long: 'Namentenga',
        short: 'NAM',
      },
      {
        long: 'Naouri/Nahouri',
        short: 'NAO',
      },
      {
        long: 'Nayala',
        short: 'NAY',
      },
      {
        long: 'Noumbiel',
        short: 'NOU',
      },
      {
        long: 'Oubritenga',
        short: 'OUB',
      },
      {
        long: 'Oudalan',
        short: 'OUD',
      },
      {
        long: 'Passoré',
        short: 'PAS',
      },
      {
        long: 'Poni',
        short: 'PON',
      },
      {
        long: 'Sanguié',
        short: 'SNG',
      },
      {
        long: 'Sanmatenga',
        short: 'SMT',
      },
      {
        long: 'Séno',
        short: 'SEN',
      },
      {
        long: 'Sissili',
        short: 'SIS',
      },
      {
        long: 'Soum',
        short: 'SOM',
      },
      {
        long: 'Sourou',
        short: 'SOR',
      },
      {
        long: 'Tapoa',
        short: 'TAP',
      },
      {
        long: 'Tui/Tuy',
        short: 'TUI',
      },
      {
        long: 'Yagha',
        short: 'YAG',
      },
      {
        long: 'Yatenga',
        short: 'YAT',
      },
      {
        long: 'Ziro',
        short: 'ZIR',
      },
      {
        long: 'Zondoma',
        short: 'ZON',
      },
      {
        long: 'Zoundwéogo',
        short: 'ZOU',
      },
    ],
  },
  {
    long: 'Burundi',
    short: 'BI',
    regions: [
      {
        long: 'Bubanza',
        short: 'BB',
      },
      {
        long: 'Bujumbura Mairie',
        short: 'BM',
      },
      {
        long: 'Bujumbura Rural',
        short: 'BL',
      },
      {
        long: 'Bururi',
        short: 'BR',
      },
      {
        long: 'Cankuzo',
        short: 'CA',
      },
      {
        long: 'Cibitoke',
        short: 'CI',
      },
      {
        long: 'Gitega',
        short: 'GI',
      },
      {
        long: 'Karuzi',
        short: 'KR',
      },
      {
        long: 'Kayanza',
        short: 'KY',
      },
      {
        long: 'Kirundo',
        short: 'KI',
      },
      {
        long: 'Makamba',
        short: 'MA',
      },
      {
        long: 'Muramvya',
        short: 'MU',
      },
      {
        long: 'Muyinga',
        short: 'MY',
      },
      {
        long: 'Mwaro',
        short: 'MW',
      },
      {
        long: 'Ngozi',
        short: 'NG',
      },
      {
        long: 'Rutana',
        short: 'RT',
      },
      {
        long: 'Ruyigi',
        short: 'RY',
      },
    ],
  },
  {
    long: 'Cambodia',
    short: 'KH',
    regions: [
      {
        long: 'Baat Dambang',
        short: '2',
      },
      {
        long: 'Banteay Mean Chey',
        short: '1',
      },
      {
        long: 'Kampong Chaam',
        short: '3',
      },
      {
        long: 'Kampong Chhnang',
        short: '4',
      },
      {
        long: 'Kampong Spueu',
        short: '5',
      },
      {
        long: 'Kampong Thum',
        short: '6',
      },
      {
        long: 'Kampot',
        short: '7',
      },
      {
        long: 'Kandaal',
        short: '8',
      },
      {
        long: 'Kaoh Kong',
        short: '9',
      },
      {
        long: 'Kracheh',
        short: '10',
      },
      {
        long: 'Krong Kaeb',
        short: '23',
      },
      {
        long: 'Krong Pailin',
        short: '24',
      },
      {
        long: 'Krong Preah Sihanouk',
        short: '18',
      },
      {
        long: 'Mondol Kiri',
        short: '11',
      },
      {
        long: 'Otdar Mean Chey',
        short: '22',
      },
      {
        long: 'Phnom Penh',
        short: '12',
      },
      {
        long: 'Pousaat',
        short: '15',
      },
      {
        long: 'Preah Vihear',
        short: '13',
      },
      {
        long: 'Prey Veaeng',
        short: '14',
      },
      {
        long: 'Rotanah Kiri',
        short: '16',
      },
      {
        long: 'Siem Reab',
        short: '17',
      },
      {
        long: 'Stueng Treng',
        short: '19',
      },
      {
        long: 'Svaay Rieng',
        short: '20',
      },
      {
        long: 'Taakaev',
        short: '21',
      },
      {
        long: 'Tbong Khmum',
        short: '25',
      },
    ],
  },
  {
    long: 'Cameroon',
    short: 'CM',
    regions: [
      {
        long: 'Adamaoua',
        short: 'AD',
      },
      {
        long: 'Centre',
        short: 'CE',
      },
      {
        long: 'Est',
        short: 'ES',
      },
      {
        long: 'Extrême-Nord',
        short: 'EN',
      },
      {
        long: 'Littoral',
        short: 'LT',
      },
      {
        long: 'Nord',
        short: 'NO',
      },
      {
        long: 'Nord-Ouest',
        short: 'NW',
      },
      {
        long: 'Ouest',
        short: 'OU',
      },
      {
        long: 'Sud',
        short: 'SU',
      },
      {
        long: 'Sud-Ouest',
        short: 'SW',
      },
    ],
  },
  {
    long: 'Canada',
    short: 'CA',
    regions: [
      {
        long: 'Alberta',
        short: 'AB',
      },
      {
        long: 'British Columbia',
        short: 'BC',
      },
      {
        long: 'Manitoba',
        short: 'MB',
      },
      {
        long: 'New Brunswick',
        short: 'NB',
      },
      {
        long: 'Newfoundland and Labrador',
        short: 'NL',
      },
      {
        long: 'Northwest Territories',
        short: 'NT',
      },
      {
        long: 'Nova Scotia',
        short: 'NS',
      },
      {
        long: 'Nunavut',
        short: 'NU',
      },
      {
        long: 'Ontario',
        short: 'ON',
      },
      {
        long: 'Prince Edward Island',
        short: 'PE',
      },
      {
        long: 'Quebec',
        short: 'QC',
      },
      {
        long: 'Saskatchewan',
        short: 'SK',
      },
      {
        long: 'Yukon',
        short: 'YT',
      },
    ],
  },
  {
    long: 'Cape Verde',
    short: 'CV',
    regions: [
      {
        long: 'Boa Vista',
        short: 'BV',
      },
      {
        long: 'Brava',
        short: 'BR',
      },
      {
        long: 'Calheta de São Miguel',
        short: 'CS',
      },
      {
        long: 'Maio',
        short: 'MA',
      },
      {
        long: 'Mosteiros',
        short: 'MO',
      },
      {
        long: 'Paúl',
        short: 'PA',
      },
      {
        long: 'Porto Novo',
        short: 'PN',
      },
      {
        long: 'Praia',
        short: 'PR',
      },
      {
        long: 'Ribeira Brava',
        short: 'RB',
      },
      {
        long: 'Ribeira Grande',
        short: 'RG',
      },
      {
        long: 'Sal',
        short: 'SL',
      },
      {
        long: 'Santa Catarina',
        short: 'CA',
      },
      {
        long: 'Santa Cruz',
        short: 'CR',
      },
      {
        long: 'São Domingos',
        short: 'SD',
      },
      {
        long: 'São Filipe',
        short: 'SF',
      },
      {
        long: 'São Nicolau',
        short: 'SN',
      },
      {
        long: 'São Vicente',
        short: 'SV',
      },
      {
        long: 'Tarrafal',
        short: 'TA',
      },
      {
        long: 'Tarrafal de São Nicolau',
        short: 'TS',
      },
    ],
  },
  {
    long: 'Cayman Islands',
    short: 'KY',
    regions: [
      {
        long: 'Creek',
        short: 'undefined',
      },
      {
        long: 'Eastern',
        short: 'undefined',
      },
      {
        long: 'Midland',
        short: 'undefined',
      },
      {
        long: 'South Town',
        short: 'undefined',
      },
      {
        long: 'Spot Bay',
        short: 'undefined',
      },
      {
        long: 'Stake Bay',
        short: 'undefined',
      },
      {
        long: 'West End',
        short: 'undefined',
      },
      {
        long: 'Western',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Central African Republic',
    short: 'CF',
    regions: [
      {
        long: 'Bamingui-Bangoran',
        short: 'BB',
      },
      {
        long: 'Bangui',
        short: 'BGF',
      },
      {
        long: 'Basse-Kotto',
        short: 'BK',
      },
      {
        long: 'Haute-Kotto',
        short: 'HK',
      },
      {
        long: 'Haut-Mbomou',
        short: 'HM',
      },
      {
        long: 'Kémo',
        short: 'KG',
      },
      {
        long: 'Lobaye',
        short: 'LB',
      },
      {
        long: 'Mambéré-Kadéï',
        short: 'HS',
      },
      {
        long: 'Mbomou',
        short: 'MB',
      },
      {
        long: 'Nana-Grebizi',
        short: '10',
      },
      {
        long: 'Nana-Mambéré',
        short: 'NM',
      },
      {
        long: "Ombella-M'Poko",
        short: 'MP',
      },
      {
        long: 'Ouaka',
        short: 'UK',
      },
      {
        long: 'Ouham',
        short: 'AC',
      },
      {
        long: 'Ouham Péndé',
        short: 'OP',
      },
      {
        long: 'Sangha-Mbaéré',
        short: 'SE',
      },
      {
        long: 'Vakaga',
        short: 'VK',
      },
    ],
  },
  {
    long: 'Chad',
    short: 'TD',
    regions: [
      {
        long: 'Bahr el Ghazal',
        short: 'BG',
      },
      {
        long: 'Batha',
        short: 'BA',
      },
      {
        long: 'Borkou',
        short: 'BO',
      },
      {
        long: 'Chari-Baguirmi',
        short: 'CB',
      },
      {
        long: 'Ennedi-Est',
        short: 'EE',
      },
      {
        long: 'Ennedi-Ouest',
        short: 'EO',
      },
      {
        long: 'Guéra',
        short: 'GR',
      },
      {
        long: 'Hadjer Lamis',
        short: 'HL',
      },
      {
        long: 'Kanem',
        short: 'KA',
      },
      {
        long: 'Lac',
        short: 'LC',
      },
      {
        long: 'Logone Occidental',
        short: 'LO',
      },
      {
        long: 'Logone Oriental',
        short: 'LR',
      },
      {
        long: 'Mondoul',
        short: 'MA',
      },
      {
        long: 'Mayo-Kébbi-Est',
        short: 'ME',
      },
      {
        long: 'Moyen-Chari',
        short: 'MC',
      },
      {
        long: 'Ouaddai',
        short: 'OD',
      },
      {
        long: 'Salamat',
        short: 'SA',
      },
      {
        long: 'Sila',
        short: 'SI',
      },
      {
        long: 'Tandjilé',
        short: 'TA',
      },
      {
        long: 'Tibesti',
        short: 'TI',
      },
      {
        long: 'Ville de Ndjamena',
        short: 'ND',
      },
      {
        long: 'Wadi Fira',
        short: 'WF',
      },
    ],
  },
  {
    long: 'Chile',
    short: 'CL',
    regions: [
      {
        long: 'Aisén del General Carlos Ibáñez del Campo',
        short: 'AI',
      },
      {
        long: 'Antofagasta',
        short: 'AN',
      },
      {
        long: 'Araucanía',
        short: 'AR',
      },
      {
        long: 'Arica y Parinacota',
        short: 'AP',
      },
      {
        long: 'Atacama',
        short: 'AT',
      },
      {
        long: 'Bío-Bío',
        short: 'BI',
      },
      {
        long: 'Coquimbo',
        short: 'CO',
      },
      {
        long: "Libertador General Bernardo O'Higgins",
        short: 'LI',
      },
      {
        long: 'Los Lagos',
        short: 'LL',
      },
      {
        long: 'Los Ríos',
        short: 'LR',
      },
      {
        long: 'Magallanes y Antartica Chilena',
        short: 'MA',
      },
      {
        long: 'Marga-Marga',
        short: '',
      },
      {
        long: 'Maule',
        short: 'ML',
      },
      {
        long: 'Ñuble',
        short: 'NB',
      },
      {
        long: 'Región Metropolitana de Santiago',
        short: 'RM',
      },
      {
        long: 'Tarapacá',
        short: 'TA',
      },
      {
        long: 'Valparaíso',
        short: 'VS',
      },
    ],
  },
  {
    long: 'China',
    short: 'CN',
    regions: [
      {
        long: 'Anhui',
        short: 'AH',
      },
      {
        long: 'Beijing',
        short: 'BJ',
      },
      {
        long: 'Chongqing',
        short: 'CQ',
      },
      {
        long: 'Fujian',
        short: 'FJ',
      },
      {
        long: 'Gansu',
        short: 'GS',
      },
      {
        long: 'Guangdong',
        short: 'GD',
      },
      {
        long: 'Guangxi',
        short: 'GX',
      },
      {
        long: 'Guizhou',
        short: 'GZ',
      },
      {
        long: 'Hainan',
        short: 'HI',
      },
      {
        long: 'Hebei',
        short: 'HE',
      },
      {
        long: 'Heilongjiang',
        short: 'HL',
      },
      {
        long: 'Henan',
        short: 'HA',
      },
      {
        long: 'Hong Kong',
        short: 'HK',
      },
      {
        long: 'Hubei',
        short: 'HB',
      },
      {
        long: 'Hunan',
        short: 'HN',
      },
      {
        long: 'Inner Mongolia',
        short: 'NM',
      },
      {
        long: 'Jiangsu',
        short: 'JS',
      },
      {
        long: 'Jiangxi',
        short: 'JX',
      },
      {
        long: 'Jilin',
        short: 'JL',
      },
      {
        long: 'Liaoning',
        short: 'LN',
      },
      {
        long: 'Macau',
        short: 'MO',
      },
      {
        long: 'Ningxia',
        short: 'NX',
      },
      {
        long: 'Qinghai',
        short: 'QH',
      },
      {
        long: 'Shaanxi',
        short: 'SN',
      },
      {
        long: 'Shandong',
        short: 'SD',
      },
      {
        long: 'Shanghai',
        short: 'SH',
      },
      {
        long: 'Shanxi',
        short: 'SX',
      },
      {
        long: 'Sichuan',
        short: 'SC',
      },
      {
        long: 'Tianjin',
        short: 'TJ',
      },
      {
        long: 'Tibet',
        short: 'XZ',
      },
      {
        long: 'Xinjiang',
        short: 'XJ',
      },
      {
        long: 'Yunnan',
        short: 'YN',
      },
      {
        long: 'Zhejiang',
        short: 'ZJ',
      },
    ],
  },
  {
    long: 'Christmas Island',
    short: 'CX',
    regions: [
      {
        long: 'Christmas Island',
        short: 'CX',
      },
    ],
  },
  {
    long: 'Cocos (Keeling) Islands',
    short: 'CC',
    regions: [
      {
        long: 'Direction Island',
        short: 'DI',
      },
      {
        long: 'Home Island',
        short: 'HM',
      },
      {
        long: 'Horsburgh Island',
        short: 'HR',
      },
      {
        long: 'North Keeling Island',
        short: 'NK',
      },
      {
        long: 'South Island',
        short: 'SI',
      },
      {
        long: 'West Island',
        short: 'WI',
      },
    ],
  },
  {
    long: 'Colombia',
    short: 'CO',
    regions: [
      {
        long: 'Amazonas',
        short: 'AMA',
      },
      {
        long: 'Antioquia',
        short: 'ANT',
      },
      {
        long: 'Arauca',
        short: 'ARA',
      },
      {
        long: 'Archipiélago de San Andrés',
        short: 'SAP',
      },
      {
        long: 'Atlántico',
        short: 'ATL',
      },
      {
        long: 'Bogotá D.C.',
        short: 'DC',
      },
      {
        long: 'Bolívar',
        short: 'BOL',
      },
      {
        long: 'Boyacá',
        short: 'BOY',
      },
      {
        long: 'Caldas',
        short: 'CAL',
      },
      {
        long: 'Caquetá',
        short: 'CAQ',
      },
      {
        long: 'Casanare',
        short: 'CAS',
      },
      {
        long: 'Cauca',
        short: 'CAU',
      },
      {
        long: 'Cesar',
        short: 'CES',
      },
      {
        long: 'Chocó',
        short: 'CHO',
      },
      {
        long: 'Córdoba',
        short: 'COR',
      },
      {
        long: 'Cundinamarca',
        short: 'CUN',
      },
      {
        long: 'Guainía',
        short: 'GUA',
      },
      {
        long: 'Guaviare',
        short: 'GUV',
      },
      {
        long: 'Huila',
        short: 'HUI',
      },
      {
        long: 'La Guajira',
        short: 'LAG',
      },
      {
        long: 'Magdalena',
        short: 'MAG',
      },
      {
        long: 'Meta',
        short: 'MET',
      },
      {
        long: 'Nariño',
        short: 'NAR',
      },
      {
        long: 'Norte de Santander',
        short: 'NSA',
      },
      {
        long: 'Putumayo',
        short: 'PUT',
      },
      {
        long: 'Quindío',
        short: 'QUI',
      },
      {
        long: 'Risaralda',
        short: 'RIS',
      },
      {
        long: 'Santander',
        short: 'SAN',
      },
      {
        long: 'Sucre',
        short: 'SUC',
      },
      {
        long: 'Tolima',
        short: 'TOL',
      },
      {
        long: 'Valle del Cauca',
        short: 'VAC',
      },
      {
        long: 'Vaupés',
        short: 'VAU',
      },
      {
        long: 'Vichada',
        short: 'VID',
      },
    ],
  },
  {
    long: 'Comoros',
    short: 'KM',
    regions: [
      {
        long: 'Andjazîdja',
        short: 'G',
      },
      {
        long: 'Andjouân',
        short: 'A',
      },
      {
        long: 'Moûhîlî',
        short: 'M',
      },
    ],
  },
  {
    long: 'Congo, Republic of the (Brazzaville)',
    short: 'CG',
    regions: [
      {
        long: 'Bouenza',
        short: '11',
      },
      {
        long: 'Brazzaville',
        short: 'BZV',
      },
      {
        long: 'Cuvette',
        short: '8',
      },
      {
        long: 'Cuvette-Ouest',
        short: '15',
      },
      {
        long: 'Kouilou',
        short: '5',
      },
      {
        long: 'Lékoumou',
        short: '2',
      },
      {
        long: 'Likouala',
        short: '7',
      },
      {
        long: 'Niari',
        short: '9',
      },
      {
        long: 'Plateaux',
        short: '14',
      },
      {
        long: 'Pointe-Noire',
        short: '16',
      },
      {
        long: 'Pool',
        short: '12',
      },
      {
        long: 'Sangha',
        short: '13',
      },
    ],
  },
  {
    long: 'Congo, the Democratic Republic of the (Kinshasa)',
    short: 'CD',
    regions: [
      {
        long: 'Bandundu',
        short: 'BN',
      },
      {
        long: 'Bas-Congo',
        short: 'BC',
      },
      {
        long: 'Équateur',
        short: 'EQ',
      },
      {
        long: 'Kasaï-Occidental',
        short: 'KE',
      },
      {
        long: 'Kasaï-Oriental',
        short: 'KW',
      },
      {
        long: 'Katanga',
        short: 'KA',
      },
      {
        long: 'Kinshasa',
        short: 'KN',
      },
      {
        long: 'Maniema',
        short: 'MA',
      },
      {
        long: 'Nord-Kivu',
        short: 'NK',
      },
      {
        long: 'Orientale',
        short: 'OR',
      },
      {
        long: 'Sud-Kivu',
        short: 'SK',
      },
    ],
  },
  {
    long: 'Cook Islands',
    short: 'CK',
    regions: [
      {
        long: 'Aitutaki',
        short: 'undefined',
      },
      {
        long: 'Atiu',
        short: 'undefined',
      },
      {
        long: 'Avarua',
        short: 'undefined',
      },
      {
        long: 'Mangaia',
        short: 'undefined',
      },
      {
        long: 'Manihiki',
        short: 'undefined',
      },
      {
        long: "Ma'uke",
        short: 'undefined',
      },
      {
        long: 'Mitiaro',
        short: 'undefined',
      },
      {
        long: 'Nassau',
        short: 'undefined',
      },
      {
        long: 'Palmerston',
        short: 'undefined',
      },
      {
        long: 'Penrhyn',
        short: 'undefined',
      },
      {
        long: 'Pukapuka',
        short: 'undefined',
      },
      {
        long: 'Rakahanga',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Costa Rica',
    short: 'CR',
    regions: [
      {
        long: 'Alajuela',
        short: '2',
      },
      {
        long: 'Cartago',
        short: '3',
      },
      {
        long: 'Guanacaste',
        short: '5',
      },
      {
        long: 'Heredia',
        short: '4',
      },
      {
        long: 'Limón',
        short: '7',
      },
      {
        long: 'Puntarenas',
        short: '6',
      },
      {
        long: 'San José',
        short: '1',
      },
    ],
  },
  {
    long: "Côte d'Ivoire, Republic of",
    short: 'CI',
    regions: [
      {
        long: 'Agnéby',
        short: '16',
      },
      {
        long: 'Bafing',
        short: '17',
      },
      {
        long: 'Bas-Sassandra',
        short: '09',
      },
      {
        long: 'Denguélé',
        short: '10',
      },
      {
        long: 'Dix-Huit Montagnes',
        short: '06',
      },
      {
        long: 'Fromager',
        short: '18',
      },
      {
        long: 'Haut-Sassandra',
        short: '02',
      },
      {
        long: 'Lacs',
        short: '07',
      },
      {
        long: 'Lagunes',
        short: '01',
      },
      {
        long: 'Marahoué',
        short: '12',
      },
      {
        long: 'Moyen-Cavally',
        short: '19',
      },
      {
        long: 'Moyen-Comoé',
        short: '05',
      },
      {
        long: "N'zi-Comoé",
        short: '11',
      },
      {
        long: 'Savanes',
        short: '03',
      },
      {
        long: 'Sud-Bandama',
        short: '15',
      },
      {
        long: 'Sud-Comoé',
        short: '13',
      },
      {
        long: 'Vallée du Bandama',
        short: '04',
      },
      {
        long: 'Worodougou',
        short: '14',
      },
      {
        long: 'Zanzan',
        short: '08',
      },
    ],
  },
  {
    long: 'Croatia',
    short: 'HR',
    regions: [
      {
        long: 'Bjelovarsko-bilogorska županija',
        short: '07',
      },
      {
        long: 'Brodsko-posavska županija',
        short: '12',
      },
      {
        long: 'Dubrovačko-neretvanska županija',
        short: '19',
      },
      {
        long: 'Grad Zagreb',
        short: '21',
      },
      {
        long: 'Istarska županija',
        short: '18',
      },
      {
        long: 'Karlovačka županija',
        short: '04',
      },
      {
        long: 'Koprivničko-križevačka županija',
        short: '06',
      },
      {
        long: 'Krapinsko-zagorska županija',
        short: '02',
      },
      {
        long: 'Ličko-senjska županija',
        short: '09',
      },
      {
        long: 'Međimurska županija',
        short: '20',
      },
      {
        long: 'Osječko-baranjska županija',
        short: '14',
      },
      {
        long: 'Požeško-slavonska županija',
        short: '11',
      },
      {
        long: 'Primorsko-goranska županija',
        short: '08',
      },
      {
        long: 'Sisačko-moslavačka županija',
        short: '03',
      },
      {
        long: 'Splitsko-dalmatinska županija',
        short: '17',
      },
      {
        long: 'Šibensko-kninska županija',
        short: '15',
      },
      {
        long: 'Varaždinska županija',
        short: '05',
      },
      {
        long: 'Virovitičko-podravska županija',
        short: '10',
      },
      {
        long: 'Vukovarsko-srijemska županija',
        short: '16',
      },
      {
        long: 'Zadarska županija',
        short: '13',
      },
      {
        long: 'Zagrebačka županija',
        short: '01',
      },
    ],
  },
  {
    long: 'Cuba',
    short: 'CU',
    regions: [
      {
        long: 'Artemisa',
        short: '15',
      },
      {
        long: 'Camagüey',
        short: '09',
      },
      {
        long: 'Ciego de Ávila',
        short: '08',
      },
      {
        long: 'Cienfuegos',
        short: '06',
      },
      {
        long: 'Granma',
        short: '12',
      },
      {
        long: 'Guantánamo',
        short: '14',
      },
      {
        long: 'Holguín',
        short: '11',
      },
      {
        long: 'Isla de la Juventud',
        short: '99',
      },
      {
        long: 'La Habana',
        short: '03',
      },
      {
        long: 'Las Tunas',
        short: '10',
      },
      {
        long: 'Matanzas',
        short: '04',
      },
      {
        long: 'Mayabeque',
        short: '16',
      },
      {
        long: 'Pinar del Río',
        short: '01',
      },
      {
        long: 'Sancti Spíritus',
        short: '07',
      },
      {
        long: 'Santiago de Cuba',
        short: '13',
      },
      {
        long: 'Villa Clara',
        short: '05',
      },
    ],
  },
  {
    long: 'Curaçao',
    short: 'CW',
    regions: [
      {
        long: 'Curaçao',
        short: 'CW',
      },
    ],
  },
  {
    long: 'Cyprus',
    short: 'CY',
    regions: [
      {
        long: 'Ammochostos',
        short: '04',
      },
      {
        long: 'Keryneia',
        short: '06',
      },
      {
        long: 'Larnaka',
        short: '03',
      },
      {
        long: 'Lefkosia',
        short: '01',
      },
      {
        long: 'Lemesos',
        short: '02',
      },
      {
        long: 'Pafos',
        short: '05',
      },
    ],
  },
  {
    long: 'Czech Republic',
    short: 'CZ',
    regions: [
      {
        long: 'Hlavní město Praha',
        short: 'PR',
      },
      {
        long: 'Jihočeský kraj',
        short: 'JC',
      },
      {
        long: 'Jihomoravský kraj',
        short: 'JM',
      },
      {
        long: 'Karlovarský kraj',
        short: 'KA',
      },
      {
        long: 'Královéhradecký kraj',
        short: 'KR',
      },
      {
        long: 'Liberecký kraj',
        short: 'LI',
      },
      {
        long: 'Moravskoslezský kraj',
        short: 'MO',
      },
      {
        long: 'Olomoucký kraj',
        short: 'OL',
      },
      {
        long: 'Pardubický kraj',
        short: 'PA',
      },
      {
        long: 'Plzeňský kraj',
        short: 'PL',
      },
      {
        long: 'Středočeský kraj',
        short: 'ST',
      },
      {
        long: 'Ústecký kraj',
        short: 'US',
      },
      {
        long: 'Vysočina',
        short: 'VY',
      },
      {
        long: 'Zlínský kraj',
        short: 'ZL',
      },
    ],
  },
  {
    long: 'Denmark',
    short: 'DK',
    regions: [
      {
        long: 'Hovedstaden',
        short: '84',
      },
      {
        long: 'Kujalleq',
        short: 'GL-KU',
      },
      {
        long: 'Midtjylland',
        short: '82',
      },
      {
        long: 'Norderøerne',
        short: 'FO-01',
      },
      {
        long: 'Nordjylland',
        short: '81',
      },
      {
        long: 'Østerø',
        short: 'FO-06',
      },
      {
        long: 'Qaasuitsup',
        short: 'GL-QA',
      },
      {
        long: 'Qeqqata',
        short: 'GL-QE',
      },
      {
        long: 'Sandø',
        short: 'FO-02',
      },
      {
        long: 'Sermersooq',
        short: 'GL-SM',
      },
      {
        long: 'Sjælland',
        short: '85',
      },
      {
        long: 'Strømø',
        short: 'FO-03',
      },
      {
        long: 'Suderø',
        short: 'FO-04',
      },
      {
        long: 'Syddanmark',
        short: '83',
      },
      {
        long: 'Vågø',
        short: 'FO-05',
      },
    ],
  },
  {
    long: 'Djibouti',
    short: 'DJ',
    regions: [
      {
        long: 'Ali Sabieh',
        short: 'AS',
      },
      {
        long: 'Arta',
        short: 'AR',
      },
      {
        long: 'Dikhil',
        short: 'DI',
      },
      {
        long: 'Obock',
        short: 'OB',
      },
      {
        long: 'Tadjourah',
        short: 'TA',
      },
    ],
  },
  {
    long: 'Dominica',
    short: 'DM',
    regions: [
      {
        long: 'Saint Andrew Parish',
        short: '02',
      },
      {
        long: 'Saint David Parish',
        short: '03',
      },
      {
        long: 'Saint George Parish',
        short: '04',
      },
      {
        long: 'Saint John Parish',
        short: '05',
      },
      {
        long: 'Saint Joseph Parish',
        short: '06',
      },
      {
        long: 'Saint Luke Parish',
        short: '07',
      },
      {
        long: 'Saint Mark Parish',
        short: '08',
      },
      {
        long: 'Saint Patrick Parish',
        short: '09',
      },
      {
        long: 'Saint Paul Parish',
        short: '10',
      },
      {
        long: 'Saint Peter Parish',
        short: '11',
      },
    ],
  },
  {
    long: 'Dominican Republic',
    short: 'DO',
    regions: [
      {
        long: 'Cibao Central',
        short: '02',
      },
      {
        long: 'Del Valle',
        short: '37',
      },
      {
        long: 'Distrito Nacional',
        short: '01',
      },
      {
        long: 'Enriquillo',
        short: '38',
      },
      {
        long: 'Norcentral',
        short: '04',
      },
      {
        long: 'Nordeste',
        short: '33',
      },
      {
        long: 'Noroeste',
        short: '34',
      },
      {
        long: 'Norte',
        short: '35',
      },
      {
        long: 'Valdesia',
        short: '42',
      },
    ],
  },
  {
    long: 'Ecuador',
    short: 'EC',
    regions: [
      {
        long: 'Azuay',
        short: 'A',
      },
      {
        long: 'Bolívar',
        short: 'B',
      },
      {
        long: 'Cañar',
        short: 'F',
      },
      {
        long: 'Carchi',
        short: 'C',
      },
      {
        long: 'Chimborazo',
        short: 'H',
      },
      {
        long: 'Cotopaxi',
        short: 'X',
      },
      {
        long: 'El Oro',
        short: 'O',
      },
      {
        long: 'Esmeraldas',
        short: 'E',
      },
      {
        long: 'Galápagos',
        short: 'W',
      },
      {
        long: 'Guayas',
        short: 'G',
      },
      {
        long: 'Imbabura',
        short: 'I',
      },
      {
        long: 'Loja',
        short: 'L',
      },
      {
        long: 'Los Ríos',
        short: 'R',
      },
      {
        long: 'Manabí',
        short: 'M',
      },
      {
        long: 'Morona-Santiago',
        short: 'S',
      },
      {
        long: 'Napo',
        short: 'N',
      },
      {
        long: 'Orellana',
        short: 'D',
      },
      {
        long: 'Pastaza',
        short: 'Y',
      },
      {
        long: 'Pichincha',
        short: 'P',
      },
      {
        long: 'Santa Elena',
        short: 'SE',
      },
      {
        long: 'Santo Domingo de los Tsáchilas',
        short: 'SD',
      },
      {
        long: 'Sucumbíos',
        short: 'U',
      },
      {
        long: 'Tungurahua',
        short: 'T',
      },
      {
        long: 'Zamora-Chinchipe',
        short: 'Z',
      },
    ],
  },
  {
    long: 'Egypt',
    short: 'EG',
    regions: [
      {
        long: 'Alexandria',
        short: 'ALX',
      },
      {
        long: 'Aswan',
        short: 'ASN',
      },
      {
        long: 'Asyout',
        short: 'AST',
      },
      {
        long: 'Bani Sueif',
        short: 'BNS',
      },
      {
        long: 'Beheira',
        short: 'BH',
      },
      {
        long: 'Cairo',
        short: 'C',
      },
      {
        long: 'Daqahlia',
        short: 'DK',
      },
      {
        long: 'Dumiat',
        short: 'DT',
      },
      {
        long: 'El Bahr El Ahmar',
        short: 'BA',
      },
      {
        long: 'El Ismailia',
        short: 'IS',
      },
      {
        long: 'El Suez',
        short: 'SUZ',
      },
      {
        long: 'El Wadi El Gedeed',
        short: 'WAD',
      },
      {
        long: 'Fayoum',
        short: 'FYM',
      },
      {
        long: 'Gharbia',
        short: 'GH',
      },
      {
        long: 'Giza',
        short: 'GZ',
      },
      {
        long: 'Helwan',
        short: 'HU',
      },
      {
        long: 'Kafr El Sheikh',
        short: 'KFS',
      },
      {
        long: 'Luxor',
        short: 'LX',
      },
      {
        long: 'Matrouh',
        short: 'MT',
      },
      {
        long: 'Menia',
        short: 'MN',
      },
      {
        long: 'Menofia',
        short: 'MNF',
      },
      {
        long: 'North Sinai',
        short: 'SIN',
      },
      {
        long: 'Port Said',
        short: 'PTS',
      },
      {
        long: 'Qalubia',
        short: 'KB',
      },
      {
        long: 'Qena',
        short: 'KN',
      },
      {
        long: 'Sharqia',
        short: 'SHR',
      },
      {
        long: 'Sixth of October',
        short: 'SU',
      },
      {
        long: 'Sohag',
        short: 'SHG',
      },
      {
        long: 'South Sinai',
        short: 'JS',
      },
    ],
  },
  {
    long: 'El Salvador',
    short: 'SV',
    regions: [
      {
        long: 'Ahuachapán',
        short: 'AH',
      },
      {
        long: 'Cabañas',
        short: 'CA',
      },
      {
        long: 'Cuscatlán',
        short: 'CU',
      },
      {
        long: 'Chalatenango',
        short: 'CH',
      },
      {
        long: 'La Libertad',
        short: 'LI',
      },
      {
        long: 'La Paz',
        short: 'PA',
      },
      {
        long: 'La Unión',
        short: 'UN',
      },
      {
        long: 'Morazán',
        short: 'MO',
      },
      {
        long: 'San Miguel',
        short: 'SM',
      },
      {
        long: 'San Salvador',
        short: 'SS',
      },
      {
        long: 'Santa Ana',
        short: 'SA',
      },
      {
        long: 'San Vicente',
        short: 'SV',
      },
      {
        long: 'Sonsonate',
        short: 'SO',
      },
      {
        long: 'Usulután',
        short: 'US',
      },
    ],
  },
  {
    long: 'Equatorial Guinea',
    short: 'GQ',
    regions: [
      {
        long: 'Annobón',
        short: 'AN',
      },
      {
        long: 'Bioko Norte',
        short: 'BN',
      },
      {
        long: 'Bioko Sur',
        short: 'BS',
      },
      {
        long: 'Centro Sur',
        short: 'CS',
      },
      {
        long: 'Kié-Ntem',
        short: 'KN',
      },
      {
        long: 'Litoral',
        short: 'LI',
      },
      {
        long: 'Wele-Nzas',
        short: 'WN',
      },
    ],
  },
  {
    long: 'Eritrea',
    short: 'ER',
    regions: [
      {
        long: 'Anseba',
        short: 'AN',
      },
      {
        long: 'Debub',
        short: 'DU',
      },
      {
        long: 'Debub-Keih-Bahri',
        short: 'DK',
      },
      {
        long: 'Gash-Barka',
        short: 'GB',
      },
      {
        long: 'Maekel',
        short: 'MA',
      },
      {
        long: 'Semien-Keih-Bahri',
        short: 'SK',
      },
    ],
  },
  {
    long: 'Estonia',
    short: 'EE',
    regions: [
      {
        long: 'Harjumaa (Tallinn)',
        short: '37',
      },
      {
        long: 'Hiiumaa (Kardla)',
        short: '39',
      },
      {
        long: 'Ida-Virumaa (Johvi)',
        short: '44',
      },
      {
        long: 'Järvamaa (Paide)',
        short: '41',
      },
      {
        long: 'Jõgevamaa (Jogeva)',
        short: '49',
      },
      {
        long: 'Läänemaa',
        short: '57',
      },
      {
        long: 'Lääne-Virumaa (Rakvere)',
        short: '59',
      },
      {
        long: 'Pärnumaa (Parnu)',
        short: '67',
      },
      {
        long: 'Põlvamaa (Polva)',
        short: '65',
      },
      {
        long: 'Raplamaa (Rapla)',
        short: '70',
      },
      {
        long: 'Saaremaa (Kuessaare)',
        short: '74',
      },
      {
        long: 'Tartumaa (Tartu)',
        short: '78',
      },
      {
        long: 'Valgamaa (Valga)',
        short: '82',
      },
      {
        long: 'Viljandimaa (Viljandi)',
        short: '84',
      },
      {
        long: 'Võrumaa (Voru)',
        short: '86',
      },
    ],
  },
  {
    long: 'Ethiopia',
    short: 'ET',
    regions: [
      {
        long: 'Addis Ababa',
        short: 'AA',
      },
      {
        long: 'Afar',
        short: 'AF',
      },
      {
        long: 'Amhara',
        short: 'AM',
      },
      {
        long: 'Benshangul-Gumaz',
        short: 'BE',
      },
      {
        long: 'Dire Dawa',
        short: 'DD',
      },
      {
        long: 'Gambela',
        short: 'GA',
      },
      {
        long: 'Harari',
        short: 'HA',
      },
      {
        long: 'Oromia',
        short: 'OR',
      },
      {
        long: 'Somali',
        short: 'SO',
      },
      {
        long: "Southern Nations Nationalities and People's Region",
        short: 'SN',
      },
      {
        long: 'Tigray',
        short: 'TI',
      },
    ],
  },
  {
    long: 'Falkland Islands (Islas Malvinas)',
    short: 'FK',
    regions: [
      {
        long: 'Falkland Islands (Islas Malvinas)',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Faroe Islands',
    short: 'FO',
    regions: [
      {
        long: 'Bordoy',
        short: 'undefined',
      },
      {
        long: 'Eysturoy',
        short: 'undefined',
      },
      {
        long: 'Mykines',
        short: 'undefined',
      },
      {
        long: 'Sandoy',
        short: 'undefined',
      },
      {
        long: 'Skuvoy',
        short: 'undefined',
      },
      {
        long: 'Streymoy',
        short: 'undefined',
      },
      {
        long: 'Suduroy',
        short: 'undefined',
      },
      {
        long: 'Tvoroyri',
        short: 'undefined',
      },
      {
        long: 'Vagar',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Fiji',
    short: 'FJ',
    regions: [
      {
        long: 'Ba',
        short: '01',
      },
      {
        long: 'Bua',
        short: '02',
      },
      {
        long: 'Cakaudrove',
        short: '03',
      },
      {
        long: 'Kadavu',
        short: '04',
      },
      {
        long: 'Lau',
        short: '05',
      },
      {
        long: 'Lomaiviti',
        short: '06',
      },
      {
        long: 'Macuata',
        short: '07',
      },
      {
        long: 'Nadroga and Navosa',
        short: '08',
      },
      {
        long: 'Naitasiri',
        short: '09',
      },
      {
        long: 'Namosi',
        short: '10',
      },
      {
        long: 'Ra',
        short: '11',
      },
      {
        long: 'Rewa',
        short: '12',
      },
      {
        long: 'Rotuma',
        short: 'R',
      },
      {
        long: 'Serua',
        short: '13',
      },
      {
        long: 'Tailevu',
        short: '14',
      },
    ],
  },
  {
    long: 'Finland',
    short: 'FI',
    regions: [
      {
        long: 'Ahvenanmaan maakunta',
        short: 'FI-01',
      },
      {
        long: 'Etelä-Karjala',
        short: 'FI-02',
      },
      {
        long: 'Etelä-Pohjanmaa',
        short: 'FI-03',
      },
      {
        long: 'Etelä-Savo',
        short: 'FI-04',
      },
      {
        long: 'Kainuu',
        short: 'FI-05',
      },
      {
        long: 'Kanta-Häme',
        short: 'FI-06',
      },
      {
        long: 'Keski-Pohjanmaa',
        short: 'FI-07',
      },
      {
        long: 'Keski-Suomi',
        short: 'FI-08',
      },
      {
        long: 'Kymenlaakso',
        short: 'FI-09',
      },
      {
        long: 'Lappi',
        short: 'FI-10',
      },
      {
        long: 'Pirkanmaa',
        short: 'FI-11',
      },
      {
        long: 'Pohjanmaa',
        short: 'FI-12',
      },
      {
        long: 'Pohjois-Karjala',
        short: 'FI-13',
      },
      {
        long: 'Pohjois-Pohjanmaa',
        short: 'FI-14',
      },
      {
        long: 'Pohjois-Savo',
        short: 'FI-15',
      },
      {
        long: 'Päijät-Häme',
        short: 'FI-16',
      },
      {
        long: 'Satakunta',
        short: 'FI-17',
      },
      {
        long: 'Uusimaa',
        short: 'FI-18',
      },
      {
        long: 'Varsinais-Suomi',
        short: 'FI-19',
      },
    ],
  },
  {
    long: 'France',
    short: 'FR',
    regions: [
      {
        long: 'Auvergne-Rhône-Alpes',
        short: 'ARA',
      },
      {
        long: 'Bourgogne-Franche-Comté',
        short: 'BFC',
      },
      {
        long: 'Bretagne',
        short: 'BRE',
      },
      {
        long: 'Centre-Val de Loire',
        short: 'CVL',
      },
      {
        long: 'Corse',
        short: 'COR',
      },
      {
        long: 'Grand Est',
        short: 'GES',
      },
      {
        long: 'Hauts-de-France',
        short: 'HDF',
      },
      {
        long: 'Île-de-France',
        short: 'IDF',
      },
      {
        long: 'Normandie',
        short: 'NOR',
      },
      {
        long: 'Nouvelle-Aquitaine',
        short: 'NAQ',
      },
      {
        long: 'Occitanie',
        short: 'OCC',
      },
      {
        long: 'Pays de la Loire',
        short: 'PDL',
      },
      {
        long: "Provence-Alpes-Cote d'Azur",
        short: 'PAC',
      },
      {
        long: 'Clipperton',
        short: 'CP',
      },
      {
        long: 'Guadeloupe',
        short: 'GP',
      },
      {
        long: 'Guyane',
        short: 'GF',
      },
      {
        long: 'Martinique',
        short: 'MQ',
      },
      {
        long: 'Mayotte',
        short: 'YT',
      },
      {
        long: 'Novelle-Calédonie',
        short: 'NC',
      },
      {
        long: 'Polynésie',
        short: 'PF',
      },
      {
        long: 'Saint-Pierre-et-Miquelon',
        short: 'PM',
      },
      {
        long: 'Saint Barthélemy',
        short: 'BL',
      },
      {
        long: 'Saint Martin',
        short: 'MF',
      },
      {
        long: 'Réunion',
        short: 'RE',
      },
      {
        long: 'Terres Australes Françaises',
        short: 'TF',
      },
      {
        long: 'Wallis-et-Futuna',
        short: 'WF',
      },
    ],
  },
  {
    long: 'French Guiana',
    short: 'GF',
    regions: [
      {
        long: 'French Guiana',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'French Polynesia',
    short: 'PF',
    regions: [
      {
        long: 'Archipel des Marquises',
        short: 'undefined',
      },
      {
        long: 'Archipel des Tuamotu',
        short: 'undefined',
      },
      {
        long: 'Archipel des Tubuai',
        short: 'undefined',
      },
      {
        long: 'Iles du Vent',
        short: 'undefined',
      },
      {
        long: 'Iles Sous-le-Vent',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'French Southern and Antarctic Lands',
    short: 'TF',
    regions: [
      {
        long: 'Adelie Land',
        short: 'undefined',
      },
      {
        long: 'Ile Crozet',
        short: 'undefined',
      },
      {
        long: 'Iles Kerguelen',
        short: 'undefined',
      },
      {
        long: 'Iles Saint-Paul et Amsterdam',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Gabon',
    short: 'GA',
    regions: [
      {
        long: 'Estuaire',
        short: '1',
      },
      {
        long: 'Haut-Ogooué',
        short: '2',
      },
      {
        long: 'Moyen-Ogooué',
        short: '3',
      },
      {
        long: 'Ngounié',
        short: '4',
      },
      {
        long: 'Nyanga',
        short: '5',
      },
      {
        long: 'Ogooué-Ivindo',
        short: '6',
      },
      {
        long: 'Ogooué-Lolo',
        short: '7',
      },
      {
        long: 'Ogooué-Maritime',
        short: '8',
      },
      {
        long: 'Woleu-Ntem',
        short: '9',
      },
    ],
  },
  {
    long: 'Gambia, The',
    short: 'GM',
    regions: [
      {
        long: 'Banjul',
        short: 'B',
      },
      {
        long: 'Central River',
        short: 'M',
      },
      {
        long: 'Lower River',
        short: 'L',
      },
      {
        long: 'North Bank',
        short: 'N',
      },
      {
        long: 'Upper River',
        short: 'U',
      },
      {
        long: 'Western',
        short: 'W',
      },
    ],
  },
  {
    long: 'Georgia',
    short: 'GE',
    regions: [
      {
        long: 'Abkhazia (Sokhumi)',
        short: 'AB',
      },
      {
        long: "Ajaria (Bat'umi)",
        short: 'AJ',
      },
      {
        long: 'Guria',
        short: 'GU',
      },
      {
        long: 'Imereti',
        short: 'IM',
      },
      {
        long: "K'akheti",
        short: 'KA',
      },
      {
        long: 'Kvemo Kartli',
        short: 'KK',
      },
      {
        long: 'Mtshkheta-Mtianeti',
        short: 'MM',
      },
      {
        long: "Rach'a-Lexhkumi-KvemoSvaneti",
        short: 'RL',
      },
      {
        long: 'Samegrelo-Zemo Svaneti',
        short: 'SZ',
      },
      {
        long: 'Samtskhe-Javakheti',
        short: 'SJ',
      },
      {
        long: 'Shida Kartli',
        short: 'SK',
      },
      {
        long: 'Tbilisi',
        short: 'TB',
      },
    ],
  },
  {
    long: 'Germany',
    short: 'DE',
    regions: [
      {
        long: 'Baden-Württemberg',
        short: 'BW',
      },
      {
        long: 'Bayern',
        short: 'BY',
      },
      {
        long: 'Berlin',
        short: 'BE',
      },
      {
        long: 'Brandenburg',
        short: 'BB',
      },
      {
        long: 'Bremen',
        short: 'HB',
      },
      {
        long: 'Hamburg',
        short: 'HH',
      },
      {
        long: 'Hessen',
        short: 'HE',
      },
      {
        long: 'Mecklenburg-Vorpommern',
        short: 'MV',
      },
      {
        long: 'Niedersachsen',
        short: 'NI',
      },
      {
        long: 'Nordrhein-Westfalen',
        short: 'NW',
      },
      {
        long: 'Rheinland-Pfalz',
        short: 'RP',
      },
      {
        long: 'Saarland',
        short: 'SL',
      },
      {
        long: 'Sachsen',
        short: 'SN',
      },
      {
        long: 'Sachsen-Anhalt',
        short: 'ST',
      },
      {
        long: 'Schleswig-Holstein',
        short: 'SH',
      },
      {
        long: 'Thüringen',
        short: 'TH',
      },
    ],
  },
  {
    long: 'Ghana',
    short: 'GH',
    regions: [
      {
        long: 'Ahafo',
        short: 'undefined',
      },
      {
        long: 'Ashanti',
        short: 'AH',
      },
      {
        long: 'Bono',
        short: 'undefined',
      },
      {
        long: 'Bono East',
        short: 'undefined',
      },
      {
        long: 'Central',
        short: 'CP',
      },
      {
        long: 'Eastern',
        short: 'EP',
      },
      {
        long: 'Greater Accra',
        short: 'AA',
      },
      {
        long: 'Northern',
        short: 'NP',
      },
      {
        long: 'North East',
        short: 'undefined',
      },
      {
        long: 'Oti',
        short: 'undefined',
      },
      {
        long: 'Savannah',
        short: 'undefined',
      },
      {
        long: 'Upper East',
        short: 'UE',
      },
      {
        long: 'Upper West',
        short: 'UW',
      },
      {
        long: 'Volta',
        short: 'TV',
      },
      {
        long: 'Western',
        short: 'WP',
      },
      {
        long: 'Western North',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Gibraltar',
    short: 'GI',
    regions: [
      {
        long: 'Gibraltar',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Greece',
    short: 'GR',
    regions: [
      {
        long: 'Anatolikí Makedonía kai Thráki',
        short: 'A',
      },
      {
        long: 'Attikḯ',
        short: 'I',
      },
      {
        long: 'Dytikí Elláda',
        short: 'G',
      },
      {
        long: 'Dytikí Makedonía',
        short: 'C',
      },
      {
        long: 'Ionía Nísia',
        short: 'F',
      },
      {
        long: 'Kentrikí Makedonía',
        short: 'B',
      },
      {
        long: 'Krítí',
        short: 'M',
      },
      {
        long: 'Notío Aigaío',
        short: 'L',
      },
      {
        long: 'Peloponnísos',
        short: 'J',
      },
      {
        long: 'Stereá Elláda',
        short: 'H',
      },
      {
        long: 'Thessalía',
        short: 'E',
      },
      {
        long: 'Voreío Aigaío',
        short: 'K',
      },
      {
        long: 'Ípeiros',
        short: 'D',
      },
      {
        long: 'Ágion Óros',
        short: '69',
      },
    ],
  },
  {
    long: 'Greenland',
    short: 'GL',
    regions: [
      {
        long: 'Kommune Kujalleq',
        short: 'KU',
      },
      {
        long: 'Kommuneqarfik Sermersooq',
        short: 'SM',
      },
      {
        long: 'Qaasuitsup Kommunia',
        short: 'QA',
      },
      {
        long: 'Qeqqata Kommunia',
        short: 'QE',
      },
    ],
  },
  {
    long: 'Grenada',
    short: 'GD',
    regions: [
      {
        long: 'Saint Andrew',
        short: '01',
      },
      {
        long: 'Saint David',
        short: '02',
      },
      {
        long: 'Saint George',
        short: '03',
      },
      {
        long: 'Saint John',
        short: '04',
      },
      {
        long: 'Saint Mark',
        short: '05',
      },
      {
        long: 'Saint Patrick',
        short: '06',
      },
      {
        long: 'Southern Grenadine Islands',
        short: '10',
      },
    ],
  },
  {
    long: 'Guadeloupe',
    short: 'GP',
    regions: [
      {
        long: 'Guadeloupe',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Guam',
    short: 'GU',
    regions: [
      {
        long: 'Guam',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Guatemala',
    short: 'GT',
    regions: [
      {
        long: 'Alta Verapaz',
        short: 'AV',
      },
      {
        long: 'Baja Verapaz',
        short: 'BV',
      },
      {
        long: 'Chimaltenango',
        short: 'CM',
      },
      {
        long: 'Chiquimula',
        short: 'CQ',
      },
      {
        long: 'El Progreso',
        short: 'PR',
      },
      {
        long: 'Escuintla',
        short: 'ES',
      },
      {
        long: 'Guatemala',
        short: 'GU',
      },
      {
        long: 'Huehuetenango',
        short: 'HU',
      },
      {
        long: 'Izabal',
        short: 'IZ',
      },
      {
        long: 'Jalapa',
        short: 'JA',
      },
      {
        long: 'Jutiapa',
        short: 'JU',
      },
      {
        long: 'Petén',
        short: 'PE',
      },
      {
        long: 'Quetzaltenango',
        short: 'QZ',
      },
      {
        long: 'Quiché',
        short: 'QC',
      },
      {
        long: 'Retalhuleu',
        short: 'Re',
      },
      {
        long: 'Sacatepéquez',
        short: 'SA',
      },
      {
        long: 'San Marcos',
        short: 'SM',
      },
      {
        long: 'Santa Rosa',
        short: 'SR',
      },
      {
        long: 'Sololá',
        short: 'SO',
      },
      {
        long: 'Suchitepéquez',
        short: 'SU',
      },
      {
        long: 'Totonicapán',
        short: 'TO',
      },
      {
        long: 'Zacapa',
        short: 'ZA',
      },
    ],
  },
  {
    long: 'Guernsey',
    short: 'GG',
    regions: [
      {
        long: 'Castel',
        short: 'undefined',
      },
      {
        long: 'Forest',
        short: 'undefined',
      },
      {
        long: 'St. Andrew',
        short: 'undefined',
      },
      {
        long: 'St. Martin',
        short: 'undefined',
      },
      {
        long: 'St. Peter Port',
        short: 'undefined',
      },
      {
        long: 'St. Pierre du Bois',
        short: 'undefined',
      },
      {
        long: 'St. Sampson',
        short: 'undefined',
      },
      {
        long: 'St. Saviour',
        short: 'undefined',
      },
      {
        long: 'Torteval',
        short: 'undefined',
      },
      {
        long: 'Vale',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Guinea',
    short: 'GN',
    regions: [
      {
        long: 'Boké',
        short: 'B',
      },
      {
        long: 'Conakry',
        short: 'C',
      },
      {
        long: 'Faranah',
        short: 'F',
      },
      {
        long: 'Kankan',
        short: 'K',
      },
      {
        long: 'Kindia',
        short: 'D',
      },
      {
        long: 'Labé',
        short: 'L',
      },
      {
        long: 'Mamou',
        short: 'M',
      },
      {
        long: 'Nzérékoré',
        short: 'N',
      },
    ],
  },
  {
    long: 'Guinea-Bissau',
    short: 'GW',
    regions: [
      {
        long: 'Bafatá',
        short: 'BA',
      },
      {
        long: 'Biombo',
        short: 'BM',
      },
      {
        long: 'Bissau',
        short: 'BS',
      },
      {
        long: 'Bolama-Bijagos',
        short: 'BL',
      },
      {
        long: 'Cacheu',
        short: 'CA',
      },
      {
        long: 'Gabú',
        short: 'GA',
      },
      {
        long: 'Oio',
        short: 'OI',
      },
      {
        long: 'Quinara',
        short: 'QU',
      },
      {
        long: 'Tombali',
        short: 'TO',
      },
    ],
  },
  {
    long: 'Guyana',
    short: 'GY',
    regions: [
      {
        long: 'Barima-Waini',
        short: 'BA',
      },
      {
        long: 'Cuyuni-Mazaruni',
        short: 'CU',
      },
      {
        long: 'Demerara-Mahaica',
        short: 'DE',
      },
      {
        long: 'East Berbice-Corentyne',
        short: 'EB',
      },
      {
        long: 'Essequibo Islands-West Demerara',
        short: 'ES',
      },
      {
        long: 'Mahaica-Berbice',
        short: 'MA',
      },
      {
        long: 'Pomeroon-Supenaam',
        short: 'PM',
      },
      {
        long: 'Potaro-Siparuni',
        short: 'PT',
      },
      {
        long: 'Upper Demerara-Berbice',
        short: 'UD',
      },
      {
        long: 'Upper Takutu-Upper Essequibo',
        short: 'UT',
      },
    ],
  },
  {
    long: 'Haiti',
    short: 'HT',
    regions: [
      {
        long: 'Artibonite',
        short: 'AR',
      },
      {
        long: 'Centre',
        short: 'CE',
      },
      {
        long: "Grand'Anse",
        short: 'GA',
      },
      {
        long: 'Nippes',
        short: 'NI',
      },
      {
        long: 'Nord',
        short: 'ND',
      },
      {
        long: 'Nord-Est',
        short: 'NE',
      },
      {
        long: 'Nord-Ouest',
        short: 'NO',
      },
      {
        long: 'Ouest',
        short: 'OU',
      },
      {
        long: 'Sud',
        short: 'SD',
      },
      {
        long: 'Sud-Est',
        short: 'SE',
      },
    ],
  },
  {
    long: 'Heard Island and McDonald Islands',
    short: 'HM',
    regions: [
      {
        long: 'Heard Island and McDonald Islands',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Holy See (Vatican City)',
    short: 'VA',
    regions: [
      {
        long: 'Holy See (Vatican City)',
        short: '01',
      },
    ],
  },
  {
    long: 'Honduras',
    short: 'HN',
    regions: [
      {
        long: 'Atlántida',
        short: 'AT',
      },
      {
        long: 'Choluteca',
        short: 'CH',
      },
      {
        long: 'Colón',
        short: 'CL',
      },
      {
        long: 'Comayagua',
        short: 'CM',
      },
      {
        long: 'Copán',
        short: 'CP',
      },
      {
        long: 'Cortés',
        short: 'CR',
      },
      {
        long: 'El Paraíso',
        short: 'EP',
      },
      {
        long: 'Francisco Morazán',
        short: 'FM',
      },
      {
        long: 'Gracias a Dios',
        short: 'GD',
      },
      {
        long: 'Intibucá',
        short: 'IN',
      },
      {
        long: 'Islas de la Bahía',
        short: 'IB',
      },
      {
        long: 'La Paz',
        short: 'LP',
      },
      {
        long: 'Lempira',
        short: 'LE',
      },
      {
        long: 'Ocotepeque',
        short: 'OC',
      },
      {
        long: 'Olancho',
        short: 'OL',
      },
      {
        long: 'Santa Bárbara',
        short: 'SB',
      },
      {
        long: 'Valle',
        short: 'VA',
      },
      {
        long: 'Yoro',
        short: 'YO',
      },
    ],
  },
  {
    long: 'Hong Kong',
    short: 'HK',
    regions: [
      {
        long: 'Hong Kong',
        short: 'HK',
      },
    ],
  },
  {
    long: 'Hungary',
    short: 'HU',
    regions: [
      {
        long: 'Bács-Kiskun',
        short: 'BK',
      },
      {
        long: 'Baranya',
        short: 'BA',
      },
      {
        long: 'Békés',
        short: 'BE',
      },
      {
        long: 'Békéscsaba',
        short: 'BC',
      },
      {
        long: 'Borsod-Abauj-Zemplen',
        short: 'BZ',
      },
      {
        long: 'Budapest',
        short: 'BU',
      },
      {
        long: 'Csongrád',
        short: 'CS',
      },
      {
        long: 'Debrecen',
        short: 'DE',
      },
      {
        long: 'Dunaújváros',
        short: 'DU',
      },
      {
        long: 'Eger',
        short: 'EG',
      },
      {
        long: 'Érd',
        short: 'ER',
      },
      {
        long: 'Fejér',
        short: 'FE',
      },
      {
        long: 'Győr',
        short: 'GY',
      },
      {
        long: 'Győr-Moson-Sopron',
        short: 'GS',
      },
      {
        long: 'Hajdú-Bihar',
        short: 'HB',
      },
      {
        long: 'Heves',
        short: 'HE',
      },
      {
        long: 'Hódmezővásárhely',
        short: 'HV',
      },
      {
        long: 'Jász-Nagykun-Szolnok',
        short: 'N',
      },
      {
        long: 'Kaposvár',
        short: 'KV',
      },
      {
        long: 'Kecskemét',
        short: 'KM',
      },
      {
        long: 'Komárom-Esztergom',
        short: 'KE',
      },
      {
        long: 'Miskolc',
        short: 'MI',
      },
      {
        long: 'Nagykanizsa',
        short: 'NK',
      },
      {
        long: 'Nógrád',
        short: 'NO',
      },
      {
        long: 'Nyíregyháza',
        short: 'NY',
      },
      {
        long: 'Pécs',
        short: 'PS',
      },
      {
        long: 'Pest',
        short: 'PE',
      },
      {
        long: 'Salgótarján',
        short: 'ST',
      },
      {
        long: 'Somogy',
        short: 'SO',
      },
      {
        long: 'Sopron',
        short: 'SN',
      },
      {
        long: 'Szabolcs-á-Bereg',
        short: 'SZ',
      },
      {
        long: 'Szeged',
        short: 'SD',
      },
      {
        long: 'Székesfehérvár',
        short: 'SF',
      },
      {
        long: 'Szekszárd',
        short: 'SS',
      },
      {
        long: 'Szolnok',
        short: 'SK',
      },
      {
        long: 'Szombathely',
        short: 'SH',
      },
      {
        long: 'Tatabánya',
        short: 'TB',
      },
      {
        long: 'Tolna',
        short: 'TO',
      },
      {
        long: 'Vas',
        short: 'VA',
      },
      {
        long: 'Veszprém',
        short: 'VE',
      },
      {
        long: 'Veszprém (City)',
        short: 'VM',
      },
      {
        long: 'Zala',
        short: 'ZA',
      },
      {
        long: 'Zalaegerszeg',
        short: 'ZE',
      },
    ],
  },
  {
    long: 'Iceland',
    short: 'IS',
    regions: [
      {
        long: 'Austurland',
        short: '7',
      },
      {
        long: 'Höfuðborgarsvæði',
        short: '1',
      },
      {
        long: 'Norðurland eystra',
        short: '6',
      },
      {
        long: 'Norðurland vestra',
        short: '5',
      },
      {
        long: 'Suðurland',
        short: '8',
      },
      {
        long: 'Suðurnes',
        short: '2',
      },
      {
        long: 'Vestfirðir',
        short: '4',
      },
      {
        long: 'Vesturland',
        short: '3',
      },
    ],
  },
  {
    long: 'India',
    short: 'IN',
    regions: [
      {
        long: 'Andaman and Nicobar Islands',
        short: 'AN',
      },
      {
        long: 'Andhra Pradesh',
        short: 'AP',
      },
      {
        long: 'Arunachal Pradesh',
        short: 'AR',
      },
      {
        long: 'Assam',
        short: 'AS',
      },
      {
        long: 'Bihar',
        short: 'BR',
      },
      {
        long: 'Chandigarh',
        short: 'CH',
      },
      {
        long: 'Chhattisgarh',
        short: 'CT',
      },
      {
        long: 'Dadra and Nagar Haveli and Daman and Diu',
        short: 'DH',
      },
      {
        long: 'Delhi',
        short: 'DL',
      },
      {
        long: 'Goa',
        short: 'GA',
      },
      {
        long: 'Gujarat',
        short: 'GJ',
      },
      {
        long: 'Haryana',
        short: 'HR',
      },
      {
        long: 'Himachal Pradesh',
        short: 'HP',
      },
      {
        long: 'Jammu and Kashmir',
        short: 'JK',
      },
      {
        long: 'Jharkhand',
        short: 'JH',
      },
      {
        long: 'Karnataka',
        short: 'KA',
      },
      {
        long: 'Kerala',
        short: 'KL',
      },
      {
        long: 'Ladakh',
        short: 'LA',
      },
      {
        long: 'Lakshadweep',
        short: 'LD',
      },
      {
        long: 'Madhya Pradesh',
        short: 'MP',
      },
      {
        long: 'Maharashtra',
        short: 'MH',
      },
      {
        long: 'Manipur',
        short: 'MN',
      },
      {
        long: 'Meghalaya',
        short: 'ML',
      },
      {
        long: 'Mizoram',
        short: 'MZ',
      },
      {
        long: 'Nagaland',
        short: 'NL',
      },
      {
        long: 'Odisha',
        short: 'OR',
      },
      {
        long: 'Puducherry',
        short: 'PY',
      },
      {
        long: 'Punjab',
        short: 'PB',
      },
      {
        long: 'Rajasthan',
        short: 'RJ',
      },
      {
        long: 'Sikkim',
        short: 'SK',
      },
      {
        long: 'Tamil Nadu',
        short: 'TN',
      },
      {
        long: 'Telangana',
        short: 'TG',
      },
      {
        long: 'Tripura',
        short: 'TR',
      },
      {
        long: 'Uttarakhand',
        short: 'UT',
      },
      {
        long: 'Uttar Pradesh',
        short: 'UP',
      },
      {
        long: 'West Bengal',
        short: 'WB',
      },
    ],
  },
  {
    long: 'Indonesia',
    short: 'ID',
    regions: [
      {
        long: 'Aceh',
        short: 'AC',
      },
      {
        long: 'Bali',
        short: 'BA',
      },
      {
        long: 'Bangka Belitung',
        short: 'BB',
      },
      {
        long: 'Banten',
        short: 'BT',
      },
      {
        long: 'Bengkulu',
        short: 'BE',
      },
      {
        long: 'Gorontalo',
        short: 'GO',
      },
      {
        long: 'Jakarta Raya',
        short: 'JK',
      },
      {
        long: 'Jambi',
        short: 'JA',
      },
      {
        long: 'Jawa Barat',
        short: 'JB',
      },
      {
        long: 'Jawa Tengah',
        short: 'JT',
      },
      {
        long: 'Jawa Timur',
        short: 'JI',
      },
      {
        long: 'Kalimantan Barat',
        short: 'KB',
      },
      {
        long: 'Kalimantan Selatan',
        short: 'KS',
      },
      {
        long: 'Kalimantan Tengah',
        short: 'KT',
      },
      {
        long: 'Kalimantan Timur',
        short: 'KI',
      },
      {
        long: 'Kalimantan Utara',
        short: 'KU',
      },
      {
        long: 'Kepulauan Riau',
        short: 'KR',
      },
      {
        long: 'Lampung',
        short: 'LA',
      },
      {
        long: 'Maluku',
        short: 'MA',
      },
      {
        long: 'Maluku Utara',
        short: 'MU',
      },
      {
        long: 'Nusa Tenggara Barat',
        short: 'NB',
      },
      {
        long: 'Nusa Tenggara Timur',
        short: 'NT',
      },
      {
        long: 'Papua',
        short: 'PA',
      },
      {
        long: 'Papua Barat',
        short: 'PB',
      },
      {
        long: 'Riau',
        short: 'RI',
      },
      {
        long: 'Sulawesi Selatan',
        short: 'SR',
      },
      {
        long: 'Sulawesi Tengah',
        short: 'ST',
      },
      {
        long: 'Sulawesi Tenggara',
        short: 'SG',
      },
      {
        long: 'Sulawesi Utara',
        short: 'SA',
      },
      {
        long: 'Sumatera Barat',
        short: 'SB',
      },
      {
        long: 'Sumatera Selatan',
        short: 'SS',
      },
      {
        long: 'Sumatera Utara',
        short: 'SU',
      },
      {
        long: 'Yogyakarta',
        short: 'YO',
      },
    ],
  },
  {
    long: 'Iran, Islamic Republic of',
    short: 'IR',
    regions: [
      {
        long: 'Alborz',
        short: '32',
      },
      {
        long: 'Ardabīl',
        short: '03',
      },
      {
        long: 'Āz̄arbāyjān-e Gharbī',
        short: '02',
      },
      {
        long: 'Āz̄arbāyjān-e Sharqī',
        short: '01',
      },
      {
        long: 'Būshehr',
        short: '06',
      },
      {
        long: 'Chahār Maḩāl va Bakhtīārī',
        short: '08',
      },
      {
        long: 'Eşfahān',
        short: '04',
      },
      {
        long: 'Fārs',
        short: '14',
      },
      {
        long: 'Gīlān',
        short: '19',
      },
      {
        long: 'Golestān',
        short: '27',
      },
      {
        long: 'Hamadān',
        short: '24',
      },
      {
        long: 'Hormozgān',
        short: '23',
      },
      {
        long: 'Īlām',
        short: '05',
      },
      {
        long: 'Kermān',
        short: '15',
      },
      {
        long: 'Kermānshāh',
        short: '17',
      },
      {
        long: 'Khorāsān-e Jonūbī',
        short: '29',
      },
      {
        long: 'Khorāsān-e Raẕavī',
        short: '30',
      },
      {
        long: 'Khorāsān-e Shomālī',
        short: '61',
      },
      {
        long: 'Khūzestān',
        short: '10',
      },
      {
        long: 'Kohgīlūyeh va Bowyer Aḩmad',
        short: '18',
      },
      {
        long: 'Kordestān',
        short: '16',
      },
      {
        long: 'Lorestān',
        short: '20',
      },
      {
        long: 'Markazi',
        short: '22',
      },
      {
        long: 'Māzandarān',
        short: '21',
      },
      {
        long: 'Qazvīn',
        short: '28',
      },
      {
        long: 'Qom',
        short: '26',
      },
      {
        long: 'Semnān',
        short: '12',
      },
      {
        long: 'Sīstān va Balūchestān',
        short: '13',
      },
      {
        long: 'Tehrān',
        short: '07',
      },
      {
        long: 'Yazd',
        short: '25',
      },
      {
        long: 'Zanjān',
        short: '11',
      },
    ],
  },
  {
    long: 'Iraq',
    short: 'IQ',
    regions: [
      {
        long: 'Al Anbār',
        short: 'AN',
      },
      {
        long: 'Al Başrah',
        short: 'BA',
      },
      {
        long: 'Al Muthanná',
        short: 'MU',
      },
      {
        long: 'Al Qādisīyah',
        short: 'QA',
      },
      {
        long: 'An Najaf',
        short: 'NA',
      },
      {
        long: 'Arbīl',
        short: 'AR',
      },
      {
        long: 'As Sulaymānīyah',
        short: 'SU',
      },
      {
        long: 'Bābil',
        short: 'BB',
      },
      {
        long: 'Baghdād',
        short: 'BG',
      },
      {
        long: 'Dohuk',
        short: 'DA',
      },
      {
        long: 'Dhī Qār',
        short: 'DQ',
      },
      {
        long: 'Diyālá',
        short: 'DI',
      },
      {
        long: "Karbalā'",
        short: 'KA',
      },
      {
        long: 'Kirkuk',
        short: 'KI',
      },
      {
        long: 'Maysān',
        short: 'MA',
      },
      {
        long: 'Nīnawá',
        short: 'NI',
      },
      {
        long: 'Şalāḩ ad Dīn',
        short: 'SD',
      },
      {
        long: 'Wāsiţ',
        short: 'WA',
      },
    ],
  },
  {
    long: 'Ireland',
    short: 'IE',
    regions: [
      {
        long: 'Carlow',
        short: 'CW',
      },
      {
        long: 'Cavan',
        short: 'CN',
      },
      {
        long: 'Clare',
        short: 'CE',
      },
      {
        long: 'Cork',
        short: 'CO',
      },
      {
        long: 'Donegal',
        short: 'DL',
      },
      {
        long: 'Dublin',
        short: 'D',
      },
      {
        long: 'Galway',
        short: 'G',
      },
      {
        long: 'Kerry',
        short: 'KY',
      },
      {
        long: 'Kildare',
        short: 'KE',
      },
      {
        long: 'Kilkenny',
        short: 'KK',
      },
      {
        long: 'Laois',
        short: 'LS',
      },
      {
        long: 'Leitrim',
        short: 'LM',
      },
      {
        long: 'Limerick',
        short: 'LK',
      },
      {
        long: 'Longford',
        short: 'LD',
      },
      {
        long: 'Louth',
        short: 'LH',
      },
      {
        long: 'Mayo',
        short: 'MO',
      },
      {
        long: 'Meath',
        short: 'MH',
      },
      {
        long: 'Monaghan',
        short: 'MN',
      },
      {
        long: 'Offaly',
        short: 'OY',
      },
      {
        long: 'Roscommon',
        short: 'RN',
      },
      {
        long: 'Sligo',
        short: 'SO',
      },
      {
        long: 'Tipperary',
        short: 'TA',
      },
      {
        long: 'Waterford',
        short: 'WD',
      },
      {
        long: 'Westmeath',
        short: 'WH',
      },
      {
        long: 'Wexford',
        short: 'WX',
      },
      {
        long: 'Wicklow',
        short: 'WW',
      },
    ],
  },
  {
    long: 'Isle of Man',
    short: 'IM',
    regions: [
      {
        long: 'Isle of Man',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Israel',
    short: 'IL',
    regions: [
      {
        long: 'HaDarom',
        short: 'D',
      },
      {
        long: 'HaMerkaz',
        short: 'M',
      },
      {
        long: 'HaTsafon',
        short: 'Z',
      },
      {
        long: 'H̱efa',
        short: 'HA',
      },
      {
        long: 'Tel-Aviv',
        short: 'TA',
      },
      {
        long: 'Yerushalayim',
        short: 'JM',
      },
    ],
  },
  {
    long: 'Italy',
    short: 'IT',
    regions: [
      {
        long: 'Abruzzo',
        short: '65',
      },
      {
        long: 'Basilicata',
        short: '77',
      },
      {
        long: 'Calabria',
        short: '78',
      },
      {
        long: 'Campania',
        short: '72',
      },
      {
        long: 'Emilia-Romagna',
        short: '45',
      },
      {
        long: 'Friuli-Venezia Giulia',
        short: '36',
      },
      {
        long: 'Lazio',
        short: '62',
      },
      {
        long: 'Liguria',
        short: '42',
      },
      {
        long: 'Lombardia',
        short: '25',
      },
      {
        long: 'Marche',
        short: '57',
      },
      {
        long: 'Molise',
        short: '67',
      },
      {
        long: 'Piemonte',
        short: '21',
      },
      {
        long: 'Puglia',
        short: '75',
      },
      {
        long: 'Sardegna',
        short: '88',
      },
      {
        long: 'Sicilia',
        short: '82',
      },
      {
        long: 'Toscana',
        short: '52',
      },
      {
        long: 'Trentino-Alto Adige',
        short: '32',
      },
      {
        long: 'Umbria',
        short: '55',
      },
      {
        long: "Valle d'Aosta",
        short: '23',
      },
      {
        long: 'Veneto',
        short: '34',
      },
    ],
  },
  {
    long: 'Jamaica',
    short: 'JM',
    regions: [
      {
        long: 'Clarendon',
        short: '13',
      },
      {
        long: 'Hanover',
        short: '09',
      },
      {
        long: 'Kingston',
        short: '01',
      },
      {
        long: 'Manchester',
        short: '12',
      },
      {
        long: 'Portland',
        short: '04',
      },
      {
        long: 'Saint Andrew',
        short: '02',
      },
      {
        long: 'Saint Ann',
        short: '06',
      },
      {
        long: 'Saint Catherine',
        short: '14',
      },
      {
        long: 'Saint Elizabeth',
        short: '11',
      },
      {
        long: 'Saint James',
        short: '08',
      },
      {
        long: 'Saint Mary',
        short: '05',
      },
      {
        long: 'Saint Thomas',
        short: '03',
      },
      {
        long: 'Trelawny',
        short: '07',
      },
      {
        long: 'Westmoreland',
        short: '10',
      },
    ],
  },
  {
    long: 'Japan',
    short: 'JP',
    regions: [
      {
        long: 'Aichi',
        short: '23',
      },
      {
        long: 'Akita',
        short: '05',
      },
      {
        long: 'Aomori',
        short: '02',
      },
      {
        long: 'Chiba',
        short: '12',
      },
      {
        long: 'Ehime',
        short: '38',
      },
      {
        long: 'Fukui',
        short: '18',
      },
      {
        long: 'Fukuoka',
        short: '40',
      },
      {
        long: 'Fukushima',
        short: '07',
      },
      {
        long: 'Gifu',
        short: '21',
      },
      {
        long: 'Gunma',
        short: '10',
      },
      {
        long: 'Hiroshima',
        short: '34',
      },
      {
        long: 'Hokkaido',
        short: '01',
      },
      {
        long: 'Hyogo',
        short: '28',
      },
      {
        long: 'Ibaraki',
        short: '08',
      },
      {
        long: 'Ishikawa',
        short: '17',
      },
      {
        long: 'Iwate',
        short: '03',
      },
      {
        long: 'Kagawa',
        short: '37',
      },
      {
        long: 'Kagoshima',
        short: '46',
      },
      {
        long: 'Kanagawa',
        short: '14',
      },
      {
        long: 'Kochi',
        short: '39',
      },
      {
        long: 'Kumamoto',
        short: '43',
      },
      {
        long: 'Kyoto',
        short: '26',
      },
      {
        long: 'Mie',
        short: '24',
      },
      {
        long: 'Miyagi',
        short: '04',
      },
      {
        long: 'Miyazaki',
        short: '45',
      },
      {
        long: 'Nagano',
        short: '20',
      },
      {
        long: 'Nagasaki',
        short: '42',
      },
      {
        long: 'Nara',
        short: '29',
      },
      {
        long: 'Niigata',
        short: '15',
      },
      {
        long: 'Oita',
        short: '44',
      },
      {
        long: 'Okayama',
        short: '33',
      },
      {
        long: 'Okinawa',
        short: '47',
      },
      {
        long: 'Osaka',
        short: '27',
      },
      {
        long: 'Saga',
        short: '41',
      },
      {
        long: 'Saitama',
        short: '11',
      },
      {
        long: 'Shiga',
        short: '25',
      },
      {
        long: 'Shimane',
        short: '32',
      },
      {
        long: 'Shizuoka',
        short: '22',
      },
      {
        long: 'Tochigi',
        short: '09',
      },
      {
        long: 'Tokushima',
        short: '36',
      },
      {
        long: 'Tokyo',
        short: '13',
      },
      {
        long: 'Tottori',
        short: '31',
      },
      {
        long: 'Toyama',
        short: '16',
      },
      {
        long: 'Wakayama',
        short: '30',
      },
      {
        long: 'Yamagata',
        short: '06',
      },
      {
        long: 'Yamaguchi',
        short: '35',
      },
      {
        long: 'Yamanashi',
        short: '19',
      },
    ],
  },
  {
    long: 'Jersey',
    short: 'JE',
    regions: [
      {
        long: 'Jersey',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Jordan',
    short: 'JO',
    regions: [
      {
        long: '‘Ajlūn',
        short: 'AJ',
      },
      {
        long: "Al 'Aqabah",
        short: 'AQ',
      },
      {
        long: 'Al Balqā’',
        short: 'BA',
      },
      {
        long: 'Al Karak',
        short: 'KA',
      },
      {
        long: 'Al Mafraq',
        short: 'MA',
      },
      {
        long: 'Al ‘A̅şimah',
        short: 'AM',
      },
      {
        long: 'Aţ Ţafīlah',
        short: 'AT',
      },
      {
        long: 'Az Zarqā’',
        short: 'AZ',
      },
      {
        long: 'Irbid',
        short: 'IR',
      },
      {
        long: 'Jarash',
        short: 'JA',
      },
      {
        long: 'Ma‘ān',
        short: 'MN',
      },
      {
        long: 'Mādabā',
        short: 'MD',
      },
    ],
  },
  {
    long: 'Kazakhstan',
    short: 'KZ',
    regions: [
      {
        long: 'Almaty',
        short: 'ALA',
      },
      {
        long: 'Aqmola',
        short: 'AKM',
      },
      {
        long: 'Aqtobe',
        short: 'AKT',
      },
      {
        long: 'Astana',
        short: 'AST',
      },
      {
        long: 'Atyrau',
        short: 'ATY',
      },
      {
        long: 'Batys Qazaqstan',
        short: 'ZAP',
      },
      {
        long: 'Bayqongyr',
        short: 'undefined',
      },
      {
        long: 'Mangghystau',
        short: 'MAN',
      },
      {
        long: 'Ongtustik Qazaqstan',
        short: 'YUZ',
      },
      {
        long: 'Pavlodar',
        short: 'PAV',
      },
      {
        long: 'Qaraghandy',
        short: 'KAR',
      },
      {
        long: 'Qostanay',
        short: 'KUS',
      },
      {
        long: 'Qyzylorda',
        short: 'KZY',
      },
      {
        long: 'Shyghys Qazaqstan',
        short: 'VOS',
      },
      {
        long: 'Soltustik Qazaqstan',
        short: 'SEV',
      },
      {
        long: 'Zhambyl',
        short: 'ZHA',
      },
    ],
  },
  {
    long: 'Kenya',
    short: 'KE',
    regions: [
      {
        long: 'Baringo',
        short: '01',
      },
      {
        long: 'Bomet',
        short: '02',
      },
      {
        long: 'Bungoma',
        short: '03',
      },
      {
        long: 'Busia',
        short: '04',
      },
      {
        long: 'Eleyo/Marakwet',
        short: '05',
      },
      {
        long: 'Embu',
        short: '06',
      },
      {
        long: 'Garissa',
        short: '07',
      },
      {
        long: 'Homa Bay',
        short: '08',
      },
      {
        long: 'Isiolo',
        short: '09',
      },
      {
        long: 'Kajiado',
        short: '10',
      },
      {
        long: 'Kakamega',
        short: '11',
      },
      {
        long: 'Kericho',
        short: '12',
      },
      {
        long: 'Kiambu',
        short: '13',
      },
      {
        long: 'Kilifi',
        short: '14',
      },
      {
        long: 'Kirinyaga',
        short: '15',
      },
      {
        long: 'Kisii',
        short: '16',
      },
      {
        long: 'Kisumu',
        short: '17',
      },
      {
        long: 'Kitui',
        short: '18',
      },
      {
        long: 'Kwale',
        short: '19',
      },
      {
        long: 'Laikipia',
        short: '20',
      },
      {
        long: 'Lamu',
        short: '21',
      },
      {
        long: 'Machakos',
        short: '22',
      },
      {
        long: 'Makueni',
        short: '23',
      },
      {
        long: 'Mandera',
        short: '24',
      },
      {
        long: 'Marsabit',
        short: '25',
      },
      {
        long: 'Meru',
        short: '26',
      },
      {
        long: 'Migori',
        short: '27',
      },
      {
        long: 'Mombasa',
        short: '28',
      },
      {
        long: "Murang'a",
        short: '29',
      },
      {
        long: 'Nairobi City',
        short: '30',
      },
      {
        long: 'Nakuru',
        short: '31',
      },
      {
        long: 'Nandi',
        short: '32',
      },
      {
        long: 'Narok',
        short: '33',
      },
      {
        long: 'Nyamira',
        short: '34',
      },
      {
        long: 'Nyandarua',
        short: '35',
      },
      {
        long: 'Nyeri',
        short: '36',
      },
      {
        long: 'Samburu',
        short: '37',
      },
      {
        long: 'Siaya',
        short: '38',
      },
      {
        long: 'Taita/Taveta',
        short: '39',
      },
      {
        long: 'Tana River',
        short: '40',
      },
      {
        long: 'Tharaka-Nithi',
        short: '41',
      },
      {
        long: 'Trans Nzoia',
        short: '42',
      },
      {
        long: 'Turkana',
        short: '43',
      },
      {
        long: 'Uasin Gishu',
        short: '44',
      },
      {
        long: 'Vihiga',
        short: '45',
      },
      {
        long: 'Wajir',
        short: '46',
      },
      {
        long: 'West Pokot',
        short: '47',
      },
    ],
  },
  {
    long: 'Kiribati',
    short: 'KI',
    regions: [
      {
        long: 'Abaiang',
        short: 'undefined',
      },
      {
        long: 'Abemama',
        short: 'undefined',
      },
      {
        long: 'Aranuka',
        short: 'undefined',
      },
      {
        long: 'Arorae',
        short: 'undefined',
      },
      {
        long: 'Banaba',
        short: 'undefined',
      },
      {
        long: 'Beru',
        short: 'undefined',
      },
      {
        long: 'Butaritari',
        short: 'undefined',
      },
      {
        long: 'Central Gilberts',
        short: 'undefined',
      },
      {
        long: 'Gilbert Islands',
        short: 'G',
      },
      {
        long: 'Kanton',
        short: 'undefined',
      },
      {
        long: 'Kiritimati',
        short: 'undefined',
      },
      {
        long: 'Kuria',
        short: 'undefined',
      },
      {
        long: 'Line Islands',
        short: 'L',
      },
      {
        long: 'Maiana',
        short: 'undefined',
      },
      {
        long: 'Makin',
        short: 'undefined',
      },
      {
        long: 'Marakei',
        short: 'undefined',
      },
      {
        long: 'Nikunau',
        short: 'undefined',
      },
      {
        long: 'Nonouti',
        short: 'undefined',
      },
      {
        long: 'Northern Gilberts',
        short: 'undefined',
      },
      {
        long: 'Onotoa',
        short: 'undefined',
      },
      {
        long: 'Phoenix Islands',
        short: 'P',
      },
      {
        long: 'Southern Gilberts',
        short: 'undefined',
      },
      {
        long: 'Tabiteuea',
        short: 'undefined',
      },
      {
        long: 'Tabuaeran',
        short: 'undefined',
      },
      {
        long: 'Tamana',
        short: 'undefined',
      },
      {
        long: 'Tarawa',
        short: 'undefined',
      },
      {
        long: 'Teraina',
        short: 'undefined',
      },
    ],
  },
  {
    long: "Korea, Democratic People's Republic of",
    short: 'KP',
    regions: [
      {
        long: 'Chagang-do (Chagang Province)',
        short: '04',
      },
      {
        long: 'Hamgyong-bukto (North Hamgyong Province)',
        short: '09',
      },
      {
        long: 'Hamgyong-namdo (South Hamgyong Province)',
        short: '08',
      },
      {
        long: 'Hwanghae-bukto (North Hwanghae Province)',
        short: '06',
      },
      {
        long: 'Hwanghae-namdo (South Hwanghae Province)',
        short: '05',
      },
      {
        long: 'Kangwon-do (Kangwon Province)',
        short: '07',
      },
      {
        long: 'Nasŏn (Najin-Sŏnbong)',
        short: '13',
      },
      {
        long: "P'yongan-bukto (North P'yongan Province)",
        short: '03',
      },
      {
        long: "P'yongan-namdo (South P'yongan Province)",
        short: '02',
      },
      {
        long: "P'yongyang-si (P'yongyang City)",
        short: '01',
      },
      {
        long: 'Yanggang-do (Yanggang Province)',
        short: '10',
      },
    ],
  },
  {
    long: 'Korea, Republic of',
    short: 'KR',
    regions: [
      {
        long: "Ch'ungch'ongbuk-do",
        short: '43',
      },
      {
        long: "Ch'ungch'ongnam-do",
        short: '44',
      },
      {
        long: 'Cheju-do',
        short: '49',
      },
      {
        long: 'Chollabuk-do',
        short: '45',
      },
      {
        long: 'Chollanam-do',
        short: '46',
      },
      {
        long: "Inch'on-Kwangyokhi",
        short: '28',
      },
      {
        long: 'Kang-won-do',
        short: '42',
      },
      {
        long: 'Kwangju-Kwangyokshi',
        short: '29',
      },
      {
        long: 'Kyonggi-do',
        short: '41',
      },
      {
        long: 'Kyongsangbuk-do',
        short: '47',
      },
      {
        long: 'Kyongsangnam-do',
        short: '48',
      },
      {
        long: 'Pusan-Kwangyokshi',
        short: '26',
      },
      {
        long: "Seoul-T'ukpyolshi",
        short: '11',
      },
      {
        long: 'Sejong',
        short: '50',
      },
      {
        long: 'Taegu-Kwangyokshi',
        short: '27',
      },
      {
        long: 'Taejon-Kwangyokshi',
        short: '30',
      },
      {
        long: 'Ulsan-Kwangyokshi',
        short: '31',
      },
    ],
  },
  {
    long: 'Kosovo',
    short: 'XK',
    regions: [
      {
        long: 'Farizaj',
        short: 'FZ',
      },
      {
        long: 'Gjakova',
        short: 'GK',
      },
      {
        long: 'Gjilan',
        short: 'GL',
      },
      {
        long: 'Mitrovica',
        short: 'MI',
      },
      {
        long: 'Peja/Peć',
        short: 'PE',
      },
      {
        long: 'Pristina',
        short: 'PR',
      },
      {
        long: 'Prizren',
        short: 'PZ',
      },
    ],
  },
  {
    long: 'Kuwait',
    short: 'KW',
    regions: [
      {
        long: 'Al Aḩmadi',
        short: 'AH',
      },
      {
        long: 'Al Farwānīyah',
        short: 'FA',
      },
      {
        long: 'Al Jahrā’',
        short: 'JA',
      },
      {
        long: 'Al ‘Āşimah',
        short: 'KU',
      },
      {
        long: 'Ḩawallī',
        short: 'HA',
      },
      {
        long: 'Mubārak al Kabir',
        short: 'MU',
      },
    ],
  },
  {
    long: 'Kyrgyzstan',
    short: 'KG',
    regions: [
      {
        long: 'Batken Oblasty',
        short: 'B',
      },
      {
        long: 'Bishkek Shaary',
        short: 'GB',
      },
      {
        long: 'Chuy Oblasty (Bishkek)',
        short: 'C',
      },
      {
        long: 'Jalal-Abad Oblasty',
        short: 'J',
      },
      {
        long: 'Naryn Oblasty',
        short: 'N',
      },
      {
        long: 'Osh Oblasty',
        short: 'O',
      },
      {
        long: 'Talas Oblasty',
        short: 'T',
      },
      {
        long: 'Ysyk-Kol Oblasty (Karakol)',
        short: 'Y',
      },
    ],
  },
  {
    long: 'Laos',
    short: 'LA',
    regions: [
      {
        long: 'Attapu',
        short: 'AT',
      },
      {
        long: 'Bokèo',
        short: 'BK',
      },
      {
        long: 'Bolikhamxai',
        short: 'BL',
      },
      {
        long: 'Champasak',
        short: 'CH',
      },
      {
        long: 'Houaphan',
        short: 'HO',
      },
      {
        long: 'Khammouan',
        short: 'KH',
      },
      {
        long: 'Louang Namtha',
        short: 'LM',
      },
      {
        long: 'Louangphabang',
        short: 'LP',
      },
      {
        long: 'Oudômxai',
        short: 'OU',
      },
      {
        long: 'Phôngsali',
        short: 'PH',
      },
      {
        long: 'Salavan',
        short: 'SL',
      },
      {
        long: 'Savannakhét',
        short: 'SV',
      },
      {
        long: 'Vientiane',
        short: 'VI',
      },
      {
        long: 'Xaignabouli',
        short: 'XA',
      },
      {
        long: 'Xékong',
        short: 'XE',
      },
      {
        long: 'Xaisomboun',
        short: 'XS',
      },
      {
        long: 'Xiangkhouang',
        short: 'XI',
      },
    ],
  },
  {
    long: 'Latvia',
    short: 'LV',
    regions: [
      {
        long: 'Aglona',
        short: '001',
      },
      {
        long: 'Aizkraukle',
        short: '002',
      },
      {
        long: 'Aizpute',
        short: '003',
      },
      {
        long: 'Aknīste',
        short: '004',
      },
      {
        long: 'Aloja',
        short: '005',
      },
      {
        long: 'Alsunga',
        short: '06',
      },
      {
        long: 'Alūksne',
        short: '007',
      },
      {
        long: 'Amata',
        short: '008',
      },
      {
        long: 'Ape',
        short: '009',
      },
      {
        long: 'Auce',
        short: '010',
      },
      {
        long: 'Ādaži',
        short: '011',
      },
      {
        long: 'Babīte',
        short: '012',
      },
      {
        long: 'Baldone',
        short: '013',
      },
      {
        long: 'Baltinava',
        short: '014',
      },
      {
        long: 'Balvi',
        short: '015',
      },
      {
        long: 'Bauska',
        short: '016',
      },
      {
        long: 'Beverīna',
        short: '017',
      },
      {
        long: 'Brocēni',
        short: '018',
      },
      {
        long: 'Burtnieki',
        short: '019',
      },
      {
        long: 'Carnikava',
        short: '020',
      },
      {
        long: 'Cesvaine',
        short: '021',
      },
      {
        long: 'Cēsis',
        short: '022',
      },
      {
        long: 'Cibla',
        short: '023',
      },
      {
        long: 'Dagda',
        short: '024',
      },
      {
        long: 'Daugavpils',
        short: '025',
      },
      {
        long: 'Daugavpils (City)',
        short: 'DGV',
      },
      {
        long: 'Dobele',
        short: '026',
      },
      {
        long: 'Dundaga',
        short: '027',
      },
      {
        long: 'Durbe',
        short: '028',
      },
      {
        long: 'Engure',
        short: '029',
      },
      {
        long: 'Ērgļi',
        short: '030',
      },
      {
        long: 'Garkalne',
        short: '031',
      },
      {
        long: 'Grobiņa',
        short: '032',
      },
      {
        long: 'Gulbene',
        short: '033',
      },
      {
        long: 'Iecava',
        short: '034',
      },
      {
        long: 'Ikšķile',
        short: '035',
      },
      {
        long: 'Ilūkste',
        short: '036',
      },
      {
        long: 'Inčukalns',
        short: '037',
      },
      {
        long: 'Jaunjelgava',
        short: '038',
      },
      {
        long: 'Jaunpiebalga',
        short: '039',
      },
      {
        long: 'Jaunpils',
        short: '040',
      },
      {
        long: 'Jelgava',
        short: '041',
      },
      {
        long: 'Jelgava (City)',
        short: 'JEL',
      },
      {
        long: 'Jēkabpils',
        short: '042',
      },
      {
        long: 'Jēkabpils (City)',
        short: 'JKB',
      },
      {
        long: 'Jūrmala (City)',
        short: 'JUR',
      },
      {
        long: 'Kandava',
        short: '043',
      },
      {
        long: 'Kārsava',
        short: '044',
      },
      {
        long: 'Kocēni',
        short: '045',
      },
      {
        long: 'Koknese',
        short: '046',
      },
      {
        long: 'Krāslava',
        short: '047',
      },
      {
        long: 'Krimulda',
        short: '048',
      },
      {
        long: 'Krustpils',
        short: '049',
      },
      {
        long: 'Kuldīga',
        short: '050',
      },
      {
        long: 'Ķegums',
        short: '051',
      },
      {
        long: 'Ķekava',
        short: '052',
      },
      {
        long: 'Lielvārde',
        short: '053',
      },
      {
        long: 'Liepāja',
        short: 'LPX',
      },
      {
        long: 'Limbaži',
        short: '054',
      },
      {
        long: 'Līgatne',
        short: '055',
      },
      {
        long: 'Līvāni',
        short: '056',
      },
      {
        long: 'Lubāna',
        short: '057',
      },
      {
        long: 'Ludza',
        short: '058',
      },
      {
        long: 'Madona',
        short: '059',
      },
      {
        long: 'Mazsalaca',
        short: '060',
      },
      {
        long: 'Mālpils',
        short: '061',
      },
      {
        long: 'Mārupe',
        short: '062',
      },
      {
        long: 'Mērsrags',
        short: '063',
      },
      {
        long: 'Naukšēni',
        short: '064',
      },
      {
        long: 'Nereta',
        short: '065',
      },
      {
        long: 'Nīca',
        short: '066',
      },
      {
        long: 'Ogre',
        short: '067',
      },
      {
        long: 'Olaine',
        short: '068',
      },
      {
        long: 'Ozolnieki',
        short: '069',
      },
      {
        long: 'Pārgauja',
        short: '070',
      },
      {
        long: 'Pāvilosta',
        short: '071',
      },
      {
        long: 'Pļaviņas',
        short: '072',
      },
      {
        long: 'Preiļi',
        short: '073',
      },
      {
        long: 'Priekule',
        short: '074',
      },
      {
        long: 'Priekuļi',
        short: '075',
      },
      {
        long: 'Rauna',
        short: '076',
      },
      {
        long: 'Rēzekne',
        short: '077',
      },
      {
        long: 'Rēzekne (City)',
        short: 'REZ',
      },
      {
        long: 'Riebiņi',
        short: '078',
      },
      {
        long: 'Rīga',
        short: 'RIX',
      },
      {
        long: 'Roja',
        short: '079',
      },
      {
        long: 'Ropaži',
        short: '080',
      },
      {
        long: 'Rucava',
        short: '081',
      },
      {
        long: 'Rugāji',
        short: '082',
      },
      {
        long: 'Rundāle',
        short: '083',
      },
      {
        long: 'Rūjiena',
        short: '084',
      },
      {
        long: 'Sala',
        short: '085',
      },
      {
        long: 'Salacgrīva',
        short: '086',
      },
      {
        long: 'Salaspils',
        short: '087',
      },
      {
        long: 'Saldus',
        short: '088',
      },
      {
        long: 'Saulkrasti',
        short: '089',
      },
      {
        long: 'Sēja',
        short: '090',
      },
      {
        long: 'Sigulda',
        short: '091',
      },
      {
        long: 'Skrīveri',
        short: '092',
      },
      {
        long: 'Skrunda',
        short: '093',
      },
      {
        long: 'Smiltene',
        short: '094',
      },
      {
        long: 'Stopiņi',
        short: '095',
      },
      {
        long: 'Strenči',
        short: '096',
      },
      {
        long: 'Talsi',
        short: '097',
      },
      {
        long: 'Tērvete',
        short: '098',
      },
      {
        long: 'Tukums',
        short: '099',
      },
      {
        long: 'Vaiņode',
        short: '100',
      },
      {
        long: 'Valka',
        short: '101',
      },
      {
        long: 'Valmiera',
        short: 'VMR',
      },
      {
        long: 'Varakļāni',
        short: '102',
      },
      {
        long: 'Vārkava',
        short: '103',
      },
      {
        long: 'Vecpiebalga',
        short: '104',
      },
      {
        long: 'Vecumnieki',
        short: '105',
      },
      {
        long: 'Ventspils',
        short: '106',
      },
      {
        long: 'Ventspils (City)',
        short: 'VEN',
      },
      {
        long: 'Viesīte',
        short: '107',
      },
      {
        long: 'Viļaka',
        short: '108',
      },
      {
        long: 'Viļāni',
        short: '109',
      },
      {
        long: 'Zilupe',
        short: '110',
      },
    ],
  },
  {
    long: 'Lebanon',
    short: 'LB',
    regions: [
      {
        long: 'Aakkâr',
        short: 'AK',
      },
      {
        long: 'Baalbelk-Hermel',
        short: 'BH',
      },
      {
        long: 'Béqaa',
        short: 'BI',
      },
      {
        long: 'Beyrouth',
        short: 'BA',
      },
      {
        long: 'Liban-Nord',
        short: 'AS',
      },
      {
        long: 'Liban-Sud',
        short: 'JA',
      },
      {
        long: 'Mont-Liban',
        short: 'JL',
      },
      {
        long: 'Nabatîyé',
        short: 'NA',
      },
    ],
  },
  {
    long: 'Lesotho',
    short: 'LS',
    regions: [
      {
        long: 'Berea',
        short: 'D',
      },
      {
        long: 'Butha-Buthe',
        short: 'B',
      },
      {
        long: 'Leribe',
        short: 'C',
      },
      {
        long: 'Mafeteng',
        short: 'E',
      },
      {
        long: 'Maseru',
        short: 'A',
      },
      {
        long: 'Mohales Hoek',
        short: 'F',
      },
      {
        long: 'Mokhotlong',
        short: 'J',
      },
      {
        long: "Qacha's Nek",
        short: 'H',
      },
      {
        long: 'Quthing',
        short: 'G',
      },
      {
        long: 'Thaba-Tseka',
        short: 'K',
      },
    ],
  },
  {
    long: 'Liberia',
    short: 'LR',
    regions: [
      {
        long: 'Bomi',
        short: 'BM',
      },
      {
        long: 'Bong',
        short: 'BG',
      },
      {
        long: 'Gbarpolu',
        short: 'GP',
      },
      {
        long: 'Grand Bassa',
        short: 'GB',
      },
      {
        long: 'Grand Cape Mount',
        short: 'CM',
      },
      {
        long: 'Grand Gedeh',
        short: 'GG',
      },
      {
        long: 'Grand Kru',
        short: 'GK',
      },
      {
        long: 'Lofa',
        short: 'LO',
      },
      {
        long: 'Margibi',
        short: 'MG',
      },
      {
        long: 'Maryland',
        short: 'MY',
      },
      {
        long: 'Montserrado',
        short: 'MO',
      },
      {
        long: 'Nimba',
        short: 'NI',
      },
      {
        long: 'River Cess',
        short: 'RI',
      },
      {
        long: 'River Geee',
        short: 'RG',
      },
      {
        long: 'Sinoe',
        short: 'SI',
      },
    ],
  },
  {
    long: 'Libya',
    short: 'LY',
    regions: [
      {
        long: 'Al Buţnān',
        short: 'BU',
      },
      {
        long: 'Al Jabal al Akhḑar',
        short: 'JA',
      },
      {
        long: 'Al Jabal al Gharbī',
        short: 'JG',
      },
      {
        long: 'Al Jafārah',
        short: 'JI',
      },
      {
        long: 'Al Jufrah',
        short: 'JU',
      },
      {
        long: 'Al Kufrah',
        short: 'FK',
      },
      {
        long: 'Al Marj',
        short: 'MJ',
      },
      {
        long: 'Al Marquab',
        short: 'MB',
      },
      {
        long: 'Al Wāḩāt',
        short: 'WA',
      },
      {
        long: 'An Nuqaţ al Khams',
        short: 'NQ',
      },
      {
        long: 'Az Zāwiyah',
        short: 'ZA',
      },
      {
        long: 'Banghāzī',
        short: 'BA',
      },
      {
        long: 'Darnah',
        short: 'DR',
      },
      {
        long: 'Ghāt',
        short: 'GH',
      },
      {
        long: 'Mişrātah',
        short: 'MI',
      },
      {
        long: 'Murzuq',
        short: 'MQ',
      },
      {
        long: 'Nālūt',
        short: 'NL',
      },
      {
        long: 'Sabhā',
        short: 'SB',
      },
      {
        long: 'Surt',
        short: 'SR',
      },
      {
        long: 'Ţarābulus',
        short: 'TB',
      },
      {
        long: 'Yafran',
        short: 'WD',
      },
      {
        long: 'Wādī ash Shāţiʾ',
        short: 'WS',
      },
    ],
  },
  {
    long: 'Liechtenstein',
    short: 'LI',
    regions: [
      {
        long: 'Balzers',
        short: '01',
      },
      {
        long: 'Eschen',
        short: '02',
      },
      {
        long: 'Gamprin',
        short: '03',
      },
      {
        long: 'Mauren',
        short: '04',
      },
      {
        long: 'Planken',
        short: '05',
      },
      {
        long: 'Ruggell',
        short: '06',
      },
      {
        long: 'Schaan',
        short: '07',
      },
      {
        long: 'Schellenberg',
        short: '08',
      },
      {
        long: 'Triesen',
        short: '09',
      },
      {
        long: 'Triesenberg',
        short: '10',
      },
      {
        long: 'Vaduz',
        short: '11',
      },
    ],
  },
  {
    long: 'Lithuania',
    short: 'LT',
    regions: [
      {
        long: 'Alytaus',
        short: 'AL',
      },
      {
        long: 'Kauno',
        short: 'KU',
      },
      {
        long: 'Klaipėdos',
        short: 'KL',
      },
      {
        long: 'Marijampolės',
        short: 'MR',
      },
      {
        long: 'Panevėžio',
        short: 'PN',
      },
      {
        long: 'Šiaulių',
        short: 'SA',
      },
      {
        long: 'Tauragės',
        short: 'TA',
      },
      {
        long: 'Telšių',
        short: 'TE',
      },
      {
        long: 'Utenos',
        short: 'UT',
      },
      {
        long: 'Vilniaus',
        short: 'VL',
      },
    ],
  },
  {
    long: 'Luxembourg',
    short: 'LU',
    regions: [
      {
        long: 'Capellen',
        short: 'CA',
      },
      {
        long: 'Clevaux',
        short: 'CL',
      },
      {
        long: 'Diekirch',
        short: 'DI',
      },
      {
        long: 'Echternach',
        short: 'EC',
      },
      {
        long: 'Esch-sur-Alzette',
        short: 'ES',
      },
      {
        long: 'Grevenmacher',
        short: 'GR',
      },
      {
        long: 'Luxembourg',
        short: 'LU',
      },
      {
        long: 'Mersch',
        short: 'ME',
      },
      {
        long: 'Redange',
        short: 'RD',
      },
      {
        long: 'Remich',
        short: 'RM',
      },
      {
        long: 'Vianden',
        short: 'VD',
      },
      {
        long: 'Wiltz',
        short: 'WI',
      },
    ],
  },
  {
    long: 'Macao',
    short: 'MO',
    regions: [
      {
        long: 'Macao',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Macedonia, Republic of',
    short: 'MK',
    regions: [
      {
        long: 'Aračinovo',
        short: '02',
      },
      {
        long: 'Berovo',
        short: '03',
      },
      {
        long: 'Bitola',
        short: '04',
      },
      {
        long: 'Bogdanci',
        short: '05',
      },
      {
        long: 'Bogovinje',
        short: '06',
      },
      {
        long: 'Bosilovo',
        short: '07',
      },
      {
        long: 'Brvenica',
        short: '08',
      },
      {
        long: 'Centar Župa',
        short: '78',
      },
      {
        long: 'Čaška',
        short: '80',
      },
      {
        long: 'Češinovo-Obleševo',
        short: '81',
      },
      {
        long: 'Čučer Sandevo',
        short: '82',
      },
      {
        long: 'Debar',
        short: '21',
      },
      {
        long: 'Debarca',
        short: '22',
      },
      {
        long: 'Delčevo',
        short: '23',
      },
      {
        long: 'Demir Hisar',
        short: '25',
      },
      {
        long: 'Demir Kapija',
        short: '24',
      },
      {
        long: 'Doran',
        short: '26',
      },
      {
        long: 'Dolneni',
        short: '27',
      },
      {
        long: 'Gevgelija',
        short: '18',
      },
      {
        long: 'Gostivar',
        short: '19',
      },
      {
        long: 'Gradsko',
        short: '20',
      },
      {
        long: 'Ilinden',
        short: '34',
      },
      {
        long: 'Jegunovce',
        short: '35',
      },
      {
        long: 'Karbinci',
        short: '37',
      },
      {
        long: 'Kavadarci',
        short: '36',
      },
      {
        long: 'Kičevo',
        short: '40',
      },
      {
        long: 'Kočani',
        short: '42',
      },
      {
        long: 'Konče',
        short: '41',
      },
      {
        long: 'Kratovo',
        short: '43',
      },
      {
        long: 'Kriva Palanka',
        short: '44',
      },
      {
        long: 'Krivogaštani',
        short: '45',
      },
      {
        long: 'Kruševo',
        short: '46',
      },
      {
        long: 'Kumanovo',
        short: '47',
      },
      {
        long: 'Lipkovo',
        short: '48',
      },
      {
        long: 'Lozovo',
        short: '49',
      },
      {
        long: 'Makedonska Kamenica',
        short: '51',
      },
      {
        long: 'Makedonski Brod',
        short: '52',
      },
      {
        long: 'Mavrovo i Rostuša',
        short: '50',
      },
      {
        long: 'Mogila',
        short: '53',
      },
      {
        long: 'Negotino',
        short: '54',
      },
      {
        long: 'Novaci',
        short: '55',
      },
      {
        long: 'Novo Selo',
        short: '56',
      },
      {
        long: 'Ohrid',
        short: '58',
      },
      {
        long: 'Pehčevo',
        short: '60',
      },
      {
        long: 'Petrovec',
        short: '59',
      },
      {
        long: 'Plasnica',
        short: '61',
      },
      {
        long: 'Prilep',
        short: '62',
      },
      {
        long: 'Probištip',
        short: '63',
      },
      {
        long: 'Radoviš',
        short: '',
      },
      {
        long: 'Rankovce',
        short: '65',
      },
      {
        long: 'Resen',
        short: '66',
      },
      {
        long: 'Rosoman',
        short: '67',
      },
      {
        long: 'Skopje',
        short: '85',
      },
      {
        long: 'Sopište',
        short: '70',
      },
      {
        long: 'Staro Nagoričane',
        short: '71',
      },
      {
        long: 'Struga',
        short: '72',
      },
      {
        long: 'Strumica',
        short: '73',
      },
      {
        long: 'Studeničani',
        short: '74',
      },
      {
        long: 'Sveti Nikole',
        short: '69',
      },
      {
        long: 'Štip',
        short: '83',
      },
      {
        long: 'Tearce',
        short: '75',
      },
      {
        long: 'Tetovo',
        short: '76',
      },
      {
        long: 'Valandovo',
        short: '10',
      },
      {
        long: 'Vasilevo',
        short: '11',
      },
      {
        long: 'Veles',
        short: '13',
      },
      {
        long: 'Vevčani',
        short: '12',
      },
      {
        long: 'Vinica',
        short: '14',
      },
      {
        long: 'Vrapčište',
        short: '16',
      },
      {
        long: 'Zelenikovo',
        short: '32',
      },
      {
        long: 'Zrnovci',
        short: '33',
      },
      {
        long: 'Želino',
        short: '30',
      },
    ],
  },
  {
    long: 'Madagascar',
    short: 'MG',
    regions: [
      {
        long: 'Antananarivo',
        short: 'T',
      },
      {
        long: 'Antsiranana',
        short: 'D',
      },
      {
        long: 'Fianarantsoa',
        short: 'F',
      },
      {
        long: 'Mahajanga',
        short: 'M',
      },
      {
        long: 'Toamasina',
        short: 'A',
      },
      {
        long: 'Toliara',
        short: 'U',
      },
    ],
  },
  {
    long: 'Malawi',
    short: 'MW',
    regions: [
      {
        long: 'Balaka',
        short: 'BA',
      },
      {
        long: 'Blantyre',
        short: 'BL',
      },
      {
        long: 'Chikwawa',
        short: 'CK',
      },
      {
        long: 'Chiradzulu',
        short: 'CR',
      },
      {
        long: 'Chitipa',
        short: 'CT',
      },
      {
        long: 'Dedza',
        short: 'DE',
      },
      {
        long: 'Dowa',
        short: 'DO',
      },
      {
        long: 'Karonga',
        short: 'KR',
      },
      {
        long: 'Kasungu',
        short: 'KS',
      },
      {
        long: 'Likoma',
        short: 'LK',
      },
      {
        long: 'Lilongwe',
        short: 'LI',
      },
      {
        long: 'Machinga',
        short: 'MH',
      },
      {
        long: 'Mangochi',
        short: 'MG',
      },
      {
        long: 'Mchinji',
        short: 'MC',
      },
      {
        long: 'Mulanje',
        short: 'MU',
      },
      {
        long: 'Mwanza',
        short: 'MW',
      },
      {
        long: 'Mzimba',
        short: 'MZ',
      },
      {
        long: 'Nkhata Bay',
        short: 'NE',
      },
      {
        long: 'Nkhotakota',
        short: 'NB',
      },
      {
        long: 'Nsanje',
        short: 'NS',
      },
      {
        long: 'Ntcheu',
        short: 'NU',
      },
      {
        long: 'Ntchisi',
        short: 'NI',
      },
      {
        long: 'Phalombe',
        short: 'PH',
      },
      {
        long: 'Rumphi',
        short: 'RU',
      },
      {
        long: 'Salima',
        short: 'SA',
      },
      {
        long: 'Thyolo',
        short: 'TH',
      },
      {
        long: 'Zomba',
        short: 'ZO',
      },
    ],
  },
  {
    long: 'Malaysia',
    short: 'MY',
    regions: [
      {
        long: 'Johor',
        short: '01',
      },
      {
        long: 'Kedah',
        short: '02',
      },
      {
        long: 'Kelantan',
        short: '03',
      },
      {
        long: 'Melaka',
        short: '04',
      },
      {
        long: 'Negeri Sembilan',
        short: '05',
      },
      {
        long: 'Pahang',
        short: '06',
      },
      {
        long: 'Perak',
        short: '08',
      },
      {
        long: 'Perlis',
        short: '09',
      },
      {
        long: 'Pulau Pinang',
        short: '07',
      },
      {
        long: 'Sabah',
        short: '12',
      },
      {
        long: 'Sarawak',
        short: '13',
      },
      {
        long: 'Selangor',
        short: '10',
      },
      {
        long: 'Terengganu',
        short: '11',
      },
      {
        long: 'Wilayah Persekutuan (Kuala Lumpur)',
        short: '14',
      },
      {
        long: 'Wilayah Persekutuan (Labuan)',
        short: '15',
      },
      {
        long: 'Wilayah Persekutuan (Putrajaya)',
        short: '16',
      },
    ],
  },
  {
    long: 'Maldives',
    short: 'MV',
    regions: [
      {
        long: 'Alifu Alifu',
        short: '02',
      },
      {
        long: 'Alifu Dhaalu',
        short: '00',
      },
      {
        long: 'Baa',
        short: '20',
      },
      {
        long: 'Dhaalu',
        short: '17',
      },
      {
        long: 'Faafu',
        short: '14',
      },
      {
        long: 'Gaafu Alifu',
        short: '27',
      },
      {
        long: 'Gaafu Dhaalu',
        short: '28',
      },
      {
        long: 'Gnaviyani',
        short: '29',
      },
      {
        long: 'Haa Alifu',
        short: '07',
      },
      {
        long: 'Haa Dhaalu',
        short: '23',
      },
      {
        long: 'Kaafu',
        short: '26',
      },
      {
        long: 'Laamu',
        short: '05',
      },
      {
        long: 'Lhaviyani',
        short: '03',
      },
      {
        long: 'Malé',
        short: 'MLE',
      },
      {
        long: 'Meemu',
        short: '12',
      },
      {
        long: 'Noonu',
        short: '25',
      },
      {
        long: 'Raa',
        short: '13',
      },
      {
        long: 'Seenu',
        short: '01',
      },
      {
        long: 'Shaviyani',
        short: '24',
      },
      {
        long: 'Thaa',
        short: '08',
      },
      {
        long: 'Vaavu',
        short: '04',
      },
    ],
  },
  {
    long: 'Mali',
    short: 'ML',
    regions: [
      {
        long: 'Bamako',
        short: 'BKO',
      },
      {
        long: 'Gao',
        short: '7',
      },
      {
        long: 'Kayes',
        short: '1',
      },
      {
        long: 'Kidal',
        short: '8',
      },
      {
        long: 'Koulikoro',
        short: '2',
      },
      {
        long: 'Mopti',
        short: '5',
      },
      {
        long: 'Segou',
        short: '4',
      },
      {
        long: 'Sikasso',
        short: '3',
      },
      {
        long: 'Tombouctou',
        short: '6',
      },
      {
        long: 'Taoudénit',
        short: '9',
      },
      {
        long: 'Ménaka',
        short: '10',
      },
    ],
  },
  {
    long: 'Malta',
    short: 'MT',
    regions: [
      {
        long: 'Attard',
        short: '01',
      },
      {
        long: 'Balzan',
        short: '02',
      },
      {
        long: 'Birgu',
        short: '03',
      },
      {
        long: 'Birkirkara',
        short: '04',
      },
      {
        long: 'Birżebbuġa',
        short: '05',
      },
      {
        long: 'Bormla',
        short: '06',
      },
      {
        long: 'Dingli',
        short: '07',
      },
      {
        long: 'Fgura',
        short: '08',
      },
      {
        long: 'Floriana',
        short: '09',
      },
      {
        long: 'Fontana',
        short: '10',
      },
      {
        long: 'Guda',
        short: '11',
      },
      {
        long: 'Gżira',
        short: '12',
      },
      {
        long: 'Għajnsielem',
        short: '13',
      },
      {
        long: 'Għarb',
        short: '14',
      },
      {
        long: 'Għargħur',
        short: '15',
      },
      {
        long: 'Għasri',
        short: '16',
      },
      {
        long: 'Għaxaq',
        short: '17',
      },
      {
        long: 'Ħamrun',
        short: '18',
      },
      {
        long: 'Iklin',
        short: '19',
      },
      {
        long: 'Isla',
        short: '20',
      },
      {
        long: 'Kalkara',
        short: '21',
      },
      {
        long: 'Kerċem',
        short: '22',
      },
      {
        long: 'Kirkop',
        short: '23',
      },
      {
        long: 'Lija',
        short: '24',
      },
      {
        long: 'Luqa',
        short: '25',
      },
      {
        long: 'Marsa',
        short: '26',
      },
      {
        long: 'Marsaskala',
        short: '27',
      },
      {
        long: 'Marsaxlokk',
        short: '28',
      },
      {
        long: 'Mdina',
        short: '29',
      },
      {
        long: 'Mellieħa',
        short: '30',
      },
      {
        long: 'Mġarr',
        short: '31',
      },
      {
        long: 'Mosta',
        short: '32',
      },
      {
        long: 'Mqabba',
        short: '33',
      },
      {
        long: 'Msida',
        short: '34',
      },
      {
        long: 'Mtarfa',
        short: '35',
      },
      {
        long: 'Munxar',
        short: '36',
      },
      {
        long: 'Nadur',
        short: '37',
      },
      {
        long: 'Naxxar',
        short: '38',
      },
      {
        long: 'Paola',
        short: '39',
      },
      {
        long: 'Pembroke',
        short: '40',
      },
      {
        long: 'Pietà',
        short: '41',
      },
      {
        long: 'Qala',
        short: '42',
      },
      {
        long: 'Qormi',
        short: '43',
      },
      {
        long: 'Qrendi',
        short: '44',
      },
      {
        long: 'Rabat Għawdex',
        short: '45',
      },
      {
        long: 'Rabat Malta',
        short: '46',
      },
      {
        long: 'Safi',
        short: '47',
      },
      {
        long: 'San Ġiljan',
        short: '48',
      },
      {
        long: 'San Ġwann',
        short: '49',
      },
      {
        long: 'San Lawrenz',
        short: '50',
      },
      {
        long: 'San Pawl il-Baħar',
        short: '51',
      },
      {
        long: 'Sannat',
        short: '52',
      },
      {
        long: 'Santa Luċija',
        short: '53',
      },
      {
        long: 'Santa Venera',
        short: '54',
      },
      {
        long: 'Siġġiewi',
        short: '55',
      },
      {
        long: 'Sliema',
        short: '56',
      },
      {
        long: 'Swieqi',
        short: '57',
      },
      {
        long: 'Tai Xbiex',
        short: '58',
      },
      {
        long: 'Tarzien',
        short: '59',
      },
      {
        long: 'Valletta',
        short: '60',
      },
      {
        long: 'Xagħra',
        short: '61',
      },
      {
        long: 'Xewkija',
        short: '62',
      },
      {
        long: 'Xgħajra',
        short: '63',
      },
      {
        long: 'Żabbar',
        short: '64',
      },
      {
        long: 'Żebbuġ Għawde',
        short: '65',
      },
      {
        long: 'Żebbuġ Malta',
        short: '66',
      },
      {
        long: 'Żejtun',
        short: '67',
      },
      {
        long: 'Żurrieq',
        short: '68',
      },
    ],
  },
  {
    long: 'Marshall Islands',
    short: 'MH',
    regions: [
      {
        long: 'Ailinglaplap',
        short: 'ALL',
      },
      {
        long: 'Ailuk',
        short: 'ALK',
      },
      {
        long: 'Arno',
        short: 'ARN',
      },
      {
        long: 'Aur',
        short: 'AUR',
      },
      {
        long: 'Bikini and Kili',
        short: 'KIL',
      },
      {
        long: 'Ebon',
        short: 'EBO',
      },
      {
        long: 'Jabat',
        short: 'JAB',
      },
      {
        long: 'Jaluit',
        short: 'JAL',
      },
      {
        long: 'Kwajalein',
        short: 'KWA',
      },
      {
        long: 'Lae',
        short: 'LAE',
      },
      {
        long: 'Lib',
        short: 'LIB',
      },
      {
        long: 'Likiep',
        short: 'LIK',
      },
      {
        long: 'Majuro',
        short: 'MAJ',
      },
      {
        long: 'Maloelap',
        short: 'MAL',
      },
      {
        long: 'Mejit',
        short: 'MEJ',
      },
      {
        long: 'Namdrik',
        short: 'NMK',
      },
      {
        long: 'Namu',
        short: 'NMU',
      },
      {
        long: 'Rongelap',
        short: 'RON',
      },
      {
        long: 'Ujae',
        short: 'UJA',
      },
      {
        long: 'Utrik',
        short: 'UTI',
      },
      {
        long: 'Wotho',
        short: 'WTH',
      },
      {
        long: 'Wotje',
        short: 'WTJ',
      },
    ],
  },
  {
    long: 'Martinique',
    short: 'MQ',
    regions: [
      {
        long: 'Martinique',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Mauritania',
    short: 'MR',
    regions: [
      {
        long: 'Adrar',
        short: '07',
      },
      {
        long: 'Assaba',
        short: '03',
      },
      {
        long: 'Brakna',
        short: '05',
      },
      {
        long: 'Dakhlet Nouadhibou',
        short: '08',
      },
      {
        long: 'Gorgol',
        short: '04',
      },
      {
        long: 'Guidimaka',
        short: '10',
      },
      {
        long: 'Hodh Ech Chargui',
        short: '01',
      },
      {
        long: 'Hodh El Gharbi',
        short: '02',
      },
      {
        long: 'Inchiri',
        short: '12',
      },
      {
        long: 'Nouakchott Nord',
        short: '14',
      },
      {
        long: 'Nouakchott Ouest',
        short: '13',
      },
      {
        long: 'Nouakchott Sud',
        short: '15',
      },
      {
        long: 'Tagant',
        short: '09',
      },
      {
        long: 'Tiris Zemmour',
        short: '11',
      },
      {
        long: 'Trarza',
        short: '06',
      },
    ],
  },
  {
    long: 'Mauritius',
    short: 'MU',
    regions: [
      {
        long: 'Agalega Islands',
        short: 'AG',
      },
      {
        long: 'Beau Bassin-Rose Hill',
        short: 'BR',
      },
      {
        long: 'Black River',
        short: 'BL',
      },
      {
        long: 'Cargados Carajos Shoals',
        short: 'CC',
      },
      {
        long: 'Curepipe',
        short: 'CU',
      },
      {
        long: 'Flacq',
        short: 'FL',
      },
      {
        long: 'Grand Port',
        short: 'GP',
      },
      {
        long: 'Moka',
        short: 'MO',
      },
      {
        long: 'Pamplemousses',
        short: 'PA',
      },
      {
        long: 'Plaines Wilhems',
        short: 'PW',
      },
      {
        long: 'Port Louis (City)',
        short: 'PU',
      },
      {
        long: 'Port Louis',
        short: 'PL',
      },
      {
        long: 'Riviere du Rempart',
        short: 'RR',
      },
      {
        long: 'Rodrigues Island',
        short: 'RO',
      },
      {
        long: 'Savanne',
        short: 'SA',
      },
      {
        long: 'Vacoas-Phoenix',
        short: 'CP',
      },
    ],
  },
  {
    long: 'Mayotte',
    short: 'YT',
    regions: [
      {
        long: 'Dzaoudzi',
        short: '01',
      },
      {
        long: 'Pamandzi',
        short: '02',
      },
      {
        long: 'Mamoudzou',
        short: '03',
      },
      {
        long: 'Dembeni',
        short: '04',
      },
      {
        long: 'Bandrélé',
        short: '05',
      },
      {
        long: 'Kani-Kéli',
        short: '06',
      },
      {
        long: 'Bouéni',
        short: '07',
      },
      {
        long: 'Chirongui',
        short: '08',
      },
      {
        long: 'Sada',
        short: '09',
      },
      {
        long: 'Ouangani',
        short: '10',
      },
      {
        long: 'Chiconi',
        short: '11',
      },
      {
        long: 'Tsingoni',
        short: '12',
      },
      {
        long: "M'Tsangamouji",
        short: '13',
      },
      {
        long: 'Acoua',
        short: '14',
      },
      {
        long: 'Mtsamboro',
        short: '15',
      },
      {
        long: 'Bandraboua',
        short: '16',
      },
      {
        long: 'Koungou',
        short: '17',
      },
    ],
  },
  {
    long: 'Mexico',
    short: 'MX',
    regions: [
      {
        long: 'Aguascalientes',
        short: 'AGU',
      },
      {
        long: 'Baja California',
        short: 'BCN',
      },
      {
        long: 'Baja California Sur',
        short: 'BCS',
      },
      {
        long: 'Campeche',
        short: 'CAM',
      },
      {
        long: 'Ciudad de México',
        short: 'DIF',
      },
      {
        long: 'Chiapas',
        short: 'CHP',
      },
      {
        long: 'Chihuahua',
        short: 'CHH',
      },
      {
        long: 'Coahuila de Zaragoza',
        short: 'COA',
      },
      {
        long: 'Colima',
        short: 'COL',
      },
      {
        long: 'Durango',
        short: 'DUR',
      },
      {
        long: 'Estado de México',
        short: 'MEX',
      },
      {
        long: 'Guanajuato',
        short: 'GUA',
      },
      {
        long: 'Guerrero',
        short: 'GRO',
      },
      {
        long: 'Hidalgo',
        short: 'HID',
      },
      {
        long: 'Jalisco',
        short: 'JAL',
      },
      {
        long: 'Michoacán de Ocampo',
        short: 'MIC',
      },
      {
        long: 'Morelos',
        short: 'MOR',
      },
      {
        long: 'Nayarit',
        short: 'NAY',
      },
      {
        long: 'Nuevo León',
        short: 'NLE',
      },
      {
        long: 'Oaxaca',
        short: 'OAX',
      },
      {
        long: 'Puebla',
        short: 'PUE',
      },
      {
        long: 'Querétaro de Arteaga',
        short: 'QUE',
      },
      {
        long: 'Quintana Roo',
        short: 'ROO',
      },
      {
        long: 'San Luis Potosí',
        short: 'SLP',
      },
      {
        long: 'Sinaloa',
        short: 'SIN',
      },
      {
        long: 'Sonora',
        short: 'SON',
      },
      {
        long: 'Tabasco',
        short: 'TAB',
      },
      {
        long: 'Tamaulipas',
        short: 'TAM',
      },
      {
        long: 'Tlaxcala',
        short: 'TLA',
      },
      {
        long: 'Veracruz',
        short: 'VER',
      },
      {
        long: 'Yucatán',
        short: 'YUC',
      },
      {
        long: 'Zacatecas',
        short: 'ZAC',
      },
    ],
  },
  {
    long: 'Micronesia, Federated States of',
    short: 'FM',
    regions: [
      {
        long: 'Chuuk (Truk)',
        short: 'TRK',
      },
      {
        long: 'Kosrae',
        short: 'KSA',
      },
      {
        long: 'Pohnpei',
        short: 'PNI',
      },
      {
        long: 'Yap',
        short: 'YAP',
      },
    ],
  },
  {
    long: 'Moldova',
    short: 'MD',
    regions: [
      {
        long: 'Aenii Noi',
        short: 'AN',
      },
      {
        long: 'Basarabeasca',
        short: 'BS',
      },
      {
        long: 'Bălți',
        short: 'BA',
      },
      {
        long: 'Bender',
        short: 'BD',
      },
      {
        long: 'Briceni',
        short: 'BR',
      },
      {
        long: 'Cahul',
        short: 'CA',
      },
      {
        long: 'Cantemir',
        short: 'CT',
      },
      {
        long: 'Călărași',
        short: 'CL',
      },
      {
        long: 'Căușeni',
        short: 'CS',
      },
      {
        long: 'Chișinău',
        short: 'CU',
      },
      {
        long: 'Cimișlia',
        short: 'CM',
      },
      {
        long: 'Criuleni',
        short: 'CR',
      },
      {
        long: 'Dondușeni',
        short: 'DO',
      },
      {
        long: 'Drochia',
        short: 'DR',
      },
      {
        long: 'Dubăsari',
        short: 'DU',
      },
      {
        long: 'Edineț',
        short: 'ED',
      },
      {
        long: 'Fălești',
        short: 'FA',
      },
      {
        long: 'Florești',
        short: 'FL',
      },
      {
        long: 'Găgăuzia',
        short: 'GA',
      },
      {
        long: 'Glodeni',
        short: 'GL',
      },
      {
        long: 'Hîncești',
        short: 'HI',
      },
      {
        long: 'Ialoveni',
        short: 'IA',
      },
      {
        long: 'Leova',
        short: 'LE',
      },
      {
        long: 'Nisporeni',
        short: 'NI',
      },
      {
        long: 'Ocnița',
        short: 'OC',
      },
      {
        long: 'Orhei',
        short: 'OR',
      },
      {
        long: 'Rezina',
        short: 'RE',
      },
      {
        long: 'Rîșcani',
        short: 'RI',
      },
      {
        long: 'Sîngerei',
        short: 'SI',
      },
      {
        long: 'Soroca',
        short: 'SO',
      },
      {
        long: 'Stânga Nistrului',
        short: 'SN',
      },
      {
        long: 'Strășeni',
        short: 'ST',
      },
      {
        long: 'Șoldănești',
        short: 'SD',
      },
      {
        long: 'Ștefan Vodă',
        short: 'SV',
      },
      {
        long: 'Taraclia',
        short: 'TA',
      },
      {
        long: 'Telenești',
        short: 'TE',
      },
      {
        long: 'Ungheni',
        short: 'UN',
      },
    ],
  },
  {
    long: 'Monaco',
    short: 'MC',
    regions: [
      {
        long: 'Colle',
        short: 'CL',
      },
      {
        long: 'Condamine',
        short: 'CO',
      },
      {
        long: 'Fontvieille',
        short: 'FO',
      },
      {
        long: 'Gare',
        short: 'GA',
      },
      {
        long: 'Jardin Exotique',
        short: 'JE',
      },
      {
        long: 'Larvotto',
        short: 'LA',
      },
      {
        long: 'Malbousquet',
        short: 'MA',
      },
      {
        long: 'Monaco-Ville',
        short: 'MO',
      },
      {
        long: 'Moneghetti',
        short: 'MG',
      },
      {
        long: 'Monte-Carlo',
        short: 'MC',
      },
      {
        long: 'Moulins',
        short: 'MU',
      },
      {
        long: 'Port-Hercule',
        short: 'PH',
      },
      {
        long: 'Saint-Roman',
        short: 'SR',
      },
      {
        long: 'Sainte-Dévote',
        short: 'SD',
      },
      {
        long: 'Source',
        short: 'SO',
      },
      {
        long: 'Spélugues',
        short: 'SP',
      },
      {
        long: 'Vallon de la Rousse',
        short: 'VR',
      },
    ],
  },
  {
    long: 'Mongolia',
    short: 'MN',
    regions: [
      {
        long: 'Arhangay',
        short: '073',
      },
      {
        long: 'Bayan-Olgiy',
        short: '071',
      },
      {
        long: 'Bayanhongor',
        short: '069',
      },
      {
        long: 'Bulgan',
        short: '067',
      },
      {
        long: 'Darhan',
        short: '037',
      },
      {
        long: 'Dornod',
        short: '061',
      },
      {
        long: 'Dornogovi',
        short: '063',
      },
      {
        long: 'Dundgovi',
        short: '059',
      },
      {
        long: 'Dzavhan',
        short: '057',
      },
      {
        long: 'Govi-Altay',
        short: '065',
      },
      {
        long: 'Govi-Sumber',
        short: '064',
      },
      {
        long: 'Hovd',
        short: '043',
      },
      {
        long: 'Hovsgol',
        short: '041',
      },
      {
        long: 'Omnogovi',
        short: '053',
      },
      {
        long: 'Ovorhangay',
        short: '055',
      },
      {
        long: 'Selenge',
        short: '049',
      },
      {
        long: 'Suhbaatar',
        short: '051',
      },
      {
        long: 'Tov',
        short: '047',
      },
      {
        long: 'Ulaanbaatar',
        short: '1',
      },
      {
        long: 'Uvs',
        short: '046',
      },
      {
        long: 'Erdenet',
        short: '074',
      },
    ],
  },
  {
    long: 'Montenegro',
    short: 'ME',
    regions: [
      {
        long: 'Andrijevica',
        short: '01',
      },
      {
        long: 'Bar',
        short: '02',
      },
      {
        long: 'Berane',
        short: '03',
      },
      {
        long: 'Bijelo Polje',
        short: '04',
      },
      {
        long: 'Budva',
        short: '05',
      },
      {
        long: 'Cetinje',
        short: '06',
      },
      {
        long: 'Danilovgrad',
        short: '07',
      },
      {
        long: 'Gusinje',
        short: '22',
      },
      {
        long: 'Herceg Novi',
        short: '08',
      },
      {
        long: 'Kolašin',
        short: '09',
      },
      {
        long: 'Kotor',
        short: '10',
      },
      {
        long: 'Mojkovac',
        short: '11',
      },
      {
        long: 'Nikšić',
        short: '12',
      },
      {
        long: 'Petnica',
        short: '23',
      },
      {
        long: 'Plav',
        short: '13',
      },
      {
        long: 'Plužine',
        short: '14',
      },
      {
        long: 'Pljevlja',
        short: '15',
      },
      {
        long: 'Podgorica',
        short: '16',
      },
      {
        long: 'Rožaje',
        short: '17',
      },
      {
        long: 'Šavnik',
        short: '18',
      },
      {
        long: 'Tivat',
        short: '19',
      },
      {
        long: 'Ulcinj',
        short: '20',
      },
      {
        long: 'Žabljak',
        short: '21',
      },
    ],
  },
  {
    long: 'Montserrat',
    short: 'MS',
    regions: [
      {
        long: 'Saint Anthony',
        short: 'undefined',
      },
      {
        long: 'Saint Georges',
        short: 'undefined',
      },
      {
        long: "Saint Peter's",
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Morocco',
    short: 'MA',
    regions: [
      {
        long: 'Tanger-Tétouan-Al Hoceïma',
        short: 'MA-01',
      },
      {
        long: "L'Oriental",
        short: 'MA-02',
      },
      {
        long: 'Fès-Meknès',
        short: 'MA-03',
      },
      {
        long: 'Rabat-Salé-Kénitra',
        short: 'MA-04',
      },
      {
        long: 'Béni Mellal-Khénifra',
        short: 'MA-05',
      },
      {
        long: 'Casablanca-Settat',
        short: 'MA-06',
      },
      {
        long: 'Marrakech-Safi',
        short: 'MA-07',
      },
      {
        long: 'Drâa-Tafilalet',
        short: 'MA-08',
      },
      {
        long: 'Souss-Massa',
        short: 'MA-09',
      },
      {
        long: 'Guelmim-Oued Noun',
        short: 'MA-10',
      },
      {
        long: 'Laâyoune-Sakia El Hamra',
        short: 'MA-11',
      },
      {
        long: 'Dakhla-Oued Ed-Dahab',
        short: 'MA-12',
      },
    ],
  },
  {
    long: 'Mozambique',
    short: 'MZ',
    regions: [
      {
        long: 'Cabo Delgado',
        short: 'P',
      },
      {
        long: 'Gaza',
        short: 'G',
      },
      {
        long: 'Inhambane',
        short: 'I',
      },
      {
        long: 'Manica',
        short: 'B',
      },
      {
        long: 'Maputo',
        short: 'L',
      },
      {
        long: 'Maputo (City)',
        short: 'MPM',
      },
      {
        long: 'Nampula',
        short: 'N',
      },
      {
        long: 'Niassa',
        short: 'A',
      },
      {
        long: 'Sofala',
        short: 'S',
      },
      {
        long: 'Tete',
        short: 'T',
      },
      {
        long: 'Zambezia',
        short: 'Q',
      },
    ],
  },
  {
    long: 'Myanmar',
    short: 'MM',
    regions: [
      {
        long: 'Ayeyarwady',
        short: '07',
      },
      {
        long: 'Bago',
        short: '02',
      },
      {
        long: 'Chin',
        short: '14',
      },
      {
        long: 'Kachin',
        short: '11',
      },
      {
        long: 'Kayah',
        short: '12',
      },
      {
        long: 'Kayin',
        short: '13',
      },
      {
        long: 'Magway',
        short: '03',
      },
      {
        long: 'Mandalay',
        short: '04',
      },
      {
        long: 'Mon',
        short: '15',
      },
      {
        long: 'Nay Pyi Taw',
        short: '18',
      },
      {
        long: 'Rakhine',
        short: '16',
      },
      {
        long: 'Sagaing',
        short: '01',
      },
      {
        long: 'Shan',
        short: '17',
      },
      {
        long: 'Tanintharyi',
        short: '05',
      },
      {
        long: 'Yangon',
        short: '06',
      },
    ],
  },
  {
    long: 'Namibia',
    short: 'NA',
    regions: [
      {
        long: 'Erongo',
        short: 'ER',
      },
      {
        long: 'Hardap',
        short: 'HA',
      },
      {
        long: 'Kavango East',
        short: 'KE',
      },
      {
        long: 'Kavango West',
        short: 'KW',
      },
      {
        long: 'Karas',
        short: 'KA',
      },
      {
        long: 'Khomas',
        short: 'KH',
      },
      {
        long: 'Kunene',
        short: 'KU',
      },
      {
        long: 'Ohangwena',
        short: 'OW',
      },
      {
        long: 'Omaheke',
        short: 'OH',
      },
      {
        long: 'Omusati',
        short: 'OS',
      },
      {
        long: 'Oshana',
        short: 'ON',
      },
      {
        long: 'Oshikoto',
        short: 'OT',
      },
      {
        long: 'Otjozondjupa',
        short: 'OD',
      },
      {
        long: 'Zambezi',
        short: 'CA',
      },
    ],
  },
  {
    long: 'Nauru',
    short: 'NR',
    regions: [
      {
        long: 'Aiwo',
        short: '01',
      },
      {
        long: 'Anabar',
        short: '02',
      },
      {
        long: 'Anetan',
        short: '03',
      },
      {
        long: 'Anibare',
        short: '04',
      },
      {
        long: 'Baiti',
        short: '05',
      },
      {
        long: 'Boe',
        short: '06',
      },
      {
        long: 'Buada',
        short: '07',
      },
      {
        long: 'Denigomodu',
        short: '08',
      },
      {
        long: 'Ewa',
        short: '09',
      },
      {
        long: 'Ijuw',
        short: '10',
      },
      {
        long: 'Meneng',
        short: '11',
      },
      {
        long: 'Nibok',
        short: '12',
      },
      {
        long: 'Uaboe',
        short: '13',
      },
      {
        long: 'Yaren',
        short: '14',
      },
    ],
  },
  {
    long: 'Nepal',
    short: 'NP',
    regions: [
      {
        long: 'Province No. 1',
        short: '1',
      },
      {
        long: 'Province No. 2',
        short: '2',
      },
      {
        long: 'Bagmati Province',
        short: '3',
      },
      {
        long: 'Gandaki Province',
        short: '4',
      },
      {
        long: 'Lumbini Province',
        short: '5',
      },
      {
        long: 'Karnali Province',
        short: '6',
      },
      {
        long: 'Sudurpashchim',
        short: '7',
      },
    ],
  },
  {
    long: 'Netherlands',
    short: 'NL',
    regions: [
      {
        long: 'Drenthe',
        short: 'DR',
      },
      {
        long: 'Flevoland',
        short: 'FL',
      },
      {
        long: 'Friesland',
        short: 'FR',
      },
      {
        long: 'Gelderland',
        short: 'GE',
      },
      {
        long: 'Groningen',
        short: 'GR',
      },
      {
        long: 'Limburg',
        short: 'LI',
      },
      {
        long: 'Noord-Brabant',
        short: 'NB',
      },
      {
        long: 'Noord-Holland',
        short: 'NH',
      },
      {
        long: 'Overijssel',
        short: 'OV',
      },
      {
        long: 'Utrecht',
        short: 'UT',
      },
      {
        long: 'Zeeland',
        short: 'ZE',
      },
      {
        long: 'Zuid-Holland',
        short: 'ZH',
      },
    ],
  },
  {
    long: 'New Caledonia',
    short: 'NC',
    regions: [
      {
        long: 'Iles Loyaute',
        short: 'undefined',
      },
      {
        long: 'Nord',
        short: 'undefined',
      },
      {
        long: 'Sud',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'New Zealand',
    short: 'NZ',
    regions: [
      {
        long: 'Auckland',
        short: 'AUK',
      },
      {
        long: 'Bay of Plenty',
        short: 'BOP',
      },
      {
        long: 'Canterbury',
        short: 'CAN',
      },
      {
        long: 'Gisborne',
        short: 'GIS',
      },
      {
        long: "Hawke's Bay",
        short: 'HKB',
      },
      {
        long: 'Marlborough',
        short: 'MBH',
      },
      {
        long: 'Manawatu-Wanganui',
        short: 'MWT',
      },
      {
        long: 'Northland',
        short: 'NTL',
      },
      {
        long: 'Nelson',
        short: 'NSN',
      },
      {
        long: 'Otago',
        short: 'OTA',
      },
      {
        long: 'Southland',
        short: 'STL',
      },
      {
        long: 'Taranaki',
        short: 'TKI',
      },
      {
        long: 'Tasman',
        short: 'TAS',
      },
      {
        long: 'Waikato',
        short: 'WKO',
      },
      {
        long: 'Wellington',
        short: 'WGN',
      },
      {
        long: 'West Coast',
        short: 'WTC',
      },
      {
        long: 'Chatham Islands Territory',
        short: 'CIT',
      },
    ],
  },
  {
    long: 'Nicaragua',
    short: 'NI',
    regions: [
      {
        long: 'Boaco',
        short: 'BO',
      },
      {
        long: 'Carazo',
        short: 'CA',
      },
      {
        long: 'Chinandega',
        short: 'CI',
      },
      {
        long: 'Chontales',
        short: 'CO',
      },
      {
        long: 'Estelí',
        short: 'ES',
      },
      {
        long: 'Granada',
        short: 'GR',
      },
      {
        long: 'Jinotega',
        short: 'JI',
      },
      {
        long: 'León',
        short: 'LE',
      },
      {
        long: 'Madriz',
        short: 'MD',
      },
      {
        long: 'Managua',
        short: 'MN',
      },
      {
        long: 'Masaya',
        short: 'MS',
      },
      {
        long: 'Matagalpa',
        short: 'MT',
      },
      {
        long: 'Nueva Segovia',
        short: 'NS',
      },
      {
        long: 'Río San Juan',
        short: 'SJ',
      },
      {
        long: 'Rivas',
        short: 'RI',
      },
      {
        long: 'Atlántico Norte',
        short: 'AN',
      },
      {
        long: 'Atlántico Sur',
        short: 'AS',
      },
    ],
  },
  {
    long: 'Niger',
    short: 'NE',
    regions: [
      {
        long: 'Agadez',
        short: '1',
      },
      {
        long: 'Diffa',
        short: '2',
      },
      {
        long: 'Dosso',
        short: '3',
      },
      {
        long: 'Maradi',
        short: '4',
      },
      {
        long: 'Niamey',
        short: '8',
      },
      {
        long: 'Tahoua',
        short: '5',
      },
      {
        long: 'Tillabéri',
        short: '6',
      },
      {
        long: 'Zinder',
        short: '7',
      },
    ],
  },
  {
    long: 'Nigeria',
    short: 'NG',
    regions: [
      {
        long: 'Abia',
        short: 'AB',
      },
      {
        long: 'Abuja Federal Capital Territory',
        short: 'FC',
      },
      {
        long: 'Adamawa',
        short: 'AD',
      },
      {
        long: 'Akwa Ibom',
        short: 'AK',
      },
      {
        long: 'Anambra',
        short: 'AN',
      },
      {
        long: 'Bauchi',
        short: 'BA',
      },
      {
        long: 'Bayelsa',
        short: 'BY',
      },
      {
        long: 'Benue',
        short: 'BE',
      },
      {
        long: 'Borno',
        short: 'BO',
      },
      {
        long: 'Cross River',
        short: 'CR',
      },
      {
        long: 'Delta',
        short: 'DE',
      },
      {
        long: 'Ebonyi',
        short: 'EB',
      },
      {
        long: 'Edo',
        short: 'ED',
      },
      {
        long: 'Ekiti',
        short: 'EK',
      },
      {
        long: 'Enugu',
        short: 'EN',
      },
      {
        long: 'Gombe',
        short: 'GO',
      },
      {
        long: 'Imo',
        short: 'IM',
      },
      {
        long: 'Jigawa',
        short: 'JI',
      },
      {
        long: 'Kaduna',
        short: 'KD',
      },
      {
        long: 'Kano',
        short: 'KN',
      },
      {
        long: 'Katsina',
        short: 'KT',
      },
      {
        long: 'Kebbi',
        short: 'KE',
      },
      {
        long: 'Kogi',
        short: 'KO',
      },
      {
        long: 'Kwara',
        short: 'KW',
      },
      {
        long: 'Lagos',
        short: 'LA',
      },
      {
        long: 'Nassarawa',
        short: 'NA',
      },
      {
        long: 'Niger',
        short: 'NI',
      },
      {
        long: 'Ogun',
        short: 'OG',
      },
      {
        long: 'Ondo',
        short: 'ON',
      },
      {
        long: 'Osun',
        short: 'OS',
      },
      {
        long: 'Oyo',
        short: 'OY',
      },
      {
        long: 'Plateau',
        short: 'PL',
      },
      {
        long: 'Rivers',
        short: 'RI',
      },
      {
        long: 'Sokoto',
        short: 'SO',
      },
      {
        long: 'Taraba',
        short: 'TA',
      },
      {
        long: 'Yobe',
        short: 'YO',
      },
      {
        long: 'Zamfara',
        short: 'ZA',
      },
    ],
  },
  {
    long: 'Niue',
    short: 'NU',
    regions: [
      {
        long: 'Niue',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Norfolk Island',
    short: 'NF',
    regions: [
      {
        long: 'Norfolk Island',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Northern Mariana Islands',
    short: 'MP',
    regions: [
      {
        long: 'Northern Islands',
        short: 'undefined',
      },
      {
        long: 'Rota',
        short: 'undefined',
      },
      {
        long: 'Saipan',
        short: 'undefined',
      },
      {
        long: 'Tinian',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Norway',
    short: 'NO',
    regions: [
      {
        long: 'Akershus',
        short: '02',
      },
      {
        long: 'Aust-Agder',
        short: '09',
      },
      {
        long: 'Buskerud',
        short: '06',
      },
      {
        long: 'Finnmark',
        short: '20',
      },
      {
        long: 'Hedmark',
        short: '04',
      },
      {
        long: 'Hordaland',
        short: '12',
      },
      {
        long: 'Møre og Romsdal',
        short: '15',
      },
      {
        long: 'Nordland',
        short: '18',
      },
      {
        long: 'Nord-Trøndelag',
        short: '17',
      },
      {
        long: 'Oppland',
        short: '05',
      },
      {
        long: 'Oslo',
        short: '03',
      },
      {
        long: 'Rogaland',
        short: '11',
      },
      {
        long: 'Sogn og Fjordane',
        short: '14',
      },
      {
        long: 'Sør-Trøndelag',
        short: '16',
      },
      {
        long: 'Telemark',
        short: '08',
      },
      {
        long: 'Troms',
        short: '19',
      },
      {
        long: 'Vest-Agder',
        short: '10',
      },
      {
        long: 'Vestfold',
        short: '07',
      },
      {
        long: 'Østfold',
        short: '01',
      },
      {
        long: 'Jan Mayen',
        short: '22',
      },
      {
        long: 'Svalbard',
        short: '21',
      },
    ],
  },
  {
    long: 'Oman',
    short: 'OM',
    regions: [
      {
        long: 'Ad Dakhiliyah',
        short: 'DA',
      },
      {
        long: 'Al Buraymi',
        short: 'BU',
      },
      {
        long: 'Al Wusta',
        short: 'WU',
      },
      {
        long: 'Az Zahirah',
        short: 'ZA',
      },
      {
        long: 'Janub al Batinah',
        short: 'BS',
      },
      {
        long: 'Janub ash Sharqiyah',
        short: 'SS',
      },
      {
        long: 'Masqat',
        short: 'MA',
      },
      {
        long: 'Musandam',
        short: 'MU',
      },
      {
        long: 'Shamal al Batinah',
        short: 'BJ',
      },
      {
        long: 'Shamal ash Sharqiyah',
        short: 'SJ',
      },
      {
        long: 'Zufar',
        short: 'ZU',
      },
    ],
  },
  {
    long: 'Pakistan',
    short: 'PK',
    regions: [
      {
        long: 'Āzād Kashmīr',
        short: 'JK',
      },
      {
        long: 'Balōchistān',
        short: 'BA',
      },
      {
        long: 'Gilgit-Baltistān',
        short: 'GB',
      },
      {
        long: 'Islāmābād',
        short: 'IS',
      },
      {
        long: 'Khaībar Pakhtūnkhwās',
        short: 'KP',
      },
      {
        long: 'Punjāb',
        short: 'PB',
      },
      {
        long: 'Sindh',
        short: 'SD',
      },
      {
        long: 'Federally Administered Tribal Areas',
        short: 'TA',
      },
    ],
  },
  {
    long: 'Palau',
    short: 'PW',
    regions: [
      {
        long: 'Aimeliik',
        short: '002',
      },
      {
        long: 'Airai',
        short: '004',
      },
      {
        long: 'Angaur',
        short: '010',
      },
      {
        long: 'Hatobohei',
        short: '050',
      },
      {
        long: 'Kayangel',
        short: '100',
      },
      {
        long: 'Koror',
        short: '150',
      },
      {
        long: 'Melekeok',
        short: '212',
      },
      {
        long: 'Ngaraard',
        short: '214',
      },
      {
        long: 'Ngarchelong',
        short: '218',
      },
      {
        long: 'Ngardmau',
        short: '222',
      },
      {
        long: 'Ngatpang',
        short: '224',
      },
      {
        long: 'Ngchesar',
        short: '226',
      },
      {
        long: 'Ngeremlengui',
        short: '227',
      },
      {
        long: 'Ngiwal',
        short: '228',
      },
      {
        long: 'Peleliu',
        short: '350',
      },
      {
        long: 'Sonsoral',
        short: '370',
      },
    ],
  },
  {
    long: 'Palestine, State of',
    short: 'PS',
    regions: [
      {
        long: 'Ak Khalīl',
        short: 'HBN',
      },
      {
        long: 'Al Quds',
        short: 'JEM',
      },
      {
        long: 'Arīḩā wal Aghwār',
        short: 'JRH',
      },
      {
        long: 'Bayt Laḩm',
        short: 'BTH',
      },
      {
        long: 'Dayr al Balaḩ',
        short: 'DEB',
      },
      {
        long: 'Ghazzah',
        short: 'GZA',
      },
      {
        long: 'Janīn',
        short: 'JEN',
      },
      {
        long: 'Khān Yūnis',
        short: 'KYS',
      },
      {
        long: 'Nāblus',
        short: 'NBS',
      },
      {
        long: 'Qalqīyah',
        short: 'QQA',
      },
      {
        long: 'Rafaḩ',
        short: 'RFH',
      },
      {
        long: 'Rām Allāh wal Bīrah',
        short: 'RBH',
      },
      {
        long: 'Salfīt',
        short: 'SLT',
      },
      {
        long: 'Shamāl Ghazzah',
        short: 'NGZ',
      },
      {
        long: 'Ţūbās',
        short: 'TBS',
      },
      {
        long: 'Ţūlkarm',
        short: 'TKM',
      },
    ],
  },
  {
    long: 'Panama',
    short: 'PA',
    regions: [
      {
        long: 'Bocas del Toro',
        short: '1',
      },
      {
        long: 'Chiriquí',
        short: '4',
      },
      {
        long: 'Coclé',
        short: '2',
      },
      {
        long: 'Colón',
        short: '3',
      },
      {
        long: 'Darién',
        short: '5',
      },
      {
        long: 'Emberá',
        short: 'EM',
      },
      {
        long: 'Herrera',
        short: '6',
      },
      {
        long: 'Kuna Yala',
        short: 'KY',
      },
      {
        long: 'Los Santos',
        short: '7',
      },
      {
        long: 'Ngäbe-Buglé',
        short: 'NB',
      },
      {
        long: 'Panamá',
        short: '8',
      },
      {
        long: 'Panamá Oeste',
        short: '10',
      },
      {
        long: 'Veraguas',
        short: '9',
      },
    ],
  },
  {
    long: 'Papua New Guinea',
    short: 'PG',
    regions: [
      {
        long: 'Bougainville',
        short: 'NSB',
      },
      {
        long: 'Central',
        short: 'CPM',
      },
      {
        long: 'Chimbu',
        short: 'CPK',
      },
      {
        long: 'East New Britain',
        short: 'EBR',
      },
      {
        long: 'East Sepik',
        short: 'ESW',
      },
      {
        long: 'Eastern Highlands',
        short: 'EHG',
      },
      {
        long: 'Enga',
        short: 'EPW',
      },
      {
        long: 'Gulf',
        short: 'GPK',
      },
      {
        long: 'Hela',
        short: 'HLA',
      },
      {
        long: 'Jiwaka',
        short: 'JWK',
      },
      {
        long: 'Madang',
        short: 'MOM',
      },
      {
        long: 'Manus',
        short: 'MRL',
      },
      {
        long: 'Milne Bay',
        short: 'MBA',
      },
      {
        long: 'Morobe',
        short: 'MPL',
      },
      {
        long: 'Port Moresby',
        short: 'NCD',
      },
      {
        long: 'New Ireland',
        short: 'NIK',
      },
      {
        long: 'Northern',
        short: 'NPP',
      },
      {
        long: 'Southern Highlands',
        short: 'SHM',
      },
      {
        long: 'West New Britain',
        short: 'WBK',
      },
      {
        long: 'West Sepik',
        short: 'SAN',
      },
      {
        long: 'Western',
        short: 'WPD',
      },
      {
        long: 'Western Highlands',
        short: 'WHM',
      },
    ],
  },
  {
    long: 'Paraguay',
    short: 'PY',
    regions: [
      {
        long: 'Alto Paraguay',
        short: '16',
      },
      {
        long: 'Alto Parana',
        short: '10',
      },
      {
        long: 'Amambay',
        short: '13',
      },
      {
        long: 'Asuncion',
        short: 'ASU',
      },
      {
        long: 'Caaguazu',
        short: '5',
      },
      {
        long: 'Caazapa',
        short: '6',
      },
      {
        long: 'Canindeyu',
        short: '14',
      },
      {
        long: 'Central',
        short: '11',
      },
      {
        long: 'Concepcion',
        short: '1',
      },
      {
        long: 'Cordillera',
        short: '3',
      },
      {
        long: 'Boqueron',
        short: '17',
      },
      {
        long: 'Guaira',
        short: '4',
      },
      {
        long: 'Itapua',
        short: '7',
      },
      {
        long: 'Misiones',
        short: '8',
      },
      {
        long: 'Neembucu',
        short: '12',
      },
      {
        long: 'Paraguari',
        short: '9',
      },
      {
        long: 'Presidente Hayes',
        short: '15',
      },
      {
        long: 'San Pedro',
        short: '2',
      },
    ],
  },
  {
    long: 'Peru',
    short: 'PE',
    regions: [
      {
        long: 'Amazonas',
        short: 'AMA',
      },
      {
        long: 'Ancash',
        short: 'ANC',
      },
      {
        long: 'Apurimac',
        short: 'APU',
      },
      {
        long: 'Arequipa',
        short: 'ARE',
      },
      {
        long: 'Ayacucho',
        short: 'AYA',
      },
      {
        long: 'Cajamarca',
        short: 'CAJ',
      },
      {
        long: 'Callao',
        short: 'CAL',
      },
      {
        long: 'Cusco',
        short: 'CUS',
      },
      {
        long: 'Huancavelica',
        short: 'HUV',
      },
      {
        long: 'Huanuco',
        short: 'HUC',
      },
      {
        long: 'Ica',
        short: 'ICA',
      },
      {
        long: 'Junin',
        short: 'JUN',
      },
      {
        long: 'La Libertad',
        short: 'LAL',
      },
      {
        long: 'Lambayeque',
        short: 'LAM',
      },
      {
        long: 'Lima',
        short: 'LIM',
      },
      {
        long: 'Loreto',
        short: 'LOR',
      },
      {
        long: 'Madre de Dios',
        short: 'MDD',
      },
      {
        long: 'Moquegua',
        short: 'MOQ',
      },
      {
        long: 'Municipalidad Metropolitana de Lima',
        short: 'LMA',
      },
      {
        long: 'Pasco',
        short: 'PAS',
      },
      {
        long: 'Piura',
        short: 'PIU',
      },
      {
        long: 'Puno',
        short: 'PUN',
      },
      {
        long: 'San Martin',
        short: 'SAM',
      },
      {
        long: 'Tacna',
        short: 'TAC',
      },
      {
        long: 'Tumbes',
        short: 'TUM',
      },
      {
        long: 'Ucayali',
        short: 'UCA',
      },
    ],
  },
  {
    long: 'Philippines',
    short: 'PH',
    regions: [
      {
        long: 'Abra',
        short: 'ABR',
      },
      {
        long: 'Agusan del Norte',
        short: 'AGN',
      },
      {
        long: 'Agusan del Sur',
        short: 'AGS',
      },
      {
        long: 'Aklan',
        short: 'AKL',
      },
      {
        long: 'Albay',
        short: 'ALB',
      },
      {
        long: 'Antique',
        short: 'ANT',
      },
      {
        long: 'Apayao',
        short: 'APA',
      },
      {
        long: 'Aurora',
        short: 'AUR',
      },
      {
        long: 'Basilan',
        short: 'BAS',
      },
      {
        long: 'Bataan',
        short: 'BAN',
      },
      {
        long: 'Batanes',
        short: 'BTN',
      },
      {
        long: 'Batangas',
        short: 'BTG',
      },
      {
        long: 'Benguet',
        short: 'BEN',
      },
      {
        long: 'Biliran',
        short: 'BIL',
      },
      {
        long: 'Bohol',
        short: 'BOH',
      },
      {
        long: 'Bukidnon',
        short: 'BUK',
      },
      {
        long: 'Bulacan',
        short: 'BUL',
      },
      {
        long: 'Cagayan',
        short: 'CAG',
      },
      {
        long: 'Camarines Norte',
        short: 'CAN',
      },
      {
        long: 'Camarines Sur',
        short: 'CAS',
      },
      {
        long: 'Camiguin',
        short: 'CAM',
      },
      {
        long: 'Capiz',
        short: 'CAP',
      },
      {
        long: 'Catanduanes',
        short: 'CAT',
      },
      {
        long: 'Cavite',
        short: 'CAV',
      },
      {
        long: 'Cebu',
        short: 'CEB',
      },
      {
        long: 'Compostela',
        short: 'COM',
      },
      {
        long: 'Cotabato',
        short: 'NCO',
      },
      {
        long: 'Davao del Norte',
        short: 'DAV',
      },
      {
        long: 'Davao del Sur',
        short: 'DAS',
      },
      {
        long: 'Davao Occidental',
        short: 'DVO',
      },
      {
        long: 'Davao Oriental',
        short: 'DAO',
      },
      {
        long: 'Dinagat Islands',
        short: 'DIN',
      },
      {
        long: 'Eastern Samar',
        short: 'EAS',
      },
      {
        long: 'Guimaras',
        short: 'GUI',
      },
      {
        long: 'Ifugao',
        short: 'IFU',
      },
      {
        long: 'Ilocos Norte',
        short: 'ILN',
      },
      {
        long: 'Ilocos Sur',
        short: 'ILS',
      },
      {
        long: 'Iloilo',
        short: 'ILI',
      },
      {
        long: 'Isabela',
        short: 'ISA',
      },
      {
        long: 'Kalinga',
        short: 'KAL',
      },
      {
        long: 'La Union',
        short: 'LUN',
      },
      {
        long: 'Laguna',
        short: 'LAG',
      },
      {
        long: 'Lanao del Norte',
        short: 'LAN',
      },
      {
        long: 'Lanao del Sur',
        short: 'LAS',
      },
      {
        long: 'Leyte',
        short: 'LEY',
      },
      {
        long: 'Maguindanao',
        short: 'MAG',
      },
      {
        long: 'Masbate',
        short: 'MAS',
      },
      {
        long: 'Metro Manila',
        short: '00',
      },
      {
        long: 'Mindoro Occidental',
        short: 'MDC',
      },
      {
        long: 'Mindoro Oriental',
        short: 'MDR',
      },
      {
        long: 'Misamis Occidental',
        short: 'MSC',
      },
      {
        long: 'Misamis Oriental',
        short: 'MSR',
      },
      {
        long: 'Mountain Province',
        short: 'MOU',
      },
      {
        long: 'Negros Occidental',
        short: 'NEC',
      },
      {
        long: 'Negros Oriental',
        short: 'NER',
      },
      {
        long: 'Northern Samar',
        short: 'NSA',
      },
      {
        long: 'Nueva Ecija',
        short: 'NUE',
      },
      {
        long: 'Nueva Vizcaya',
        short: 'NUV',
      },
      {
        long: 'Palawan',
        short: 'PLW',
      },
      {
        long: 'Pampanga',
        short: 'PAM',
      },
      {
        long: 'Pangasinan',
        short: 'PAN',
      },
      {
        long: 'Quezon',
        short: 'QUE',
      },
      {
        long: 'Quirino',
        short: 'QUI',
      },
      {
        long: 'Rizal',
        short: 'RIZ',
      },
      {
        long: 'Romblon',
        short: 'ROM',
      },
      {
        long: 'Samar',
        short: 'WSA',
      },
      {
        long: 'Sarangani',
        short: 'SAR',
      },
      {
        long: 'Siquijor',
        short: 'SIG',
      },
      {
        long: 'Sorsogon',
        short: 'SOR',
      },
      {
        long: 'Southern Leyte',
        short: 'SLE',
      },
      {
        long: 'Sultan Kudarat',
        short: 'AUK',
      },
      {
        long: 'Sulu',
        short: 'SLU',
      },
      {
        long: 'Surigao del Norte',
        short: 'SUN',
      },
      {
        long: 'Surigao del Sur',
        short: 'SUR',
      },
      {
        long: 'Tarlac',
        short: 'TAR',
      },
      {
        long: 'Tawi-Tawi',
        short: 'TAW',
      },
      {
        long: 'Zambales',
        short: 'ZMB',
      },
      {
        long: 'Zamboanga del Norte',
        short: 'ZAN',
      },
      {
        long: 'Zamboanga del Sur',
        short: 'ZAS',
      },
      {
        long: 'Zamboanga Sibugay',
        short: 'ZSI',
      },
    ],
  },
  {
    long: 'Pitcairn',
    short: 'PN',
    regions: [
      {
        long: 'Pitcairn Islands',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Poland',
    short: 'PL',
    regions: [
      {
        long: 'Dolnośląskie',
        short: 'DS',
      },
      {
        long: 'Kujawsko-pomorskie',
        short: 'KP',
      },
      {
        long: 'Łódzkie',
        short: 'LD',
      },
      {
        long: 'Lubelskie',
        short: 'LU',
      },
      {
        long: 'Lubuskie',
        short: 'LB',
      },
      {
        long: 'Małopolskie',
        short: 'MA',
      },
      {
        long: 'Mazowieckie',
        short: 'MZ',
      },
      {
        long: 'Opolskie',
        short: 'OP',
      },
      {
        long: 'Podkarpackie',
        short: 'PK',
      },
      {
        long: 'Podlaskie',
        short: 'PD',
      },
      {
        long: 'Pomorskie',
        short: 'PM',
      },
      {
        long: 'Śląskie',
        short: 'SL',
      },
      {
        long: 'Świętokrzyskie',
        short: 'SK',
      },
      {
        long: 'Warmińsko-mazurskie',
        short: 'WN',
      },
      {
        long: 'Wielkopolskie',
        short: 'WP',
      },
      {
        long: 'Zachodniopomorskie',
        short: 'ZP',
      },
    ],
  },
  {
    long: 'Portugal',
    short: 'PT',
    regions: [
      {
        long: 'Açores',
        short: '20',
      },
      {
        long: 'Aveiro',
        short: '01',
      },
      {
        long: 'Beja',
        short: '02',
      },
      {
        long: 'Braga',
        short: '03',
      },
      {
        long: 'Bragança',
        short: '04',
      },
      {
        long: 'Castelo Branco',
        short: '05',
      },
      {
        long: 'Coimbra',
        short: '06',
      },
      {
        long: 'Évora',
        short: '07',
      },
      {
        long: 'Faro',
        short: '08',
      },
      {
        long: 'Guarda',
        short: '09',
      },
      {
        long: 'Leiria',
        short: '10',
      },
      {
        long: 'Lisboa',
        short: '11',
      },
      {
        long: 'Madeira',
        short: '30',
      },
      {
        long: 'Portalegre',
        short: '12',
      },
      {
        long: 'Porto',
        short: '13',
      },
      {
        long: 'Santarém',
        short: '14',
      },
      {
        long: 'Setúbal',
        short: '15',
      },
      {
        long: 'Viana do Castelo',
        short: '16',
      },
      {
        long: 'Vila Real',
        short: '17',
      },
      {
        long: 'Viseu',
        short: '18',
      },
    ],
  },
  {
    long: 'Puerto Rico',
    short: 'PR',
    regions: [
      {
        long: 'Adjuntas',
        short: 'undefined',
      },
      {
        long: 'Aguada',
        short: 'undefined',
      },
      {
        long: 'Aguadilla',
        short: 'undefined',
      },
      {
        long: 'Aguas Buenas',
        short: 'undefined',
      },
      {
        long: 'Aibonito',
        short: 'undefined',
      },
      {
        long: 'Anasco',
        short: 'undefined',
      },
      {
        long: 'Arecibo',
        short: 'undefined',
      },
      {
        long: 'Arroyo',
        short: 'undefined',
      },
      {
        long: 'Barceloneta',
        short: 'undefined',
      },
      {
        long: 'Barranquitas',
        short: 'undefined',
      },
      {
        long: 'Bayamon',
        short: 'undefined',
      },
      {
        long: 'Cabo Rojo',
        short: 'undefined',
      },
      {
        long: 'Caguas',
        short: 'undefined',
      },
      {
        long: 'Camuy',
        short: 'undefined',
      },
      {
        long: 'Canovanas',
        short: 'undefined',
      },
      {
        long: 'Carolina',
        short: 'undefined',
      },
      {
        long: 'Cat',
        short: 'undefined',
      },
      {
        long: 'Ceiba',
        short: 'undefined',
      },
      {
        long: 'Ciales',
        short: 'undefined',
      },
      {
        long: 'Cidra',
        short: 'undefined',
      },
      {
        long: 'Coamo',
        short: 'undefined',
      },
      {
        long: 'Comerio',
        short: 'undefined',
      },
      {
        long: 'Corozal',
        short: 'undefined',
      },
      {
        long: 'Culebra',
        short: 'undefined',
      },
      {
        long: 'Dorado',
        short: 'undefined',
      },
      {
        long: 'Fajardo',
        short: 'undefined',
      },
      {
        long: 'Florida',
        short: 'undefined',
      },
      {
        long: 'Guanica',
        short: 'undefined',
      },
      {
        long: 'Guayama',
        short: 'undefined',
      },
      {
        long: 'Guayanilla',
        short: 'undefined',
      },
      {
        long: 'Guaynabo',
        short: 'undefined',
      },
      {
        long: 'Gurabo',
        short: 'undefined',
      },
      {
        long: 'Hatillo',
        short: 'undefined',
      },
      {
        long: 'Hormigueros',
        short: 'undefined',
      },
      {
        long: 'Humacao',
        short: 'undefined',
      },
      {
        long: 'Isabe',
        short: 'undefined',
      },
      {
        long: 'Juana Diaz',
        short: 'undefined',
      },
      {
        long: 'Juncos',
        short: 'undefined',
      },
      {
        long: 'Lajas',
        short: 'undefined',
      },
      {
        long: 'Lares',
        short: 'undefined',
      },
      {
        long: 'Las Marias',
        short: 'undefined',
      },
      {
        long: 'Las oiza',
        short: 'undefined',
      },
      {
        long: 'Luquillo',
        short: 'undefined',
      },
      {
        long: 'Manati',
        short: 'undefined',
      },
      {
        long: 'Maricao',
        short: 'undefined',
      },
      {
        long: 'Maunabo',
        short: 'undefined',
      },
      {
        long: 'Mayaguez',
        short: 'undefined',
      },
      {
        long: 'Moca',
        short: 'undefined',
      },
      {
        long: 'Morovis',
        short: 'undefined',
      },
      {
        long: 'Naguabo',
        short: 'undefined',
      },
      {
        long: 'Naranjito',
        short: 'undefined',
      },
      {
        long: 'Orocovis',
        short: 'undefined',
      },
      {
        long: 'Patillas',
        short: 'undefined',
      },
      {
        long: 'Penuelas',
        short: 'undefined',
      },
      {
        long: 'Ponce',
        short: 'undefined',
      },
      {
        long: 'Quebradillas',
        short: 'undefined',
      },
      {
        long: 'Rincon',
        short: 'undefined',
      },
      {
        long: 'Rio Grande',
        short: 'undefined',
      },
      {
        long: 'Sabana linas',
        short: 'undefined',
      },
      {
        long: 'San German',
        short: 'undefined',
      },
      {
        long: 'San Juan',
        short: 'undefined',
      },
      {
        long: 'San Lorenzo',
        short: 'undefined',
      },
      {
        long: 'San Sebastian',
        short: 'undefined',
      },
      {
        long: 'Santa Isabel',
        short: 'undefined',
      },
      {
        long: 'Toa Alta',
        short: 'undefined',
      },
      {
        long: 'Toa Baja',
        short: 'undefined',
      },
      {
        long: 'Trujillo Alto',
        short: 'undefined',
      },
      {
        long: 'Utuado',
        short: 'undefined',
      },
      {
        long: 'Vega Alta',
        short: 'undefined',
      },
      {
        long: 'Vega ues',
        short: 'undefined',
      },
      {
        long: 'Villalba',
        short: 'undefined',
      },
      {
        long: 'Yabucoa',
        short: 'undefined',
      },
      {
        long: 'Yauco',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Qatar',
    short: 'QA',
    regions: [
      {
        long: 'Ad Dawḩah',
        short: 'DA',
      },
      {
        long: 'Al Khawr wa adh Dhakhīrah',
        short: 'KH',
      },
      {
        long: 'Al Wakrah',
        short: 'WA',
      },
      {
        long: 'Ar Rayyān',
        short: 'RA',
      },
      {
        long: 'Ash Shamāl',
        short: 'MS',
      },
      {
        long: 'Az̧ Za̧`āyin',
        short: 'ZA',
      },
      {
        long: 'Umm Şalāl',
        short: 'US',
      },
    ],
  },
  {
    long: 'Réunion',
    short: 'RE',
    regions: [
      {
        long: 'Réunion',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Romania',
    short: 'RO',
    regions: [
      {
        long: 'Alba',
        short: 'AB',
      },
      {
        long: 'Arad',
        short: 'AR',
      },
      {
        long: 'Arges',
        short: 'AG',
      },
      {
        long: 'Bacau',
        short: 'BC',
      },
      {
        long: 'Bihor',
        short: 'BH',
      },
      {
        long: 'Bistrita-Nasaud',
        short: 'BN',
      },
      {
        long: 'Botosani',
        short: 'BT',
      },
      {
        long: 'Braila',
        short: 'BR',
      },
      {
        long: 'Brasov',
        short: 'BV',
      },
      {
        long: 'Bucuresti',
        short: 'B',
      },
      {
        long: 'Buzau',
        short: 'BZ',
      },
      {
        long: 'Calarasi',
        short: 'CL',
      },
      {
        long: 'Caras-Severin',
        short: 'CS',
      },
      {
        long: 'Cluj',
        short: 'CJ',
      },
      {
        long: 'Constanta',
        short: 'CT',
      },
      {
        long: 'Covasna',
        short: 'CV',
      },
      {
        long: 'Dambovita',
        short: 'DB',
      },
      {
        long: 'Dolj',
        short: 'DJ',
      },
      {
        long: 'Galati',
        short: 'GL',
      },
      {
        long: 'Giurgiu',
        short: 'GR',
      },
      {
        long: 'Gorj',
        short: 'GJ',
      },
      {
        long: 'Harghita',
        short: 'HR',
      },
      {
        long: 'Hunedoara',
        short: 'HD',
      },
      {
        long: 'Ialomita',
        short: 'IL',
      },
      {
        long: 'Iasi',
        short: 'IS',
      },
      {
        long: 'Maramures',
        short: 'MM',
      },
      {
        long: 'Mehedinti',
        short: 'MH',
      },
      {
        long: 'Mures',
        short: 'MS',
      },
      {
        long: 'Neamt',
        short: 'NT',
      },
      {
        long: 'Olt',
        short: 'OT',
      },
      {
        long: 'Prahova',
        short: 'PH',
      },
      {
        long: 'Salaj',
        short: 'SJ',
      },
      {
        long: 'Satu Mare',
        short: 'SM',
      },
      {
        long: 'Sibiu',
        short: 'SB',
      },
      {
        long: 'Suceava',
        short: 'SV',
      },
      {
        long: 'Teleorman',
        short: 'TR',
      },
      {
        long: 'Timis',
        short: 'TM',
      },
      {
        long: 'Tulcea',
        short: 'TL',
      },
      {
        long: 'Valcea',
        short: 'VL',
      },
      {
        long: 'Vaslui',
        short: 'VS',
      },
      {
        long: 'Vrancea',
        short: 'VN',
      },
    ],
  },
  {
    long: 'Russian Federation',
    short: 'RU',
    regions: [
      {
        long: 'Republic of Adygea',
        short: 'AD',
      },
      {
        long: 'Republic of Altai (Gorno-Altaysk)',
        short: 'AL',
      },
      {
        long: 'Altai Krai',
        short: 'ALT',
      },
      {
        long: 'Amur Oblast',
        short: 'AMU',
      },
      {
        long: 'Arkhangelsk Oblast',
        short: 'ARK',
      },
      {
        long: 'Astrakhan Oblast',
        short: 'AST',
      },
      {
        long: 'Republic of Bashkortostan',
        short: 'BA',
      },
      {
        long: 'Belgorod Oblast',
        short: 'BEL',
      },
      {
        long: 'Bryansk Oblast',
        short: 'BRY',
      },
      {
        long: 'Republic of Buryatia',
        short: 'BU',
      },
      {
        long: 'Chechen Republic',
        short: 'CE',
      },
      {
        long: 'Chelyabinsk Oblast',
        short: 'CHE',
      },
      {
        long: 'Chukotka Autonomous Okrug',
        short: 'CHU',
      },
      {
        long: 'Chuvash Republic',
        short: 'CU',
      },
      {
        long: 'Republic of Dagestan',
        short: 'DA',
      },
      {
        long: 'Republic of Ingushetia',
        short: 'IN',
      },
      {
        long: 'Irkutsk Oblast',
        short: 'IRK',
      },
      {
        long: 'Ivanovo Oblast',
        short: 'IVA',
      },
      {
        long: 'Jewish Autonomous Oblast',
        short: 'JEW',
      },
      {
        long: 'Kabardino-Balkar Republic',
        short: 'KB',
      },
      {
        long: 'Kaliningrad Oblast',
        short: 'KLN',
      },
      {
        long: 'Republic of Kalmykia',
        short: 'KL',
      },
      {
        long: 'Kaluga Oblast',
        short: 'KLU',
      },
      {
        long: 'Kamchatka Krai',
        short: 'KAM',
      },
      {
        long: 'Karachay-Cherkess Republic',
        short: 'KC',
      },
      {
        long: 'Republic of Karelia',
        short: 'KR',
      },
      {
        long: 'Khabarovsk Krai',
        short: 'KHA',
      },
      {
        long: 'Republic of Khakassia',
        short: 'KK',
      },
      {
        long: 'Khanty-Mansi Autonomous Okrug - Yugra',
        short: 'KHM',
      },
      {
        long: 'Kemerovo Oblast',
        short: 'KEM',
      },
      {
        long: 'Kirov Oblast',
        short: 'KIR',
      },
      {
        long: 'Komi Republic',
        short: 'KO',
      },
      {
        long: 'Kostroma Oblast',
        short: 'KOS',
      },
      {
        long: 'Krasnodar Krai',
        short: 'KDA',
      },
      {
        long: 'Krasnoyarsk Krai',
        short: 'KYA',
      },
      {
        long: 'Kurgan Oblast',
        short: 'KGN',
      },
      {
        long: 'Kursk Oblast',
        short: 'KRS',
      },
      {
        long: 'Leningrad Oblast',
        short: 'LEN',
      },
      {
        long: 'Lipetsk Oblast',
        short: 'LIP',
      },
      {
        long: 'Magadan Oblast',
        short: 'MAG',
      },
      {
        long: 'Mari El Republic',
        short: 'ME',
      },
      {
        long: 'Republic of Mordovia',
        short: 'MO',
      },
      {
        long: 'Moscow Oblast',
        short: 'MOS',
      },
      {
        long: 'Moscow',
        short: 'MOW',
      },
      {
        long: 'Murmansk Oblast',
        short: 'MU',
      },
      {
        long: 'Nenets Autonomous Okrug',
        short: 'NEN',
      },
      {
        long: 'Nizhny Novgorod Oblast',
        short: 'NIZ',
      },
      {
        long: 'Novgorod Oblast',
        short: 'NGR',
      },
      {
        long: 'Novosibirsk Oblast',
        short: 'NVS',
      },
      {
        long: 'Omsk Oblast',
        short: 'OMS',
      },
      {
        long: 'Orenburg Oblast',
        short: 'ORE',
      },
      {
        long: 'Oryol Oblast',
        short: 'ORL',
      },
      {
        long: 'Penza Oblast',
        short: 'PNZ',
      },
      {
        long: 'Perm Krai',
        short: 'PER',
      },
      {
        long: 'Primorsky Krai',
        short: 'PRI',
      },
      {
        long: 'Pskov Oblast',
        short: 'PSK',
      },
      {
        long: 'Rostov Oblast',
        short: 'ROS',
      },
      {
        long: 'Ryazan Oblast',
        short: 'RYA',
      },
      {
        long: 'Saint Petersburg',
        short: 'SPE',
      },
      {
        long: 'Sakha (Yakutia) Republic',
        short: 'SA',
      },
      {
        long: 'Sakhalin Oblast',
        short: 'SAK',
      },
      {
        long: 'Samara Oblast',
        short: 'SAM',
      },
      {
        long: 'Saratov Oblast',
        short: 'SAR',
      },
      {
        long: 'Republic of North Ossetia-Alania',
        short: 'NOA',
      },
      {
        long: 'Smolensk Oblast',
        short: 'SMO',
      },
      {
        long: 'Stavropol Krai',
        short: 'STA',
      },
      {
        long: 'Sverdlovsk Oblast',
        short: 'SVE',
      },
      {
        long: 'Tambov Oblast',
        short: 'TAM',
      },
      {
        long: 'Republic of Tatarstan',
        short: 'TA',
      },
      {
        long: 'Tomsk Oblast',
        short: 'TOM',
      },
      {
        long: 'Tuva Republic',
        short: 'TU',
      },
      {
        long: 'Tula Oblast',
        short: 'TUL',
      },
      {
        long: 'Tver Oblast',
        short: 'TVE',
      },
      {
        long: 'Tyumen Oblast',
        short: 'TYU',
      },
      {
        long: 'Udmurt Republic',
        short: 'UD',
      },
      {
        long: 'Ulyanovsk Oblast',
        short: 'ULY',
      },
      {
        long: 'Vladimir Oblast',
        short: 'VLA',
      },
      {
        long: 'Volgograd Oblast',
        short: 'VGG',
      },
      {
        long: 'Vologda Oblast',
        short: 'VLG',
      },
      {
        long: 'Voronezh Oblast',
        short: 'VOR',
      },
      {
        long: 'Yamalo-Nenets Autonomous Okrug',
        short: 'YAN',
      },
      {
        long: 'Yaroslavl Oblast',
        short: 'YAR',
      },
      {
        long: 'Zabaykalsky Krai',
        short: 'ZAB',
      },
    ],
  },
  {
    long: 'Rwanda',
    short: 'RW',
    regions: [
      {
        long: 'Kigali',
        short: '01',
      },
      {
        long: 'Eastern',
        short: '02',
      },
      {
        long: 'Northern',
        short: '03',
      },
      {
        long: 'Western',
        short: '04',
      },
      {
        long: 'Southern',
        short: '05',
      },
    ],
  },
  {
    long: 'Saint Barthélemy',
    short: 'BL',
    regions: [
      {
        long: 'Au Vent',
        short: '02',
      },
      {
        long: 'Sous le Vent',
        short: '01',
      },
    ],
  },
  {
    long: 'Saint Helena, Ascension and Tristan da Cunha',
    short: 'SH',
    regions: [
      {
        long: 'Ascension',
        short: 'AC',
      },
      {
        long: 'Saint Helena',
        short: 'HL',
      },
      {
        long: 'Tristan da Cunha',
        short: 'TA',
      },
    ],
  },
  {
    long: 'Saint Kitts and Nevis',
    short: 'KN',
    regions: [
      {
        long: 'Saint Kitts',
        short: 'K',
      },
      {
        long: 'Nevis',
        short: 'N',
      },
    ],
  },
  {
    long: 'Saint Lucia',
    short: 'LC',
    regions: [
      {
        long: 'Anse-la-Raye',
        short: '01',
      },
      {
        long: 'Canaries',
        short: '12',
      },
      {
        long: 'Castries',
        short: '02',
      },
      {
        long: 'Choiseul',
        short: '03',
      },
      {
        long: 'Dennery',
        short: '05',
      },
      {
        long: 'Gros Islet',
        short: '06',
      },
      {
        long: 'Laborie',
        short: '07',
      },
      {
        long: 'Micoud',
        short: '08',
      },
      {
        long: 'Soufriere',
        short: '10',
      },
      {
        long: 'Vieux Fort',
        short: '11',
      },
    ],
  },
  {
    long: 'Saint Martin',
    short: 'MF',
    regions: [
      {
        long: 'Saint Martin',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Saint Pierre and Miquelon',
    short: 'PM',
    regions: [
      {
        long: 'Miquelon',
        short: 'undefined',
      },
      {
        long: 'Saint Pierre',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Saint Vincent and the Grenadines',
    short: 'VC',
    regions: [
      {
        long: 'Charlotte',
        short: '01',
      },
      {
        long: 'Grenadines',
        short: '06',
      },
      {
        long: 'Saint Andrew',
        short: '02',
      },
      {
        long: 'Saint David',
        short: '03',
      },
      {
        long: 'Saint George',
        short: '04',
      },
      {
        long: 'Saint Patrick',
        short: '05',
      },
    ],
  },
  {
    long: 'Samoa',
    short: 'WS',
    regions: [
      {
        long: "A'ana",
        short: 'AA',
      },
      {
        long: 'Aiga-i-le-Tai',
        short: 'AL',
      },
      {
        long: 'Atua',
        short: 'AT',
      },
      {
        long: "Fa'asaleleaga",
        short: 'FA',
      },
      {
        long: "Gaga'emauga",
        short: 'GE',
      },
      {
        long: 'Gagaifomauga',
        short: 'GI',
      },
      {
        long: 'Palauli',
        short: 'PA',
      },
      {
        long: "Satupa'itea",
        short: 'SA',
      },
      {
        long: 'Tuamasaga',
        short: 'TU',
      },
      {
        long: "Va'a-o-Fonoti",
        short: 'VF',
      },
      {
        long: 'Vaisigano',
        short: 'VS',
      },
    ],
  },
  {
    long: 'San Marino',
    short: 'SM',
    regions: [
      {
        long: 'Acquaviva',
        short: '01',
      },
      {
        long: 'Borgo Maggiore',
        short: '06',
      },
      {
        long: 'Chiesanuova',
        short: '02',
      },
      {
        long: 'Domagnano',
        short: '03',
      },
      {
        long: 'Faetano',
        short: '04',
      },
      {
        long: 'Fiorentino',
        short: '05',
      },
      {
        long: 'Montegiardino',
        short: '08',
      },
      {
        long: 'San Marino',
        short: '07',
      },
      {
        long: 'Serravalle',
        short: '09',
      },
    ],
  },
  {
    long: 'Sao Tome and Principe',
    short: 'ST',
    regions: [
      {
        long: 'Principe',
        short: 'P',
      },
      {
        long: 'Sao Tome',
        short: 'S',
      },
    ],
  },
  {
    long: 'Saudi Arabia',
    short: 'SA',
    regions: [
      {
        long: "'Asir",
        short: '14',
      },
      {
        long: 'Al Bahah',
        short: '11',
      },
      {
        long: 'Al Hudud ash Shamaliyah',
        short: '08',
      },
      {
        long: 'Al Jawf',
        short: '12',
      },
      {
        long: 'Al Madinah al Munawwarah',
        short: '03',
      },
      {
        long: 'Al Qasim',
        short: '05',
      },
      {
        long: 'Ar Riyad',
        short: '01',
      },
      {
        long: 'Ash Sharqiyah',
        short: '04',
      },
      {
        long: "Ha'il",
        short: '06',
      },
      {
        long: 'Jazan',
        short: '09',
      },
      {
        long: 'Makkah al Mukarramah',
        short: '02',
      },
      {
        long: 'Najran',
        short: '10',
      },
      {
        long: 'Tabuk',
        short: '07',
      },
    ],
  },
  {
    long: 'Senegal',
    short: 'SN',
    regions: [
      {
        long: 'Dakar',
        short: 'DK',
      },
      {
        long: 'Diourbel',
        short: 'DB',
      },
      {
        long: 'Fatick',
        short: 'FK',
      },
      {
        long: 'Kaffrine',
        short: 'KA',
      },
      {
        long: 'Kaolack',
        short: 'KL',
      },
      {
        long: 'Kedougou',
        short: 'KE',
      },
      {
        long: 'Kolda',
        short: 'KD',
      },
      {
        long: 'Louga',
        short: 'LG',
      },
      {
        long: 'Matam',
        short: 'MT',
      },
      {
        long: 'Saint-Louis',
        short: 'SL',
      },
      {
        long: 'Sedhiou',
        short: 'SE',
      },
      {
        long: 'Tambacounda',
        short: 'TC',
      },
      {
        long: 'Thies',
        short: 'TH',
      },
      {
        long: 'Ziguinchor',
        short: 'ZG',
      },
    ],
  },
  {
    long: 'Serbia',
    short: 'RS',
    regions: [
      {
        long: 'Beograd (Belgrade)',
        short: '00',
      },
      {
        long: 'Borski',
        short: '14',
      },
      {
        long: 'Braničevski',
        short: '11',
      },
      {
        long: 'Jablanički',
        short: '23',
      },
      {
        long: 'Južnobački',
        short: '06',
      },
      {
        long: 'Južnobanatski',
        short: '04',
      },
      {
        long: 'Kolubarski',
        short: '09',
      },
      {
        long: 'Kosovski',
        short: '25',
      },
      {
        long: 'Kosovsko-Mitrovački',
        short: '28',
      },
      {
        long: 'Kosovsko-Pomoravski',
        short: '29',
      },
      {
        long: 'Mačvanski',
        short: '08',
      },
      {
        long: 'Moravički',
        short: '17',
      },
      {
        long: 'Nišavski',
        short: '20',
      },
      {
        long: 'Pčinjski',
        short: '24',
      },
      {
        long: 'Pećki',
        short: '26',
      },
      {
        long: 'Pirotski',
        short: '22',
      },
      {
        long: 'Podunavski',
        short: '10',
      },
      {
        long: 'Pomoravski',
        short: '13',
      },
      {
        long: 'Prizrenski',
        short: '27',
      },
      {
        long: 'Rasinski',
        short: '19',
      },
      {
        long: 'Raški',
        short: '18',
      },
      {
        long: 'Severnobački',
        short: '01',
      },
      {
        long: 'Severnobanatski',
        short: '03',
      },
      {
        long: 'Srednjebanatski',
        short: '02',
      },
      {
        long: 'Sremski',
        short: '07',
      },
      {
        long: 'Šumadijski',
        short: '12',
      },
      {
        long: 'Toplički',
        short: '21',
      },
      {
        long: 'Zaječarski',
        short: '15',
      },
      {
        long: 'Zapadnobački',
        short: '05',
      },
      {
        long: 'Zlatiborski',
        short: '16',
      },
    ],
  },
  {
    long: 'Seychelles',
    short: 'SC',
    regions: [
      {
        long: 'Anse aux Pins',
        short: '01',
      },
      {
        long: 'Anse Boileau',
        short: '02',
      },
      {
        long: 'Anse Etoile',
        short: '03',
      },
      {
        long: 'Anse Royale',
        short: '05',
      },
      {
        long: 'Anu Cap',
        short: '04',
      },
      {
        long: 'Baie Lazare',
        short: '06',
      },
      {
        long: 'Baie Sainte Anne',
        short: '07',
      },
      {
        long: 'Beau Vallon',
        short: '08',
      },
      {
        long: 'Bel Air',
        short: '09',
      },
      {
        long: 'Bel Ombre',
        short: '10',
      },
      {
        long: 'Cascade',
        short: '11',
      },
      {
        long: 'Glacis',
        short: '12',
      },
      {
        long: "Grand'Anse Mahe",
        short: '13',
      },
      {
        long: "Grand'Anse Praslin",
        short: '14',
      },
      {
        long: 'La Digue',
        short: '15',
      },
      {
        long: 'La Riviere Anglaise',
        short: '16',
      },
      {
        long: 'Les Mamelles',
        short: '24',
      },
      {
        long: 'Mont Buxton',
        short: '17',
      },
      {
        long: 'Mont Fleuri',
        short: '18',
      },
      {
        long: 'Plaisance',
        short: '19',
      },
      {
        long: 'Pointe La Rue',
        short: '20',
      },
      {
        long: 'Port Glaud',
        short: '21',
      },
      {
        long: 'Roche Caiman',
        short: '25',
      },
      {
        long: 'Saint Louis',
        short: '22',
      },
      {
        long: 'Takamaka',
        short: '23',
      },
    ],
  },
  {
    long: 'Sierra Leone',
    short: 'SL',
    regions: [
      {
        long: 'Eastern',
        short: 'E',
      },
      {
        long: 'Northern',
        short: 'N',
      },
      {
        long: 'Southern',
        short: 'S',
      },
      {
        long: 'Western',
        short: 'W',
      },
    ],
  },
  {
    long: 'Singapore',
    short: 'SG',
    regions: [
      {
        long: 'Central Singapore',
        short: '01',
      },
      {
        long: 'North East',
        short: '02',
      },
      {
        long: 'North West',
        short: '03',
      },
      {
        long: 'South East',
        short: '04',
      },
      {
        long: 'South West',
        short: '05',
      },
    ],
  },
  {
    long: 'Sint Maarten (Dutch part)',
    short: 'SX',
    regions: [
      {
        long: 'Sint Maarten',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Slovakia',
    short: 'SK',
    regions: [
      {
        long: 'Banskobystricky',
        short: 'BC',
      },
      {
        long: 'Bratislavsky',
        short: 'BL',
      },
      {
        long: 'Kosicky',
        short: 'KI',
      },
      {
        long: 'Nitriansky',
        short: 'NI',
      },
      {
        long: 'Presovsky',
        short: 'PV',
      },
      {
        long: 'Trenciansky',
        short: 'TC',
      },
      {
        long: 'Trnavsky',
        short: 'TA',
      },
      {
        long: 'Zilinsky',
        short: 'ZI',
      },
    ],
  },
  {
    long: 'Slovenia',
    short: 'SI',
    regions: [
      {
        long: 'Ajdovscina',
        short: '001',
      },
      {
        long: 'Apace',
        short: '195',
      },
      {
        long: 'Beltinci',
        short: '002',
      },
      {
        long: 'Benedikt',
        short: '148',
      },
      {
        long: 'Bistrica ob Sotli',
        short: '149',
      },
      {
        long: 'Bled',
        short: '003',
      },
      {
        long: 'Bloke',
        short: '150',
      },
      {
        long: 'Bohinj',
        short: '004',
      },
      {
        long: 'Borovnica',
        short: '005',
      },
      {
        long: 'Bovec',
        short: '006',
      },
      {
        long: 'Braslovce',
        short: '151',
      },
      {
        long: 'Brda',
        short: '007',
      },
      {
        long: 'Brezice',
        short: '009',
      },
      {
        long: 'Brezovica',
        short: '008',
      },
      {
        long: 'Cankova',
        short: '152',
      },
      {
        long: 'Celje',
        short: '011',
      },
      {
        long: 'Cerklje na Gorenjskem',
        short: '012',
      },
      {
        long: 'Cerknica',
        short: '013',
      },
      {
        long: 'Cerkno',
        short: '014',
      },
      {
        long: 'Cerkvenjak',
        short: '153',
      },
      {
        long: 'Cirkulane',
        short: '196',
      },
      {
        long: 'Crensovci',
        short: '015',
      },
      {
        long: 'Crna na Koroskem',
        short: '016',
      },
      {
        long: 'Crnomelj',
        short: '017',
      },
      {
        long: 'Destrnik',
        short: '018',
      },
      {
        long: 'Divaca',
        short: '019',
      },
      {
        long: 'Dobje',
        short: '154',
      },
      {
        long: 'Dobrepolje',
        short: '020',
      },
      {
        long: 'Dobrna',
        short: '155',
      },
      {
        long: 'Dobrova-Polhov Gradec',
        short: '021',
      },
      {
        long: 'Dobrovnik',
        short: '156',
      },
      {
        long: 'Dol pri Ljubljani',
        short: '022',
      },
      {
        long: 'Dolenjske Toplice',
        short: '157',
      },
      {
        long: 'Domzale',
        short: '023',
      },
      {
        long: 'Dornava',
        short: '024',
      },
      {
        long: 'Dravograd',
        short: '025',
      },
      {
        long: 'Duplek',
        short: '026',
      },
      {
        long: 'Gorenja Vas-Poljane',
        short: '027',
      },
      {
        long: 'Gorisnica',
        short: '028',
      },
      {
        long: 'Gorje',
        short: '207',
      },
      {
        long: 'Gornja Radgona',
        short: '029',
      },
      {
        long: 'Gornji Grad',
        short: '030',
      },
      {
        long: 'Gornji Petrovci',
        short: '031',
      },
      {
        long: 'Grad',
        short: '158',
      },
      {
        long: 'Grosuplje',
        short: '032',
      },
      {
        long: 'Hajdina',
        short: '159',
      },
      {
        long: 'Hoce-Slivnica',
        short: '160',
      },
      {
        long: 'Hodos',
        short: '161',
      },
      {
        long: 'Horjul',
        short: '162',
      },
      {
        long: 'Hrastnik',
        short: '034',
      },
      {
        long: 'Hrpelje-Kozina',
        short: '035',
      },
      {
        long: 'Idrija',
        short: '036',
      },
      {
        long: 'Ig',
        short: '037',
      },
      {
        long: 'Ilirska Bistrica',
        short: '038',
      },
      {
        long: 'Ivancna Gorica',
        short: '039',
      },
      {
        long: 'Izola',
        short: '040',
      },
      {
        long: 'Jesenice',
        short: '041',
      },
      {
        long: 'Jursinci',
        short: '042',
      },
      {
        long: 'Kamnik',
        short: '043',
      },
      {
        long: 'Kanal',
        short: '044',
      },
      {
        long: 'Kidricevo',
        short: '045',
      },
      {
        long: 'Kobarid',
        short: '046',
      },
      {
        long: 'Kobilje',
        short: '047',
      },
      {
        long: 'Kocevje',
        short: '048',
      },
      {
        long: 'Komen',
        short: '049',
      },
      {
        long: 'Komenda',
        short: '164',
      },
      {
        long: 'Koper',
        short: '050',
      },
      {
        long: 'Kodanjevica na Krki',
        short: '197',
      },
      {
        long: 'Kostel',
        short: '165',
      },
      {
        long: 'Kozje',
        short: '051',
      },
      {
        long: 'Kranj',
        short: '052',
      },
      {
        long: 'Kranjska Gora',
        short: '053',
      },
      {
        long: 'Krizevci',
        short: '166',
      },
      {
        long: 'Krsko',
        short: '054',
      },
      {
        long: 'Kungota',
        short: '055',
      },
      {
        long: 'Kuzma',
        short: '056',
      },
      {
        long: 'Lasko',
        short: '057',
      },
      {
        long: 'Lenart',
        short: '058',
      },
      {
        long: 'Lendava',
        short: '059',
      },
      {
        long: 'Litija',
        short: '060',
      },
      {
        long: 'Ljubljana',
        short: '061',
      },
      {
        long: 'Ljubno',
        short: '062',
      },
      {
        long: 'Ljutomer',
        short: '063',
      },
      {
        long: 'Log-Dragomer',
        short: '208',
      },
      {
        long: 'Logatec',
        short: '064',
      },
      {
        long: 'Loska Dolina',
        short: '065',
      },
      {
        long: 'Loski Potok',
        short: '066',
      },
      {
        long: 'Lovrenc na Pohorju',
        short: '167',
      },
      {
        long: 'Lukovica',
        short: '068',
      },
      {
        long: 'Luce',
        short: '067',
      },
      {
        long: 'Majsperk',
        short: '069',
      },
      {
        long: 'Makole',
        short: '198',
      },
      {
        long: 'Maribor',
        short: '070',
      },
      {
        long: 'Markovci',
        short: '168',
      },
      {
        long: 'Medvode',
        short: '071',
      },
      {
        long: 'Menges',
        short: '072',
      },
      {
        long: 'Metlika',
        short: '073',
      },
      {
        long: 'Mezica',
        short: '074',
      },
      {
        long: 'Miklavz na Dravskem Polju',
        short: '169',
      },
      {
        long: 'Miren-Kostanjevica',
        short: '075',
      },
      {
        long: 'Mirna',
        short: '212',
      },
      {
        long: 'Mirna Pec',
        short: '170',
      },
      {
        long: 'Mislinja',
        short: '076',
      },
      {
        long: 'Mokronog-Trebelno',
        short: '199',
      },
      {
        long: 'Moravce',
        short: '077',
      },
      {
        long: 'Moravske Toplice',
        short: '078',
      },
      {
        long: 'Mozirje',
        short: '079',
      },
      {
        long: 'Murska Sobota',
        short: '080',
      },
      {
        long: 'Naklo',
        short: '082',
      },
      {
        long: 'Nazarje',
        short: '083',
      },
      {
        long: 'Nova Gorica',
        short: '084',
      },
      {
        long: 'Novo Mesto',
        short: '085',
      },
      {
        long: 'Odranci',
        short: '086',
      },
      {
        long: 'Ormoz',
        short: '087',
      },
      {
        long: 'Osilnica',
        short: '088',
      },
      {
        long: 'Pesnica',
        short: '089',
      },
      {
        long: 'Piran',
        short: '090',
      },
      {
        long: 'Pivka',
        short: '091',
      },
      {
        long: 'Podcetrtek',
        short: '092',
      },
      {
        long: 'Podlehnik',
        short: '172',
      },
      {
        long: 'Podvelka',
        short: '093',
      },
      {
        long: 'Poljcane',
        short: '200',
      },
      {
        long: 'Postojna',
        short: '094',
      },
      {
        long: 'Prebold',
        short: '174',
      },
      {
        long: 'Preddvor',
        short: '095',
      },
      {
        long: 'Prevalje',
        short: '175',
      },
      {
        long: 'Ptuj',
        short: '096',
      },
      {
        long: 'Race-Fram',
        short: '098',
      },
      {
        long: 'Radece',
        short: '099',
      },
      {
        long: 'Radenci',
        short: '100',
      },
      {
        long: 'Radlje ob Dravi',
        short: '101',
      },
      {
        long: 'Radovljica',
        short: '102',
      },
      {
        long: 'Ravne na Koroskem',
        short: '103',
      },
      {
        long: 'Razkrizje',
        short: '176',
      },
      {
        long: 'Recica ob Savinji',
        short: '209',
      },
      {
        long: 'Rence-Vogrsko',
        short: '201',
      },
      {
        long: 'Ribnica',
        short: '104',
      },
      {
        long: 'Ribnica na Poboriu',
        short: '177',
      },
      {
        long: 'Rogaska Slatina',
        short: '106',
      },
      {
        long: 'Rogasovci',
        short: '105',
      },
      {
        long: 'Rogatec',
        short: '107',
      },
      {
        long: 'Ruse',
        short: '108',
      },
      {
        long: 'Salovci',
        short: '033',
      },
      {
        long: 'Selnica ob Dravi',
        short: '178',
      },
      {
        long: 'Semic',
        short: '109',
      },
      {
        long: 'Sempeter-Vrtojba',
        short: '183',
      },
      {
        long: 'Sencur',
        short: '117',
      },
      {
        long: 'Sentilj',
        short: '118',
      },
      {
        long: 'Sentjernej',
        short: '119',
      },
      {
        long: 'Sentjur',
        short: '120',
      },
      {
        long: 'Sentrupert',
        short: '211',
      },
      {
        long: 'Sevnica',
        short: '110',
      },
      {
        long: 'Sezana',
        short: '111',
      },
      {
        long: 'Skocjan',
        short: '121',
      },
      {
        long: 'Skofja Loka',
        short: '122',
      },
      {
        long: 'Skofljica',
        short: '123',
      },
      {
        long: 'Slovenj Gradec',
        short: '112',
      },
      {
        long: 'Slovenska Bistrica',
        short: '113',
      },
      {
        long: 'Slovenske Konjice',
        short: '114',
      },
      {
        long: 'Smarje pri elsah',
        short: '124',
      },
      {
        long: 'Smarjeske Toplice',
        short: '206',
      },
      {
        long: 'Smartno ob Paki',
        short: '125',
      },
      {
        long: 'Smartno pri Litiji',
        short: '194',
      },
      {
        long: 'Sodrazica',
        short: '179',
      },
      {
        long: 'Solcava',
        short: '180',
      },
      {
        long: 'Sostanj',
        short: '126',
      },
      {
        long: 'Sredisce ob Dravi',
        short: '202',
      },
      {
        long: 'Starse',
        short: '115',
      },
      {
        long: 'Store',
        short: '127',
      },
      {
        long: 'Straza',
        short: '203',
      },
      {
        long: 'Sveta Ana',
        short: '181',
      },
      {
        long: 'Sveta Trojica v Slovenskih Goricah',
        short: '204',
      },
      {
        long: 'Sveta Andraz v Slovenskih Goricah',
        short: '182',
      },
      {
        long: 'Sveti Jurij',
        short: '116',
      },
      {
        long: 'Sveti Jurij v Slovenskih Goricah',
        short: '210',
      },
      {
        long: 'Sveti Tomaz',
        short: '205',
      },
      {
        long: 'Tabor',
        short: '184',
      },
      {
        long: 'Tisina',
        short: '010',
      },
      {
        long: 'Tolmin',
        short: '128',
      },
      {
        long: 'Trbovlje',
        short: '129',
      },
      {
        long: 'Trebnje',
        short: '130',
      },
      {
        long: 'Trnovska Vas',
        short: '185',
      },
      {
        long: 'Trzin',
        short: '186',
      },
      {
        long: 'Trzic',
        short: '131',
      },
      {
        long: 'Turnisce',
        short: '132',
      },
      {
        long: 'Velenje',
        short: '133',
      },
      {
        long: 'Velika Polana',
        short: '187',
      },
      {
        long: 'Velike Lasce',
        short: '134',
      },
      {
        long: 'Verzej',
        short: '188',
      },
      {
        long: 'Videm',
        short: '135',
      },
      {
        long: 'Vipava',
        short: '136',
      },
      {
        long: 'Vitanje',
        short: '137',
      },
      {
        long: 'Vodice',
        short: '138',
      },
      {
        long: 'Vojnik',
        short: '139',
      },
      {
        long: 'Vransko',
        short: '189',
      },
      {
        long: 'Vrhnika',
        short: '140',
      },
      {
        long: 'Vuzenica',
        short: '141',
      },
      {
        long: 'Zagorje ob Savi',
        short: '142',
      },
      {
        long: 'Zavrc',
        short: '143',
      },
      {
        long: 'Zrece',
        short: '144',
      },
      {
        long: 'Zalec',
        short: '190',
      },
      {
        long: 'Zelezniki',
        short: '146',
      },
      {
        long: 'Zetale',
        short: '191',
      },
      {
        long: 'Ziri',
        short: '147',
      },
      {
        long: 'Zirovnica',
        short: '192',
      },
      {
        long: 'Zuzemberk',
        short: '193',
      },
    ],
  },
  {
    long: 'Solomon Islands',
    short: 'SB',
    regions: [
      {
        long: 'Central',
        short: 'CE',
      },
      {
        long: 'Choiseul',
        short: 'CH',
      },
      {
        long: 'Guadalcanal',
        short: 'GU',
      },
      {
        long: 'Honiara',
        short: 'CT',
      },
      {
        long: 'Isabel',
        short: 'IS',
      },
      {
        long: 'Makira-Ulawa',
        short: 'MK',
      },
      {
        long: 'Malaita',
        short: 'ML',
      },
      {
        long: 'Rennell and Bellona',
        short: 'RB',
      },
      {
        long: 'Temotu',
        short: 'TE',
      },
      {
        long: 'Western',
        short: 'WE',
      },
    ],
  },
  {
    long: 'Somalia',
    short: 'SO',
    regions: [
      {
        long: 'Awdal',
        short: 'AW',
      },
      {
        long: 'Bakool',
        short: 'BK',
      },
      {
        long: 'Banaadir',
        short: 'BN',
      },
      {
        long: 'Bari',
        short: 'BR',
      },
      {
        long: 'Bay',
        short: 'BY',
      },
      {
        long: 'Galguduud',
        short: 'GA',
      },
      {
        long: 'Gedo',
        short: 'GE',
      },
      {
        long: 'Hiiraan',
        short: 'HI',
      },
      {
        long: 'Jubbada Dhexe',
        short: 'JD',
      },
      {
        long: 'Jubbada Hoose',
        short: 'JH',
      },
      {
        long: 'Mudug',
        short: 'MU',
      },
      {
        long: 'Nugaal',
        short: 'NU',
      },
      {
        long: 'Sanaag',
        short: 'SA',
      },
      {
        long: 'Shabeellaha Dhexe',
        short: 'SD',
      },
      {
        long: 'Shabeellaha Hoose',
        short: 'SH',
      },
      {
        long: 'Sool',
        short: 'SO',
      },
      {
        long: 'Togdheer',
        short: 'TO',
      },
      {
        long: 'Woqooyi Galbeed',
        short: 'WO',
      },
    ],
  },
  {
    long: 'South Africa',
    short: 'ZA',
    regions: [
      {
        long: 'Eastern Cape',
        short: 'EC',
      },
      {
        long: 'Free State',
        short: 'FS',
      },
      {
        long: 'Gauteng',
        short: 'GT',
      },
      {
        long: 'KwaZulu-Natal',
        short: 'NL',
      },
      {
        long: 'Limpopo',
        short: 'LP',
      },
      {
        long: 'Mpumalanga',
        short: 'MP',
      },
      {
        long: 'Northern Cape',
        short: 'NC',
      },
      {
        long: 'North West',
        short: 'NW',
      },
      {
        long: 'Western Cape',
        short: 'WC',
      },
    ],
  },
  {
    long: 'South Georgia and South Sandwich Islands',
    short: 'GS',
    regions: [
      {
        long: 'Bird Island',
        short: 'undefined',
      },
      {
        long: 'Bristol Island',
        short: 'undefined',
      },
      {
        long: 'Clerke Rocks',
        short: 'undefined',
      },
      {
        long: 'Montagu Island',
        short: 'undefined',
      },
      {
        long: 'Saunders Island',
        short: 'undefined',
      },
      {
        long: 'South Georgia',
        short: 'undefined',
      },
      {
        long: 'Southern Thule',
        short: 'undefined',
      },
      {
        long: 'Traversay Islands',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'South Sudan',
    short: 'SS',
    regions: [
      {
        long: 'Central Equatoria',
        short: 'CE',
      },
      {
        long: 'Eastern Equatoria',
        short: 'EE',
      },
      {
        long: 'Jonglei',
        short: 'JG',
      },
      {
        long: 'Lakes',
        short: 'LK',
      },
      {
        long: 'Northern Bahr el Ghazal',
        short: 'BN',
      },
      {
        long: 'Unity',
        short: 'UY',
      },
      {
        long: 'Upper Nile',
        short: 'NU',
      },
      {
        long: 'Warrap',
        short: 'WR',
      },
      {
        long: 'Western Bahr el Ghazal',
        short: 'BW',
      },
      {
        long: 'Western Equatoria',
        short: 'EW',
      },
    ],
  },
  {
    long: 'Spain',
    short: 'ES',
    regions: [
      {
        long: 'Albacete',
        short: 'AB',
      },
      {
        long: 'Alicante',
        short: 'A',
      },
      {
        long: 'Almería',
        short: 'AN',
      },
      {
        long: 'Araba/Álava',
        short: 'VI',
      },
      {
        long: 'Asturias',
        short: 'O',
      },
      {
        long: 'Ávila',
        short: 'AV',
      },
      {
        long: 'Badajoz',
        short: 'BA',
      },
      {
        long: 'Barcelona',
        short: 'B',
      },
      {
        long: 'Bizkaia',
        short: 'BI',
      },
      {
        long: 'Burgos',
        short: 'BU',
      },
      {
        long: 'Cáceres',
        short: 'CC',
      },
      {
        long: 'Cádiz',
        short: 'CA',
      },
      {
        long: 'Cantabria',
        short: 'S',
      },
      {
        long: 'Castellón/Castelló',
        short: 'CS',
      },
      {
        long: 'Ceuta',
        short: 'CE',
      },
      {
        long: 'Ciudad Real',
        short: 'CR',
      },
      {
        long: 'Córdoba',
        short: 'CO',
      },
      {
        long: 'A Coruña',
        short: 'C',
      },
      {
        long: 'Cuenca',
        short: 'CU',
      },
      {
        long: 'Gipuzkoa',
        short: 'SS',
      },
      {
        long: 'Girona',
        short: 'GI',
      },
      {
        long: 'Granada',
        short: 'GR',
      },
      {
        long: 'Guadalajara',
        short: 'GU',
      },
      {
        long: 'Huelva',
        short: 'H',
      },
      {
        long: 'Huesca',
        short: 'HU',
      },
      {
        long: 'Illes Balears',
        short: 'PM',
      },
      {
        long: 'Jaén',
        short: 'J',
      },
      {
        long: 'León',
        short: 'LE',
      },
      {
        long: 'Lleida',
        short: 'L',
      },
      {
        long: 'Lugo',
        short: 'LU',
      },
      {
        long: 'Madrid',
        short: 'M',
      },
      {
        long: 'Málaga',
        short: 'MA',
      },
      {
        long: 'Melilla',
        short: 'ML',
      },
      {
        long: 'Murcia',
        short: 'MU',
      },
      {
        long: 'Navarra/Nafarroa',
        short: 'NA',
      },
      {
        long: 'Ourense',
        short: 'OR',
      },
      {
        long: 'Palencia',
        short: 'P',
      },
      {
        long: 'Las Palmas',
        short: 'GC',
      },
      {
        long: 'Pontevedra',
        short: 'PO',
      },
      {
        long: 'La Rioja',
        short: 'LO',
      },
      {
        long: 'Salamanca',
        short: 'SA',
      },
      {
        long: 'Santa Cruz de Tenerife',
        short: 'TF',
      },
      {
        long: 'Segovia',
        short: 'SG',
      },
      {
        long: 'Sevilla',
        short: 'SE',
      },
      {
        long: 'Soria',
        short: 'SO',
      },
      {
        long: 'Tarragona',
        short: 'T',
      },
      {
        long: 'Teruel',
        short: 'TE',
      },
      {
        long: 'Toledo',
        short: 'TO',
      },
      {
        long: 'Valencia/València',
        short: 'V',
      },
      {
        long: 'Valladolid',
        short: 'VA',
      },
      {
        long: 'Zamora',
        short: 'ZA',
      },
      {
        long: 'Zaragoza',
        short: 'Z',
      },
    ],
  },
  {
    long: 'Sri Lanka',
    short: 'LK',
    regions: [
      {
        long: 'Basnahira',
        short: '1',
      },
      {
        long: 'Dakunu',
        short: '3',
      },
      {
        long: 'Madhyama',
        short: '2',
      },
      {
        long: 'Naegenahira',
        short: '5',
      },
      {
        long: 'Sabaragamuwa',
        short: '9',
      },
      {
        long: 'Uturu',
        short: '4',
      },
      {
        long: 'Uturumaeda',
        short: '7',
      },
      {
        long: 'Vayamba',
        short: '6',
      },
      {
        long: 'Uva',
        short: '8',
      },
    ],
  },
  {
    long: 'Sudan',
    short: 'SD',
    regions: [
      {
        long: 'Al Bahr al Ahmar',
        short: 'RS',
      },
      {
        long: 'Al Jazirah',
        short: 'GZ',
      },
      {
        long: 'Al Khartum',
        short: 'KH',
      },
      {
        long: 'Al Qadarif',
        short: 'GD',
      },
      {
        long: 'An Nil al Abyad',
        short: 'NW',
      },
      {
        long: 'An Nil al Azraq',
        short: 'NB',
      },
      {
        long: 'Ash Shamaliyah',
        short: 'NO',
      },
      {
        long: 'Gharb Darfur',
        short: 'DW',
      },
      {
        long: 'Gharb Kurdufan',
        short: 'GK',
      },
      {
        long: 'Janub Darfur',
        short: 'DS',
      },
      {
        long: 'Janub Kurdufan',
        short: 'KS',
      },
      {
        long: 'Kassala',
        short: 'KA',
      },
      {
        long: 'Nahr an Nil',
        short: 'NR',
      },
      {
        long: 'Shamal Darfur',
        short: 'DN',
      },
      {
        long: 'Sharq Darfur',
        short: 'DE',
      },
      {
        long: 'Shiamal Kurdufan',
        short: 'KN',
      },
      {
        long: 'Sinnar',
        short: 'SI',
      },
      {
        long: 'Wasat Darfur Zalinjay',
        short: 'DC',
      },
    ],
  },
  {
    long: 'Suriname',
    short: 'SR',
    regions: [
      {
        long: 'Brokopondo',
        short: 'BR',
      },
      {
        long: 'Commewijne',
        short: 'CM',
      },
      {
        long: 'Coronie',
        short: 'CR',
      },
      {
        long: 'Marowijne',
        short: 'MA',
      },
      {
        long: 'Nickerie',
        short: 'NI',
      },
      {
        long: 'Para',
        short: 'PR',
      },
      {
        long: 'Paramaribo',
        short: 'PM',
      },
      {
        long: 'Saramacca',
        short: 'SA',
      },
      {
        long: 'Sipaliwini',
        short: 'SI',
      },
      {
        long: 'Wanica',
        short: 'WA',
      },
    ],
  },
  {
    long: 'Swaziland',
    short: 'SZ',
    regions: [
      {
        long: 'Hhohho',
        short: 'HH',
      },
      {
        long: 'Lubombo',
        short: 'LU',
      },
      {
        long: 'Manzini',
        short: 'MA',
      },
      {
        long: 'Shiselweni',
        short: 'SH',
      },
    ],
  },
  {
    long: 'Sweden',
    short: 'SE',
    regions: [
      {
        long: 'Blekinge',
        short: 'K',
      },
      {
        long: 'Dalarna',
        short: 'W',
      },
      {
        long: 'Gävleborg',
        short: 'X',
      },
      {
        long: 'Gotland',
        short: 'I',
      },
      {
        long: 'Halland',
        short: 'N',
      },
      {
        long: 'Jämtland',
        short: 'Z',
      },
      {
        long: 'Jönköping',
        short: 'F',
      },
      {
        long: 'Kalmar',
        short: 'H',
      },
      {
        long: 'Kronoberg',
        short: 'G',
      },
      {
        long: 'Norrbotten',
        short: 'BD',
      },
      {
        long: 'Örebro',
        short: 'T',
      },
      {
        long: 'Östergötland',
        short: 'E',
      },
      {
        long: 'Skåne',
        short: 'M',
      },
      {
        long: 'Södermanland',
        short: 'D',
      },
      {
        long: 'Stockholm',
        short: 'AB',
      },
      {
        long: 'Uppsala',
        short: 'C',
      },
      {
        long: 'Värmland',
        short: 'S',
      },
      {
        long: 'Västerbotten',
        short: 'AC',
      },
      {
        long: 'Västernorrland',
        short: 'Y',
      },
      {
        long: 'Västmanland',
        short: 'U',
      },
      {
        long: 'Västra Götaland',
        short: 'O',
      },
    ],
  },
  {
    long: 'Switzerland',
    short: 'CH',
    regions: [
      {
        long: 'Aargau',
        short: 'AG',
      },
      {
        long: 'Appenzell Ausserrhoden',
        short: 'AR',
      },
      {
        long: 'Appenzell Innerhoden',
        short: 'AI',
      },
      {
        long: 'Basel-Landschaft',
        short: 'BL',
      },
      {
        long: 'Basel-Stadt',
        short: 'BS',
      },
      {
        long: 'Bern',
        short: 'BE',
      },
      {
        long: 'Fribourg',
        short: 'FR',
      },
      {
        long: 'Genève',
        short: 'GE',
      },
      {
        long: 'Glarus',
        short: 'GL',
      },
      {
        long: 'Graubünden',
        short: 'GR',
      },
      {
        long: 'Jura',
        short: 'JU',
      },
      {
        long: 'Luzern',
        short: 'LU',
      },
      {
        long: 'Neuchâtel',
        short: 'NE',
      },
      {
        long: 'Nidwalden',
        short: 'NW',
      },
      {
        long: 'Obwalden',
        short: 'OW',
      },
      {
        long: 'Sankt Gallen',
        short: 'SG',
      },
      {
        long: 'Schaffhausen',
        short: 'SH',
      },
      {
        long: 'Schwyz',
        short: 'SZ',
      },
      {
        long: 'Solothurn',
        short: 'SO',
      },
      {
        long: 'Thurgau',
        short: 'TG',
      },
      {
        long: 'Ticino',
        short: 'TI',
      },
      {
        long: 'Uri',
        short: 'UR',
      },
      {
        long: 'Valais',
        short: 'VS',
      },
      {
        long: 'Vaud',
        short: 'VD',
      },
      {
        long: 'Zug',
        short: 'ZG',
      },
      {
        long: 'Zürich',
        short: 'ZH',
      },
    ],
  },
  {
    long: 'Syrian Arab Republic',
    short: 'SY',
    regions: [
      {
        long: 'Al Hasakah',
        short: 'HA',
      },
      {
        long: 'Al Ladhiqiyah',
        short: 'LA',
      },
      {
        long: 'Al Qunaytirah',
        short: 'QU',
      },
      {
        long: 'Ar Raqqah',
        short: 'RA',
      },
      {
        long: "As Suwayda'",
        short: 'SU',
      },
      {
        long: "Dar'a",
        short: 'DR',
      },
      {
        long: 'Dayr az Zawr',
        short: 'DY',
      },
      {
        long: 'Dimashq',
        short: 'DI',
      },
      {
        long: 'Halab',
        short: 'HL',
      },
      {
        long: 'Hamah',
        short: 'HM',
      },
      {
        long: 'Hims',
        short: 'HI',
      },
      {
        long: 'Idlib',
        short: 'ID',
      },
      {
        long: 'Rif Dimashq',
        short: 'RD',
      },
      {
        long: 'Tartus',
        short: 'TA',
      },
    ],
  },
  {
    long: 'Taiwan',
    short: 'TW',
    regions: [
      {
        long: 'Chang-hua',
        short: 'CHA',
      },
      {
        long: 'Chia-i',
        short: 'CYQ',
      },
      {
        long: 'Hsin-chu',
        short: 'HSQ',
      },
      {
        long: 'Hua-lien',
        short: 'HUA',
      },
      {
        long: 'Kao-hsiung',
        short: 'KHH',
      },
      {
        long: 'Keelung',
        short: 'KEE',
      },
      {
        long: 'Kinmen',
        short: 'KIN',
      },
      {
        long: 'Lienchiang',
        short: 'LIE',
      },
      {
        long: 'Miao-li',
        short: 'MIA',
      },
      {
        long: "Nan-t'ou",
        short: 'NAN',
      },
      {
        long: "P'eng-hu",
        short: 'PEN',
      },
      {
        long: 'New Taipei',
        short: 'NWT',
      },
      {
        long: "P'ing-tung",
        short: 'PING',
      },
      {
        long: "T'ai-chung",
        short: 'TXG',
      },
      {
        long: "T'ai-nan",
        short: 'TNN',
      },
      {
        long: "T'ai-pei",
        short: 'TPE',
      },
      {
        long: "T'ai-tung",
        short: 'TTT',
      },
      {
        long: "T'ao-yuan",
        short: 'TAO',
      },
      {
        long: 'Yi-lan',
        short: 'ILA',
      },
      {
        long: 'Yun-lin',
        short: 'YUN',
      },
    ],
  },
  {
    long: 'Tajikistan',
    short: 'TJ',
    regions: [
      {
        long: 'Dushanbe',
        short: 'DU',
      },
      {
        long: 'Kŭhistoni Badakhshon',
        short: 'GB',
      },
      {
        long: 'Khatlon',
        short: 'KT',
      },
      {
        long: 'Sughd',
        short: 'SU',
      },
    ],
  },
  {
    long: 'Tanzania, United Republic of',
    short: 'TZ',
    regions: [
      {
        long: 'Arusha',
        short: '01',
      },
      {
        long: 'Coast',
        short: '19',
      },
      {
        long: 'Dar es Salaam',
        short: '02',
      },
      {
        long: 'Dodoma',
        short: '03',
      },
      {
        long: 'Iringa',
        short: '04',
      },
      {
        long: 'Kagera',
        short: '05',
      },
      {
        long: 'Kigoma',
        short: '08',
      },
      {
        long: 'Kilimanjaro',
        short: '09',
      },
      {
        long: 'Lindi',
        short: '12',
      },
      {
        long: 'Manyara',
        short: '26',
      },
      {
        long: 'Mara',
        short: '13',
      },
      {
        long: 'Mbeya',
        short: '14',
      },
      {
        long: 'Morogoro',
        short: '16',
      },
      {
        long: 'Mtwara',
        short: '17',
      },
      {
        long: 'Mwanza',
        short: '18',
      },
      {
        long: 'Pemba North',
        short: '06',
      },
      {
        long: 'Pemba South',
        short: '10',
      },
      {
        long: 'Rukwa',
        short: '20',
      },
      {
        long: 'Ruvuma',
        short: '21',
      },
      {
        long: 'Shinyanga',
        short: '22',
      },
      {
        long: 'Singida',
        short: '23',
      },
      {
        long: 'Tabora',
        short: '24',
      },
      {
        long: 'Tanga',
        short: '25',
      },
      {
        long: 'Zanzibar North',
        short: '07',
      },
      {
        long: 'Zanzibar Central/South',
        short: '11',
      },
      {
        long: 'Zanzibar Urban/West',
        short: '15',
      },
    ],
  },
  {
    long: 'Thailand',
    short: 'TH',
    regions: [
      {
        long: 'Amnat Charoen',
        short: '37',
      },
      {
        long: 'Ang Thong',
        short: '15',
      },
      {
        long: 'Bueng Kan',
        short: '38',
      },
      {
        long: 'Buri Ram',
        short: '31',
      },
      {
        long: 'Chachoengsao',
        short: '24',
      },
      {
        long: 'Chai Nat',
        short: '18',
      },
      {
        long: 'Chaiyaphum',
        short: '36',
      },
      {
        long: 'Chanthaburi',
        short: '22',
      },
      {
        long: 'Chiang Mai',
        short: '50',
      },
      {
        long: 'Chiang Rai',
        short: '57',
      },
      {
        long: 'Chon Buri',
        short: '20',
      },
      {
        long: 'Chumphon',
        short: '86',
      },
      {
        long: 'Kalasin',
        short: '46',
      },
      {
        long: 'Kamphaeng Phet',
        short: '62',
      },
      {
        long: 'Kanchanaburi',
        short: '71',
      },
      {
        long: 'Khon Kaen',
        short: '40',
      },
      {
        long: 'Krabi',
        short: '81',
      },
      {
        long: 'Krung Thep Mahanakhon (Bangkok)',
        short: '10',
      },
      {
        long: 'Lampang',
        short: '52',
      },
      {
        long: 'Lamphun',
        short: '51',
      },
      {
        long: 'Loei',
        short: '42',
      },
      {
        long: 'Lop Buri',
        short: '16',
      },
      {
        long: 'Mae Hong Son',
        short: '58',
      },
      {
        long: 'Maha Sarakham',
        short: '44',
      },
      {
        long: 'Mukdahan',
        short: '49',
      },
      {
        long: 'Nakhon Nayok',
        short: '26',
      },
      {
        long: 'Nakhon Phathom',
        short: '73',
      },
      {
        long: 'Nakhon Phanom',
        short: '48',
      },
      {
        long: 'Nakhon Ratchasima',
        short: '30',
      },
      {
        long: 'Nakhon Sawan',
        short: '60',
      },
      {
        long: 'Nakhon Si Thammarat',
        short: '80',
      },
      {
        long: 'Nan',
        short: '55',
      },
      {
        long: 'Narathiwat',
        short: '96',
      },
      {
        long: 'Nong Bua Lam Phu',
        short: '39',
      },
      {
        long: 'Nong Khai',
        short: '43',
      },
      {
        long: 'Nonthaburi',
        short: '12',
      },
      {
        long: 'Pathum Thani',
        short: '13',
      },
      {
        long: 'Pattani',
        short: '94',
      },
      {
        long: 'Phangnga',
        short: '82',
      },
      {
        long: 'Phatthalung',
        short: '93',
      },
      {
        long: 'Phayao',
        short: '56',
      },
      {
        long: 'Phetchabun',
        short: '67',
      },
      {
        long: 'Phetchaburi',
        short: '76',
      },
      {
        long: 'Phichit',
        short: '66',
      },
      {
        long: 'Phitsanulok',
        short: '65',
      },
      {
        long: 'Phra Nakhon Si Ayutthaya',
        short: '14',
      },
      {
        long: 'Phrae',
        short: '54',
      },
      {
        long: 'Phuket',
        short: '83',
      },
      {
        long: 'Prachin Buri',
        short: '25',
      },
      {
        long: 'Prachuap Khiri Khan',
        short: '77',
      },
      {
        long: 'Ranong',
        short: '85',
      },
      {
        long: 'Ratchaburi',
        short: '70',
      },
      {
        long: 'Rayong',
        short: '21',
      },
      {
        long: 'Roi Et',
        short: '45',
      },
      {
        long: 'Sa Kaeo',
        short: '27',
      },
      {
        long: 'Sakon Nakhon',
        short: '47',
      },
      {
        long: 'Samut Prakan',
        short: '11',
      },
      {
        long: 'Samut Sakhon',
        short: '74',
      },
      {
        long: 'Samut Songkhram',
        short: '75',
      },
      {
        long: 'Saraburi',
        short: '19',
      },
      {
        long: 'Satun',
        short: '91',
      },
      {
        long: 'Sing Buri',
        short: '17',
      },
      {
        long: 'Si Sa ket',
        short: '33',
      },
      {
        long: 'Songkhla',
        short: '90',
      },
      {
        long: 'Sukhothai',
        short: '64',
      },
      {
        long: 'Suphan Buri',
        short: '72',
      },
      {
        long: 'Surat Thani',
        short: '84',
      },
      {
        long: 'Surin',
        short: '32',
      },
      {
        long: 'Tak',
        short: '63',
      },
      {
        long: 'Trang',
        short: '92',
      },
      {
        long: 'Trat',
        short: '23',
      },
      {
        long: 'Ubon Ratchathani',
        short: '34',
      },
      {
        long: 'Udon Thani',
        short: '41',
      },
      {
        long: 'Uthai Thani',
        short: '61',
      },
      {
        long: 'Uttaradit',
        short: '53',
      },
      {
        long: 'Yala',
        short: '95',
      },
      {
        long: 'Yasothon',
        short: '35',
      },
    ],
  },
  {
    long: 'Timor-Leste',
    short: 'TL',
    regions: [
      {
        long: 'Aileu',
        short: 'AL',
      },
      {
        long: 'Ainaro',
        short: 'AN',
      },
      {
        long: 'Baucau',
        short: 'BA',
      },
      {
        long: 'Bobonaro',
        short: 'BO',
      },
      {
        long: 'Cova Lima',
        short: 'CO',
      },
      {
        long: 'Dili',
        short: 'DI',
      },
      {
        long: 'Ermera',
        short: 'ER',
      },
      {
        long: 'Lautem',
        short: 'LA',
      },
      {
        long: 'Liquica',
        short: 'LI',
      },
      {
        long: 'Manatuto',
        short: 'MT',
      },
      {
        long: 'Manufahi',
        short: 'MF',
      },
      {
        long: 'Oecussi',
        short: 'OE',
      },
      {
        long: 'Viqueque',
        short: 'VI',
      },
    ],
  },
  {
    long: 'Togo',
    short: 'TG',
    regions: [
      {
        long: 'Centre',
        short: 'C',
      },
      {
        long: 'Kara',
        short: 'K',
      },
      {
        long: 'Maritime',
        short: 'M',
      },
      {
        long: 'Plateaux',
        short: 'P',
      },
      {
        long: 'Savannes',
        short: 'S',
      },
    ],
  },
  {
    long: 'Tokelau',
    short: 'TK',
    regions: [
      {
        long: 'Atafu',
        short: 'undefined',
      },
      {
        long: 'Fakaofo',
        short: 'undefined',
      },
      {
        long: 'Nukunonu',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Tonga',
    short: 'TO',
    regions: [
      {
        long: "'Eua",
        short: '01',
      },
      {
        long: "Ha'apai",
        short: '02',
      },
      {
        long: 'Niuas',
        short: '03',
      },
      {
        long: 'Tongatapu',
        short: '04',
      },
      {
        long: "Vava'u",
        short: '05',
      },
    ],
  },
  {
    long: 'Trinidad and Tobago',
    short: 'TT',
    regions: [
      {
        long: 'Arima',
        short: 'ARI',
      },
      {
        long: 'Chaguanas',
        short: 'CHA',
      },
      {
        long: 'Couva-Tabaquite-Talparo',
        short: 'CTT',
      },
      {
        long: 'Diefo Martin',
        short: 'DMN',
      },
      {
        long: 'Mayaro-Rio Claro',
        short: 'MRC',
      },
      {
        long: 'Penal-Debe',
        short: 'PED',
      },
      {
        long: 'Point Fortin',
        short: 'PTF',
      },
      {
        long: 'Port-of-Spain',
        short: 'POS',
      },
      {
        long: 'Princes Town',
        short: 'PRT',
      },
      {
        long: 'San Fernando',
        short: 'SFO',
      },
      {
        long: 'San Juan-Laventille',
        short: 'SJL',
      },
      {
        long: 'Sangre Grande',
        short: 'SGE',
      },
      {
        long: 'Siparia',
        short: 'SIP',
      },
      {
        long: 'Tobago',
        short: 'TOB',
      },
      {
        long: 'Tunapuna-Piarco',
        short: 'TUP',
      },
    ],
  },
  {
    long: 'Tunisia',
    short: 'TN',
    regions: [
      {
        long: 'Ariana',
        short: '12',
      },
      {
        long: 'Beja',
        short: '31',
      },
      {
        long: 'Ben Arous',
        short: '13',
      },
      {
        long: 'Bizerte',
        short: '23',
      },
      {
        long: 'Gabes',
        short: '81',
      },
      {
        long: 'Gafsa',
        short: '71',
      },
      {
        long: 'Jendouba',
        short: '32',
      },
      {
        long: 'Kairouan',
        short: '41',
      },
      {
        long: 'Kasserine',
        short: '42',
      },
      {
        long: 'Kebili',
        short: '73',
      },
      {
        long: 'Kef',
        short: '33',
      },
      {
        long: 'Mahdia',
        short: '53',
      },
      {
        long: 'Medenine',
        short: '82',
      },
      {
        long: 'Monastir',
        short: '52',
      },
      {
        long: 'Nabeul',
        short: '21',
      },
      {
        long: 'Sfax',
        short: '61',
      },
      {
        long: 'Sidi Bouzid',
        short: '43',
      },
      {
        long: 'Siliana',
        short: '34',
      },
      {
        long: 'Sousse',
        short: '51',
      },
      {
        long: 'Tataouine',
        short: '83',
      },
      {
        long: 'Tozeur',
        short: '72',
      },
      {
        long: 'Tunis',
        short: '11',
      },
      {
        long: 'Zaghouan',
        short: '22',
      },
    ],
  },
  {
    long: 'Turkey',
    short: 'TR',
    regions: [
      {
        long: 'Adana',
        short: '01',
      },
      {
        long: 'Adiyaman',
        short: '02',
      },
      {
        long: 'Afyonkarahisar',
        short: '03',
      },
      {
        long: 'Agri',
        short: '04',
      },
      {
        long: 'Aksaray',
        short: '68',
      },
      {
        long: 'Amasya',
        short: '05',
      },
      {
        long: 'Ankara',
        short: '06',
      },
      {
        long: 'Antalya',
        short: '07',
      },
      {
        long: 'Ardahan',
        short: '75',
      },
      {
        long: 'Artvin',
        short: '08',
      },
      {
        long: 'Aydin',
        short: '09',
      },
      {
        long: 'Balikesir',
        short: '10',
      },
      {
        long: 'Bartin',
        short: '74',
      },
      {
        long: 'Batman',
        short: '72',
      },
      {
        long: 'Bayburt',
        short: '69',
      },
      {
        long: 'Bilecik',
        short: '11',
      },
      {
        long: 'Bingol',
        short: '12',
      },
      {
        long: 'Bitlis',
        short: '13',
      },
      {
        long: 'Bolu',
        short: '14',
      },
      {
        long: 'Burdur',
        short: '15',
      },
      {
        long: 'Bursa',
        short: '16',
      },
      {
        long: 'Canakkale',
        short: '17',
      },
      {
        long: 'Cankiri',
        short: '18',
      },
      {
        long: 'Corum',
        short: '19',
      },
      {
        long: 'Denizli',
        short: '20',
      },
      {
        long: 'Diyarbakir',
        short: '21',
      },
      {
        long: 'Duzce',
        short: '81',
      },
      {
        long: 'Edirne',
        short: '22',
      },
      {
        long: 'Elazig',
        short: '23',
      },
      {
        long: 'Erzincan',
        short: '24',
      },
      {
        long: 'Erzurum',
        short: '25',
      },
      {
        long: 'Eskisehir',
        short: '26',
      },
      {
        long: 'Gaziantep',
        short: '27',
      },
      {
        long: 'Giresun',
        short: '28',
      },
      {
        long: 'Gumushane',
        short: '29',
      },
      {
        long: 'Hakkari',
        short: '30',
      },
      {
        long: 'Hatay',
        short: '31',
      },
      {
        long: 'Igdir',
        short: '76',
      },
      {
        long: 'Isparta',
        short: '32',
      },
      {
        long: 'Istanbul',
        short: '34',
      },
      {
        long: 'Izmir',
        short: '35',
      },
      {
        long: 'Kahramanmaras',
        short: '46',
      },
      {
        long: 'Karabuk',
        short: '78',
      },
      {
        long: 'Karaman',
        short: '70',
      },
      {
        long: 'Kars',
        short: '36',
      },
      {
        long: 'Kastamonu',
        short: '37',
      },
      {
        long: 'Kayseri',
        short: '38',
      },
      {
        long: 'Kilis',
        short: '79',
      },
      {
        long: 'Kirikkale',
        short: '71',
      },
      {
        long: 'Kirklareli',
        short: '39',
      },
      {
        long: 'Kirsehir',
        short: '40',
      },
      {
        long: 'Kocaeli',
        short: '41',
      },
      {
        long: 'Konya',
        short: '42',
      },
      {
        long: 'Kutahya',
        short: '43',
      },
      {
        long: 'Malatya',
        short: '44',
      },
      {
        long: 'Manisa',
        short: '45',
      },
      {
        long: 'Mardin',
        short: '47',
      },
      {
        long: 'Mersin',
        short: '33',
      },
      {
        long: 'Mugla',
        short: '48',
      },
      {
        long: 'Mus',
        short: '49',
      },
      {
        long: 'Nevsehir',
        short: '50',
      },
      {
        long: 'Nigde',
        short: '51',
      },
      {
        long: 'Ordu',
        short: '52',
      },
      {
        long: 'Osmaniye',
        short: '80',
      },
      {
        long: 'Rize',
        short: '53',
      },
      {
        long: 'Sakarya',
        short: '54',
      },
      {
        long: 'Samsun',
        short: '55',
      },
      {
        long: 'Sanliurfa',
        short: '63',
      },
      {
        long: 'Siirt',
        short: '56',
      },
      {
        long: 'Sinop',
        short: '57',
      },
      {
        long: 'Sirnak',
        short: '73',
      },
      {
        long: 'Sivas',
        short: '58',
      },
      {
        long: 'Tekirdag',
        short: '59',
      },
      {
        long: 'Tokat',
        short: '60',
      },
      {
        long: 'Trabzon',
        short: '61',
      },
      {
        long: 'Tunceli',
        short: '62',
      },
      {
        long: 'Usak',
        short: '64',
      },
      {
        long: 'Van',
        short: '65',
      },
      {
        long: 'Yalova',
        short: '77',
      },
      {
        long: 'Yozgat',
        short: '66',
      },
      {
        long: 'Zonguldak',
        short: '67',
      },
    ],
  },
  {
    long: 'Turkmenistan',
    short: 'TM',
    regions: [
      {
        long: 'Ahal',
        short: 'A',
      },
      {
        long: 'Asgabat',
        short: 'S',
      },
      {
        long: 'Balkan',
        short: 'B',
      },
      {
        long: 'Dashoguz',
        short: 'D',
      },
      {
        long: 'Lebap',
        short: 'L',
      },
      {
        long: 'Mary',
        short: 'M',
      },
    ],
  },
  {
    long: 'Turks and Caicos Islands',
    short: 'TC',
    regions: [
      {
        long: 'Turks and Caicos Islands',
        short: 'undefined',
      },
    ],
  },
  {
    long: 'Tuvalu',
    short: 'TV',
    regions: [
      {
        long: 'Funafuti',
        short: 'FUN',
      },
      {
        long: 'Nanumanga',
        short: 'NMG',
      },
      {
        long: 'Nanumea',
        short: 'NMA',
      },
      {
        long: 'Niutao',
        short: 'NIT',
      },
      {
        long: 'Nui',
        short: 'NUI',
      },
      {
        long: 'Nukufetau',
        short: 'NKF',
      },
      {
        long: 'Nukulaelae',
        short: 'NKL',
      },
      {
        long: 'Vaitupu',
        short: 'VAU',
      },
    ],
  },
  {
    long: 'Uganda',
    short: 'UG',
    regions: [
      {
        long: 'Abim',
        short: '317',
      },
      {
        long: 'Adjumani',
        short: '301',
      },
      {
        long: 'Amolatar',
        short: '314',
      },
      {
        long: 'Amuria',
        short: '216',
      },
      {
        long: 'Amuru',
        short: '319',
      },
      {
        long: 'Apac',
        short: '302',
      },
      {
        long: 'Arua',
        short: '303',
      },
      {
        long: 'Budaka',
        short: '217',
      },
      {
        long: 'Bududa',
        short: '223',
      },
      {
        long: 'Bugiri',
        short: '201',
      },
      {
        long: 'Bukedea',
        short: '224',
      },
      {
        long: 'Bukwa',
        short: '218',
      },
      {
        long: 'Buliisa',
        short: '419',
      },
      {
        long: 'Bundibugyo',
        short: '401',
      },
      {
        long: 'Bushenyi',
        short: '402',
      },
      {
        long: 'Busia',
        short: '202',
      },
      {
        long: 'Butaleja',
        short: '219',
      },
      {
        long: 'Dokolo',
        short: '318',
      },
      {
        long: 'Gulu',
        short: '304',
      },
      {
        long: 'Hoima',
        short: '403',
      },
      {
        long: 'Ibanda',
        short: '416',
      },
      {
        long: 'Iganga',
        short: '203',
      },
      {
        long: 'Isingiro',
        short: '417',
      },
      {
        long: 'Jinja',
        short: '204',
      },
      {
        long: 'Kaabong',
        short: '315',
      },
      {
        long: 'Kabale',
        short: '404',
      },
      {
        long: 'Kabarole',
        short: '405',
      },
      {
        long: 'Kaberamaido',
        short: '213',
      },
      {
        long: 'Kalangala',
        short: '101',
      },
      {
        long: 'Kaliro',
        short: '220',
      },
      {
        long: 'Kampala',
        short: '102',
      },
      {
        long: 'Kamuli',
        short: '205',
      },
      {
        long: 'Kamwenge',
        short: '413',
      },
      {
        long: 'Kanungu',
        short: '414',
      },
      {
        long: 'Kapchorwa',
        short: '206',
      },
      {
        long: 'Kasese',
        short: '406',
      },
      {
        long: 'Katakwi',
        short: '207',
      },
      {
        long: 'Kayunga',
        short: '112',
      },
      {
        long: 'Kibaale',
        short: '407',
      },
      {
        long: 'Kiboga',
        short: '103',
      },
      {
        long: 'Kiruhura',
        short: '418',
      },
      {
        long: 'Kisoro',
        short: '408',
      },
      {
        long: 'Kitgum',
        short: '305',
      },
      {
        long: 'Koboko',
        short: '316',
      },
      {
        long: 'Kotido',
        short: '306',
      },
      {
        long: 'Kumi',
        short: '208',
      },
      {
        long: 'Kyenjojo',
        short: '415',
      },
      {
        long: 'Lira',
        short: '307',
      },
      {
        long: 'Luwero',
        short: '104',
      },
      {
        long: 'Lyantonde',
        short: '116',
      },
      {
        long: 'Manafwa',
        short: '221',
      },
      {
        long: 'Maracha',
        short: '320',
      },
      {
        long: 'Masaka',
        short: '105',
      },
      {
        long: 'Masindi',
        short: '409',
      },
      {
        long: 'Mayuge',
        short: '214',
      },
      {
        long: 'Mbale',
        short: '209',
      },
      {
        long: 'Mbarara',
        short: '410',
      },
      {
        long: 'Mityana',
        short: '114',
      },
      {
        long: 'Moroto',
        short: '308',
      },
      {
        long: 'Moyo',
        short: '309',
      },
      {
        long: 'Mpigi',
        short: '106',
      },
      {
        long: 'Mubende',
        short: '107',
      },
      {
        long: 'Mukono',
        short: '108',
      },
      {
        long: 'Nakapiripirit',
        short: '311',
      },
      {
        long: 'Nakaseke',
        short: '115',
      },
      {
        long: 'Nakasongola',
        short: '109',
      },
      {
        long: 'Namutumba',
        short: '222',
      },
      {
        long: 'Nebbi',
        short: '310',
      },
      {
        long: 'Ntungamo',
        short: '411',
      },
      {
        long: 'Oyam',
        short: '321',
      },
      {
        long: 'Pader',
        short: '312',
      },
      {
        long: 'Pallisa',
        short: '210',
      },
      {
        long: 'Rakai',
        short: '110',
      },
      {
        long: 'Rukungiri',
        short: '412',
      },
      {
        long: 'Sembabule',
        short: '111',
      },
      {
        long: 'Sironko',
        short: '215',
      },
      {
        long: 'Soroti',
        short: '211',
      },
      {
        long: 'Tororo',
        short: '212',
      },
      {
        long: 'Wakiso',
        short: '113',
      },
      {
        long: 'Yumbe',
        short: '313',
      },
    ],
  },
  {
    long: 'Ukraine',
    short: 'UA',
    regions: [
      {
        long: 'Cherkasy',
        short: '71',
      },
      {
        long: 'Chernihiv',
        short: '74',
      },
      {
        long: 'Chernivtsi',
        short: '77',
      },
      {
        long: 'Dnipropetrovsk',
        short: '12',
      },
      {
        long: 'Donetsk',
        short: '14',
      },
      {
        long: 'Ivano-Frankivsk',
        short: '26',
      },
      {
        long: 'Kharkiv',
        short: '63',
      },
      {
        long: 'Kherson',
        short: '65',
      },
      {
        long: 'Khmelnytskyi',
        short: '68',
      },
      {
        long: 'Kiev',
        short: '32',
      },
      {
        long: 'Kirovohrad',
        short: '35',
      },
      {
        long: 'Luhansk',
        short: '09',
      },
      {
        long: 'Lviv',
        short: '46',
      },
      {
        long: 'Mykolaiv',
        short: '48',
      },
      {
        long: 'Odessa',
        short: '51',
      },
      {
        long: 'Poltava',
        short: '53',
      },
      {
        long: 'Rivne',
        short: '56',
      },
      {
        long: 'Sumy',
        short: '59',
      },
      {
        long: 'Ternopil',
        short: '61',
      },
      {
        long: 'Vinnytsia',
        short: '05',
      },
      {
        long: 'Volyn',
        short: '07',
      },
      {
        long: 'Zakarpattia',
        short: '21',
      },
      {
        long: 'Zaporizhia',
        short: '23',
      },
      {
        long: 'Zhytomyr',
        short: '18',
      },
      {
        long: 'Avtonomna Respublika Krym',
        short: '43',
      },
      {
        long: 'Kyïv',
        short: '30',
      },
      {
        long: 'Sevastopol',
        short: '40',
      },
    ],
  },
  {
    long: 'United Arab Emirates',
    short: 'AE',
    regions: [
      {
        long: 'Abu Dhabi',
        short: 'AZ',
      },
      {
        long: 'Ajman',
        short: 'AJ',
      },
      {
        long: 'Dubai',
        short: 'DU',
      },
      {
        long: 'Fujairah',
        short: 'FU',
      },
      {
        long: 'Ras al Khaimah',
        short: 'RK',
      },
      {
        long: 'Sharjah',
        short: 'SH',
      },
      {
        long: 'Umm Al Quwain',
        short: 'UQ',
      },
    ],
  },
  {
    long: 'United Kingdom',
    short: 'GB',
    regions: [
      {
        long: 'Aberdeen City',
        short: 'ABE',
      },
      {
        long: 'Aberdeenshire',
        short: 'ABD',
      },
      {
        long: 'Angus',
        short: 'ANS',
      },
      {
        long: 'Antrim and Newtownabbey',
        short: 'ANN',
      },
      {
        long: 'Ards and North Down',
        short: 'AND',
      },
      {
        long: 'Argyll and Bute',
        short: 'AGB',
      },
      {
        long: 'Armagh, Banbridge and Craigavon',
        short: 'ABC',
      },
      {
        long: 'Barking and Dagenham',
        short: 'BDG',
      },
      {
        long: 'Barnet',
        short: 'BNE',
      },
      {
        long: 'Barnsley',
        short: 'BNS',
      },
      {
        long: 'Bath and North East Somerset',
        short: 'BAS',
      },
      {
        long: 'Bedford',
        short: 'BDF',
      },
      {
        long: 'Belfast',
        short: 'BFS',
      },
      {
        long: 'Bexley',
        short: 'BEX',
      },
      {
        long: 'Birmingham',
        short: 'BIR',
      },
      {
        long: 'Blackburn with Darwen',
        short: 'BBD',
      },
      {
        long: 'Blackpool',
        short: 'BPL',
      },
      {
        long: 'Blaenau Gwent',
        short: 'BGW',
      },
      {
        long: 'Bolton',
        short: 'BOL',
      },
      {
        long: 'Bournemouth',
        short: 'BMH',
      },
      {
        long: 'Bracknell Forest',
        short: 'BRC',
      },
      {
        long: 'Bradford',
        short: 'BRD',
      },
      {
        long: 'Brent',
        short: 'BEN',
      },
      {
        long: 'Bridgend',
        short: 'BGE',
      },
      {
        long: 'Brighton and Hove',
        short: 'BNH',
      },
      {
        long: 'Bristol, City of',
        short: 'BST',
      },
      {
        long: 'Bromley',
        short: 'BRY',
      },
      {
        long: 'Buckinghamshire',
        short: 'BKM',
      },
      {
        long: 'Bury',
        short: 'BUR',
      },
      {
        long: 'Caerphilly',
        short: 'CAY',
      },
      {
        long: 'Calderdale',
        short: 'CLD',
      },
      {
        long: 'Cambridgeshire',
        short: 'CAM',
      },
      {
        long: 'Camden',
        short: 'CMD',
      },
      {
        long: 'Cardiff',
        short: 'CRF',
      },
      {
        long: 'Carmarthenshire',
        short: 'CMN',
      },
      {
        long: 'Causeway Coast and Glens',
        short: 'CCG',
      },
      {
        long: 'Central Bedfordshire',
        short: 'CBF',
      },
      {
        long: 'Ceredigion',
        short: 'CGN',
      },
      {
        long: 'Cheshire East',
        short: 'CHE',
      },
      {
        long: 'Cheshire West and Chester',
        short: 'CHW',
      },
      {
        long: 'Clackmannanshire',
        short: 'CLK',
      },
      {
        long: 'Conwy',
        short: 'CWY',
      },
      {
        long: 'Cornwall',
        short: 'CON',
      },
      {
        long: 'Coventry',
        short: 'COV',
      },
      {
        long: 'Croydon',
        short: 'CRY',
      },
      {
        long: 'Cumbria',
        short: 'CMA',
      },
      {
        long: 'Darlington',
        short: 'DAL',
      },
      {
        long: 'Denbighshire',
        short: 'DEN',
      },
      {
        long: 'Derby',
        short: 'DER',
      },
      {
        long: 'Derbyshire',
        short: 'DBY',
      },
      {
        long: 'Derry and Strabane',
        short: 'DRS',
      },
      {
        long: 'Devon',
        short: 'DEV',
      },
      {
        long: 'Doncaster',
        short: 'DNC',
      },
      {
        long: 'Dorset',
        short: 'DOR',
      },
      {
        long: 'Dudley',
        short: 'DUD',
      },
      {
        long: 'Dumfries and Galloway',
        short: 'DGY',
      },
      {
        long: 'Dundee City',
        short: 'DND',
      },
      {
        long: 'Durham County',
        short: 'DUR',
      },
      {
        long: 'Ealing',
        short: 'EAL',
      },
      {
        long: 'East Ayrshire',
        short: 'EAY',
      },
      {
        long: 'East Dunbartonshire',
        short: 'EDU',
      },
      {
        long: 'East Lothian',
        short: 'ELN',
      },
      {
        long: 'East Renfrewshire',
        short: 'ERW',
      },
      {
        long: 'East Riding of Yorkshire',
        short: 'ERY',
      },
      {
        long: 'East Sussex',
        short: 'ESX',
      },
      {
        long: 'Edinburgh, City of',
        short: 'EDH',
      },
      {
        long: 'Eilean Siar',
        short: 'ELS',
      },
      {
        long: 'Enfield',
        short: 'ENF',
      },
      {
        long: 'Essex',
        short: 'ESS',
      },
      {
        long: 'Falkirk',
        short: 'FAL',
      },
      {
        long: 'Fermanagh and Omagh',
        short: 'FMO',
      },
      {
        long: 'Fife',
        short: 'FIF',
      },
      {
        long: 'Flintshire',
        short: 'FLN',
      },
      {
        long: 'Gateshead',
        short: 'GAT',
      },
      {
        long: 'Glasgow City',
        short: 'GLG',
      },
      {
        long: 'Gloucestershire',
        short: 'GLS',
      },
      {
        long: 'Greenwich',
        short: 'GRE',
      },
      {
        long: 'Gwynedd',
        short: 'GWN',
      },
      {
        long: 'Hackney',
        short: 'HCK',
      },
      {
        long: 'Halton',
        short: 'HAL',
      },
      {
        long: 'Hammersmith and Fulham',
        short: 'HMF',
      },
      {
        long: 'Hampshire',
        short: 'HAM',
      },
      {
        long: 'Haringey',
        short: 'HRY',
      },
      {
        long: 'Harrow',
        short: 'HRW',
      },
      {
        long: 'Hartlepool',
        short: 'HPL',
      },
      {
        long: 'Havering',
        short: 'HAV',
      },
      {
        long: 'Herefordshire',
        short: 'HEF',
      },
      {
        long: 'Hertfordshire',
        short: 'HRT',
      },
      {
        long: 'Highland',
        short: 'HLD',
      },
      {
        long: 'Hillingdon',
        short: 'HIL',
      },
      {
        long: 'Hounslow',
        short: 'HNS',
      },
      {
        long: 'Inverclyde',
        short: 'IVC',
      },
      {
        long: 'Isle of Anglesey',
        short: 'AGY',
      },
      {
        long: 'Isle of Wight',
        short: 'IOW',
      },
      {
        long: 'Isles of Scilly',
        short: 'IOS',
      },
      {
        long: 'Islington',
        short: 'ISL',
      },
      {
        long: 'Kensington and Chelsea',
        short: 'KEC',
      },
      {
        long: 'Kent',
        short: 'KEN',
      },
      {
        long: 'Kingston upon Hull',
        short: 'KHL',
      },
      {
        long: 'Kingston upon Thames',
        short: 'KTT',
      },
      {
        long: 'Kirklees',
        short: 'KIR',
      },
      {
        long: 'Knowsley',
        short: 'KWL',
      },
      {
        long: 'Lambeth',
        short: 'LBH',
      },
      {
        long: 'Lancashire',
        short: 'LAN',
      },
      {
        long: 'Leeds',
        short: 'LDS',
      },
      {
        long: 'Leicester',
        short: 'LCE',
      },
      {
        long: 'Leicestershire',
        short: 'LEC',
      },
      {
        long: 'Lewisham',
        short: 'LEW',
      },
      {
        long: 'Lincolnshire',
        short: 'LIN',
      },
      {
        long: 'Lisburn and Castlereagh',
        short: 'LBC',
      },
      {
        long: 'Liverpool',
        short: 'LIV',
      },
      {
        long: 'London, City of',
        short: 'LND',
      },
      {
        long: 'Luton',
        short: 'LUT',
      },
      {
        long: 'Manchester',
        short: 'MAN',
      },
      {
        long: 'Medway',
        short: 'MDW',
      },
      {
        long: 'Merthyr Tydfil',
        short: 'MTY',
      },
      {
        long: 'Merton',
        short: 'MRT',
      },
      {
        long: 'Mid and East Antrim',
        short: 'MEA',
      },
      {
        long: 'Mid Ulster',
        short: 'MUL',
      },
      {
        long: 'Middlesbrough',
        short: 'MDB',
      },
      {
        long: 'Midlothian',
        short: 'MLN',
      },
      {
        long: 'Milton Keynes',
        short: 'MIK',
      },
      {
        long: 'Monmouthshire',
        short: 'MON',
      },
      {
        long: 'Moray',
        short: 'MRY',
      },
      {
        long: 'Neath Port Talbot',
        short: 'NTL',
      },
      {
        long: 'Newcastle upon Tyne',
        short: 'NET',
      },
      {
        long: 'Newham',
        short: 'NWM',
      },
      {
        long: 'Newport',
        short: 'NWP',
      },
      {
        long: 'Newry, Mourne and Down',
        short: 'NMD',
      },
      {
        long: 'Norfolk',
        short: 'NFK',
      },
      {
        long: 'North Ayrshire',
        short: 'NAY',
      },
      {
        long: 'North East Lincolnshire',
        short: 'NEL',
      },
      {
        long: 'North Lanarkshire',
        short: 'NLK',
      },
      {
        long: 'North Lincolnshire',
        short: 'NLN',
      },
      {
        long: 'North Somerset',
        short: 'NSM',
      },
      {
        long: 'North Tyneside',
        short: 'NTY',
      },
      {
        long: 'North Yorkshire',
        short: 'NYK',
      },
      {
        long: 'Northamptonshire',
        short: 'NTH',
      },
      {
        long: 'Northumberland',
        short: 'NBL',
      },
      {
        long: 'Nottingham',
        short: 'NGM',
      },
      {
        long: 'Nottinghamshire',
        short: 'NTT',
      },
      {
        long: 'Oldham',
        short: 'OLD',
      },
      {
        long: 'Orkney Islands',
        short: 'ORK',
      },
      {
        long: 'Oxfordshire',
        short: 'OXF',
      },
      {
        long: 'Pembrokeshire',
        short: 'PEM',
      },
      {
        long: 'Perth and Kinross',
        short: 'PKN',
      },
      {
        long: 'Peterborough',
        short: 'PTE',
      },
      {
        long: 'Plymouth',
        short: 'PLY',
      },
      {
        long: 'Poole',
        short: 'POL',
      },
      {
        long: 'Portsmouth',
        short: 'POR',
      },
      {
        long: 'Powys',
        short: 'POW',
      },
      {
        long: 'Reading',
        short: 'RDG',
      },
      {
        long: 'Redbridge',
        short: 'RDB',
      },
      {
        long: 'Redcar and Cleveland',
        short: 'RCC',
      },
      {
        long: 'Renfrewshire',
        short: 'RFW',
      },
      {
        long: 'Rhondda, Cynon, Taff',
        short: 'RCT',
      },
      {
        long: 'Richmond upon Thames',
        short: 'RIC',
      },
      {
        long: 'Rochdale',
        short: 'RCH',
      },
      {
        long: 'Rotherham',
        short: 'ROT',
      },
      {
        long: 'Rutland',
        short: 'RUT',
      },
      {
        long: 'St. Helens',
        short: 'SHN',
      },
      {
        long: 'Salford',
        short: 'SLF',
      },
      {
        long: 'Sandwell',
        short: 'SAW',
      },
      {
        long: 'Scottish Borders, The',
        short: 'SCB',
      },
      {
        long: 'Sefton',
        short: 'SFT',
      },
      {
        long: 'Sheffield',
        short: 'SHF',
      },
      {
        long: 'Shetland Islands',
        short: 'ZET',
      },
      {
        long: 'Shropshire',
        short: 'SHR',
      },
      {
        long: 'Slough',
        short: 'SLG',
      },
      {
        long: 'Solihull',
        short: 'SOL',
      },
      {
        long: 'Somerset',
        short: 'SOM',
      },
      {
        long: 'South Ayrshire',
        short: 'SAY',
      },
      {
        long: 'South Gloucestershire',
        short: 'SGC',
      },
      {
        long: 'South Lanarkshire',
        short: 'SLK',
      },
      {
        long: 'South Tyneside',
        short: 'STY',
      },
      {
        long: 'Southampton',
        short: 'STH',
      },
      {
        long: 'Southend-on-Sea',
        short: 'SOS',
      },
      {
        long: 'Southwark',
        short: 'SWK',
      },
      {
        long: 'Staffordshire',
        short: 'STS',
      },
      {
        long: 'Stirling',
        short: 'STG',
      },
      {
        long: 'Stockport',
        short: 'SKP',
      },
      {
        long: 'Stockton-on-Tees',
        short: 'STT',
      },
      {
        long: 'Stoke-on-Trent',
        short: 'STE',
      },
      {
        long: 'Suffolk',
        short: 'SFK',
      },
      {
        long: 'Sunderland',
        short: 'SND',
      },
      {
        long: 'Surrey',
        short: 'SRY',
      },
      {
        long: 'Sutton',
        short: 'STN',
      },
      {
        long: 'Swansea',
        short: 'SWA',
      },
      {
        long: 'Swindon',
        short: 'SWD',
      },
      {
        long: 'Tameside',
        short: 'TAM',
      },
      {
        long: 'Telford and Wrekin',
        short: 'TFW',
      },
      {
        long: 'Thurrock',
        short: 'THR',
      },
      {
        long: 'Torbay',
        short: 'TOB',
      },
      {
        long: 'Torfaen',
        short: 'TOF',
      },
      {
        long: 'Tower Hamlets',
        short: 'TWH',
      },
      {
        long: 'Trafford',
        short: 'TRF',
      },
      {
        long: 'Vale of Glamorgan, The',
        short: 'VGL',
      },
      {
        long: 'Wakefield',
        short: 'WKF',
      },
      {
        long: 'Walsall',
        short: 'WLL',
      },
      {
        long: 'Waltham Forest',
        short: 'WFT',
      },
      {
        long: 'Wandsworth',
        short: 'WND',
      },
      {
        long: 'Warrington',
        short: 'WRT',
      },
      {
        long: 'Warwickshire',
        short: 'WAR',
      },
      {
        long: 'West Berkshire',
        short: 'WBK',
      },
      {
        long: 'West Dunbartonshire',
        short: 'WDU',
      },
      {
        long: 'West Lothian',
        short: 'WLN',
      },
      {
        long: 'West Sussex',
        short: 'WSX',
      },
      {
        long: 'Westminster',
        short: 'WSM',
      },
      {
        long: 'Wigan',
        short: 'WGN',
      },
      {
        long: 'Wiltshire',
        short: 'WIL',
      },
      {
        long: 'Windsor and Maidenhead',
        short: 'WNM',
      },
      {
        long: 'Wirral',
        short: 'WRL',
      },
      {
        long: 'Wokingham',
        short: 'WOK',
      },
      {
        long: 'Wolverhampton',
        short: 'WLV',
      },
      {
        long: 'Worcestershire',
        short: 'WOR',
      },
      {
        long: 'Wrexham',
        short: 'WRX',
      },
      {
        long: 'York',
        short: 'YOR',
      },
    ],
  },
  {
    long: 'United States',
    short: 'US',
    regions: [
      {
        long: 'Alabama',
        short: 'AL',
      },
      {
        long: 'Alaska',
        short: 'AK',
      },
      {
        long: 'American Samoa',
        short: 'AS',
      },
      {
        long: 'Arizona',
        short: 'AZ',
      },
      {
        long: 'Arkansas',
        short: 'AR',
      },
      {
        long: 'California',
        short: 'CA',
      },
      {
        long: 'Colorado',
        short: 'CO',
      },
      {
        long: 'Connecticut',
        short: 'CT',
      },
      {
        long: 'Delaware',
        short: 'DE',
      },
      {
        long: 'District of Columbia',
        short: 'DC',
      },
      {
        long: 'Micronesia',
        short: 'FM',
      },
      {
        long: 'Florida',
        short: 'FL',
      },
      {
        long: 'Georgia',
        short: 'GA',
      },
      {
        long: 'Guam',
        short: 'GU',
      },
      {
        long: 'Hawaii',
        short: 'HI',
      },
      {
        long: 'Idaho',
        short: 'ID',
      },
      {
        long: 'Illinois',
        short: 'IL',
      },
      {
        long: 'Indiana',
        short: 'IN',
      },
      {
        long: 'Iowa',
        short: 'IA',
      },
      {
        long: 'Kansas',
        short: 'KS',
      },
      {
        long: 'Kentucky',
        short: 'KY',
      },
      {
        long: 'Louisiana',
        short: 'LA',
      },
      {
        long: 'Maine',
        short: 'ME',
      },
      {
        long: 'Marshall Islands',
        short: 'MH',
      },
      {
        long: 'Maryland',
        short: 'MD',
      },
      {
        long: 'Massachusetts',
        short: 'MA',
      },
      {
        long: 'Michigan',
        short: 'MI',
      },
      {
        long: 'Minnesota',
        short: 'MN',
      },
      {
        long: 'Mississippi',
        short: 'MS',
      },
      {
        long: 'Missouri',
        short: 'MO',
      },
      {
        long: 'Montana',
        short: 'MT',
      },
      {
        long: 'Nebraska',
        short: 'NE',
      },
      {
        long: 'Nevada',
        short: 'NV',
      },
      {
        long: 'New Hampshire',
        short: 'NH',
      },
      {
        long: 'New Jersey',
        short: 'NJ',
      },
      {
        long: 'New Mexico',
        short: 'NM',
      },
      {
        long: 'New York',
        short: 'NY',
      },
      {
        long: 'North Carolina',
        short: 'NC',
      },
      {
        long: 'North Dakota',
        short: 'ND',
      },
      {
        long: 'Northern Mariana Islands',
        short: 'MP',
      },
      {
        long: 'Ohio',
        short: 'OH',
      },
      {
        long: 'Oklahoma',
        short: 'OK',
      },
      {
        long: 'Oregon',
        short: 'OR',
      },
      {
        long: 'Palau',
        short: 'PW',
      },
      {
        long: 'Pennsylvania',
        short: 'PA',
      },
      {
        long: 'Puerto Rico',
        short: 'PR',
      },
      {
        long: 'Rhode Island',
        short: 'RI',
      },
      {
        long: 'South Carolina',
        short: 'SC',
      },
      {
        long: 'South Dakota',
        short: 'SD',
      },
      {
        long: 'Tennessee',
        short: 'TN',
      },
      {
        long: 'Texas',
        short: 'TX',
      },
      {
        long: 'Utah',
        short: 'UT',
      },
      {
        long: 'Vermont',
        short: 'VT',
      },
      {
        long: 'Virgin Islands',
        short: 'VI',
      },
      {
        long: 'Virginia',
        short: 'VA',
      },
      {
        long: 'Washington',
        short: 'WA',
      },
      {
        long: 'West Virginia',
        short: 'WV',
      },
      {
        long: 'Wisconsin',
        short: 'WI',
      },
      {
        long: 'Wyoming',
        short: 'WY',
      },
      {
        long: 'Armed Forces Americas',
        short: 'AA',
      },
      {
        long: 'Armed Forces Europe, Canada, Africa and Middle East',
        short: 'AE',
      },
      {
        long: 'Armed Forces Pacific',
        short: 'AP',
      },
    ],
  },
  {
    long: 'United States Minor Outlying Islands',
    short: 'UM',
    regions: [
      {
        long: 'Baker Island',
        short: '81',
      },
      {
        long: 'Howland Island',
        short: '84',
      },
      {
        long: 'Jarvis Island',
        short: '86',
      },
      {
        long: 'Johnston Atoll',
        short: '67',
      },
      {
        long: 'Kingman Reef',
        short: '89',
      },
      {
        long: 'Midway Islands',
        short: '71',
      },
      {
        long: 'Navassa Island',
        short: '76',
      },
      {
        long: 'Palmyra Atoll',
        short: '95',
      },
      {
        long: 'Wake Island',
        short: '79',
      },
      {
        long: 'Bajo Nuevo Bank',
        short: 'BN',
      },
      {
        long: 'Serranilla Bank',
        short: 'SB',
      },
    ],
  },
  {
    long: 'Uruguay',
    short: 'UY',
    regions: [
      {
        long: 'Artigas',
        short: 'AR',
      },
      {
        long: 'Canelones',
        short: 'CA',
      },
      {
        long: 'Cerro Largo',
        short: 'CL',
      },
      {
        long: 'Colonia',
        short: 'CO',
      },
      {
        long: 'Durazno',
        short: 'DU',
      },
      {
        long: 'Flores',
        short: 'FS',
      },
      {
        long: 'Florida',
        short: 'FD',
      },
      {
        long: 'Lavalleja',
        short: 'LA',
      },
      {
        long: 'Maldonado',
        short: 'MA',
      },
      {
        long: 'Montevideo',
        short: 'MO',
      },
      {
        long: 'Paysandú',
        short: 'PA',
      },
      {
        long: 'Río Negro',
        short: 'RN',
      },
      {
        long: 'Rivera',
        short: 'RV',
      },
      {
        long: 'Rocha',
        short: 'RO',
      },
      {
        long: 'Salto',
        short: 'SA',
      },
      {
        long: 'San José',
        short: 'SJ',
      },
      {
        long: 'Soriano',
        short: 'SO',
      },
      {
        long: 'Tacuarembó',
        short: 'TA',
      },
      {
        long: 'Treinta y Tres',
        short: 'TT',
      },
    ],
  },
  {
    long: 'Uzbekistan',
    short: 'UZ',
    regions: [
      {
        long: 'Toshkent shahri',
        short: 'TK',
      },
      {
        long: 'Andijon',
        short: 'AN',
      },
      {
        long: 'Buxoro',
        short: 'BU',
      },
      {
        long: 'Farg‘ona',
        short: 'FA',
      },
      {
        long: 'Jizzax',
        short: 'JI',
      },
      {
        long: 'Namangan',
        short: 'NG',
      },
      {
        long: 'Navoiy',
        short: 'NW',
      },
      {
        long: 'Qashqadaryo (Qarshi)',
        short: 'QA',
      },
      {
        long: 'Samarqand',
        short: 'SA',
      },
      {
        long: 'Sirdaryo (Guliston)',
        short: 'SI',
      },
      {
        long: 'Surxondaryo (Termiz)',
        short: 'SU',
      },
      {
        long: 'Toshkent wiloyati',
        short: 'TO',
      },
      {
        long: 'Xorazm (Urganch)',
        short: 'XO',
      },
      {
        long: 'Qoraqalpog‘iston Respublikasi (Nukus)',
        short: 'QR',
      },
    ],
  },
  {
    long: 'Vanuatu',
    short: 'VU',
    regions: [
      {
        long: 'Malampa',
        short: 'MAP',
      },
      {
        long: 'Pénama',
        short: 'PAM',
      },
      {
        long: 'Sanma',
        short: 'SAM',
      },
      {
        long: 'Shéfa',
        short: 'SEE',
      },
      {
        long: 'Taféa',
        short: 'TAE',
      },
      {
        long: 'Torba',
        short: 'TOB',
      },
    ],
  },
  {
    long: 'Venezuela, Bolivarian Republic of',
    short: 'VE',
    regions: [
      {
        long: 'Dependencias Federales',
        short: 'W',
      },
      {
        long: 'Distrito Federal',
        short: 'A',
      },
      {
        long: 'Amazonas',
        short: 'Z',
      },
      {
        long: 'Anzoátegui',
        short: 'B',
      },
      {
        long: 'Apure',
        short: 'C',
      },
      {
        long: 'Aragua',
        short: 'D',
      },
      {
        long: 'Barinas',
        short: 'E',
      },
      {
        long: 'Bolívar',
        short: 'F',
      },
      {
        long: 'Carabobo',
        short: 'G',
      },
      {
        long: 'Cojedes',
        short: 'H',
      },
      {
        long: 'Delta Amacuro',
        short: 'Y',
      },
      {
        long: 'Falcón',
        short: 'I',
      },
      {
        long: 'Guárico',
        short: 'J',
      },
      {
        long: 'Lara',
        short: 'K',
      },
      {
        long: 'Mérida',
        short: 'L',
      },
      {
        long: 'Miranda',
        short: 'M',
      },
      {
        long: 'Monagas',
        short: 'N',
      },
      {
        long: 'Nueva Esparta',
        short: 'O',
      },
      {
        long: 'Portuguesa',
        short: 'P',
      },
      {
        long: 'Sucre',
        short: 'R',
      },
      {
        long: 'Táchira',
        short: 'S',
      },
      {
        long: 'Trujillo',
        short: 'T',
      },
      {
        long: 'Vargas',
        short: 'X',
      },
      {
        long: 'Yaracuy',
        short: 'U',
      },
      {
        long: 'Zulia',
        short: 'V',
      },
    ],
  },
  {
    long: 'Vietnam',
    short: 'VN',
    regions: [
      {
        long: 'An Giang',
        short: '44',
      },
      {
        long: 'Bà Rịa - Vũng Tàu',
        short: '43',
      },
      {
        long: 'Bình Dương',
        short: '57',
      },
      {
        long: 'Bình Phước',
        short: '58',
      },
      {
        long: 'Bình Định',
        short: '31',
      },
      {
        long: 'Bình Thuận',
        short: '40',
      },
      {
        long: 'Bạc Liêu',
        short: '55',
      },
      {
        long: 'Bắc Giang',
        short: '54',
      },
      {
        long: 'Bắc Kạn',
        short: '53',
      },
      {
        long: 'Bắc Ninh',
        short: '56',
      },
      {
        long: 'Bến Tre',
        short: '50',
      },
      {
        long: 'Cao Bằng',
        short: '04',
      },
      {
        long: 'Cà Mau',
        short: 'undefined',
      },
      {
        long: 'Đắk Lắk',
        short: '33',
      },
      {
        long: 'Đắk Nông',
        short: '72',
      },
      {
        long: 'Điện Biên',
        short: '71',
      },
      {
        long: 'Đồng Nai',
        short: '39',
      },
      {
        long: 'Đồng Tháp',
        short: '45',
      },
      {
        long: 'Gia Lai',
        short: '30',
      },
      {
        long: 'Hà Giang',
        short: '03',
      },
      {
        long: 'Hà Nam',
        short: '63',
      },
      {
        long: 'Hà Tây',
        short: '15',
      },
      {
        long: 'Hà Tĩnh',
        short: '23',
      },
      {
        long: 'Hải Dương',
        short: '61',
      },
      {
        long: 'Hậu Giang',
        short: '73',
      },
      {
        long: 'Hòa Bình',
        short: '14',
      },
      {
        long: 'Hưng Yên',
        short: '66',
      },
      {
        long: 'Khánh Hòa',
        short: '34',
      },
      {
        long: 'Kiên Giang',
        short: '47',
      },
      {
        long: 'Kon Tum',
        short: '28',
      },
      {
        long: 'Lai Châu',
        short: '01',
      },
      {
        long: 'Lâm Đồng',
        short: '35',
      },
      {
        long: 'Lạng Sơn',
        short: '09',
      },
      {
        long: 'Lào Cai',
        short: '02',
      },
      {
        long: 'Long An',
        short: '41',
      },
      {
        long: 'Nam Định',
        short: '67',
      },
      {
        long: 'Nghệ An',
        short: '22',
      },
      {
        long: 'Ninh Bình',
        short: '18',
      },
      {
        long: 'Ninh Thuận',
        short: '36',
      },
      {
        long: 'Phú Thọ',
        short: '68',
      },
      {
        long: 'Phú Yên',
        short: '32',
      },
      {
        long: 'Quảng Bình',
        short: '24',
      },
      {
        long: 'Quảng Nam',
        short: '27',
      },
      {
        long: 'Quảng Ngãi',
        short: '29',
      },
      {
        long: 'Quảng Ninh',
        short: '13',
      },
      {
        long: 'Quảng Trị',
        short: '25',
      },
      {
        long: 'Sóc Trăng',
        short: '52',
      },
      {
        long: 'Sơn La',
        short: '05',
      },
      {
        long: 'Tây Ninh',
        short: '37',
      },
      {
        long: 'Thái Bình',
        short: '20',
      },
      {
        long: 'Thái Nguyên',
        short: '69',
      },
      {
        long: 'Thanh Hóa',
        short: '21',
      },
      {
        long: 'Thừa Thiên–Huế',
        short: '26',
      },
      {
        long: 'Tiền Giang',
        short: '46',
      },
      {
        long: 'Trà Vinh',
        short: '51',
      },
      {
        long: 'Tuyên Quang',
        short: '07',
      },
      {
        long: 'Vĩnh Long',
        short: '49',
      },
      {
        long: 'Vĩnh Phúc',
        short: '70',
      },
      {
        long: 'Yên Bái',
        short: '06',
      },
      {
        long: 'Cần Thơ',
        short: 'CT',
      },
      {
        long: 'Đà Nẵng',
        short: 'DN',
      },
      {
        long: 'Hà Nội',
        short: 'HN',
      },
      {
        long: 'Hải Phòng',
        short: 'HP',
      },
      {
        long: 'Hồ Chí Minh (Sài Gòn)',
        short: 'SG',
      },
    ],
  },
  {
    long: 'Virgin Islands, British',
    short: 'VG',
    regions: [
      {
        long: 'Anegada',
        short: 'ANG',
      },
      {
        long: 'Jost Van Dyke',
        short: 'JVD',
      },
      {
        long: 'Tortola',
        short: 'TTA',
      },
      {
        long: 'Virgin Gorda',
        short: 'VGD',
      },
    ],
  },
  {
    long: 'Virgin Islands, U.S.',
    short: 'VI',
    regions: [
      {
        long: 'St. Thomas',
        short: 'STH',
      },
      {
        long: 'St. John',
        short: 'SJO',
      },
      {
        long: 'St. Croix',
        short: 'SCR',
      },
    ],
  },
  {
    long: 'Wallis and Futuna',
    short: 'WF',
    regions: [
      {
        long: 'Alo',
        short: 'ALO',
      },
      {
        long: 'Sigave',
        short: 'SIG',
      },
      {
        long: 'Wallis',
        short: 'WAL',
      },
    ],
  },
  {
    long: 'Western Sahara',
    short: 'EH',
    regions: [
      {
        long: 'Es Smara',
        short: 'ESM',
      },
      {
        long: 'Boujdour',
        short: 'BOD',
      },
      {
        long: 'Laâyoune',
        short: 'LAA',
      },
      {
        long: 'Aousserd',
        short: 'AOU',
      },
      {
        long: 'Oued ed Dahab',
        short: 'OUD',
      },
    ],
  },
  {
    long: 'Yemen',
    short: 'YE',
    regions: [
      {
        long: 'Abyān',
        short: 'AB',
      },
      {
        long: "'Adan",
        short: 'AD',
      },
      {
        long: "Aḑ Ḑāli'",
        short: 'DA',
      },
      {
        long: "Al Bayḑā'",
        short: 'BA',
      },
      {
        long: 'Al Ḩudaydah',
        short: 'HU',
      },
      {
        long: 'Al Jawf',
        short: 'JA',
      },
      {
        long: 'Al Mahrah',
        short: 'MR',
      },
      {
        long: 'Al Maḩwīt',
        short: 'MW',
      },
      {
        long: "'Amrān",
        short: 'AM',
      },
      {
        long: 'Dhamār',
        short: 'DH',
      },
      {
        long: 'Ḩaḑramawt',
        short: 'HD',
      },
      {
        long: 'Ḩajjah',
        short: 'HJ',
      },
      {
        long: 'Ibb',
        short: 'IB',
      },
      {
        long: 'Laḩij',
        short: 'LA',
      },
      {
        long: "Ma'rib",
        short: 'MA',
      },
      {
        long: 'Raymah',
        short: 'RA',
      },
      {
        long: 'Şā‘dah',
        short: 'SD',
      },
      {
        long: "Şan‘ā'",
        short: 'SN',
      },
      {
        long: 'Shabwah',
        short: 'SH',
      },
      {
        long: 'Tā‘izz',
        short: 'TA',
      },
    ],
  },
  {
    long: 'Zambia',
    short: 'ZM',
    regions: [
      {
        long: 'Central',
        short: '02',
      },
      {
        long: 'Copperbelt',
        short: '08',
      },
      {
        long: 'Eastern',
        short: '03',
      },
      {
        long: 'Luapula',
        short: '04',
      },
      {
        long: 'Lusaka',
        short: '09',
      },
      {
        long: 'Northern',
        short: '05',
      },
      {
        long: 'North-Western',
        short: '06',
      },
      {
        long: 'Southern',
        short: '07',
      },
      {
        long: 'Western',
        short: '01',
      },
    ],
  },
  {
    long: 'Zimbabwe',
    short: 'ZW',
    regions: [
      {
        long: 'Bulawayo',
        short: 'BU',
      },
      {
        long: 'Harare',
        short: 'HA',
      },
      {
        long: 'Manicaland',
        short: 'MA',
      },
      {
        long: 'Mashonaland Central',
        short: 'MC',
      },
      {
        long: 'Mashonaland East',
        short: 'ME',
      },
      {
        long: 'Mashonaland West',
        short: 'MW',
      },
      {
        long: 'Masvingo',
        short: 'MV',
      },
      {
        long: 'Matabeleland North',
        short: 'MN',
      },
      {
        long: 'Matabeleland South',
        short: 'MS',
      },
      {
        long: 'Midlands',
        short: 'MI',
      },
    ],
  },
]
