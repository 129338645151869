import React from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, ServerStore, NotificationStore } from '../../../../stores'
import { PaginationData, SearchableParams } from '../../../../lib/types/Params'
import {
  isDirtySelectableItem,
  SelectableItem,
  ServerBackedMultiSelectAutoComplete,
} from '@mobilizeyourtech/vision-core-react'

export interface IBusinessClassifications {
  id: number
  name: string
}

type TProps = {
  serverStore?: ServerStore
  notificationStore?: NotificationStore
  selected: Array<IBusinessClassifications & SelectableItem>
  onChange: (nc: Array<IBusinessClassifications & SelectableItem>) => void
}

export const BusinessClassifications = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const getBusinessClassifications = (params: SearchableParams) => {
      return props.serverStore!.getBusinessClassifications(params).then((response) => ({
        data: response.data.map((r) => ({ ...r, label: r.name })),
        // This is a hack, since this isn't paginated
        pagination: {
          count: response.data.length,
          page: 1,
          pages: 1,
        } as PaginationData,
      }))
    }

    const getSelectedBusinessClassifications = () => {
      return props.selected.map((nc) => {
        return { ...nc, label: nc.name }
      })
    }

    return (
      <section className="BusinessClassifications" data-testid="BusinessClassifications-section">
        <ServerBackedMultiSelectAutoComplete
          loadMore={getBusinessClassifications}
          onChange={(change) =>
            // DirtySelectableItem is only possible if allowDirty were set to tru
            props.onChange(
              change.filter((e) => !isDirtySelectableItem(e)) as Array<
                IBusinessClassifications & SelectableItem
              >,
            )
          }
          selections={getSelectedBusinessClassifications()}
          placeholder={'Search and select multiple business classifications'}
          className="business-classifications"
          data-testid={'business-classifications-select'}
        />
      </section>
    )
  }),
)
