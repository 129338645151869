import { ListingFormData } from '../../stores/ListingStore'
import { validateLength, validateWebsite, validateYear } from './validation'
import { NamedServerArtifact, NamedWithLabelServerArtifact, SubPageData } from '../types'
import { PaginationParams } from '../types/Params'
import { Dispatch, SetStateAction } from 'react'
import { NotificationStore } from '../../stores'
import { ServerPageData } from '@mobilizeyourtech/vision-core-react'

export const UNCLASSIFIED_WARNING_PROMPT =
  'This system is able to handle information up to the UNCLASSIFIED level. Do not add any information or upload any documents that are classified.'

export const FILE_UPLOAD_MAX_SIZE_BYTES = 10000000 // 10MB

export const FILE_UPLOAD_MAX_SIZE_CONTENT = '10MB filesize limit'
export const uuid = () => {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const formatDate = (date: Date) => {
  return `${days[date.getDay()]}, ${
    months[date.getMonth()]
  } ${date.getDate()} ${date.getFullYear()}`
}

export const determineSocialMediaIconClassName = (name: string) => {
  switch (name.toLowerCase()) {
    case 'facebook':
      return 'fab fa-facebook'
    case 'linkedin':
      return 'fab fa-linkedin'
    case 'twitter':
      return 'fab fa-twitter-square'
    default:
      return undefined
  }
}

export const arrayContainsAny = (arr: any[], elements: any[]) => {
  return arr.some((e) => elements.includes(e))
}

export const objectIsEmpty = (obj: object) => {
  return Object.keys(obj).length === 0 && Object.values(obj).every((v) => !v)
}

export const capitalizeFirstLetter = (str: string) => {
  const l = str.toLowerCase()
  return l.charAt(0).toUpperCase() + l.slice(1)
}

export const objectContainsAnyValue = (params: {
  [key: string]: string | undefined | number | Date
}) => {
  return Object.values(params).some((value) => !!value)
}

export const titleCase = (str: string) => {
  const words = str.split(' ')
  const formats = words.map((w) => {
    return capitalizeFirstLetter(w)
  })
  return formats.join(' ')
}

export const formatUrlProtocol = (url: string): string => {
  return /^(http(s?)):\/\//i.test(url) ? url : `https://${url}`
}

export const formatNumber = (num: string | number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const isListingSubmittable = (formData: Partial<ListingFormData> | undefined): boolean => {
  if (!formData) {
    return false
  }

  let errors = [
    validateWebsite(formData.website),
    validateYear(formData.yearFounded),
    validateLength(formData.capabilityStatement, 50),
    validateLength(formData.name, 2),
    validateLength(formData.tagLine, 2),
    validateLength(formData.headquarters?.city, 2),
    validateLength(formData.headquarters?.addressLine1, 2),
    validateLength(formData.headquarters?.postalCode, 2),
  ]

  formData.socialMedia &&
    formData.socialMedia.forEach((socialAccount) => {
      if (socialAccount.identifier.length === 1) {
        errors.push(validateLength(socialAccount.identifier, 2))
      }
    })

  let validAttachments = formData.listingArtifactAttachmentsAttributes
    ? formData.listingArtifactAttachmentsAttributes?.every((v) => !!v.file)
    : true
  let validLinks = formData.listingArtifactLinksAttributes
    ? formData.listingArtifactLinksAttributes?.every((v) => !validateWebsite(v.url) && !!v.name)
    : true
  let validLogo = !!formData.logo

  if (errors.some((err) => !!err)) {
    return false
  } else if (!validAttachments || !validLinks || !validLogo) {
    return false
  }

  return [
    formData.pointOfContact,
    formData.headquarters?.stateOrProvince,
    formData.headquarters?.country,
  ].every((e) => !!e)
}

type SearchParamType<T extends NamedWithLabelServerArtifact | NamedServerArtifact> = {
  ids: string | undefined
  getDropdownData: (p: PaginationParams & { ids?: string }) => Promise<ServerPageData<T>>
  setSelected: Dispatch<SetStateAction<T[]>>
  typeForErrorMessage: string
}

export const initializeSearchDropdown = <
  T extends NamedWithLabelServerArtifact | NamedServerArtifact,
>(
  searchParams: SearchParamType<T>,
  notificationStore: NotificationStore,
) => {
  if (searchParams.ids) {
    searchParams
      .getDropdownData({
        page: 1,
        perPage: searchParams.ids?.split(',').length,
        ...searchParams,
      })
      .then((response) =>
        searchParams.setSelected(
          response.data.map((d) => {
            const label = (d as NamedWithLabelServerArtifact).label
            const name = (d as NamedServerArtifact).name

            return { ...d, label: name ? name : label }
          }),
        ),
      )
      .catch(() =>
        notificationStore?.setNotificationMessage(
          'Failed to populate ' + searchParams.typeForErrorMessage + '. Please try again later',
          'error',
          3000,
        ),
      )
  }
}

export const isSubPageData = <Type>(o: any): o is SubPageData<Type> => {
  return 'data' in o && 'count' in o && 'path' in o
}

export const generateRandomId = () => Math.random().toString(36).substring(2, 12)
