import { CCard, CCardBody, CCardTitle, CCol, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { ApplicationStore, ListingStore, NotificationStore, UserData } from '../../stores'
import { inject, observer } from 'mobx-react'
import { SocialMediaAccount } from '../../stores/UserStore'
import { determineSocialMediaIconClassName } from '../../lib/helpers/utils'
import { Avatar } from '@mobilizeyourtech/vision-core-react'
import { extractRestfulError } from '../../lib/errors/utils'

type TProps = {
  listingId: number | string
  notificationStore?: NotificationStore
  listingStore?: ListingStore
}
export const PointOfContactCard = inject(
  ApplicationStore.names.listingStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const [userData, setUserData] = useState<UserData | undefined>(undefined)
    const [error, setError] = useState<string | undefined>(undefined)

    useEffect(() => {
      props.listingStore
        ?.getPointOfContact(props.listingId)
        .then((data) => {
          setUserData(data)
        })
        .catch((error) => {
          let errorResponse = extractRestfulError(error)
          setError(errorResponse?.error ? errorResponse.error : 'UnhandledError')

          if (errorResponse?.error !== 'NoUserPointOfContact') {
            props.notificationStore?.setNotificationMessage(
              'Failed to load primary contact data. Please try again.',
              'danger',
              3000,
            )
          }
        })
    }, [props.listingId])

    const renderSocialMediaAccount = (sma: SocialMediaAccount) => {
      return (
        <CRow key={sma.id} data-testid="">
          <CCol>
            <i className={determineSocialMediaIconClassName(sma.socialMediaTypeName)} />
            <span>{sma.identifier}</span>
          </CCol>
        </CRow>
      )
    }

    const renderUserData = (data: UserData) => {
      return (
        <>
          <CRow className={'mb-2'}>
            <CCol>
              <strong>
                {data.firstName} {data.lastName}
              </strong>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CRow className="mb-2">
                <CCol>
                  <i className={'fas fa-shield-alt'} />
                  <span>{data.title}</span>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <i className={'far fa-envelope'} />
                  <span>{data.email}</span>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <i className={'fas fa-mobile-alt'} />
                  <span>{data.cellPhone}</span>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <i className={'fas fa-phone-alt'} />
                  <span>{formatWorkPhone(data)}</span>
                </CCol>
              </CRow>
            </CCol>
            <CCol>
              <CRow className="mt-2">{data.socialMediaAccounts.map(renderSocialMediaAccount)}</CRow>
            </CCol>
          </CRow>
        </>
      )
    }

    const formatWorkPhone = (data: UserData): string | undefined => {
      let result

      if (!data.workPhone) {
        result = undefined
      } else if (!data.workPhoneExt) {
        result = data.workPhone
      } else if (data.workPhoneExt.toLowerCase().includes('x')) {
        result = `${data.workPhone} ${data.workPhoneExt}`
      } else {
        result = `${data.workPhone} x${data.workPhoneExt}`
      }

      return result
    }

    const renderError = (error: string) => {
      if (error === 'NoUserPointOfContact') {
        return <p className="subtle">No Point of Contact Data</p>
      }

      return <p className="subtle">Error loading data</p>
    }

    return (
      <CCard className="listing-card">
        <CCardBody>
          <CCardTitle className="bold-text d-flex align-items-center">
            {userData && (
              <Avatar
                className="me-2"
                prefix={userData.firstName}
                suffix={userData.lastName}
                src={userData.headshot?.path}
              />
            )}
            Primary Contact
          </CCardTitle>
          {userData && renderUserData(userData)}
          {error && renderError(error)}
        </CCardBody>
      </CCard>
    )
  }),
)
