import { CCard, CCardText, CCol, CLink, CRow } from '@coreui/react'
import {
  isAttachment,
  isLink,
  ListingArtifactAttachment,
  ListingArtifactLink,
} from '../../stores/ListingStore'
import { fileIconLookup, formatUrlProtocol } from '@mobilizeyourtech/vision-core-react'

type TProps = {
  data?: ListingArtifactAttachment | ListingArtifactLink
}
export const ViewListingArtifactAttachmentOrLink = (props: TProps) => {
  return (
    <CCard data-testid="listing-artifact-or-link" className="listing-card p-2 mt-3 rounded">
      <CRow className="mt-2 justify-content-md-start px-2">
        {props.data && isLink(props.data) && (
          <CLink
            className="artifact-link"
            target="_blank"
            href={props.data.url && formatUrlProtocol(props.data.url)}
          >
            {props.data.name}
          </CLink>
        )}
        {props.data && isAttachment(props.data) && (
          <CRow className="d-flex align-items-center">
            <CCol className="attachment-image" md={2}>
              <CLink href={props.data.file.path} download>
                {fileIconLookup(props.data.file.mimeType, '#005CB9')}
              </CLink>
            </CCol>
            <CCol md={10}>
              <CLink className="artifact-link" href={props.data.file.path} download>
                {props.data?.name}
              </CLink>
            </CCol>
          </CRow>
        )}
        <CRow className="pt-2">
          <CCardText>{props.data?.description}</CCardText>
        </CRow>
      </CRow>
    </CCard>
  )
}
