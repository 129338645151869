import { PieChart, Pie, Cell, Legend } from 'recharts'

export type IgnitePieChartPlaceHolderProps = {
  name: string
}

export const IgnitePieChartPlaceHolder = (props: IgnitePieChartPlaceHolderProps) => {
  const colors = {
    strokes: '#ECECEC',
    unclickable: '#A2A2A2',
  }

  const data = [{ name: 'No Results', value: 1200, fill: colors.strokes }]

  const style = {
    top: '50%',
    transform: 'translate(40%, -30%)',
    lineHeight: '30px',
    left: '66%',
  }

  const formatLegend = (value: string) => {
    return (
      !parseInt(value) && (
        <span style={{ color: colors.unclickable, padding: '10px' }}>{value}</span>
      )
    )
  }

  return (
    <div data-testid={`${props.name}-empty`}>
      <PieChart height={175} width={300}>
        <Pie
          dataKey="value"
          data={data}
          cy="50%"
          cx="75%"
          outerRadius={80}
          innerRadius={60}
          paddingAngle={-10}
          isAnimationActive={true}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={colors.strokes} />
          ))}
        </Pie>
        <Legend
          iconType={'circle'}
          iconSize={20}
          layout="vertical"
          verticalAlign="middle"
          align={'right'}
          wrapperStyle={style}
          formatter={formatLegend}
        />
      </PieChart>
    </div>
  )
}
