import { RestfulMessage } from '../types'

export const extractRestfulError = (error: any): RestfulMessage | undefined => {
  if (error?.response?.data) {
    return error.response.data
  }
  return undefined
}

export class VisionRequestInterceptedError extends Error {}
