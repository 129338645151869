/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-comment-textnodes */
import { useState } from 'react'
import {
  OptionalImage,
  formatUrlProtocol,
  parseDate,
  SimpleIconButton,
} from '@mobilizeyourtech/vision-core-react'
import {
  isUseCase,
  ListingArtifactAttachment,
  ListingArtifactLink,
  ListingArtifactTechnologyType,
  ListingServiceProductData,
  ListingUseCaseData,
} from '../../stores/ListingStore'
import {
  CCardHeader,
  CCardLink,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react'
import '../../scss/views/listings/custom-modal.scss'
import { ViewListingArtifactAttachmentOrLink } from './ViewListingArtifactAttachmentOrLink'
import { ViewListingArtifactTechnologyType } from './ViewListingArtifactTechnologyType'

type TProps = {
  data: ListingServiceProductData | ListingUseCaseData
  artifactType: string
  handleEditClick: (artifactId: string | number) => void
}

export const ViewListingArtifact = (props: TProps) => {
  const [activeKey, setActiveKey] = useState<number>(1)
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        data-testid="artifact-modal"
      >
        <div className="header-content d-flex w-100 px-3 pb-3 align-items-center text-center justify-content-between">
          <OptionalImage src={props.data.logo?.path} className="service-logo" />
          <h4 className="service-name pt-3">{props.data.name}</h4>
          <SimpleIconButton
            className="icon-container"
            data-testid={`edit-${props.artifactType}-button`}
            onInvoke={() => props.handleEditClick(props.data.id)}
            iconClassName="fas fa-pen"
          />
        </div>
        {props.data.url && (
          <div className="header-url">
            <CCardLink
              className="pb-4"
              target="_blank"
              href={formatUrlProtocol(props.data.url)}
              rel="noreferrer"
            >
              {props.data.url.replace(/^https?:\/\//, '')}
            </CCardLink>
          </div>
        )}
      </div>
      <div className="px-4 pb-5">
        <p className="pb-3">{props.data.description}</p>
        {isUseCase(props.data) && (
          <CCardHeader className="dates d-flex w-100 align-content-center justify-content-between mb-4">
            <p>
              <span className="bold-label">Start Date:</span>{' '}
              {props.data.startDate ? (
                parseDate(props.data.startDate)
              ) : (
                <span className="grey-label">N/A</span>
              )}
            </p>
            <p>
              <span className="bold-label">End Date:</span>{' '}
              {props.data.endDate ? (
                parseDate(props.data.endDate)
              ) : (
                <span className="grey-label">N/A</span>
              )}
            </p>
          </CCardHeader>
        )}
        <CNav variant="tabs" role="tablist">
          <>
            <CNavItem>
              <CNavLink active={activeKey === 1} onClick={() => setActiveKey(1)}>
                Attachments
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink active={activeKey === 2} onClick={() => setActiveKey(2)}>
                Links
              </CNavLink>
            </CNavItem>
            {!isUseCase(props.data) && (
              <CNavItem>
                <CNavLink active={activeKey === 3} onClick={() => setActiveKey(3)}>
                  Technology Types
                </CNavLink>
              </CNavItem>
            )}
          </>
        </CNav>
        <CTabContent>
          <CTabPane className="pt-3" visible={activeKey === 1}>
            {props.data.listingArtifactAttachments &&
              props.data.listingArtifactAttachments.length < 1 && (
                <p className="py-5 text-center">{`Attachments not available for this ${
                  props.artifactType === 'useCase'
                    ? props.artifactType.replace('useCase', 'use case')
                    : props.artifactType
                }.`}</p>
              )}
            {props.data.listingArtifactAttachments?.map((attachment: ListingArtifactAttachment) => (
              <ViewListingArtifactAttachmentOrLink key={attachment.id} data={attachment} />
            ))}
          </CTabPane>
          <CTabPane className="pt-2" visible={activeKey === 2}>
            {props.data.listingArtifactLinks && props.data.listingArtifactLinks.length < 1 && (
              <p className="py-5 text-center">{`Links not available for this ${props.artifactType}.`}</p>
            )}
            {props.data.listingArtifactLinks?.map((link: ListingArtifactLink) => (
              <ViewListingArtifactAttachmentOrLink key={link.id} data={link} />
            ))}
          </CTabPane>
          {!isUseCase(props.data) && (
            <CTabPane className="pt-3" visible={activeKey === 3}>
              {props.data.listingArtifactTechnologyTypes &&
                props.data.listingArtifactTechnologyTypes.length < 1 && (
                  <p className="py-5 text-center">{`Technology types not available for this ${props.artifactType}.`}</p>
                )}
              {props.data.listingArtifactTechnologyTypes?.map(
                (techType: ListingArtifactTechnologyType) => (
                  <ViewListingArtifactTechnologyType
                    key={techType.technologyTypeId}
                    data={techType}
                  />
                ),
              )}
            </CTabPane>
          )}
        </CTabContent>
      </div>
    </>
  )
}
