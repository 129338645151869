export const activeStatusIcon = (className) => {
  return className === 'tooltip' ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 17" fill="none">
      <circle cx="8" cy="8.5" r="6.81818" fill="#0D6EFD" stroke="white" strokeWidth="1.63636" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <circle
        cx="7.67712"
        cy="8.5"
        r="6.81818"
        fill="#0D6EFD"
        stroke="white"
        strokeWidth="1.63636"
      />
      <circle cx="7.6814" cy="8.50427" r="3" fill="white" />
    </svg>
  )
}
